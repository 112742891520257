<template>
  <div
    class="d-flex flex-row justify-content-center align-items-center"
    @click="$emit('click')"
  >
    <svg
      height="2em"
      width="2em"
      viewBox="0 0 512 512"
    >
      <path
        v-if="checked===true"
        fill="currentcolor"
        d="M405.333 64H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333V106.667C448 83.198 428.802 64 405.333 64zm-192 298.667L106.667 256l29.864-29.864 76.802 76.802 162.136-162.136 29.864 29.865-192 192z"
      >
      </path>
      <path
        v-if="checked===false || checked===undefined"
        fill="currentcolor"
        d="M405.333 106.667v298.666H106.667V106.667h298.666m0-42.667H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333V106.667C448 83.198 428.802 64 405.333 64z"
      ></path>

      <path
        v-if="checked==='waitServer'"
        fill="currentcolor"
        d="M256 224c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM128.4 224c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.4-32-32-32zM384 224c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32z"
      >
      </path>

    </svg>

  </div>
</template>

<script>
export default {
  props: {
    checked: { type: [Boolean, String /* to allow the value "waitServer" */] }
  }
};
</script>

<style>
</style>

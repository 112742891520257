export function dateParts(dateIsoStringOrUnixTime) {
  // 14:30 Thu
  // 31 Dec 18

  const date = new Date(dateIsoStringOrUnixTime);
  const locale = "en-UK";
  const out = {};
  out.t = date.toLocaleTimeString(locale, { hour12: false });
  out.hhmm = out.t.slice(0, 5);
  out.weekday = date.toLocaleDateString(locale, { weekday: "short" });
  out.date = date.toLocaleDateString(locale, { day: "2-digit" });
  out.month = date.toLocaleDateString("en-UK", { month: "short" });
  out.dateMonth = out.date + " " + out.month;
  out.year = date.toLocaleDateString("en-UK", { year: "numeric" });
  return out;
}

export function dateTwoTier(dateIsoStringOrUnixTime) {
  const parts = dateParts(dateIsoStringOrUnixTime);
  const upperTier = parts.t + " " + parts.weekday;
  const lowerTier = parts.dateMonth + " " + parts.year;
  const output = upperTier + "<br>" + lowerTier;
  return output;
}

export function isoFromReferralId(referralId) {
  // Format 1. ref~2018-12-18_21:24:55p608~ef3252c5
  // Format 2. ref~2018-12-30@09-10-28p635~bd959170
  const datePart = referralId.slice(4, 14);
  const hmsPart = referralId.slice(15, 23).replace(/-/g, ":");
  const msPart = referralId.slice(24, 27);
  const iso = datePart + "T" + hmsPart + "." + msPart + "Z";
  return iso;
}

export function timeAgoHumanFromIso(isoString) {
  const timeAgoTwoTier = this.timeAgoTwoTierFromIso(isoString);
  return timeAgoTwoTier.n + " " + timeAgoTwoTier.units;
}

export function timeAgoTwoTierFromIso(isoString) {
  const ms = this.timeAgoMsFromIso(isoString);
  const s = ms / 1000;
  const m = s / 60;
  const h = m / 60;
  const d = h / 24;
  if (d > 2) return { n: Math.round(d), units: "days" };
  if (h > 3) return { n: Math.round(h), units: "hours" };
  return { n: Math.round(m), units: "min" };
}

export function timeAgoMsFromIso(isoString) {
  return new Date() - new Date(isoString);
}

export function isoIsMoreThanNDaysAgo(isoString, days = 300) {
  const oldDate = new Date(isoString);
  const nowDate = new Date();
  const diffMs = nowDate - oldDate;
  const oneDayMs = 1000 * 60 * 60 * 24;
  return diffMs > days * oneDayMs;
}

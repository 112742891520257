<template>
  <div
    class="px-2 pt-2 d-flex flex-column justify-content-between"
    style="height: 100vh"
  >
    <app-header-bar title="Randomize a patient"></app-header-bar>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('enroltrial_teleacs')"
    >
      <svg class="ion" style="height: 60px; width: 60px">
        <use xlink:href="#ion-barcode" />
      </svg>
      <h4 style="width: 60%">Tele-ACS</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('enroltrial_teletavi')"
    >
      <svg class="ion" style="height: 60px; width: 60px">
        <use xlink:href="#ion-barcode" />
      </svg>
      <h4 style="width: 60%">Tele-TAVI</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <home-pages-bar from="researcher" :user="user"></home-pages-bar>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import HomePagesBar from "./HomePagesBar.vue";

export default {
  components: { AppHeaderBar, HomePagesBar },

  props: {
    user: { type: Object, required: true },
  },

  created() {
    this.$emit("checkUserGivenEnoughInfo");
    this.user.contactRef.update({ lastHomePage: "researcher" });
  },

  methods: {
    goPage(pageName) {
      this.$router.push({ name: pageName });
    },
  },
};
</script>

<style></style>

<template>
  <div
    style="height: 100vh"
    class="p-2 d-flex flex-column justify-content-between"
  >
    <app-header-bar title="Appoint Managers"></app-header-bar>

    <section id="middleScrolling" style="overflow-y: auto; flex-grow: 1">
      <div
        v-if="
          !user.privilegesIsLoaded ||
          (user.privilegesIsLoaded &&
            user.privilegesObj.managementPathStrings.length > 0 &&
            myTrees.length === 0)
        "
      >
        <Spinner></Spinner>
      </div>

      <div
        v-if="
          user.privilegesIsLoaded && !user.privilegesObj.managementPathStrings
        "
      >
        <b-alert show variant="danger">
          <h4>No management privileges</h4>
          <h6>
            Ask your manager to enable your management privileges. If you are
            the lead research manager for the Trust, ask FoundBy.Me headquarters
            to do so.
          </h6>
        </b-alert>
      </div>

      <!-- <div v-if="user.privilegesIsLoaded && Object.keys(user.privilegesObj.managementPathStrings || {}).length>0">
        <h4>Multiple manager regions</h4>
        <p>You lead more than one management tree, e.g. two departments or two Trusts. Manage each region separately.</p>
      </div>-->

      <h4>Editing your management team</h4>
      <p>
        Click on an oval, representing a management level, to give management
        privileges to other managers.
      </p>

      <article v-for="(tree, treeKey) in myTrees" :key="treeKey">
        <div
          class="p-2 mt-4 bg-white text-primary"
          style="border-radius: 0.5em 0.5em 0 0"
        >
          <h5>
            {{ deFbmUk(tree.pathStringToMyTree) }}
          </h5>

          <!-- <sl-vue-tree
            v-model="user.privilegesObj.managementPathStrings[treeKey]"
            :ref="'slVueTree_'+treeKey"
            @select="dummyFunctionToPreventDefault"
            @nodeclick="dummyFunctionToPreventDefault"
            @nodedblclick="dummyFunctionToPreventDefault"
            @toggle="dummyFunctionToPreventDefault"
            @drop="dummyFunctionToPreventDefault"
            @nodecontextmenu="dummyFunctionToPreventDefault"
            @externaldrop="dummyFunctionToPreventDefault"
            :allow-multiselect="false"
          >
          </sl-vue-tree>-->
        </div>

        <sl-vue-tree v-model="tree.myTreeAsNodeModel" ref="tree.ref">
          <template slot="toggle">{{
            "" /* To get rid of the little "-" / "+" at the left*/
          }}</template>

          <template slot="title" slot-scope="{ node }">
            <!--

The Absolute path of the thing you click on is composed of:

The absolute path of my current tree within the master management tree:
   tree.pathNumericalToMyTree

followed by the relative path within this tree (deleting the initial "0" because this tree always has only one item at its root)
   node.path.slice(1)

            -->

            <span
              @click="
                onManageManagersNode(
                  node,
                  pathStringFromPathNumerical(
                    tree.pathNumericalToMyTree.concat(node.path.slice(1))
                  )
                )
              "
            >
              <span class="node--tabbing--monospaced-font">
                {{ tabbing(node, tree.ref) }}
                <span v-if="!node.isLastChild">├</span>
                <span v-if="node.isLastChild && node.path.length !== 1">╰</span>
                <span v-if="node.isLastChild && node.path.length === 1"
                  >&nbsp;</span
                >
              </span>
              <span
                :class="{
                  'node--title--is-logical': !node.isLeaf,
                  blinking: node.isSelected,
                  highlighted: node.isSelected,
                }"
                >{{ node.title }}</span
              >
              &nbsp;
              <span style="font-size: 70%">{{
                managerCountString(node.data.n || 0)
              }}</span>
            </span>
          </template>
        </sl-vue-tree>
      </article>

      <div id="globalManagementTree" style="display: none">
        <!--
    This is the only way I know of creating an SLVUETREE instance we can traverse, to store the MASTER management tree
        -->
        THIS NEEDS TO EXIST, but does not need to be displayed
        <sl-vue-tree
          v-model="managementTreeNodeModel"
          ref="managementSlVueTree"
        ></sl-vue-tree>
      </div>
    </section>

    <!-- <section
      id="bottomBar"
      class="mt-2 d-flex justify-content-between"
      style="flex-shrink:0;"
    >
      ## bottom ##
    </section>-->
  </div>
</template>

<script>
import SlVueTree from "sl-vue-tree";
import {
  getManagementPeersAndSubordinatesInData,
  managementTreeRef,
} from "../util/firebase";
import { tabbing } from "../util/tabbing.js";
import Spinner from "./ext/Spinner.vue";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { SlVueTree, Spinner, AppHeaderBar },
  props: { user: {} },
  data() {
    return {
      // Actual Data
      myTrees: [],
      managementTreeNodeModel: [],

      // Making global consts and functions available to THIS
      tabbing,
    };
  },
  computed: {},
  created() {
    this.refreshDisplay();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    async refreshDisplay() {
      // 1. Get list of pathStrings at which I have authority

      this.pathStringToMyTreesArray = Object.values(
        // Guarantee to become an array, even if starts as object or null
        (
          await this.user.privilegesRef
            .child("managementPathStrings")
            .once("value")
        ).val() || {}
      );

      // 2. Ask firebase functions for list of all people who are attached at or below my level, in ANY of my pathStrings
      await getManagementPeersAndSubordinatesInData()
        .then((response) => {
          this.mySubordinatesArray = response.data;
        })
        .catch((e) => console.error(e));

      // 3. Get Master Management Tree of whole universe
      this.managementTreeNodeModel = (
        await managementTreeRef.once("value")
      ).val();

      // 4. PAUSE (wait for next tick) - vital to allow the above to all catch up. Otherwise your trees don't get populated
      await new Promise((resolve) => setTimeout(() => resolve(), 1000));

      // 5. Populate the "data" properties of Management Tree, the details of all MY (not everyone in the world's) subordinates and colleagues attached at that point, so we can look them up later.

      this.$refs.managementSlVueTree.traverse((node) => {
        const pathString = this.pathStringFromPathNumerical(node.path);
        this.mySubordinatesArray.forEach((subordinateObject) => {
          if (subordinateObject.pathString === pathString) {
            node.data = node.data || {};
            node.data.n = node.data.n || 0;
            node.data.n = node.data.n + 1;
            // node.data.ids = node.data.ids || [];
            // node.data.ids.push(subordinateObject.id);
            node.data.people = node.data.people || [];
            node.data.people.push(subordinateObject);

            this.$refs.managementSlVueTree.updateNode(node.path, node);
          }
        });
      });

      // 6. Make the MYTREES array. Each element is an object indicating what (numerical) path in the master management tree, and what pathString, and a copy of the nodeModel of the TREE, which means not just a node: it needs to be an element inside an (empty) array.

      this.myTrees = [];

      this.pathStringToMyTreesArray.forEach((pathStringToMyTree) => {
        const pathNumericalToMyTree =
          this.pathNumericalFromPathString(pathStringToMyTree);

        if (pathNumericalToMyTree /* it comes back as null if not found */) {
          // 6a. First make a copy of the NODE
          const myNodeModel = JSON.parse(
            JSON.stringify(
              this.$refs.managementSlVueTree.getNode(pathNumericalToMyTree)
            )
          );

          // 6b. Then make it into a TREE
          const myTreeAsNodeModel = [{ ...myNodeModel }];
          this.myTrees.push({
            pathNumericalToMyTree,
            pathStringToMyTree,
            myTreeAsNodeModel,
          });
        }
      });
    },

    pathNumericalFromPathString(pathString) {
      // SlVueTree uses numerical paths
      let foundPathNumerical = null;
      this.$refs.managementSlVueTree.traverse((node) => {
        if (this.pathStringFromPathNumerical(node.path) === pathString) {
          foundPathNumerical = JSON.parse(JSON.stringify(node.path));
        }
      });
      return foundPathNumerical;
    },

    pathStringFromPathNumerical(pathNumerical) {
      let pathSoFar = [];
      let pathStringArray = [];
      pathNumerical.forEach((iChild) => {
        pathSoFar.push(iChild);
        const oneNodeTitle = (
          this.$refs.managementSlVueTree.getNode(pathSoFar) || {
            title:
              "No entry in Management Tree at position " + pathSoFar.join("."),
          }
        ).title;

        pathStringArray.push(oneNodeTitle);
      });
      const pathString = pathStringArray.join(" | ");
      return pathString;
    },

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    onManageManagersNode(node, pathStringToTheSubtreeToEdit) {
      this.$router.push({
        name: "manageManagersNode",
        params: {
          propsJsonB: btoa(
            JSON.stringify({
              node,
              pathStringToTheSubtreeToEdit,
            })
          ),
        },
      });
    },

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    deFbmUk(s) {
      return (s || "").replace("FBM | ", "");
    },

    managerCountString(n) {
      if (!n) {
        n = 0;
      }
      if (n === 0) {
        return "No managers";
      }
      if (n === 1) {
        return "1 manager";
      }
      return n.toString() + " managers";
    },

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.screen-panel--top--this-trial-criteria {
  border-radius: 0.5em;
  background: #f0f0f0;
  min-height: 4em;
  max-height: 20em;
  overflow: auto;
}

.screen-panel--middle--fact-box-enter-cutoffs-etc {
  border-radius: 0.5em;
  padding: 0.5em;
  background: lightgray;
  color: black;
}

.screen-panel--bottom--choose-from-available-criteria {
  height: 100%;
  overflow-y: scroll;
  border-color: white;
  border-width: 0.5px;
  border-style: solid;
}

.screen-panel--bottom--choose-from-available-criteria--icon {
  height: 1.5em;
  width: 1.5em;
}

/*

Formatting an individual node, i.e. a line of text in the display of the tree.

*/

.node--tabbing--monospaced-font {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.1;
}

.node--title--is-logical {
  padding-left: 0.2em;
  padding-right: 0.4em;
  border-color: white;
  border-style: solid;
  border-radius: 1em;
}

.node--title--is-selected {
  background-color: #476ef080;
  /* color: black; need this for the lower panel */
}

/*

General stuff for slVueTree component

*/

.tree-container {
  flex-grow: 1;
}

.sl-vue-tree {
  position: relative;
  cursor: default;
  user-select: none;
  line-height: 1;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
  overflow: hidden;
  position: relative;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: orange;
}

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  padding-right: 10px;
  /* line-height: 28px; */
  border: 1px solid transparent;
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid blue;
}

.sl-vue-tree-gap {
  /* size of indent for membership */
  width: 25px;
  min-height: 1px;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree-sidebar {
  margin-left: auto;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid green;
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
  position: absolute;
  background-color: orange;
  opacity: 0.5;
  margin-left: 20px;
  padding: 5px 10px;
}

.highlighted {
  background-color: lightgray;
}
</style>

<template>
  <div style="height: 100vh">
    <section v-if="!trials.criterionIsLoaded || !trials.publicIsLoaded">
      <Spinner></Spinner>
    </section>

    <section v-else class="d-flex flex-column" style="height: 100%">
      <div
        id="mid-scrolling"
        class="mx-2 iphone-scroll-fudge"
        style="height: 100%; overflow-y: auto"
      >
        <div v-if="trials.criterionIsLoaded && trials.publicIsLoaded">
          <b-form
            v-if="state === 'screening-but-no-trials'"
            class="center-container"
          >
            <div>
              <h1 style="text-align: center">Sorry</h1>
              <br />
              <h3 style="text-align: center">
                Currently
                <br />no suitable <br />trials
              </h3>

              <div
                class="mt-5 d-flex flex-row justify-content-center"
                style="width: 90vw"
              >
                <b-button
                  class="d-flex flex-row align-items-center justify-content-between"
                  variant="light"
                  @click="goHome()"
                  style="min-width: 45%"
                >
                  <svg class="ion">
                    <use xlink:href="#ion-chevron-left" />
                  </svg>
                  <div>OK</div>
                  &nbsp;
                </b-button>
              </div>
            </div>
          </b-form>

          <b-form v-if="Object.keys(factsSought).length > 0">
            <!-- Some items still left to answer -->
            <transition name="swish">
              <section v-if="user.contactObj.inTour">
                <br />
                <tour-message>
                  <h2>Screening questions</h2>
                  <h4>
                    Imagine your patient is a 40 year old man, and answer below.
                  </h4>
                </tour-message>
              </section>
            </transition>

            <b-form-group>
              <div class="mt-2 d-flex flex-row justify-content-between">
                <transition name="swish">
                  <div v-if="knownHistoryJson.length === 0">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      @click="goBack()"
                      class="mt-1"
                      style="margin: 0; padding: 0"
                    >
                      <svg class="ion" style="width: 1.5em; height: 1em">
                        <use xlink:href="#ion-chevron-left" />
                      </svg>
                    </b-button>
                  </div>
                  <div v-if="knownHistoryJson.length >= 1">
                    <b-button
                      variant="light"
                      size="sm"
                      @click="historyPop"
                      class="mt-1"
                      >Undo</b-button
                    >
                  </div>
                </transition>

                <div>
                  <h6 style>
                    Answer in
                    <em>any</em> order
                  </h6>
                </div>
              </div>

              <transition-group name="list-complete">
                <div
                  v-for="fact in makeSortedArray(factsSought)"
                  :key="fact.id"
                  class="list-complete-item p-0 my-2"
                  style="display: block"
                >
                  <!-- the tag under the transition-group is not allowed to be b-card, for some reason -->
                  <b-card>
                    <!--- CATEGORICAL type -->
                    <div v-if="fact.type === 'Categorical'">
                      <div class="d-flex flex-row justify-content-between">
                        <div>
                          <h4>{{ fact.shortText }}</h4>
                          <h6 v-if="showLongText[fact.id]">
                            {{ fact.longText }}
                          </h6>
                        </div>
                        <div>
                          <b-button
                            v-if="
                              !showLongText[fact.id] &&
                              thereIsSomeLongText(fact)
                            "
                            variant="outline-primary"
                            size="sm"
                            @click="$set(showLongText, fact.id, true)"
                            >?</b-button
                          >
                        </div>
                      </div>
                      <transition-group name="list-complete">
                        <div
                          v-for="option in optionsAugmentedWithUnnamed(fact)"
                          :key="option.id"
                          class="list-complete-item"
                        >
                          <b-button
                            variant="primary"
                            class="mr-1 mb-1 p-2"
                            @click="
                              historyPush();
                              templateUpdateKnownCategorical(
                                known,
                                trials.factObj,
                                factCriterionObj,
                                fact.id,
                                option.id
                              );
                              recalcFromKnown();
                            "
                          >
                            {{ option.shortText }}
                            <span
                              v-if="showLongText[fact.id]"
                              class="help-text"
                              >{{ option.longText }}</span
                            >
                          </b-button>
                        </div>
                      </transition-group>
                    </div>

                    <!--- CONTINUOUS type -->
                    <div v-else-if="fact.type === 'Continuous'">
                      <div class="d-flex flex-row justify-content-between">
                        <div>
                          <h4>
                            {{ fact.shortText }}
                            <span class="units">{{ fact.units }}</span>
                          </h4>
                          <h6 v-if="showLongText[fact.id]">
                            {{ fact.longText }}
                          </h6>
                        </div>
                        <div>
                          <b-button
                            v-if="
                              !showLongText[fact.id] &&
                              thereIsSomeLongText(fact)
                            "
                            variant="outline-primary"
                            size="sm"
                            @click="$set(showLongText, fact.id, true)"
                            >?</b-button
                          >
                        </div>
                      </div>

                      <transition-group name="list-complete">
                        <div
                          v-for="band in fact.bands"
                          :key="band.shortText"
                          class="list-complete-item"
                        >
                          <b-button
                            variant="primary"
                            @click="
                              historyPush();
                              templateUpdateKnownContinuous(
                                known,
                                trials.factObj,
                                factCriterionObj,
                                fact.id,
                                band
                              );
                              recalcFromKnown();
                            "
                            class="mr-1 mb-1 p-2"
                            >{{ band.shortText }}</b-button
                          >
                        </div>
                      </transition-group>
                    </div>
                    <div v-else>
                      <b-alert show variant="danger">
                        <h3>Unknown fact type</h3>
                        <pre>{{ fact }}</pre>
                      </b-alert>
                    </div>
                  </b-card>
                </div>
              </transition-group>
            </b-form-group>
          </b-form>
          <b-form v-else class="center-container">
            <!-- No items left to answer. This particularly happens when you use the BACK arrow to come back here, after fully answering questions -->
            <div
              class="d-flex flex-row justify-content-between"
              style="width: 90vw"
            >
              <b-button
                class="d-flex flex-row align-items-center"
                variant="light"
                @click="$router.go(-1)"
                style="min-width: 45%"
              >
                <svg class="ion">
                  <use xlink:href="#ion-chevron-left" />
                </svg>
                <div>
                  New
                  <br />conditions
                </div> </b-button
              >&nbsp;
              <b-button
                class="d-flex flex-row align-items-center"
                variant="light"
                @click="$router.go(+1)"
                style="min-width: 45%"
              >
                <div>
                  See
                  <br />trials
                </div>
                <svg class="ion">
                  <use xlink:href="#ion-chevron-right" />
                </svg>
              </b-button>
            </div>
          </b-form>

          <br />
        </div>
      </div>

      <div id="bottom-bar" v-if="nDepends > 0">
        <b-navbar toggleable class="d-flex flex-column">
          <div
            id="text-explanation-of-dots"
            class="d-flex flex-row justify-content-between"
          >
            <transition name="tray">
              <div>
                <h6 style="text-align: left; font-size: 70%">
                  Questions cover {{ triagedTrials[DEPENDS].length }} trial{{
                    triagedTrials[DEPENDS].length > 1 ? "s" : ""
                  }}
                  (
                  <span
                    class="blob lower"
                    :style="'background-color:' + blobColor[DEPENDS] + ';'"
                  ></span
                  >)
                </h6>
              </div>
            </transition>

            <transition name="tray">
              <div class="ml-2">
                <h6 style="text-align: right; font-size: 70%">
                  <span v-if="nNo > 0">
                    (Removed questions of
                    <span v-if="nNo > 0">{{ nNo }} unsuitable</span>
                    trial{{ nNo > 1 ? "s" : "" }})
                  </span>
                </h6>
              </div>
            </transition>

            <transition name="tray">
              <div class="ml-2 d-flex flex-row" v-if="nYes > 0">
                <h6 style="text-align: right; font-size: 70%">
                  Skip other questions and see
                  {{ nYes }} suitable trial{{ nYes > 1 ? "s" : "" }} (
                  <span
                    class="blob lower"
                    :style="'background-color:' + blobColor[YES] + ';'"
                  ></span
                  >)
                </h6>
                <div>
                  <b-button
                    class="ml-1 p-0"
                    size="sm"
                    variant="outline-light"
                    @click="onwardToRefer()"
                  >
                    <svg class="ion" style="height: 1em; width: 1em">
                      <use xlink:href="#ion-chevron-right" />
                    </svg>
                  </b-button>
                </div>
              </div>
            </transition>
          </div>

          <transition name="tray">
            <div
              id="peek-at-trial-name"
              v-if="peekTrial"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
              "
            >
              <div
                v-for="blob in makeColoredBlobArray"
                :key="blob.id"
                class="{blob_width:true, text_for_peeked_trial: peekTrial===blob.id}"
                :style="'color:' + blobTextColor[blob.suit]"
                @click="setPeekTrial(null)"
              >
                <div v-if="blob.id === peekTrial">
                  <div>
                    <span v-if="blob.suit === YES">Suitable:</span>
                    <!-- <span v-if="blob.suit===NO" style="color:red;">Not eligible</span> -->
                    <span v-if="blob.suit === DEPENDS">Screening for</span>
                  </div>
                  <div>{{ trials.publicObj[peekTrial].shortText }}</div>
                  <br />
                </div>
              </div>
            </div>
          </transition>

          <div
            v-if="
              triagedTrials[YES] && triagedTrials[NO] && triagedTrials[DEPENDS]
            "
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 100%;
            "
          >
            <div
              v-for="blob in makeColoredBlobArray"
              :key="blob.id"
              :style="'background-color:' + blobColor[blob.suit] + ';'"
              :class="{ blob: true, blink_me: peekTrial === blob.id }"
              @click="
                if (blob.suit !== NO) {
                  setPeekTrial(blob.id);
                }
              "
            ></div>
          </div>
        </b-navbar>
      </div>
    </section>
  </div>
</template>

<script>
import { YES, NO, DEPENDS, TITLE_PATH_SEPARATOR } from "../util/constants.js";
import { matchGeo } from "../util/geography.js";
import { matchDiagnosis, getLastElementsOnly } from "../util/diagnosis.js";
import { yesNoDependsTree } from "./Clin20Scr20FiltYND.js";
import { combineCriteria } from "./Clin20Scr30CombineCriteria.js";
import {
  updateKnownCategorical,
  updateKnownContinuous,
} from "./Clin20Scr40UpdateKnown.js";
import Spinner from "./ext/Spinner.vue";

const EMPTY_KNOWN = {
  fact: {
    // Here will be globally applicable facts, like "gender = male" or "creatinine = 145". It is an object so you can easily look up against a fact Id.
  },
  criterion: {
    //  Here will be specific BOOLEAN criterion, which have individual id's even for two instances addressing the same fact, at the same cutoff, in the same trial. E.g. if you say "men over 50 OR women over 50", the two "over 50"s are two separate criteria with separate criterionIds, even though they address the same fact (age) with the same cutoff in the same trial.
  },
};
const EMPTY_TRIAGED_TRIAL_IDS = { [YES]: [], [NO]: [], [DEPENDS]: [] };
const EMPTY_TRIAGED_TRIALS = { [YES]: [], [NO]: [], [DEPENDS]: [] };

export default {
  components: { Spinner },
  props: {
    // Part 1. props passed by IMMEDIATELY PRECEDING route

    hasSymptomsTitlesJoined: {
      // If you want to store the param in the URL (to allow BACK), you cannot have an array, so join before passing, and then split it on arrival here, in the data: form: diagnoses below
      type: String,
      required: true,
      default: function () {
        return ["Angina" + TITLE_PATH_SEPARATOR + "Dandruff"];
      },
    },

    // Part 2. props passed from App.vue
    user: { type: Object },
    trials: {
      type: Object,
      required: true,
      default: () => ({
        // Just to teach Vue the structure
        factIsLoaded: false,
        factObj: {},
        criterionIsLoaded: false,
        criterionObj: {},
        publicIsLoaded: false,
        publicObj: {},
        publicArray: [],
      }),
    },
  },

  data: function () {
    return {
      // user input
      form: {
        // If this patient has a postcode, then use "p" + postcode; otherwise if he has a country, use "c" + country, otherwise ""
        geography:
          this.user.contactObj &&
          this.user.contactObj.location &&
          this.user.contactObj.location.postcode
            ? "p" + this.user.contactObj.location.postcode
            : this.user.contactObj &&
              this.user.contactObj.location &&
              this.user.contactObj.location.country
            ? "c" + this.user.contactObj.location.country
            : "",

        diagnoses: this.hasSymptomsTitlesJoined.split(TITLE_PATH_SEPARATOR),
      },
      state: "screening",
      peekTrial: "", // id of trial whose name is shown at bottom
      peekTrialTimeouts: [], // we push onto here, and then pop on each timeout, and disappear it when popped to empty

      // continuously updated status of what is known
      known: JSON.parse(JSON.stringify(EMPTY_KNOWN)),
      knownHistoryJson: [], // to allow undo

      // outputs of filterTrials:
      triagedTrials: JSON.parse(JSON.stringify(EMPTY_TRIAGED_TRIALS)),
      factsSought: {},
      criteriaSought: {},
      factCriterionObj: {},

      //whether to show expanded LongText info: this is an object with one key for each fact
      showLongText: {},

      // Just make constants reactive to avoid a Vue warning when used in template
      YES,
      NO,
      DEPENDS,
      blobColor: {
        [YES]: "palegreen",
        [NO]: "transparent",
        [DEPENDS]: "white",
      },
      blobTextColor: {
        [YES]: "palegreen",
        [NO]: "transparent",
        [DEPENDS]: "white",
      },
    };
  },

  computed: {
    nYes() {
      return this.triagedTrials[YES].length;
    },
    nNo() {
      return this.triagedTrials[NO].length;
    },
    nDepends() {
      return this.triagedTrials[DEPENDS].length;
    },

    makeColoredBlobArray() {
      const trialBlobArray = [];
      [DEPENDS, NO, YES].forEach((suit) => {
        Object.values(this.triagedTrials[suit]).forEach((trial) => {
          trialBlobArray.push({
            id: trial.id,
            suit: trial.suit,
            shortText: trial.shortText,
          });
        });
      });
      return trialBlobArray.sort(
        (trialMiniObj1, trialMiniObj2) => trialMiniObj1.id < trialMiniObj2.id
      );
    },
  },

  created: function () {
    // this.trialPublicArray = Object.keys(this.trials.publicObj).map(key => ({
    //   ...this.trials.publicObj[key],
    //   id: key
    // }));
    if (this.trials.publicArray) {
      this.recalcFromKnown();
    } else {
      // Probably just refreshed this page - dont want that.
      this.goHome();
    }

    // scienceFactRootRef.on("value", this.firebaseBindingScienceFact);
    // trialCriterionRootRef.on("value", this.firebaseBindingTrialCriterion);
    // trialPublicRootRef.on("value", this.firebaseBindingTrialPublic);
  },

  beforeDestroy: function () {
    // scienceFactRootRef.off("value", this.firebaseBindingScienceFact);
    // trialCriterionRootRef.off("value", this.firebaseBindingTrialCriterion);
    // trialPublicRootRef.off("value", this.firebaseBindingTrialPublic);
  },

  methods: {
    // firebaseBindingScienceFact: function(snapshot) {
    // this.trials.factObj = snapshot.val();
    // this.trials.factIsLoaded = true;
    // this.ifAllLoadedRecalcFromKnown();
    // },
    // firebaseBindingTrialCriterion: function(snapshot) {
    // this.trials.criterionObj = snapshot.val();
    // this.trials.criterionIsLoaded = true;
    // this.ifAllLoadedRecalcFromKnown();
    // },
    // firebaseBindingTrialPublic: function(snapshot) {
    // ONly this one needs array because it will be filtered, but maybe someday I can refactor it out.
    // this.trials.publicObj = snapshot.val();
    // this.trialPublicArray = Object.keys(this.trials.publicObj).map(key => ({
    //   ...this.trials.publicObj[key],
    //   id: key
    // }));
    // this.trialPublicIsLoaded = true;
    // this.ifAllLoadedRecalcFromKnown();
    // },

    // ifAllLoadedRecalcFromKnown: function() {
    //   if (
    //     this.trials.factIsLoaded &&
    //     this.trials.criterionIsLoaded &&
    //     this.trialPublicIsLoaded
    //   ) {
    //     this.recalcFromKnown();
    //   }
    // },

    makeSortedArray(factsSought) {
      const keys = Object.keys(factsSought);
      keys.sort((key1, key2) =>
        (
          factsSought[key1].priority || factsSought[key1].shortText
        ).localeCompare(
          factsSought[key2].priority || factsSought[key2].shortText
        )
      );
      return keys.map((key) => factsSought[key]);
    },

    recalcFromKnown: function () {
      Object.assign(this, this.filterTrials()); // triagedTrials, factsSought,criteriaSought, factCriterionObj;

      if (this.triagedTrials[DEPENDS].length > 0) {
        // No action, we should stil stay in screening mode
      } else if (this.triagedTrials[YES].length === 0) {
        this.state = "screening-but-no-trials";
      } else {
        this.state = "screening-complete";
        // go to next page
        this.onwardToRefer();
      }
    },

    onwardToRefer() {
      const trialIdsForDisplay = {
        [YES]: this.triagedTrials[YES].map((trial) => ({
          id: trial.id,
          suit: trial.suit,
        })),
        [DEPENDS]: this.triagedTrials[DEPENDS].map((trial) => ({
          id: trial.id,
          suit: trial.suit,
        })),
      };
      this.$router.push({
        name: "clin30Trials",
        params: {
          trialIdsForDisplayStringified: JSON.stringify(trialIdsForDisplay),
        },
      });
    },

    optionsAugmentedWithUnnamed(fact) {
      // All
      const nOptions = Object.keys(fact.options).length;
      // Named
      const uniqueOptionsNamedArray = Object.values(fact.optionsNamed)
        .map((option) => option.id)
        .filter((value, index, self) => self.indexOf(value) === index);
      const nNamed = uniqueOptionsNamedArray.length;
      const nUnnamed = nOptions - nNamed;
      // process:
      if (nUnnamed <= 1) {
        return fact.options;
      } else {
        // nUnnamed >=2, so let's make an "OTHER" class. Must list the individual items in the "longText" so can be shown if user asks for it

        // Set subtraction, based on: http://2ality.com/2015/01/es6-set-operations.html

        const arrayOfOptionKeys = Object.keys(fact.options);
        const setOfOptionKeysNamed = new Set([
          ...Object.keys(fact.optionsNamed),
        ]);
        const arrayOfOptionKeysUnnamed = arrayOfOptionKeys.filter(
          (key) => !setOfOptionKeysNamed.has(key)
        );

        let longText = "";
        longText += "(";
        longText += arrayOfOptionKeysUnnamed
          .map((key) => fact.options[key].shortText)
          .join(", ");
        longText += ")";

        const returnValue = {
          ...fact.optionsNamed,
          unnamedOption: {
            id: "Other",
            shortText: "Other",
            longText: longText,
          },
        };
        return returnValue;
      }
    },

    filterTrials: function () {
      // Look at the diagnoses the patient has (must have one or more, even if it is "None" for trials of healthy controls)
      // Look at his GEOGRAPHY (must have one or more)
      //
      // Level 1 search ---------------------------
      //
      // Filter trials as follows:
      // -- diagnosis
      // -- geography
      //
      // Level 2 search ---------------------------
      //
      // Filter out trials that we reject based on facts we already know.
      // Keep in:
      // Trials that are DEFINITELY eligible
      // And trials that MAY BE eligible (plus for each one, what more facts are NEEDED, if possible to find out)
      //
      // Show user:
      // How many trials still in (and what they are)
      // How many potential questions there are to answer (perhaps how many questions deleted since began?)
      // List of remaining questions

      // Then maybe work out somehow the easiest questions to answer, or most fruitful somehow?

      const criteriaSought = {};
      const triagedTrialIdsInKeys = JSON.parse(
        JSON.stringify(EMPTY_TRIAGED_TRIAL_IDS)
      );
      const triagedTrials = JSON.parse(JSON.stringify(EMPTY_TRIAGED_TRIALS));

      this.trials.publicArray
        .filter((trialPublic) =>
          matchDiagnosis(
            this.form.diagnoses,
            getLastElementsOnly(trialPublic.diagnoses)
          )
        )
        .filter((trialPublic) =>
          matchGeo(this.form.geography, trialPublic.geographies)
        )
        .map((trialPublic) => trialPublic.id)
        .forEach((id) => {
          let {
            suit,
            criteriaSought: oneTrialCriteriaSought /* rename on arrival, as "oneTrialCriteriaSought"*/,
          } = yesNoDependsTree(
            this.trials.criterionObj[id][0],
            /* OK because EditTrialLogic forces 1 root element  */
            this.known,
            this.trials.factObj
          );
          triagedTrialIdsInKeys[suit][id] = true;
          if (suit === DEPENDS) {
            let criteriaSoughtEachAugmentedWithTrialId = JSON.parse(
              JSON.stringify(oneTrialCriteriaSought)
            );
            Object.keys(criteriaSoughtEachAugmentedWithTrialId).forEach(
              (criterionId) => {
                criteriaSoughtEachAugmentedWithTrialId[
                  criterionId
                ].trialId = id;
              }
            );
            Object.assign(
              criteriaSought,
              criteriaSoughtEachAugmentedWithTrialId
            );
          }
        });

      const { factsSought, factCriterionObj } = combineCriteria(
        criteriaSought,
        this.known,
        this.trials.factObj
      );

      [YES, NO, DEPENDS].forEach((suit) => {
        triagedTrials[suit] = Object.keys(triagedTrialIdsInKeys[suit]).map(
          (id) => ({
            ...this.trials.publicObj[id],
            suit: suit,
            id: id,
          })
        );
      });

      return {
        triagedTrials,
        factsSought,
        criteriaSought,
        factCriterionObj,
      };
    },
    templateUpdateKnownCategorical(k, sf, fc, f, o) {
      // Can't call it directly from template.
      // Scr40
      updateKnownCategorical(k, sf, fc, f, o);
    },
    templateUpdateKnownContinuous(k, sf, fc, f, b) {
      // Can't call it directly from template
      // Scr40
      updateKnownContinuous(k, sf, fc, f, b);
    },
    setPeekTrial(id) {
      if (id === this.peekTrial) {
        //  click same item again to hide
        id = null;
      }
      this.peekTrial = id;
      this.peekTrialTimeouts.push(id /* any dummy thing */);
      setTimeout(() => {
        this.peekTrialTimeouts.pop();
        if (this.peekTrialTimeouts.length === 0) {
          this.peekTrial = null;
        }
      }, 5000);
    },

    // ______                         _   _   _
    // |  ___|                       | | | | (_)
    // | |_ ___  _ __ _ __ ___   __ _| |_| |_ _ _ __   __ _
    // |  _/ _ \| '__| '_ ` _ \ / _` | __| __| | '_ \ / _` |
    // | || (_) | |  | | | | | | (_| | |_| |_| | | | | (_| |
    // \_| \___/|_|  |_| |_| |_|\__,_|\__|\__|_|_| |_|\__, |
    //                                                 __/ |
    //                                                |___/
    thereIsSomeLongText(fact) {
      let cumulativeLongTextLength = 0;
      if (fact.longText) {
        cumulativeLongTextLength += fact.longText.length;
      }

      if (fact.type === "Categorical") {
        Object.values(fact.optionsNamed)
          .map((option) => (option.longText || "").length)
          .forEach((longTextLength) => {
            cumulativeLongTextLength += longTextLength;
          });
      }
      //    console.log(cumulativeLongTextLength);
      return cumulativeLongTextLength > 0;
    },

    //  _   _ _     _
    // | | | (_)   | |
    // | |_| |_ ___| |_ ___  _ __ _   _
    // |  _  | / __| __/ _ \| '__| | | |
    // | | | | \__ \ || (_) | |  | |_| |
    // \_| |_/_|___/\__\___/|_|   \__, |
    //                             __/ |
    //                            |___/

    historyPush() {
      this.knownHistoryJson.push(JSON.stringify(this.known));
    },
    historyPop() {
      this.known = JSON.parse(this.knownHistoryJson.pop());
      this.recalcFromKnown();
    },

    //  _   _             _             _   _
    // | \ | |           (_)           | | (_)
    // |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    // | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    // | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    // \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                       __/ |
    //                      |___/

    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.replace({ name: "home" });
    },
  },
};
</script>

<style scoped>
.units {
  font-size: 70%;
  color: gray;
}

.help-text {
  font-size: 70%;
  color: white;
}

.lower {
  vertical-align: sub;
}

.card-body {
  padding: 0.5rem;
  color: black;
}

.list-complete-item {
  transition: all 1s;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}
.list-complete-leave-active {
  position: absolute;
}

/* FOR BLOBS AT BOTTOM */
.blob {
  display: inline-block;
  width: 1em;

  height: 1em;

  border-radius: 0.5em;
}

.text_for_peeked_trial {
  flex: auto;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.tray-enter,
.tray-leave-to {
  opacity: 0;
  max-height: 0;
}

.tray-leave,
.tray-enter-to {
  opacity: 1;
  max-height: 5em;
}

.tray-enter-active {
  transition: max-height 1s, opacity 2s;
}

.tray-leave-active {
  transition: max-height 2s, opacity 1s;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: scale(0.001);
}
.list-complete-leave-active {
  position: absolute;
}
</style>

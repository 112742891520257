<template>
  <transition name="slow-to-anger">
    <div class="alert alert-danger m-1">

      <!-- This is deliberately NOT full width, so that users can still reach the "LOCATION" and "USER NAME" areas behind the top -->

      <h2>Internet? <b-button
          variant="link"
          size="sm"
          @click="showMoreHelp=!showMoreHelp;"
        >{{!showMoreHelp?"Help":"Close help"}}</b-button>
      </h2>
      <h4>Cannot reach server. </h4>
      <transition name="swish">
        <h5 v-if="showMoreHelp">
          <p>We use cellphone mobile data for secure communication with our servers. Can you improve phone reception?</p>
          <p>We can also use WiFi or wired internet, but will not do so if we detect attempts at intrusion. Unfortunately some NHS networks intrude on your encrypted communications, so are unusable.</p>
          <p>Without internet, you may still be able to search for trials, but you will see a warning when you try to refer. Leave the App unclosed: it will retry until internet is re-established, and will report successful transmission. (You can use other apps in the meantime.)</p>
        </h5>
      </transition>

    </div>
  </transition>
</template>

<script>
export default {
  data: function() {
    return { showMoreHelp: false };
  }
};
</script>

<style scoped>
/* Slow-appearing warning message, quick-vanishing*/

.slow-to-anger-enter,
.slow-to-anger-leave-to {
  opacity: 0;
  max-height: 0;
  overflow-y: scroll;
}

.slow-to-anger-enter-active {
  transition: opacity 5s ease-in-out 10s, max-height 5s ease-in-out 10s;
}

.slow-to-anger-leave-active {
  transition: opacity 2s, max-height 2s linear;
}

.slow-to-anger-enter-to,
.slow-to-anger-leave {
  opacity: 1;
  max-height: 100vh;
}
</style>
export const YES = +1;
export const NO = -1;
export const DEPENDS = 0;

export const REJECT = -10; // Patient
export const ACCEPT = +10; // Patient

export const TITLE_PATH_SEPARATOR = "|";

export const ADMIN_EMAIL = "admin@FoundBy.Me";

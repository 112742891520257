<template>
  <div
    class="px-2 pt-2 d-flex flex-column justify-content-between"
    style="height:100vh; max-width:100%;"
  >
    <app-header-bar title="Manager Home" :hide-back-arrow="true" :hide-home-icon="true"></app-header-bar>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('manageManagers')"
    >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 32 32">
          <path
            fill="yellow"
            d="M 16,0 C 15.12,0 14,1.12 14,2.5 14,3.87 15.12,5 16.5,5 17.87,5 19,3.87 19,2.5 19,1.12 17.87,0 16.5,0 Z M 14.68,4.96 C 13.19,4.96 12,6.19 12,7.68 L 12,8.27 12,11 14.68,11 18.31,11 21,11 21,8.27 21,7.68 C 21,6.19 19.80,4.96 18.31,4.96 l -0.26,0 L 16.5,6.54 14.94,4.96 Z"
          ></path>
          <path
            fill="white"
            d="M 15.5,12 l 0,1.5 -7,0 C 7.12,13.5 6,14.62 6,16 l 0,3 1,0 0,-3 c 0,-0.84 0.66,-1.5 1.5,-1.5 l 7,0 1,0 8,0 c 0.84,0 1.5,0.66 1.5,1.5 l 0,3 1,0 0,-3 c 0,-1.37 -1.12,-2.5 -2.5,-2.5 L 16.5,13.5 16.5,12 Z m -9,8 c -1.41,0 -2.57,1.15 -2.56,2.57 0,1.41 1.15,2.56 2.56,2.56 1.41,0 2.56,-1.15 2.56,-2.56 C 9,21.15 7.83,20 6.41,20 Z m 20.16,0 c -1.41,0 -2.56,1.15 -2.56,2.57 0,1.41 1.15,2.56 2.56,2.56 1.41,0 2.56,-1.15 2.56,-2.56 C 29.14,21.15 28,20 26.58,20 Z M 4.64,26 C 3.18,26 2,27.19 2,28.64 L 2,28.78 2,32 l 8.83,0 0,-3.24 0,-0.10 C 10.83,27.19 9.65,26 8.19,26 Z m 20.16,0 c -1.45,0 -2.64,1.19 -2.64,2.64 l 0,0.14 0,3.21 L 31,32 31,28.7 31,28.64 C 31,27.19 29.8,26 28.3,26 Z"
          ></path>
        </svg>
      </div>
      <h4 style="width:50%">Appoint Managers</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('manageTrials')"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 32 32">
        <path
          fill="white"
          d="M 16,0 C 15.12,0 14,1.12 14,2.5 14,3.87 15.12,5 16.5,5 17.87,5 19,3.87 19,2.5 19,1.12 17.87,0 16.5,0 Z M 14.68,4.96 C 13.19,4.96 12,6.19 12,7.68 L 12,8.27 12,11 14.68,11 18.31,11 21,11 21,8.27 21,7.68 C 21,6.19 19.80,4.96 18.31,4.96 l -0.26,0 L 16.5,6.54 14.94,4.96 Z"
        ></path>
        <path
          fill="yellow"
          d="M 16.5,12  m -9,8 c -1.41,0 -2.57,1.15 -2.56,2.57 0,1.41 1.15,2.56 2.56,2.56 1.41,0 2.56,-1.15 2.56,-2.56 C 9,21.15 7.83,20 6.41,20 Z m 20.16,0 c -1.41,0 -2.56,1.15 -2.56,2.57 0,1.41 1.15,2.56 2.56,2.56 1.41,0 2.56,-1.15 2.56,-2.56 C 29.14,21.15 28,20 26.58,20 Z M 4.64,26 C 3.18,26 2,27.19 2,28.64 L 2,28.78 2,32 l 8.83,0 0,-3.24 0,-0.10 C 10.83,27.19 9.65,26 8.19,26 Z m 20.16,0 c -1.45,0 -2.64,1.19 -2.64,2.64 l 0,0.14 0,3.21 L 31,32 31,28.7 31,28.64 C 31,27.19 29.8,26 28.3,26 Z"
        ></path>
        <path
          fill="white"
          d="M 15.5,12 l 0,1.5 -7,0 C 7.12,13.5 6,14.62 6,16 l 0,3 1,0 0,-3 c 0,-0.84 0.66,-1.5 1.5,-1.5 l 7,0 1,0 8,0 c 0.84,0 1.5,0.66 1.5,1.5 l 0,3 1,0 0,-3 c 0,-1.37 -1.12,-2.5 -2.5,-2.5 L 16.5,13.5 16.5,12 Z"
        ></path>
      </svg>

      <div style="width:50%">
        <h4>Manage Researcher Access
          <br>
        </h4>
        <h6>Trial-by-trial</h6>
      </div>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('resReferralAll')"
    >
      <svg class="ion" style="height:60px; width:60px;">
        <use xlink:href="#referred-in"></use>
      </svg>
      <h4 style="width:50%;">Referrals to my team</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
    </div>

    <div class="d-flex flex-row justify-content-around align-items-center" @click="goPage('my')">
      <svg class="ion" style="height:60px;width:60px;">
        <use xlink:href="#ion-android-person"></use>
      </svg>
      <h4 style="width:50%;">My details</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
    </div>

    <home-pages-bar from="manager" :user="user"></home-pages-bar>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import HomePagesBar from "./HomePagesBar.vue";

export default {
  components: { AppHeaderBar, HomePagesBar },

  props: {
    user: { type: Object, required: true }
  },

  created() {
    this.$emit("checkUserGivenEnoughInfo");
    this.user.contactRef.update({ lastHomePage: "manage" });
  },

  methods: {
    goPage(pageName) {
      this.$router.push({ name: pageName });
    }
  }
};
</script>

<style>
</style>

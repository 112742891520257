<template>
  <div class="p-3 d-flex flex-column" style="height: 100vh; max-width: 100%">
    <app-header-bar title="HQ Edit User"></app-header-bar>

    <section
      id="middle-scrollable"
      class="iphone-scroll-fudge"
      style="overflow-y: auto; overflow-x: hidden"
    >
      <b-form>
        <h5>{{ targetUser.contactObj.fullName || "" }}</h5>

        <!-- <div v-if="targetUser.contactIsLoaded && !targetUser.contactObj.privacyAgreedIso">
          <b-alert
            show
            variant="danger"
            class="my-3"
          >
            <h3>Privacy</h3>
            <hr>
            <h6>User hasn't agreed to privacy statement yet. Ask them to do this on their app.</h6>

          </b-alert>
        </div> -->
        <br />
        <h6>Email</h6>

        <h5>
          {{ targetUser.presenceObj.emailAtLastLogin || "No email address" }}
        </h5>

        <div
          v-if="
            targetUser.presenceObj.emailAtLastLogin &&
            !targetUser.contactObj.emailVerified
          "
        >
          <b-alert show variant="danger">
            <h3>Awaiting email verification</h3>
            <hr />
            <h6>
              Link has been emailed to user. If they can't find it, ask them to
              click "Send New Link" on their App.
            </h6>
          </b-alert>
        </div>
        <br />

        <h6>
          Title and Name
          <span style="font-size: 70%">in conventional full form</span>
        </h6>

        <b-form-input
          type="text"
          v-model="targetUser.contactObj.fullName"
          required
          placeholder="e.g. Dr Jane Smith or Sister Ong Chen-Li"
          v-on:change="
            targetUser.contactRef
              .child('fullName')
              .set(targetUser.contactObj.fullName)
          "
        >
        </b-form-input>
        <br />

        <h6>
          Mobile phone
          <span style="font-size: 70%">for identity confirmation</span>
        </h6>
        <b-form-input
          type="text"
          required
          v-model="targetUser.contactObj.mobileSelf"
          @change="
            targetUser.contactRef
              .child('mobileSelf')
              .set(formatMobile(targetUser.contactObj.mobileSelf))
          "
          placeholder="e.g. +44 7000 000 000"
        >
        </b-form-input>
        <br />

        <h6>
          Administrative contact tel
          <span style="font-size: 70%"
            >in case difficulty tracing patient info</span
          >
          &nbsp; (Optional)
        </h6>
        <b-form-input
          type="text"
          v-model="targetUser.contactObj.adminTel"
          v-on:change="
            targetUser.contactRef
              .child('adminTel')
              .set(targetUser.contactObj.adminTel)
          "
          placeholder="e.g. Clinic reception phone number"
        >
        </b-form-input>
        <br />

        <div v-if="targetUser.contactObj.adminTel">
          <h6>
            Name of administrative contact
            <span style="font-size: 70%"
              >if anyone in particular covers your patients</span
            >
            &nbsp; (Optional)
          </h6>
          <b-form-input
            type="text"
            v-model="targetUser.contactObj.adminWho"
            v-on:change="
              targetUser.contactRef
                .child('adminWho')
                .set(targetUser.contactObj.adminWho)
            "
            placeholder="e.g. Chris"
          >
          </b-form-input>
          <br />
        </div>
      </b-form>

      <div
        v-if="
          targetUser.contactIsLoaded &&
          (!targetUser.contactObj.fullName ||
            targetUser.contactObj.fullName.length < 7)
        "
      >
        <h6>They haven't entered their name.</h6>
      </div>

      <div>Full User ID</div>
      <div
        style="
          color: white;
          user-select: all;
          text-align: right;
          font-weight: 700;
        "
      >
        {{ id }}
      </div>
      <br />

      <hr style="border-color: white" />
      <br />

      <b-form>
        <div v-if="targetUser.privilegesObj.manager">
          <h4>This person is a manager</h4>
        </div>
        <div v-else>
          <h4>To make this person a manager</h4>
          <p>Go to "Manager Home", "Manage Research Managers"</p>
        </div>

        <div v-if="targetUser.privilegesObj.researcher">
          <h4>This person is a researcher</h4>
        </div>
        <div v-else>
          <h4>To make this person a researcher</h4>
          <p>
            They automatically become a researcher (i.e. able to access
            "Researcher Home") when someone adds them as a researcher to a
            trial. Usually their manager will do this.<br />If you want to do it
            yourself at HQ, go to "Manager Home", "Manage Access and
            Notifications Trial-by-Trial", select the trial and add the person.
          </p>
        </div>
      </b-form>
    </section>
  </div>
</template>

<script>
import { contactRootRef, privilegesRootRef } from "../util/firebase";
import { bindBranchAsREFandOBJandISLOADED } from "../util/firebaseBind";
import { formatMobile } from "../util/phoneNumber.js";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { AppHeaderBar },

  props: {
    id: {
      type: String,
      required: true,
      default: "zjBbs5FLYRYjdiMBSwnhGUf3Q7u2",
    },
  },

  data() {
    return {
      targetUser: { contactObj: {}, presenceObj: {}, privilegesObj: {} },
      showPrivacyMore: false,
      showAdvancedBottom: false,
      emailVerificationLinkSentLocaleTime: undefined,

      // Make imported functions available in THIS, so template can reference
      formatMobile: formatMobile,
    };
  },
  created() {
    bindBranchAsREFandOBJandISLOADED(
      this.targetUser,
      "contact",
      contactRootRef.child(this.id)
    );
    bindBranchAsREFandOBJandISLOADED(
      this.targetUser,
      "presence",
      contactRootRef.child(this.id)
    );
    bindBranchAsREFandOBJandISLOADED(
      this.targetUser,
      "privileges",
      privilegesRootRef.child(this.id)
    );
  },
  methods: {
    // Navigation ===============================================

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>

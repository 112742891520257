<template>
  <div class="p-2 page-scroll-y">
    <section v-if="formTrialCriterionIsLoaded && formTrialPublicIsLoaded">
      <b-form>
        <!-- There is an identical copy of this Nav section at the bottom -->
        <!-- from here -->
        <b-form-group>
          <div v-if="userHasEdited">
            <div
              v-if="formCheckObj.valid"
              class="d-flex justify-content-between"
            >
              <b-button v-on:click="onSubmit" variant="light"
                >&nbsp; Save &nbsp;</b-button
              >
              <h3>Edit Trial</h3>
              <b-button v-on:click="goBack" variant="danger"
                >&nbsp; Cancel &nbsp;</b-button
              >
            </div>
            <div v-else class="d-flex justify-content-between">
              <!-- edited, invalid, so invisible button as dummy spacer-->
              <b-button variant="link">&nbsp;</b-button>
            </div>
          </div>
          <div v-else class="d-flex justify-content-between">
            <!-- not edited -->
            <b-button v-on:click="goBack" variant="outline-light">
              <svg class="ion" style="height: 2em">
                <use xlink:href="#ion-chevron-left" />
              </svg>
            </b-button>

            <h3>HQ Edit Trial</h3>
            &nbsp;
            <!-- <b-button v-on:click="goEditTrialLogic" variant="primary"> Edit screening logic </b-button> -->
          </div>
        </b-form-group>
        <!-- to here -->

        <b-form-group label="Short name (typically acronym)">
          <b-form-input
            size="lg"
            type="text"
            v-model="form.trialPublic.shortText"
            required
            placeholder="e.g. ORBITA-9"
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group>
          <b-form-checkbox
            type="text"
            v-model="form.trialPublic.isMultiCentre"
          >Multicentre trial?
          </b-form-checkbox>
        </b-form-group>-->

        <transition name="swish">
          <b-form-group label="Research centre">
            <b-form-input
              type="text"
              v-model="form.trialPublic.centre"
              required
              placeholder="e.g. Imperial or Wycombe"
            ></b-form-input>
            <div style="line-height: 1">
              <small
                >This will be shown in small print under the trial name, when
                suitable trials are displayed.</small
              >
            </div>
          </b-form-group>
        </transition>

        <b-form-group label="Geographies">
          <div style="line-height: 1" class="mb-1">
            <small>
              List the
              <span
                class="text-warning"
                @click="showGeographiesHelp = !showGeographiesHelp"
                >geographies</span
              >
              to recruit from.
            </small>
          </div>
          <b-alert :show="showGeographiesHelp">
            One line of text listing geographies from which you can pay travel
            expenses. Geographies are separated by commas, and each begins with
            a character giving the class of geography:
            <h4>p</h4>
            Followed by a postcode outcode (letters + numbers, before the space)
            e.g. "HA3", or just the letters, e.g. "W". A bug in the matching
            system is that it is a prefix match, so if you recruit from "W1", a
            doctor at "W12" will be matched. Tough luck.
            <h4>r</h4>
            Radius. Super-advanced Haversine-based surface-distance algorithm.
            Give a postcode outcode, a colon, and a number of kilometres. E.g.
            r(W12:30) or r(HA3,15.5).
            <h4>c</h4>
            This should be followed by the 2-character ISO country code, e.g.
            "GB" for UK or "FR" for France.
            <h4>g</h4>
            A special geography for "global", i.e. anywhere.
            <h4>n</h4>
            A special geography for "nowhere", i.e. don't recruit.
            <hr />
            <h3 align="center">Example entry</h3>
            <h4>pB,pNW,cDE,r(CB2:19.5)</h4>
            This would recruit from Birmingham, "NW" postcodes of London,
            anywhere within 19.5km of that bit of Cambridge, and all of Germany.
            <br />
            <div class="d-flex justify-content-end">
              <b-button variant="light" @click="showGeographiesHelp = false"
                >&nbsp; &nbsp; OK &nbsp; &nbsp;</b-button
              >
            </div>
          </b-alert>
          <b-form-input
            v-model="form.trialPublic.geographies"
            @blur.native="geographiesCheckObjTriggerRecomputation++"
          ></b-form-input>
          <b-alert
            variant="danger"
            show
            v-if="
              form.trialPublic.geographies &&
              form.trialPublic.geographies.length > 0 &&
              !geographiesCheckObj.valid
            "
            >{{ geographiesCheckObj.errorMessage }}</b-alert
          >
          <b-alert
            variant="warning"
            show
            v-if="
              !geographiesCheckObj.errorMessage &&
              geographiesCheckObj.warningMessage
            "
            >{{ geographiesCheckObj.warningMessage }}</b-alert
          >

          <div
            v-if="geographiesCheckObj.valid && referrerPlaces.length > 0"
            class="d-flex flex-row justify-content-end align-items-center"
            style="font-size: 70%"
          >
            {{ referrerPlaces.length }} referrer sites match
            <b-button
              variant="outline-light"
              class="ml-1"
              size="sm"
              @click="showReferrerPlaces = !showReferrerPlaces"
            >
              <span v-if="!showReferrerPlaces">Show</span>
              <span v-else>Hide</span>
            </b-button>
          </div>
          <transition name="swish">
            <div v-if="showReferrerPlaces">
              <div
                v-for="place in referrerPlaces"
                :key="place.shortText"
                class="d-flex"
              >
                <div style="width: 10%">
                  {{ Math.round(place.distanceKm || 0) }}
                </div>
                <div style="width: 10%">
                  {{ place.type === "trust" ? "T" : "C" }}
                </div>
                <div style="width: 70%">{{ place.shortName }}</div>
              </div>
            </div>
          </transition>
        </b-form-group>
        <b-form-group label="Diagnoses">
          <div style="line-height: 1" class="mb-2">
            <small
              >One term per line (first letter in caps) from the terminal nodes
              (nodes with no children) in the master list in
              'diagnosisTreeFullTheoreticalForm.js', e.g. Heart failure or
              Angina. Each term is allowed to appear in more than one place in
              the tree, allowing "Dilated aorta" to appear under both
              "Cardiology" and "Vascular surgery", for instance, so that
              "Dilated aorta" trials are brought to the attention of both
              specialties.</small
            >
          </div>
          <div
            v-for="(diagnosis, index) in form.trialPublic.diagnoses"
            :key="index"
          >
            <b-form-input
              v-model="form.trialPublic.diagnoses[index]"
              @blur.native="
                updateTrialPublicDiagnosesAndDiagnosisTop;
                formCheckObjTriggerRecomputation++;
              "
              placeholder="e.g. Prinzmetal angina"
              class="mt-1"
            ></b-form-input>
          </div>
          <div class="d-flex justify-content-end mt-1">
            <b-button @click="onClickAddDiagnosis" variant="warning">
              <span
                v-if="
                  form.trialPublic.diagnoses &&
                  form.trialPublic.diagnoses.length > 0
                "
                >&nbsp; Add another diagnosis</span
              >
              <span v-else>Enter a diagnosis (compulsory)</span> &nbsp;
              <svg class="ion" style="height: 2em">
                <use xlink:href="#ion-ios-plus-outline" />
              </svg>
            </b-button>
          </div>

          <!-- Top diagnosis <small>(auto-copied on save)</small>
          <b-form-input
            type="text"
            disabled
            v-model="form.trialPublic.diagnosisTop"
            placeholder="Automatically the first in the list above"
            style="color:lightgray;"
          >
          </b-form-input>-->
        </b-form-group>

        <b-form-group label="This trial is ...">
          <b-form-textarea
            v-model="form.trialPublic.thisTrialIs"
            placeholder="This trial is trying a new medication to improve itchiness in Scorpio syndrome."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <div class="d-flex justify-content-end">
            <small style="line-height: 1">Give a one-sentence summary.</small>
          </div>
        </b-form-group>

        <b-form-group label="Bullet points">
          <div style="line-height: 1">
            <small
              >Aim for 3, e.g: What is the most intrusive part? How many visits?
              How many months?</small
            >
          </div>
          <div v-for="(bullet, index) in form.trialPublic.bullets" :key="index">
            <b-form-input
              v-model="form.trialPublic.bullets[index]"
              :placeholder="bulletPlaceholder(index)"
              @change="updateBullets"
              @blur.native="updateBullets"
              class="mt-1"
            ></b-form-input>
          </div>
          <div class="d-flex justify-content-end mt-1">
            <b-button
              @click="
                updateBullets();
                onClickAddBullet();
              "
              variant="warning"
            >
              <span
                v-if="
                  form.trialPublic.bullets &&
                  form.trialPublic.bullets.length > 0
                "
                >&nbsp; Add another bullet</span
              >
              <span v-else>Add a bullet point</span> &nbsp;
              <svg class="ion" style="height: 2em">
                <use xlink:href="#ion-ios-plus-outline" />
              </svg>
            </b-button>
          </div>
          <div></div>
        </b-form-group>

        <b-form-group label="Researchers">
          <div style="line-height: 1">
            <small>Access and notifications</small>
          </div>
          <div v-if="!userHasEdited" class="mt-1 d-flex justify-content-end">
            <b-button variant="warning" @click="goEditTrialAccess">
              &nbsp; Researchers &nbsp;
              <svg class="ion" style="height: 2em">
                <use xlink:href="#ion-android-people" />
              </svg>
            </b-button>
          </div>
          <div v-else class="mb-1 d-flex justify-content-end">
            <small>Can edit once rest of page is correct and saved.</small>
            <b-button id="dummy" variant="link">&nbsp;</b-button>
          </div>
        </b-form-group>

        <b-form-group label="Screening Logic">
          <EditTrialLogic
            :image="true"
            :id="id"
            style="
              border-radius: 0.5em;
              background: #f0f0f0;
              color: black;
              height: 100%;
            "
          ></EditTrialLogic>

          <div v-if="!userHasEdited" class="mt-1 d-flex justify-content-end">
            <b-button variant="warning" @click="goEditTrialLogic">
              &nbsp; Edit Screening Logic &nbsp;
              <svg class="ion" style="height: 2em">
                <use xlink:href="#ion-chevron-right" />
              </svg>
            </b-button>
          </div>
          <div v-else class="mb-1 d-flex justify-content-end">
            <small
              >Can edit screening logic once rest of page is correct and
              saved.</small
            >
            <b-button id="dummy" variant="link">&nbsp;</b-button>
          </div>
        </b-form-group>

        <b-form-group>
          <div v-if="!formCheckObj.valid">
            <b-alert show variant="danger">{{
              formCheckObj.errorMessage
            }}</b-alert>
          </div>
          <div v-if="!formCheckObj.warningMessage">
            <b-alert show variant="alert">{{
              formCheckObj.warningMessage
            }}</b-alert>
          </div>
        </b-form-group>
      </b-form>

      <b-form-group label="Video">
        <b-form-input
          type="text"
          v-model="form.trialPublic.videoUrl"
          placeholder="e.g. https://hosting-site.com/videos/whatever.mp4"
        ></b-form-input>
        <div style="line-height: 1; text-align: right">
          <small
            >Complete URL from "https" to ".mp4". Squareish, ideally
            &lt;30s.</small
          >
        </div>

        <div v-if="form.trialPublic.videoUrl">
          <video
            :src="form.trialPublic.videoUrl"
            style="
              width: 50vw;
              max-width: 50vh;
              height: 50vw;
              max-height: 50vh;
              border-radius: 1em;
            "
            controls
          >
            <!-- e.g. https://storage.googleapis.com/found-by-me/media/ISCOMAT.mp4 -->
            Unable to play this video on this device currently.
          </video>
        </div>
        <div v-if="!form.trialPublic.videoUrl">
          Paste above the complete url for the video, beginning with "https://"
          and ending with ".mp4", and it will play here.
        </div>
      </b-form-group>
    </section>

    <hr class="my-2" style="border-color: white" />

    <section v-if="formTrialCriterionIsLoaded && formTrialPrivateIsLoaded">
      <h3 class>FoundBy.Me HQ Private Info</h3>
      <div style="line-height: 1" class="mb-3">
        <small
          >If we ever let the Trusts fill in the above section, we will still
          keep this section to ourselves.</small
        >
      </div>
      <b-form>
        <b-form-group label="Management path">
          <b-form-input
            type="text"
            v-model="form.trialPrivate.centreManagementPath"
            placeholder="e.g. FBM | UK | Imperial | Cardiology"
            :formatter="onFormatPipedInput"
            lazy-formatter
          ></b-form-input>

          <div style="line-height: 1; text-align: right">
            <small>
              Used for billing, and determining which managers can edit access
              to NHS numbers/notifications.
              <br />To create a new branch (e.g. a new Trust or new Dept /
              Subdept) edit
              <span style="font-family: 'Courier New', monospace, serif"
                >managementTree</span
              >
              in Firebase.
            </small>
          </div>
        </b-form-group>

        <b-form-group label="Optional additional billing info">
          <b-form-textarea
            rows="4"
            max-rows="6"
            v-model="form.trialPrivate.billingId"
            placeholder="e.g. if billed separately from Management Path, might say: Northwick Park, Acct 45, Budgetholder = J Smith, 0208 808 8088."
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </section>

    <section class="officeUseOnly">
      <hr />
      <b-form-group label="FoundBy.Me Headquarters use only">
        <b-form-row>
          <b-col>Trial Permanent ID</b-col>
          <b-col>{{ id }}</b-col>
        </b-form-row>
      </b-form-group>
    </section>
  </div>
</template>

<script>
import EditTrialLogic from "./EditTrialLogic.vue";
import {
  trialCriterionRootRef,
  trialPublicRootRef,
  trialPrivateRootRef,
} from "../util/firebase.js";

import { geographyCountryCodes } from "../util/tableGeographyCountryCodes.js";
import {
  isValidPostcodeZone,
  referrerPlacesInRange,
  isValidRadiusGeography,
} from "../util/geography.js";

var jsonStableStringify = require("json-stable-stringify"); // We need to use this, rather than plain "JSON.stringify" because the plain one does NOT guarantee the order of the items.

const EMPTY_FORM_PUBLIC = {
  shortText: "",
  thisTrialIs: "",
  centre: "",
  geographies: "",
  diagnoses: ["", ""],
  diagnosisTop: "",
  bullets: [],
};

const EMPTY_FORM_PRIVATE = {
  centreManagementPath: "",
};

export default {
  components: { EditTrialLogic },

  props: {
    id: {
      type: String,
      required: true,
      default: "tri~dummy-for-testing-001",
    },
  },

  data: () => ({
    form: {
      trialCriterion: {},
      trialPublic: EMPTY_FORM_PUBLIC,
      trialPrivate: EMPTY_FORM_PRIVATE,
    },
    formFromCloudJSON: {
      trialCriterion: "{}",
      trialPublic: "{}",
      trialPrivate: "{}",
    },
    formTrialCriterionIsLoaded: false,
    formTrialPublicIsLoaded: false,
    formTrialPrivateIsLoaded: false,

    formCheckObjTriggerRecomputation: 0,
    geographiesCheckObjTriggerRecomputation: 0,
    userHasEditedTriggerRecomputation: 0,

    showGeographiesHelp: false,
    referrerPlaces: [],
    showReferrerPlaces: false,
  }),

  computed: {
    formCheckObj: function () {
      const dummy = Math.floor(this.formCheckObjTriggerRecomputation * 10); // Complex enough not to be optimized away
      if (dummy + 1 < dummy) {
        // Stop EsLing complaining about not using dummy
      }
      let valid = true;
      let warningMessage = "";
      let errorMessage = "";
      if (!this.geographiesCheckObj.valid) {
        // Although geographiesCheckObj is a computed property in Vue templates, here in the JS it is still just a function attached to "this"
        valid = false;
        errorMessage += "Geographies are not valid.\n";
      }
      if (!this.form.trialPublic.shortText) {
        valid = false;
        errorMessage += "Short name needed.\n";
      }
      if (
        !this.form.trialPublic.geographies ||
        this.form.trialPublic.geographies.length === 0
      ) {
        valid = false;
        errorMessage +=
          "At least one geography needed (or use 'n' for 'nowhere').\n";
      }

      if (
        !this.form.trialPublic.diagnoses ||
        this.form.trialPublic.diagnoses.length === 0 ||
        this.form.trialPublic.diagnoses.filter((x) => x.trim().length > 0)
          .length === 0
      ) {
        valid = false;
        errorMessage += "At least one diagnosis needed.\n";
      }

      if (
        !this.form.trialPrivate.centreManagementPath ||
        !this.form.trialPrivate.centreManagementPath.length === 0
      ) {
        valid = false;
        errorMessage += "Missing management path.\n";
      }

      if (!this.form.trialPublic.thisTrialIs) {
        warningMessage += "Missing one-sentence summary.\n";
      }

      if (
        !this.form.trialPublic.bullets ||
        this.form.trialPublic.bullets.length === 0 ||
        this.form.trialPublic.bullets[0].length === 0
      ) {
        warningMessage += "Missing bullet point.\n";
      }
      return { valid, warningMessage, errorMessage };
    },

    userHasEdited: function () {
      const dummy = Math.floor(this.userHasEditedTriggerRecomputation * 10); // Complex enough not to be optimized away
      if (dummy + 1 < dummy) {
        // Stop EsLing complaining about not using dummy
      }
      return (
        jsonStableStringify(this.form.trialCriterion) !==
          this.formFromCloudJSON.trialCriterion ||
        jsonStableStringify(this.form.trialPublic) !==
          this.formFromCloudJSON.trialPublic ||
        jsonStableStringify(this.form.trialPrivate) !==
          this.formFromCloudJSON.trialPrivate
      );
    },

    geographiesCheckObj: function () {
      const dummy = Math.floor(
        this.geographiesCheckObjTriggerRecomputation * 10
      );
      if (dummy + 1 < dummy) {
        // Stop EsLing complaining about not using dummy
      }
      let valid = true;
      let warningMessage = "";
      let errorMessage = "";
      let itemsNoComma = (this.form.trialPublic.geographies || "")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x.length > 0);
      let formattedItems = itemsNoComma.map((item) => {
        let domain = item.slice(0, 1).toLowerCase();
        let value = item.slice(1).trim();
        if (domain === "g") {
          // g represents global: everyone is welcome
          value = ""; // Any suffix of "g" gets dropped
        } else if (domain === "c") {
          // Country code
          value = value.toUpperCase();
          if (value === "UK") {
            value = "GB";
            warningMessage +=
              '"UK" has been changed to "GB" which is the ISO code for whole United Kingdom (Britain + NI).\n';
          }
          item = domain + value;
          if (!Object.keys(geographyCountryCodes).includes(value)) {
            valid = false;
            if (value.length === 2) {
              errorMessage +=
                '"c" indicates country but there is no country with code "' +
                value +
                '".\n';
            } else {
              errorMessage +=
                "After the \"c\", give the two-letter country code, e.g. GB or FR. Non-GB countries are not yet implemented as user locations, so will never be found on searching, so don't bother putting them. If you put GB, a referrer with any UK postcode (for their Trust, not their patient's home address) will find the trial.";
            }
          }
        } else if (domain === "p") {
          value = value.toUpperCase();
          item = domain + value;
          if (!isValidPostcodeZone(value)) {
            valid = false;
            errorMessage +=
              '"p" indicates postcode zone but there is no such postcode zone as "' +
              value +
              '". Use the first word of the full 2-word postcode (the alphanumeric "outcode" such as HA3, W12, W1) or just its initial alphabetical part (HA, W).\n';
          }
        } else if (domain === "r") {
          value = value.toUpperCase();
          item = domain + value;
          if (!isValidRadiusGeography(value)) {
            valid = false;
            errorMessage +=
              '"r" indicates radius in km. This geography must be formatted as r(W12:4) for 4 km from the centre of W12, or r(HA3:1.8). No spaces, no units.\n';
          }
        } else if (domain === "n") {
          item = domain + value;
          if (value.length > 0) {
            valid = false;
            errorMessage +=
              '"n" indicates nowhere, but is not allowed to have further characters, such as "' +
              value +
              '".\n';
          }
        } else {
          valid = false;
          errorMessage +=
            '"' + domain + '" is not a valid type of geography.\n';
        }
        return item; // Variables WITHIN THIS FUNCTION set for validity and error/warning messages.
      });

      if (valid) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.referrerPlaces = referrerPlacesInRange(
          this.form.trialPublic.geographies
        );
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.referrerPlaces = [];
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.trialPublic.geographies = formattedItems.join(",");
      return { valid, errorMessage, warningMessage };
    },
  },
  created: function () {
    trialCriterionRootRef
      .child(this.id)
      .on("value", this.firebaseBindingTrialCriterion);
    trialPublicRootRef
      .child(this.id)
      .on("value", this.firebaseBindingTrialPublic);
    trialPrivateRootRef
      .child(this.id)
      .on("value", this.firebaseBindingTrialPrivate);
  },

  beforeDestroy: function () {
    trialCriterionRootRef
      .child(this.id)
      .off("value", this.firebaseBindingTrialCriterion);
    trialPublicRootRef
      .child(this.id)
      .off("value", this.firebaseBindingTrialPublic);
    trialPrivateRootRef
      .child(this.id)
      .off("value", this.firebaseBindingTrialPrivate);
  },

  methods: {
    // FIREBASE CONNECTION ------------------------------------------

    // 3 identical methods for the various parts of the trial data
    firebaseBindingTrialCriterion: function (snapshot) {
      // This will function will need to be unbound just before component destroyed

      if (snapshot.val()) {
        this.form.trialCriterion = snapshot.val();
      } else {
        this.form.trialCriterion = {};
      }
      this.formFromCloudJSON.trialCriterion = jsonStableStringify(
        this.form.trialCriterion
      );
      this.formTrialCriterionIsLoaded = true;
      this.userHasEditedTriggerRecomputation++;
    },
    firebaseBindingTrialPublic: function (snapshot) {
      if (snapshot.val()) {
        this.form.trialPublic = snapshot.val();
      }
      this.formFromCloudJSON.trialPublic = jsonStableStringify(
        this.form.trialPublic
      );
      this.form.trialPublic = Object.assign(
        {},
        EMPTY_FORM_PUBLIC,
        this.form.trialPublic
      ); // Ensure fields newly added to the EMPTY_FORM_PUBLIC are automatically added to any existing trials when they are opened for editing and saved
      this.formTrialPublicIsLoaded = true;
      this.userHasEditedTriggerRecomputation++;
    },
    firebaseBindingTrialPrivate: function (snapshot) {
      if (snapshot.val()) {
        this.form.trialPrivate = snapshot.val();
      }
      this.formFromCloudJSON.trialPrivate = jsonStableStringify(
        this.form.trialPrivate
      );
      this.form.trialPrivate = Object.assign(
        {},
        EMPTY_FORM_PRIVATE,
        this.form.trialPrivate
      ); // ditto EMPTY_FORM_PRIVATE
      this.formTrialPrivateIsLoaded = true;
      this.userHasEditedTriggerRecomputation++;
    },

    // Nifty things during edit

    onClickAddDiagnosis: function () {
      if (!this.form.trialPublic.diagnoses) {
        this.form.trialPublic.diagnoses = [];
      }
      this.form.trialPublic.diagnoses.push("");
      this.$forceUpdate();
    },

    updateTrialPublicDiagnosesAndDiagnosisTop: function () {
      let rows = this.form.trialPublic.diagnoses || [];
      rows = rows.map((row) => this.tidyPipedString(row, { separator: " | " }));
      rows = rows.filter((row) => row.length > 0);
      if (rows.length === 0) {
        this.form.trialPublic.diagnoses = "";
        this.form.trialPublic.diagnosisTop = "";
      } else if (rows.length >= 1) {
        this.form.trialPublic.diagnosisTop = rows[0];
        this.form.trialPublic.diagnoses = rows;
      }
      this.$forceUpdate();
    },

    tidyPipedString: function (row, params) {
      // Params is an object containing any subset of these properties:
      //   capitalizeFirst: Boolean, default false. Capitalize the first letter of the first word of each item in the path
      //   spacer: Separator, default " | ". What to put between the items if there is more than one.

      if (!params) {
        params = {};
      }
      if (!params.separator) {
        params.separator = " , ";
      }
      let items = row
        .trim()
        .split("|")
        .map((x) => x.trim())
        .filter((x) => x.length > 0);
      if (params.capitalizeFirst) {
        items = items.map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1)
        );
      }
      return items.join(params.separator);
    },

    onFormatPipedInput: function (value /*, event*/) {
      return this.tidyPipedString(value, {
        capitalizeFirst: true,
        separator: " | ",
      });
    },

    onClickAddBullet: function () {
      if (!this.form.trialPublic.bullets) {
        this.form.trialPublic.bullets = [];
      }
      this.form.trialPublic.bullets.push("");
      this.$forceUpdate();
    },

    bulletPlaceholder: function (index) {
      switch (index) {
        case 0:
          return "e.g. 3 injections";
        case 1:
          return "e.g. one year";
        case 2:
          return "e.g. 6 visits to St Olav's Hospital";
        case 3:
          return "";
        case 4:
          return "Warning: too many bullets.";
        default:
          return "Too many! Trial may be omitted from search.";
      }
    },

    updateBullets: function () {
      this.form.trialPublic.bullets = this.form.trialPublic.bullets
        .map((bullet) => bullet.trim())
        .filter((bullet) => bullet.length > 0);
      this.$forceUpdate();
    },

    // TRANSITIONING & SAVING
    //    ------------------------------------------

    onSubmit: function () {
      this.updateBullets(); // Removes any blank bullets, for example
      this.updateTrialPublicDiagnosesAndDiagnosisTop(); // Need this because of the edge case of creating a new diagnosis, not entering anything, and then pressing save: the new diagnosis never gets focus so never triggers @blur.native
      Promise.all([
        trialCriterionRootRef.child(this.id).set(this.form.trialCriterion),
        trialPublicRootRef.child(this.id).set(this.form.trialPublic),
        trialPrivateRootRef.child(this.id).set(this.form.trialPrivate),
      ]).then(() => {
        // Don't go back. When they SAVE, they need to have the option of editing the trial logic
        //    this.$router.back();
      });
    },

    goEditTrialLogic: function () {
      this.$router.push({ name: "editTrialLogic", params: { id: this.id } });
    },

    goEditTrialAccess: function () {
      this.$router.push({
        name: "manageTrialsOne",
        params: {
          id: this.id,
          trialPrivate:
            this
              .trialPrivate /* this is an OBJECT so you can pass it but it won't be remembered on page refresh or BACK button, but that's OK for this use */,
        },
      });
    },

    goBack: function () {
      // Skip back TWO because there is a problem with losing the value of trialPrivate if you do "-1". It is added by the GRANDPARENT (HQ) and works the first time you go from grandparent to parent, but when you come here and go back, it is lost. Not sure why
      this.$router.go(-2);
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div
    class="px-2 pt-2 d-flex flex-column justify-content-between"
    style="height: 100vh"
  >
    <app-header-bar
      title="Researcher Home"
      :hide-back-arrow="true"
      :hide-home-icon="true"
    ></app-header-bar>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('resReferralAll')"
    >
      <svg class="ion" style="height: 60px; width: 60px">
        <use xlink:href="#referred-in" />
      </svg>
      <h4 style="width: 60%">Incoming referrals</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('resRandomize')"
    >
      <svg
        class="ion"
        style="height: 60px; width: 60px; transform: rotate(180deg)"
      >
        <use xlink:href="#ion-network" />
      </svg>
      <h4 style="width: 60%">Randomize a patient</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      v-if="user.privilegesObj.seeResMonitor"
      @click="goPage('resMonitorAll')"
    >
      <svg class="ion" style="height: 60px; width: 60px">
        <use xlink:href="#ion-grid" />
      </svg>
      <h4 style="width: 60%">Randomized patients</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row justify-content-around align-items-center"
      @click="goPage('my')"
    >
      <svg class="ion" style="height: 60px; width: 60px">
        <use xlink:href="#ion-android-person" />
      </svg>
      <h4 style="width: 60%">My details</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <!-- <div
      class="d-flex flex-row  justify-content-around align-items-center"
      @click="goPage('resTrialAll')"
    >
      <svg
        class="ion"
        style="height:60px; width:60px; transform: rotate(180deg);"
      >
        <use xlink:href="#ion-network"></use>
      </svg>
      <h4 style="width:60%;">My trials</h4>
      <svg
        class="ion"
      >
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
    </div> -->
    <home-pages-bar from="researcher" :user="user"></home-pages-bar>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import HomePagesBar from "./HomePagesBar.vue";

export default {
  components: { AppHeaderBar, HomePagesBar },

  props: {
    user: { type: Object, required: true },
  },

  created() {
    this.$emit("checkUserGivenEnoughInfo");
    this.user.contactRef.update({ lastHomePage: "researcher" });
  },

  methods: {
    goPage(pageName) {
      this.$router.push({ name: pageName });
    },
  },
};
</script>

<style>
</style>

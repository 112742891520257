<template>
  <div class="p-4 d-flex flex-column justify-content-around" style="height:100vh; max-width:100%;">
    <app-header-bar title></app-header-bar>

    <transition name="swish">
      <div v-if="user.contactObj.inTourStage===2">
        <tour-message>
          <h5>You can explore the options later. For now, tap "People I referred"</h5>
        </tour-message>
      </div>
    </transition>

    <div
      class="d-flex flex-row align-items-center justify-content-between"
      @click="goPage('myLocation')"
    >
      <svg class="ion" style="height:60px; width:60px; ">
        <use xlink:href="#ion-ios-location" />
      </svg>
      <h4 style="width:50%;">My location</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between"
      @click="goPage('myAccount')"
    >
      <svg class="ion" style="height:60px; width:60px;">
        <use xlink:href="#ion-android-person" />
      </svg>
      <h4 style="width:50%;">My account</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between"
      @click="goPage('tellAFriend')"
    >
      <img src="/tell-a-friend.svg" style="height:60px;width:60px;" />
      <h4 style="width:50%;">Tell a friend</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div class="d-flex flex-row align-items-center justify-content-between" @click="onClickDoTour">
      <svg class="ion" style="height:60px; width:60px;">
        <use xlink:href="#ion-eye" />
      </svg>
      <h4 style="width:50%;">Redo tour of app</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between"
      @click="goPage('myPatients')"
    >
      <svg class="ion" style="height:60px; width:60px;">
        <use xlink:href="#referred-out" />
      </svg>
      <h4 style="width:50%;">People I referred</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between"
      @click="goPage('enroltrialc19acs')"
    >
      <svg class="ion" style="height:60px; width:60px;">
        <use xlink:href="#coronavirus" />
      </svg>
      <h4 style="width:50%;">Enrol Covid-19 patient</h4>
      <svg class="ion">
        <use xlink:href="#ion-chevron-right" />
      </svg>
    </div>

    <p style="font-size:70%;text-align:center;">
      Need help?
      <email-admin-link :user="user" sty="color:white;"></email-admin-link>
    </p>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import EmailAdminLink from "./EmailAdminLink.vue";

export default {
  components: { AppHeaderBar, EmailAdminLink },

  props: { user: { type: Object, required: true } },

  created() {
    // if(!this.user.contactObj || !this.user.contactObj.fullName || this.user.contactObj.fullName.length<=1){
    // }
  },

  methods: {
    onClickDoTour() {
      this.user.contactRef
        .update({
          doneTour: false,
          inTour: true,
          inTourStage: 1
        })
        .then(() => this.goPage("home"));
    },

    // Navigation ===============================================

    goPage(pageName) {
      this.$router.push({ name: pageName });
    },

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>

export function combineCriteria(criteriaSought, known, scienceFactObj) {
  const LUMP_UPWARDS = +1;
  const LUMP_DOWNWARDS = -1;

  const critArray = Object.keys(criteriaSought).map(id => criteriaSought[id]);
  const factObj = {};
  const factCriterionObj = {}; /* This is going to be a tiered object:
  fact1:    {
    crit1: {...}}
  */

  /*
INPUT

{
  cri~-LPo5nULKt-pBs25F_En:{
    factId: "fact~-LPQBWoO4IwHmeIu3Jm-"
    id: "cri~-LPo5nULKt-pBs25F_En"
    isIn: true
    optionId: "opt~-LPQBY0T-XZh9INwOB_F"
    title: "Hair Colour is Black"
    trialID: "tri~98709870987"
  },
  cri~-LPo42_Qilef9gRH-CIg: {
    factId: "oneFactId"
    id: "cri~-LPbw0hKfusTa9xy2eXs"
    title: "LVEF < 33"
    ul: 33
    trialID: "tri~f0gdf0g0h0gh"
  },
  cri~-LPoHgMC3YT6M7FD7hgo: {…},
  cri~-LPoNSWuILhQAUl_OF2E: {…}
  ...
}


OUTPUT, a factsSought object

Containing a (single) property for each FACT.

{
  "fac~490d8790fd8": { ... }
  "fac~fsd0fsd0f0f": { ... }
}

Note that if one trial says "red hair" and another "blue hair", then there is one property covering both: "hair"

Each fact is categorical or continuous, and contains the following info.

For CATEGORICAL:
{
  id:

}
{
  categorical:[
    {

    }
  ]

  continuous:
        value: number
        go: +1 or -1
        interestedTrials: {
          "tri~9f87gd90f8g70df9":"tri~9f87gd90f8g70df9"
          "tri~04503fdgd0sg0dgf":"tri~04503fdgd0sg0dgf"
        }


}

*/

  critArray.forEach(criterion => {
    const factId = criterion.factId;
    if (!factObj[factId]) {
      // Fact not seen before
      factObj[factId] = JSON.parse(JSON.stringify(scienceFactObj[factId]));
      factCriterionObj[factId] = {}; // create a branch for this fact so we can add elements for each criterion using this fact
    }
    // Whether old or new, we can now write the fact-criterion
    factCriterionObj[factId][criterion.id] = JSON.parse(JSON.stringify(criterion));
  });
  const factsSought = {};
  const factIdArray = Object.keys(factObj);
  factIdArray.forEach(factId => {
    const fact = factObj[factId];
    if (!factsSought[factId]) {
      factsSought[factId] = JSON.parse(JSON.stringify(fact));
    }
    if (fact.type === "Categorical") combineCriteriaCategorical(factId);
    if (fact.type === "Continuous") combineCriteriaContinuous(factId);
  });
  return { factsSought, factCriterionObj };

  //------------------------------------------------------------

  function combineCriteriaCategorical(factId) {
    let optionsNamed = {};
    let interestedTrials = {};
    Object.keys(factCriterionObj[factId]).forEach(criterionId => {
      const criterion = criteriaSought[criterionId]; // This has the trial ID in it
      const optionId = criterion.optionId;
      const trialId = criterion.trialId;
      if (!optionsNamed[optionId]) {
        optionsNamed[optionId] = {
          id: optionId,
          shortText: scienceFactObj[factId].options[optionId].shortText,
          trials: {}
        };
      }
      optionsNamed[optionId][trialId] = trialId;
      interestedTrials[trialId] = trialId;
    });
    factsSought[factId].optionsNamed = optionsNamed;
    factsSought[factId].interestedTrials = interestedTrials;
  }

  function combineCriteriaContinuous(factId) {
    if (!factsSought[factId].cuts) {
      factsSought[factId].cuts = [];
    }
    const cutsUnsorted = [];
    Object.keys(factCriterionObj[factId]).forEach(criterionId => {
      const criterion = factCriterionObj[factId][criterionId];

      if (criterion.ul) {
        cutsUnsorted.push({
          value: criterion.ul,
          go: criterion.lteq ? LUMP_DOWNWARDS : LUMP_UPWARDS
        });
      }
      if (criterion.ll) {
        cutsUnsorted.push({
          value: criterion.ll,
          go: criterion.gteq ? LUMP_UPWARDS : LUMP_DOWNWARDS
        });
      }
    });

    const cutsJson = cutsUnsorted.map(x => JSON.stringify(x));
    const cutsJsonUnique = [...new Set(cutsJson)];
    const cutsUnique = cutsJsonUnique.map(x => JSON.parse(x));

    const cuts = cutsUnique.sort((cut1, cut2) => {
      // This is written as though we want the highest first (i.e. for rendering in a vertical list) but we later changed to lowest first, to allow wide-screen display, and therefore will ".reverse()" it at the end.
      if (cut1.value === cut2.value) {
        return cut2.go - cut1.go;
      } else {
        return cut2.value - cut1.value;
      }
    });

    const augmentedCuts = [{ dummyExtreme: true }, ...cuts, { dummyExtreme: true }];

    const bands = [];
    for (let iHigh = 0; iHigh < augmentedCuts.length - 1; iHigh++) {
      let pseudoValue = null;
      let shortText = "";
      const iLow = iHigh + 1;
      const cH = augmentedCuts[iHigh];
      const cL = augmentedCuts[iLow];
      if (cH.dummyExtreme) {
        pseudoValue = cL.value + 1; // An arbitrary amount above the uppermost cut
        shortText = cL.go === LUMP_UPWARDS ? cL.value + " and over" : "Over " + cL.value;
      } else if (cL.dummyExtreme) {
        pseudoValue = cH.value - 1; // An arbitrary amount below the lowermost cut
        shortText = cH.go === LUMP_DOWNWARDS ? cH.value + " and under" : "Under " + cH.value;
      } else if (cL.value === cH.value) {
        pseudoValue = cL.value;
        shortText = "Exactly " + cL.value;
      } else {
        pseudoValue = (cL.value + cH.value) / 2;
        shortText = (cL.go === LUMP_UPWARDS ? cL.value : "Over " + cL.value + ",") + " to " + (cH.go === LUMP_DOWNWARDS ? cH.value : "just under " + cH.value);
      }
      bands.push({ shortText, cL, cH, pseudoValue });
    }
    factsSought[factId].bands = bands.reverse();
  }
}

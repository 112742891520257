<template>
  <div class="p-3 d-flex flex-column" style="height: 100vh; max-width: 100%">
    <app-header-bar title="Location"></app-header-bar>

    <section v-if="state === 'home'">
      <div>
        <br />
        <br />

        <h5 v-if="locationNoLongerExist">
          The location you previously gave us is no longer available. Please
          choose a new one.
        </h5>
        <h5 v-else>
          We need to know your location, to show you the right trials.
        </h5>
        <br />
        <br />
        <div v-if="locationHistoryComputedProperty.length > 0">
          <h6>Where are you now?</h6>

          <b-button
            v-for="longName in locationHistoryComputedProperty"
            block
            variant="light"
            size="lg"
            :key="longName"
            @click="setLocationByLongName(longName)"
            >{{ longName }}</b-button
          >

          <br />
          <b-button block variant="light" size="lg" @click="state = 'newLoc'"
            >I'm somewhere else now</b-button
          >
        </div>
        <div v-else>
          <!-- no location history -->
          <b-button block variant="light" size="lg" @click="state = 'newLoc'"
            >OK</b-button
          >
        </div>
      </div>
    </section>

    <section v-if="state === 'newLoc'">
      <br />
      <br />
      <br />
      <div>
        <b-button
          block
          variant="light"
          style="min-height: 4em"
          size="lg"
          @click="
            options = nhsTrusts;
            state = 'newLocSelect';
          "
        >
          UK Hospital
        </b-button>
      </div>

      <br />
      <br />

      <div>
        <b-button
          block
          variant="light"
          style="min-height: 4em"
          size="lg"
          @click="
            options = nhsCcgs;
            state = 'newLocSelect';
          "
        >
          UK Primary Care
        </b-button>
      </div>

      <br />
      <br />

      <div>
        <b-button
          block
          variant="light"
          style="min-height: 4em"
          size="lg"
          @click="
            options = internationalHospitals;
            state = 'newLocSelect';
          "
        >
          Outside UK
        </b-button>
      </div>
    </section>

    <section
      v-if="state === 'newLocSelect'"
      style="
        overflow-y: auto;
        max-height: 100%;
        -webkit-overflow-scrolling: touch;
      "
    >
      <!--
The "-webkit-overflow-scrolling: touch;" is to solve slow scrolling on iPhone
  https://github.com/twbs/bootstrap/issues/26631
      -->
      <b-list-group>
        <b-list-group-item
          v-for="option in optionsValid"
          :key="option.longName"
          style="color: black"
          @click="setLocationByLongName(option.longName)"
          >{{ option.longName }}</b-list-group-item
        >
      </b-list-group>
    </section>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import { nhsTrusts } from "../util/tableLocationsNhsTrusts.js";
import { nhsCcgs } from "../util/tableLocationsNhsCcgs.js";
import { internationalHospitals } from "../util/tableLocationsInternationalHospitals.js";

import {
  existsNhsLocationObjByLongName,
  getOneNhsLocationObjByLongName,
} from "../util/geography.js";

export default {
  components: { AppHeaderBar },

  props: {
    user: { type: Object, required: true },
    locationNoLongerExist: {
      type: Boolean,
      required: false,
      default: false,
    } /* HOME detects that the location longName we have for this user is no longer in our lists of trusts/ccgs and calls this component with this flag set */,
  },

  data() {
    return {
      state: "home",
      options: [], // To be filled with  list of  NHS Trusts OR  list of  CCGs

      // These two are imported constant lists, that are hereby made accessible within "this", so the template can see them
      nhsTrusts,
      nhsCcgs,
      internationalHospitals,
    };
  },

  computed: {
    locationHistoryComputedProperty: function () {
      const rawLocationHistory = this.user.contactObj.locationHistory;
      if (Array.isArray(rawLocationHistory)) {
        return rawLocationHistory.filter((longName) =>
          existsNhsLocationObjByLongName(longName)
        );
      } else {
        return [];
      }
    },

    optionsValid: function () {
      return this.options.filter(
        (option) => option.longName && option.longName.length > 0
      );
    },
  },

  created() {
    if (this.locationNoLongerExist) {
      // We have been called by HOME.vue, after it discovered that the user's current location longName doesn't exist any more
      this.setOrClearLocationAndUpdateHistoryP(null);
    }
  },

  methods: {
    goHome() {
      this.$router.replace({ name: "home" });
    },

    setLocationByLongName(longName) {
      this.setOrClearLocationAndUpdateHistoryP(
        getOneNhsLocationObjByLongName(longName)
      );
    },

    async setOrClearLocationAndUpdateHistoryP(location) {
      if (location /* is non-null */ && location.longName) {
        await this.user.contactRef.update({
          location: location,
        });
        await this.addLocationHistoryP(location.longName);
        this.goHome();
      } else {
        /* location is NULL, i.e. clear it because the firebase-stored location.longName is non-existent */
        await this.user.contactRef.update({
          location: null,
        });
        this.goHome();
      }
    },

    addLocationHistoryP(longName) {
      // Add newest at top, remove any lower occurrences (and indeed any duplicates) and any non-existent trusts
      if (!existsNhsLocationObjByLongName(longName)) {
        throw new Error("No such place as " + longName);
      }
      const oldLocationHistory = this.locationHistoryComputedProperty;
      oldLocationHistory.unshift(longName);

      // Remove duplicates
      const dedupeLocationHistory = [];
      oldLocationHistory.forEach((longName) => {
        if (!dedupeLocationHistory.includes(longName)) {
          dedupeLocationHistory.push(longName);
        }
      });

      // Remove non-existent (e.g. may have become non-existent due to passage of time, since originally added)
      const validLocationHistory = [];
      dedupeLocationHistory.forEach((longName) => {
        if (existsNhsLocationObjByLongName(longName)) {
          validLocationHistory.push(longName);
        }
      });

      //
      return this.user.contactRef
        .child("locationHistory")
        .set(validLocationHistory);
    },
  },
};
</script>

<style>
</style>

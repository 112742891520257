<template>
  <div class="p-2 page-scroll-y">
    <!-- style="height:100vh;  overflow-y:auto; justify-content:space-evenly" -->
    <div id="topMessageToClinician">
      <transition name="swish">
        <section v-if="user.contactObj.inTour">
          <tour-message>
            <h3>
              At this stage, you
              <div style="font-size:150%;">
                give the phone
                to the patient
              </div>to answer one question:
            </h3>
            <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
            </div>-->
          </tour-message>
        </section>
      </transition>

      <h3 style="text-align:center" v-if="!user.contactObj.inTour">
        Give screen to
        <em>patient</em>&nbsp; to choose and click
      </h3>
    </div>

    <hr style="width:100%; border:solid white 0.5px;" />

    <div
      id="mainMessageToPatient"
      class="p-4"
      :style="'max-width:40em; color:black; border-radius:0.5em; ' + (user.contactObj.inTour?'background-color:darkgray; ':'background-color:white;')"
    >
      <h4>
        <span v-if="user.contactObj.location.la==='pt'">
          Prezado paciente,
          <br />Os médicos e equipe do estudo gostariam de verificar seus dados médicos com mais detalhes para ver se a pesquisa se aplica para você. Nós contactaremos você com mais informações.
        </span>
        <span v-else>
          Dear Patient,
          <br />The research doctors and nurses have offered to check your medical records in more detail, to see if the research suits you, and then contact you with more information.
          <br />
        </span>
      </h4>

      <transition name="swish">
        <b-alert variant="primary" show v-if="showPrivacyInformation">
          <span
            v-if="user.contactObj.location.la==='pt'"
          >Este Sistema armazena seu número de registro hospitalar de forma segura para a equipe da pesquisa ter conhecimento do seu interesse no estudo. Estas informações são confidenciais e não serão divulgadas.</span>
          <span
            v-else
          >This system conveys your patient identity number securely to the research team to show your interest in hearing more about the trial if the trial matches your medical condition. No-one else will receive it. It will not be transferred outside the UK. You have a right to a copy of the data, a right to lodge a complaint with the Data Protection Authority, and a right to withdraw consent at any time. There is no automated decision-making.</span>
        </b-alert>
      </transition>

      <h6
        style="font-size:90%; color:#005eb9;"
        @click="showPrivacyInformation = !showPrivacyInformation"
      >
        <span v-if="showPrivacyInformation">
          <span v-if="user.contactObj.location.la==='pt'">Fechar informações de privacidade</span>
          <span v-else>Close privacy information</span>
        </span>
        <span v-else>
          <span v-if="user.contactObj.location.la==='pt'">Mostrar informações de privacidade</span>
          <span v-else>Show privacy information</span>
        </span>
      </h6>

      <h4>
        <span
          v-if="user.contactObj.location.la==='pt'"
        >Você nos dá permissão para ver seus registros medicos?</span>
        <span v-else>Do you give permission for this?</span>
      </h4>

      <div class="d-flex flex-row justify-content-between">
        <div @click="goCancel()">
          <b-button variant="danger" size="lg">
            &nbsp;
            <span v-if="user.contactObj.location.la==='pt'">Não</span>
            <span v-else>No</span>
            &nbsp;
          </b-button>
        </div>
        <div @click="goNext()">
          <div>
            <b-button variant="primary" size="lg">
              &nbsp; &nbsp;
              <span v-if="user.contactObj.location.la==='pt'">Sim</span>
              <span v-else>Yes</span>
              &nbsp; &nbsp;
            </b-button>
          </div>
          <div style="margin-top:0.5em; line-height:1em;text-align:center;">
            <span v-if="user.contactObj.location.la==='pt'">Eu autorizo</span>
            <span v-else>
              Let them check
              <br />and then call me
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { YES, DEPENDS } from "../util/constants.js";

export default {
  props: {
    trialIdsPatientAccepted: {
      type: Array,
      default: () => [
        // This is just a factory function to avoid an error message in Vue
        {
          id: "tri~-LPusKzJKsh3ESE0s19t",
          suit: YES /* whether eligibility confirmed or still depends on some unanswered Qs */
        },
        { id: "tri~dummy-for-testing-001", suit: DEPENDS }
      ]
    },
    user: { type: Object, required: true }
  },
  data: function() {
    return {
      showPrivacyInformation: false
    };
  },
  computed: {
    nTrials: function() {
      return this.trialIdsPatientAccepted.length;
    },
    pluralTrials: function() {
      return this.trialIdsPatientAccepted.length > 1;
    }
  },
  methods: {
    goCancel: function() {
      this.$router.push({
        name: "clin42ThanksForConsidering"
      });
    },

    goNext: function() {
      this.$router.push({
        name: "clin50NhsNumber",
        params: { trialIdsPatientAccepted: this.trialIdsPatientAccepted }
      });
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <div style="height:100vh; " class="p-2 d-flex flex-column justify-content-between">
    <app-header-bar title></app-header-bar>

    <section id="middleScrolling" class="iphone-scroll-fudge" style="height:100%;overflow-y:auto;">
      <tour-message>
        <h3>Tour complete!</h3>
        <h5>Please use this App whenever you are with patients.</h5>
        <h5>As you saw, it takes &lt;1 minute to screen and refer.</h5>
        <br>
        <div class="d-flex flex-row justify-content-between">
          <b-button variant="danger" @click="onClickRedo">Do tour again</b-button>
          <b-button variant="primary" @click="onClickOk">&nbsp;OK, I got it&nbsp;</b-button>
        </div>
      </tour-message>
    </section>

    <section id="bottomBar" class="mt-2 d-flex justify-content-between" style="flex-shrink:0;"></section>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { AppHeaderBar },
  props: { user: { type: Object, required: true } },
  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //
    onClickOk() {
      this.user.contactRef.update({
        doneTour: true,
        inTour: false,
        inTourStage: null
      });
      this.$router.replace({ name: "clinician" });
    },

    onClickRedo() {
      this.user.contactRef.update({ inTour: true, inTourStage: 1 });
      this.$router.replace({ name: "clinician" });
    },
    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
</style>

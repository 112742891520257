<template>
  <div
    class="d-flex flex-column justify-content-space-between align-items-center"
    style="background-color:white; color:black; height:100vh; width:100vw;"
  >
    <div id="centered-div" style="width:100vw; max-width:30em; min-height:100vh; max-height:40em;">
      <section
        v-if="referralObj.statusId"
        style="height:100%;"
        class="d-flex flex-column justify-content-between"
      >
        <!-- test for statusId is a proxy for the whole referralObj being loaded -->

        <div
          id="status"
          class="p-2 d-flex flex-column justify-content-center align-items-center"
          :style="{'min-height':'15vh','background-color':statusObjFromId(referralObj.statusId).background,'color':statusObjFromId(referralObj.statusId).color,'min-width':'100%'}"
          @click="onClickStatus"
        >
          <h2 class="my-1">{{statusObjFromId(referralObj.statusId).short}}</h2>
          <h6 class="my-1">{{statusObjFromId(referralObj.statusId).long}}</h6>
          <h6 class="my-1">{{referralObj.reason}}</h6>
        </div>

        <div
          class="p-2 d-flex flex-column justify-content-around"
          style="height:100%;"
          id="everythingOtherThanTopColouredBar"
        >
          <div class="mt-2 d-flex flex-row justify-content-between">
            <b-button v-on:click="goBack" variant="light">
              <svg class="ion">
                <use xlink:href="#ion-chevron-left" />
              </svg>
            </b-button>

            <b-button
              variant="outline-dark"
              class="d-flex flex-row"
              @click="onClickStatus"
              :style="{'background-color':statusObjFromId(referralObj.statusId).background,'color':statusObjFromId(referralObj.statusId).color}"
            >
              <svg class="ion ion-edit">
                <use xlink:href="#ion-edit" />
              </svg> &nbsp;
              <div style="font-size:0.8rem;">
                <span v-if="referralObj.statusId<'sta~100750'">
                  Keep referrers keen
                  <br />with status updates
                </span>
              </div>
            </b-button>
          </div>

          <transition name="swish">
            <div v-if="trapdoorError">
              <h5>{{trapdoorError}}</h5>
            </div>
          </transition>

          <div id="nhsNumber">
            <h1 align="center" class="mt-0 pt-0">
              <span>{{trapdoorItem.nhsNumberString }}</span>
              <Spinner v-if="!trapdoorItem.nhsNumberString"></Spinner>
            </h1>
          </div>

          <div id="recordLocation" v-if="trapdoorItem.recordLocation">
            <h6 class="mb-0 pb-0" style="color:gray;">Details on</h6>
            <h2>{{trapdoorItem.recordLocation}}</h2>
          </div>

          <div id="contactPreferences" v-if="trapdoorItem.contactPreferences">
            <h6 class="mb-0 pb-0" style="color:gray;">Patient's preferred contact</h6>
            <h2>{{trapdoorItem.contactPreferences}}</h2>
          </div>
          <div v-if="!trapdoorItem.contactPreferences">
            <!-- <h5>
          None stated
            </h5>-->
          </div>

          <div id="referralSourceDetails">
            <div v-if="referrerIdentity">
              <h6 style="color:gray;">Referred by</h6>
              <h6>{{referrerIdentity}}</h6>
              <h6>{{referralObj.referrerLocationText||""}}</h6>
            </div>
            <div v-else>
              <h6 style="color:gray;">Patient's location</h6>
            </div>
            <h6>
              {{trapdoorItem.permissionLocation}}
              <!-- {{new Date(referralObj.referralIso).toLocaleString()}}. -->
            </h6>
          </div>

          <div v-if="trapdoorItem && referrerIdentity">
            <div
              v-if="referralObj.statusEvents && typeof referralObj.statusEvents ==='object' && Object.keys(referralObj.statusEvents).length>0"
              id="history"
            >
              <h6 class="mt-1" style="color:gray;">Progress events</h6>
              <div
                v-for="(statusEvent,statusEventId) in referralObj.statusEvents"
                :key="statusEventId"
                class="my-2 d-flex flex-row align-items-center"
              >
                <div
                  id="dateStack"
                  style="width:4em; flex-grow:0; flex-shrink:0; "
                  class="d-flex flex-column align-items-center"
                >
                  <div style="font-size:150%;line-height:1;">{{dateParts(statusEvent.iso).date}}</div>
                  <div style="font-size:100%;line-height:1;">{{dateParts(statusEvent.iso).month}}</div>
                  <div style="font-size:70%;line-height:1;">{{dateParts(statusEvent.iso).hhmm}}</div>
                </div>

                <div id="statusAndReasonStack">
                  <div
                    style="font-size:100%; "
                  >{{statusObjFromId(statusEvent.statusId,{newReferral:true}).long}}</div>
                  <div style="font-size:70%; line-height:1.1;">{{statusEvent.reason}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-else>
        <!-- not loaded status Id, i.e. not loaded anything useful -->
        <spinner></spinner>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from "./ext/Spinner.vue";
import { dateParts } from "../util/formatDate.js";
import {
  // Data
  STATUS_OPTIONS,
  // Functions
  dbRefFromURL,
  statusObjFromId,
  getFullNameOrEmailFromIdObjToDataObj,
  setStatus
} from "../util/firebase.js";
import { TRAPDOOR_READ } from "../util/amazon.js";

export default {
  components: { Spinner },

  props: {
    user: { type: Object, required: true },
    trialReferralRefString: {
      // This is the URL of the firebase node containing the trialReferral (unique referral).
      type: String,
      required: true
    }
  },

  data() {
    return {
      // Make accessible in THIS:
      STATUS_OPTIONS,

      // Actual data:
      referralObj: {},
      trapdoorItem: {}, // Needs to be empty object so we can test for properties existing
      trapdoorError: undefined, // Needs to be undefined because we test for WHOLE THING existing
      referrerIdentity: ""
    };
  },

  computed: {},

  async created() {
    dbRefFromURL(this.trialReferralRefString).on(
      "value",
      this.onTrialReferralLoadedOrChanged
    );
  },

  beforeDestroy() {
    dbRefFromURL(this.trialReferralRefString).off(
      "value",
      this.onTrialReferralLoadedOrChanged
    );
  },

  methods: {
    onTrialReferralLoadedOrChanged(snapshot) {
      // Must do first, then other two in either order
      this.referralObj = snapshot.val();
      this.keepGettingTrapdoorInfo(); // From AWS
      this.getReferrerIdentity(); // From firebase (doesn't depend on AWS result)

      // If any non-HQ staff open this item, we log it as opened
      if (
        !this.user.privilegesObj.hq &&
        this.referralObj.statusId === "sta~100100"
      ) {
        const statusObj = {
          trialId: this.referralObj.trialId,
          referralId: this.referralObj.referralId,
          referrerId: this.referralObj.referrerId,
          statusId: "sta~100200"
        };
        setStatus(statusObj);
      }
    },

    timeoutMs(ms) {
      // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async keepGettingTrapdoorInfo() {
      const postObject = {
        referralId: this.referralObj.referralId,
        referralPassword: this.referralObj.referralPassword
      };

      let gotItem = false;
      let delayMs = 1000;
      while (!gotItem) {
        let trapdoor = await this.postRequestRtnResponseError(
          TRAPDOOR_READ,
          postObject
        );
        this.trapdoorItem = (trapdoor.response || {}).Item || {};
        this.trapdoorError = trapdoor.error || undefined;
        if (this.trapdoorError) {
          console.warn(
            "Failed trapdoor communication, retrying in ",
            Math.round(delayMs / 100) / 10,
            " seconds."
          );
          await this.timeoutMs(delayMs);
          delayMs *= 1.2;
          if (delayMs >= 5 * 60 * 1000) {
            delayMs = 5 * 60 * 1000;
          }
        } else {
          gotItem = true;
        }
      }
    },

    async postRequestRtnResponseError(url, data) {
      // https://gist.github.com/justsml/529d0b1ddc5249095ff4b890aad5e801
      try {
        const fetchPromise = await fetch(url, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json"
          }),
          body: JSON.stringify(data) // Coordinate the body type with 'Content-Type'
        });
        const response = await fetchPromise.json();
        return { response, error: null };
      } catch (error) {
        console.error("error in postReq", error);
        return { response: null, error: error };
      }
    },

    async getReferrerIdentity() {
      // from FIREBASE not google
      const dataObj = await getFullNameOrEmailFromIdObjToDataObj({
        id: this.referralObj.referrerId
      });
      this.referrerIdentity = dataObj.data.identity;
    },

    // FORMATTER FUNCTIONS =====================================

    isoIsMoreThanNDaysAgo(isoString, days = 300) {
      const oldDate = new Date(isoString);
      const nowDate = new Date();
      const diffMs = nowDate - oldDate;
      const oneDayMs = 1000 * 60 * 60 * 24;
      return diffMs > days * oneDayMs;
    },

    // make accessible in THIS
    dateParts,
    statusObjFromId,
    getFullNameOrEmailFromIdObjToDataObj,

    // NAVIGATION FUNCTIONS =============================

    onClickStatus() {
      this.$router.push({
        name: "resRefStatus",
        params: {
          trialId: this.referralObj.trialId,
          referralId: this.referralObj.referralId,
          referrerId: this.referralObj.referrerId
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>

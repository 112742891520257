export function formatMobile(raw) {
  const analysis = processMobile(raw);
  return analysis.numberString;
}

export function processMobile(raw) {
  let digits = raw.replace(/[^0-9]/g, "");

  let internationalDigits = null;
  if (digits.length === 10 && digits.slice(0, 1) === "7") {
    internationalDigits = "447" + digits.slice(1);
  } else if (digits.length === 11 && digits.slice(0, 2) === "07") {
    internationalDigits = "447" + digits.slice(2);
  } else if (digits.length === 12 && digits.slice(0, 3) === "447") {
    internationalDigits = "447" + digits.slice(3);
  }
  if (internationalDigits && internationalDigits.length > 12) {
    internationalDigits = internationalDigits.slice(0, 12);
  }

  if (internationalDigits) {
    return {
      valid: true,
      numberString:
        "+" +
        internationalDigits.slice(0, 2) +
        " " +
        internationalDigits.slice(2, 6) +
        " " +
        internationalDigits.slice(6, 9) +
        " " +
        internationalDigits.slice(9, 12)
    };
  } else {
    return { valid: false, numberString: raw };
  }
}

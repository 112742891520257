<template>
  <div class="p-2 d-flex flex-column" style="height: 100vh">
    <app-header-bar title="Randomised patients"></app-header-bar>

    <!--
 _____            _
/  ___|          | |
\ `--.  ___  _ __| |_
 `--. \/ _ \| '__| __|
/\__/ / (_) | |  | |_
\____/ \___/|_|   \__|
    -->
    <section
      id="sortingReferrals"
      v-if="myMonitorsArrayFilteredForDisplay.length > 1"
      class="mb-1 small"
    >
      <div
        v-if="
          currentSortContext.sortBy === 'statusId' &&
          currentSortContext.sortDesc === false
        "
      >
        In order of priority
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-2"
          @click="
            explainWhyOrderedByPriority = !explainWhyOrderedByPriority;
            choosingWhichOneToShow = false;
          "
          >Why?</b-button
        >
        <transition name="swish">
          <div
            v-if="explainWhyOrderedByPriority"
            style="
              background-color: white;
              color: black;
              border-radius: 1em;
              padding: 1em;
            "
          >
            <h5>Take control with priority ordering</h5>
            <p>
              Attend to the unopened and uncalled patients first. Once dealt
              with, they move down in priority.
            </p>
            <p>
              Patients progress through the stages until they enter the study
              (blue) or do not (grey).
            </p>
            <p>
              No patient can be lost, and you always spend your precious time on
              the highest-priority tasks.
            </p>

            <h5>Inbox-like alternative</h5>
            If you prefer the 'inbox-like' display, click twice on 'Time'. Now
            the most recent referrals are at the top, and older ones further
            down, where they may be forgotten.
            <div class="d-flex flex-row justify-content-end">
              <b-button
                variant="primary"
                @click="explainWhyOrderedByPriority = false"
                >OK</b-button
              >
            </div>
          </div>
        </transition>
      </div>
    </section>

    <!--
______ _ _ _
|  ___(_) | |
| |_   _| | |_ ___ _ __
|  _| | | | __/ _ \ '__|
| |   | | | ||  __/ |
\_|   |_|_|\__\___|_|
    -->
    <section
      id="filteringTrials"
      v-if="myTrialIds && myTrialIds.length > 1"
      class="mb-2"
    >
      <transition name="swish">
        <div v-if="choosingWhichOneToShow">
          Choose trial to display:
          <b-list-group style="z-index: 100">
            <b-list-group-item
              v-for="trialId in myTrialIds"
              :key="trialId"
              @click="
                displayTrialIds = [trialId];
                choosingWhichOneToShow = false;
              "
              style="
                border-radius: 0.8em;
                background-color: white;
                color: black;
              "
              class="mb-1"
              >{{ trials.publicObj[trialId].shortText }}</b-list-group-item
            >
            <b-list-group-item
              @click="
                displayTrialIds = myTrialIds;
                choosingWhichOneToShow = false;
              "
              style="
                border-radius: 0.8em;
                background-color: lightgray;
                color: black;
              "
              class="mb-3"
            >
              Return to showing
              <strong>all</strong> trials
            </b-list-group-item>
          </b-list-group>
        </div>
      </transition>

      <div
        v-if="!choosingWhichOneToShow && displayTrialIds.length === 1"
        class="d-flex flex-row justify-content-start align-items-baseline small"
      >
        Showing only {{ trials.publicObj[displayTrialIds[0]].shortText }} &nbsp;
        &nbsp;
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-1"
          @click="
            choosingWhichOneToShow = true;
            explainWhyOrderedByPriority = false;
          "
          >Change</b-button
        >
      </div>

      <div
        v-if="!choosingWhichOneToShow && displayTrialIds.length > 1"
        class="d-flex flex-row justify-content-start align-items-baseline small"
      >
        Showing all trials &nbsp; &nbsp;
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-1"
          @click="
            choosingWhichOneToShow = true;
            explainWhyOrderedByPriority = false;
          "
          >Show only one</b-button
        >
      </div>
    </section>

    <!--
  _____     _     _        _ _            _  __
 |_   _|   | |   | |      (_) |          | |/ _|
   | | __ _| |__ | | ___   _| |_ ___  ___| | |_
   | |/ _` | '_ \| |/ _ \ | | __/ __|/ _ \ |  _|
   | | (_| | |_) | |  __/ | | |_\__ \  __/ | |
   \_/\__,_|_.__/|_|\___| |_|\__|___/\___|_|_|
    -->

    <section
      v-if="myMonitorsArrayIsLoaded"
      style="height: 100%; width: 100%; overflow-y: auto"
    >
      <div
        v-if="myMonitorsArrayFilteredForDisplay.length === 0"
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 100%"
      >
        <h1>None</h1>
      </div>

      <b-table
        v-if="myMonitorsArrayFilteredForDisplay.length > 0"
        :items="myMonitorsArrayFilteredForDisplay"
        :fields="fields"
        sort-by="priority"
        striped
        @DISABLED___row-clicked="onClickOneMonitor"
        @sort-changed="sortingChanged"
      >
        <template #cell(enrolmentIso)="data">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-2">
              {{ dateParts(data.item.enrolmentIso).dateMonth }}
            </div>
            <div v-if="isoIsMoreThanNDaysAgo(data.item.enrolmentIso, 360)">
              {{ dateParts(data.item.enrolmentIso).year }}
            </div>
            <div style="font-size: 90%">
              {{ dateParts(data.item.enrolmentIso).t.slice(0, 5) }}
            </div>
            <div style="font-size: 90%; width: 1em"></div>
          </div>
        </template>

        <template #cell(trialId)="data">
          <span style="word-break: break-all">{{
            trials.publicObj[data.item.trialId].shortText
          }}</span>
        </template>

        <!-- <template slot="tickA" slot-scope="data">
          <div v-if="data.item.tickA">✓</div>
        </template>

        <template slot="tickB" slot-scope="data">
          <div v-if="data.item.tickB">✓</div>
        </template>

        <template slot="tickC" slot-scope="data">
          <div v-if="data.item.tickC">✓</div>
        </template>-->
      </b-table>
    </section>

    <section id="bottom">
      <transition name="swish">
        <div v-if="showNotResearcherMessage">
          When your researchers click on a referral, the personal information
          will be obtained and decrypted for them.
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import {
  // Data
  accessByUserThenTrialRef,
  trialMonitorRootRef,
  // Functions
  statusObjFromId,
} from "../util/firebase.js";
import { bindBranchAsREFandOBJandISLOADED } from "../util/firebaseBind.js";
import {
  dateParts,
  isoFromReferralId,
  timeAgoHumanFromIso,
  timeAgoTwoTierFromIso,
  timeAgoMsFromIso,
  isoIsMoreThanNDaysAgo,
} from "../util/formatDate.js";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  name: "ResMonitorAll",
  components: { AppHeaderBar },

  props: {
    user: { type: Object, required: true },
    trials: { type: Object, required: true },
  },

  data() {
    return {
      myAccess: {},
      myTrialIds: [],
      displayTrialIds: [], // The subset I have chosen for display
      choosingWhichOneToShow: false,
      choosingSortOrder: false,
      currentSortContext: {
        sortBy: "enrolmentIso",
        sortDesc: false, // Actual  sortContext returned by sortingChanged callback contains lots of other (irrelevant) stuff
      },
      explainWhyOrderedByPriority: false,

      myMonitorsObj: {},
      myMonitorsArray: [],
      myMonitorsArrayIsLoaded: false,
      // Table

      fields: [
        {
          label:
            "Date rand" /* this is replaced in a scoped slot by WAIT TIME */,
          key: "enrolmentIso",
          sortable: true,
        },
        {
          label: "Study Id",
          key: "enrolmentId",
          sortable: true,
          formatter: (str) => {
            const s = (str || "").replace(/ /g, "");
            return `${s.slice(0, 3)} ${s.slice(3)}`;
          },
        },
        { label: "Site", key: "location_short_name", sortable: true },
        ...(this.user.privilegesObj.hq
          ? [
              {
                label: "NHS Number",
                key: "nhsNumberString",
                sortable: true,
                formatter: (str) => {
                  const s = (str || "").replace(/ /g, "");
                  return `${s.slice(0, 3)} ${s.slice(3, 6)} ${s.slice(6)} ${
                    s === "0000000000" ? " (test)" : ""
                  }`;
                },
              },
            ]
          : []),
        {
          label: "Arm",
          key: "allocation",
          sortable: true,
          formatter: (str) => (str || "").toUpperCase(),
        },
        {
          label: "Consented by",
          key: "email",
          sortable: true,
          formatter: (str) => str.split("@")[0],
        },
        // { label: "Tick A", key: "tickA", sortable: true },
        // { label: "Tick B", key: "tickB", sortable: true },
        // { label: "Tick C", key: "tickC", sortable: true },
        { label: "Trial", key: "trialId", sortable: true },

        // { label: "priority", key: "priority", sortable: true }
      ],
      showNotResearcherMessage: false,
    };
  },

  computed: {
    clientWidth: () => document.documentElement.clientWidth,
    clientHeight: () => document.documentElement.clientHeight,
    myMonitorsArrayFilteredForDisplay: function () {
      // must use the FUNCTION() format, otherwise THIS doesn't get set by Vue
      return this.myMonitorsArray.filter((monitor) =>
        this.displayTrialIds.includes(monitor.trialId)
      );
    },
  },

  created() {
    this.watchAccessAndBindTrialsMonitor();
  },

  beforeDestroy() {
    this.undo_watchAccessAndBindTrialsMonitor();
  },

  methods: {
    // Make available in THIS
    statusObjFromId,

    watchAccessAndBindTrialsMonitor() {
      if (!this.trials.monitor) {
        this.trials.monitor = {};
      }
      accessByUserThenTrialRef.child(this.user.id).on("value", (snapshot) => {
        this.myAccess = snapshot.val();
        this.myTrialIds = Object.keys(this.myAccess);
        this.displayTrialIds = JSON.parse(JSON.stringify(this.myTrialIds));
        this.myTrialIds.forEach((trialId) => {
          bindBranchAsREFandOBJandISLOADED(
            this.trials.monitor,
            trialId,
            trialMonitorRootRef.child(trialId).child("short"),
            {
              callback: this.updateMyMonitors, // So, as each trial's referrals become available through this binding, they will appear on screen. Likewise, if new data arrives, they will be updated into THIS.MyMonitors
            }
          );
        });
      });
    },

    undo_watchAccessAndBindTrialsMonitor() {
      accessByUserThenTrialRef.child(this.user.id).off("value");
      this.myTrialIds.forEach((trialId) => {
        bindBranchAsREFandOBJandISLOADED(this.trials.monitor, trialId, null);
      });
    },

    updateMyMonitors() {
      let allMonitors = {};
      if (this.myTrialIds) {
        this.myTrialIds.forEach((trialId) => {
          if (this.trials.monitor[trialId + "IsLoaded"]) {
            const oneTrialObj = this.trials.monitor[trialId + "Obj"];
            const oneTrialRef = this.trials.monitor[trialId + "Ref"];
            const enrolmentIds = Object.keys(oneTrialObj);
            enrolmentIds.forEach((enrolmentId) => {
              const uniqueMonitorId = trialId + "_" + enrolmentId;
              const oneMonitorObj = this.trials.monitor[trialId + "Obj"][
                enrolmentId
              ];
              const priority = oneMonitorObj.statusId + "_" + enrolmentId;

              allMonitors[uniqueMonitorId] = Object.assign(
                {},
                this.trials.monitor[trialId + "Obj"][enrolmentId],
                {
                  trialId,
                  enrolmentId,
                  uniqueMonitorId,
                  shortText: this.trials.publicObj[trialId].shortText,
                  trialMonitorRefString: oneTrialRef
                    .child(enrolmentId)
                    .toString(),
                  priority,
                }
              );
            });
          }
        });
      }
      this.myMonitorsObj = allMonitors;
      this.myMonitorsArray = Object.keys(allMonitors).map(
        (uniqueMonitorId) => ({
          ...this.myMonitorsObj[uniqueMonitorId],
        })
      );
      this.myMonitorsArray.sort((refA, refB) =>
        refA.priority < refB.priority
          ? -1
          : refA.priority > refB.priority
          ? 1
          : 0
      );
      this.myMonitorsArrayIsLoaded = true;
    },

    sortingChanged(ctx) {
      this.currentSortContext = ctx;
      this.explainWhyOrderedByPriority = false;
    },

    // FORMATTING FUNCTIONS ======================

    // transduce from global (imported) scope to THIS
    timeAgoHumanFromIso,
    timeAgoTwoTierFromIso,
    timeAgoMsFromIso,
    isoIsMoreThanNDaysAgo,
    isoFromReferralId,
    dateParts,

    // NAVIGATION FUNCTIONS =====================

    onClickOneMonitor: function (referralObj /*, index */) {
      if (this.user.privilegesObj.researcher === true) {
        this.$router.push({
          name: "resReferralOne",
          params: {
            trialReferralRefString: referralObj.trialReferralRefString,
          },
        });
      } else {
        this.showNotResearcherMessage = true;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.table > tbody > tr > td {
  vertical-align: middle;
  padding: 0;
}
</style>
// STRANGE STUFF, which I admit I don't understand, but generally good

// 1. Always give all routes a NAME. This is essential if you want to send (and receive in the component) props in the most convenient way. Users will never see the name so it can be long and (for example) match the COmponent name
// 2. Set "props" to true, in each route individually.
// 3. If you want to be able to navigate BACK and "pop" back the props of the relevant page (e.g. going from deep in the fine detail of an item, back to the item's overall page, i.e. retaining the item Id) you MUST insert the relevant prop(s) into the ROUTE, as follows, "itemPage/:id", where id is the id. That way the id is saved in the url and can be resurrected when you go back. Incidentally it also allows people to jump direct to that page using a link.
// 4. In the main APP program, you can and should access firebase etc, and then pass the firebase objects as a prop to all routes. This works really well, so that each component no longer needs to create its own firebase objects. For example, you COULD create a "global" object and make all your global things children of that.
// 4b. I am not sure what advantages VUEX gives over this arrangement. Perhaps debugging etc, allowing tracing of what component changed the data to what, and when. But if you are only using this arrangement in the manner used here, with a set of firebase objects, each with their own isLoaded, and their own ref (for writing), then we should be OK.

import Vue from "vue";
import Router from "vue-router";

import Home from "./components/Home.vue";
import Login from "./components/Login.vue";

// Anyone
import My from "./components/My.vue";
import MyAccount from "./components/MyAccount.vue";
import MyLocation from "./components/MyLocation.vue";
import MyPatients from "./components/MyPatients.vue";
import TellAFriend from "./components/TellAFriend.vue";

// Referrer
import Clin20Screen from "./components/Clin20Screen.vue";
import Clin30Trials from "./components/Clin30Trials.vue";
import Clin40Permission from "./components/Clin40Permission.vue";
import Clin42ThanksForConsidering from "./components/Clin42ThanksForConsidering.vue";
import Clin50NhsNumber from "./components/Clin50NhsNumber.vue";
import Clin60Refer from "./components/Clin60Refer.vue";
import ClinTrained from "./components/ClinTrained.vue";
import Clinician from "./components/Clinician.vue";

// Researcher
import ResMonitorAll from "./components/ResMonitorAll.vue";
import ResMonitorOne from "./components/ResMonitorOne.vue";
import ResRandomize from "./components/ResRandomize.vue";
import ResRefStatus from "./components/ResRefStatus.vue";
import ResReferralAll from "./components/ResReferralAll.vue";
import ResReferralOne from "./components/ResReferralOne.vue";
import Researcher from "./components/Researcher.vue";

// Manager
import Manage from "./components/Manage.vue";
import ManageManagers from "./components/ManageManagers.vue";
import ManageManagersNode from "./components/ManageManagersNode.vue";
import ManageTrials from "./components/ManageTrials.vue";
import ManageTrialsOne from "./components/ManageTrialsOne.vue";

// HQ
import EditFacts from "./components/EditFacts.vue";
import EditOneFact from "./components/EditOneFact.vue";
import EditOneFactClassification from "./components/EditOneFactClassification.vue";
import EditTrial from "./components/EditTrial.vue";
import EditTrialLogic from "./components/EditTrialLogic.vue";
import EditTrials from "./components/EditTrials.vue";
import EditUser from "./components/EditUser.vue";
import EditUsers from "./components/EditUsers.vue";
import Hq from "./components/Hq.vue";

// Odds
import NotFound from "./components/NotFound.vue";

// import Notifications from "./components/Notifications.vue";
import Settings from "./components/Settings.vue";

import EnrolTrialC19Acs from "./components/EnrolTrialC19Acs.vue";
import EnrolTrial_TeleACS from "./components/EnrolTrial_TeleACS.vue";
import EnrolTrial_TeleTAVI from "./components/EnrolTrial_TeleTAVI.vue";

Vue.use(Router);

export default new Router({
  mode: "history", // This avoids the "hash" and MORE IMPORTANTLY allows params to be remembered on "popping" back
  // https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations

  routes: [
    // We need the NAME property if we want to pass parameters in the URL, which is essential if we want the BACK to work correctly. If we don't pass in URL, when we go BACK, the parent component no longer has any values in its props

    // SLIDERPATH
    // This is a variable I made up and added to the TransitionPage component, that creates a virtual path used ONLY by TransitionPage's slider logic to work out which way to slide the page. This is needed because most of the pages have shallow actual URL paths, and I don't want users to see detailed paths in the URL. So instead we create a hidden path which is never shown. The ONLY processing done on sliderPath is counting of "/" symbols. Doesn't matter what the other characters are: just for ease of programmer memory.
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/",
      name: "home",
      component: Home,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedIn",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/c10",
      name: "clinician",
      component: Clinician,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin ",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/c20/:hasSymptomsTitlesJoined",
      name: "clin20Screen",
      component: Clin20Screen,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I]  loggedin [R]  criteria",
        gracePeriodMinutesBeforeAutoUpdate: 60,
      },
    },

    {
      path: "/c30/:trialIdsForDisplayStringified",
      name: "clin30Trials",
      component: Clin30Trials,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R]  criteria [R] trials",
      },
      gracePeriodMinutesBeforeAutoUpdate: 60,
    },

    {
      path: "/c40",
      name: "clin40Permission",
      component: Clin40Permission,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R]  criteria [R] trials [R] permission",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/c42",
      name: "clin42ThanksForConsidering",
      component: Clin42ThanksForConsidering,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath:
          "root [I] loggedin [R]  criteria [R] trials [R] permission [L] thanksForConsidering",
        gracePeriodMinutesBeforeAutoUpdate: 1,
      },
    },

    {
      path: "/c50",
      name: "clin50NhsNumber",
      component: Clin50NhsNumber,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath:
          "root [I] loggedin [R]  criteria [R] trials [R] permission [R] nhs",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/c60",
      name: "clin60Refer",
      component: Clin60Refer,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath:
          "root [I] loggedin [R]  criteria [R] trials [R] permission [R] nhs [R] refer",
        gracePeriodMinutesBeforeAutoUpdate: 60 * 24 * 7, // Can hold for up to a week at this point
      },
    },
    {
      path: "/clinTrained",
      name: "clinTrained",
      component: ClinTrained,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] ",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/tellAFriend",
      name: "tellAFriend",
      component: TellAFriend,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] ",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/my",
      name: "my",
      component: My,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] symptom [U] my",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/myLocation",
      name: "myLocation",
      component: MyLocation,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I]loggedin [R] symptom [U] my [R] location",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/myAccount",
      name: "myAccount",
      component: MyAccount,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] symptom [U] my [R] account",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/myPatients",
      name: "myPatients",
      component: MyPatients,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] symptom [U] my [R] patients",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/hq",
      name: "hq",
      component: Hq,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin ",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/editUsers",
      name: "editUsers",
      component: EditUsers,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] users",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/editUser/:id",
      name: "editUser",
      component: EditUser,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] users [I] editUser",
        gracePeriodMinutesBeforeAutoUpdate: 60,
      },
    },

    {
      path: "/editFacts",
      name: "editFacts",
      component: EditFacts,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] facts",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/editOneFact/:id",
      name: "editOneFact",
      component: EditOneFact,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] facts [I] fact",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/editOneFactClassification/:id", // Because multiple params are being passed, we can't show them in the URL, so don't bother trying
      name: "editOneFactClassification",
      component: EditOneFactClassification,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] facts [I] fact [I] classification",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/editTrials/:trialPrivateObj/:trialPrivateIsLoaded",
      name: "editTrials",
      component: EditTrials,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] trials",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/editTrial/:id",
      name: "editTrial",
      component: EditTrial,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] trials [I] trial",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/editTrialLogic/:id",
      name: "editTrialLogic",
      component: EditTrialLogic,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [R] trials [I] trial [I] logic",
        gracePeriodMinutesBeforeAutoUpdate: 120,
      },
    },

    {
      path: "/researcher",
      name: "researcher",
      component: Researcher,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin ",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/resReferralAll",
      name: "resReferralAll",
      component: ResReferralAll,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] referrals",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/resReferralOne/:trialReferralRefString",
      name: "resReferralOne",
      component: ResReferralOne,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] referrals [R] one",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/resRefStatus/:trialId/:referralId/:referrerId",
      name: "resRefStatus",
      component: ResRefStatus,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] referrals [R] one [I] status",
        gracePeriodMinutesBeforeAutoUpdate: 1,
      },
    },

    {
      path: "/resrandomize",
      name: "resRandomize",
      component: ResRandomize,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] randomize ",
        gracePeriodMinutesBeforeAutoUpdate: 1,
      },
    },

    {
      path: "/resMonitorAll",
      name: "resMonitorAll",
      component: ResMonitorAll,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] monitor ",
        gracePeriodMinutesBeforeAutoUpdate: 1,
      },
    },

    {
      path: "/resMonitorOne",
      name: "resMonitorOne",
      component: ResMonitorOne,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin  [I] monitor [R] one",
        gracePeriodMinutesBeforeAutoUpdate: 30,
      },
    },

    {
      path: "/manage",
      name: "manage",
      component: Manage,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/manageManagers",
      name: "manageManagers",
      component: ManageManagers,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] manageManagers",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/manageManagersNode/:propsJsonB",
      name: "manageManagersNode",
      component: ManageManagersNode,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] manageManagers [I] Node",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/manageTrials",
      name: "manageTrials",
      component: ManageTrials,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] ManageTrials",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/manageTrialsOne",
      name: "manageTrialsOne",
      component: ManageTrialsOne,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] ManageTrials [R] ManageTrials",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    // {
    //   path: "/notifications",
    //   name: "notifications",
    //   component: Notifications,
    //   props: true,
    //   meta: {
    //     transitionName: "slide",
    //     sliderPath: "root [I] loggedin [D] notifications",
    //     gracePeriodMinutesBeforeAutoUpdate: 0
    //   }
    // },

    {
      path: "/settings",
      name: "settings",
      component: Settings,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [D] settings",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/enrol/c19acs",
      name: "enroltrialc19acs",
      component: EnrolTrialC19Acs,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] settings [R] enrol",
        gracePeriodMinutesBeforeAutoUpdate: 30,
      },
    },

    {
      path: "/enrol/teleacs",
      name: "enroltrial_teleacs",
      component: EnrolTrial_TeleACS,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] settings [R] enrol",
        gracePeriodMinutesBeforeAutoUpdate: 30,
      },
    },

    {
      path: "/enrol/teletavi",
      name: "enroltrial_teletavi",
      component: EnrolTrial_TeleTAVI,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "root [I] loggedin [R] settings [R] enrol",
        gracePeriodMinutesBeforeAutoUpdate: 30,
      },
    },

    // The next two elements implement 404 handling, as shown in https://stackoverflow.com/questions/45619407
    {
      path: "/404",
      name: "404",
      component: NotFound,
      props: true,
      meta: {
        transitionName: "slide",
        sliderPath: "[O] [O] [O] so exiting from this is always zooming in",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

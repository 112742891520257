<template>
  <div>
    <!-- During debugging, we have a second instance of the "AppAutoupdate" component, just to show the status at the bottom of home pages. It doesn't produce a yellow warning but (as it so happens) it does cause its own autoupdates -->
    <div
      v-if="onlyShowCopyright"
      class=""
      style="text-align: center; font-size: 50%; color: darkgray"
    >
      (C) FoundBy.Me Ltd 2017-2021. Status code
      {{ runningVersion.slice(12).replace(/:/g, "") }}&nbsp;:&nbsp;{{
        latestVersion.slice(12).replace(/:/g, "")
      }}
    </div>

    <div v-if="!onlyShowCopyright">
      <b-alert
        show
        v-if="runningVersionRelative() > 0"
        variant="warning"
        style="position: absolute; opacity: 0.85; width: 100%"
        class="px-4 py-1"
      >
        <h6 style="text-align: center">Being built and deployed...</h6>
      </b-alert>

      <b-alert
        show
        v-if="runningVersionRelative() < 0"
        variant="warning"
        @click="getNewest"
        style="position: absolute; opacity: 0.85; width: 100%"
        class="px-4 py-1"
      >
        <!-- {{runningVersion}}
      <br>
      {{latestVersion}} -->
        <div v-if="aboutToAutoUpdateNow">
          <br />
          <br />
          <h6 style="text-align: center">Updating...</h6>
          <br />
          <br />
        </div>
        <div v-else>
          <h5 style="text-align: center">App will shortly update</h5>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BUILDVERSION } from "./ext/buildVersion.js"; // This is overwritten by the building process

import {
  systemStatusRef, // just in the global space, non- "this."
} from "../util/firebase.js";

import { getNewest } from "../util/getNewest.js"; // but remember to also re-export it in methods

export default {
  props: {
    onlyShowCopyright: { type: Boolean, required: false, default: false },
  },

  data: function () {
    return {
      aboutToAutoUpdateNow: false, // Will write to this once have $ROUTE and LATESTVERSION
      runningVersion: BUILDVERSION,
      latestVersion: "",
      latestVersionRead: false,
    };
  },

  watch: {
    $route: function (routeObj) {
      if (routeObj.nonExistentProperty) {
        // Do nothing, but prevent the linter complaining we are not using routeObj
      }
      this.makeAutoUpdateDecision();
    },
  },

  created() {
    systemStatusRef.child("latestVersion").on("value", (snapshot) => {
      this.latestVersion = snapshot.val() || null;
      this.latestVersionRead = true;
      this.makeAutoUpdateDecision();
    });
  },

  methods: {
    runningVersionRelative: function () {
      if (this.latestVersionRead) {
        if (this.runningVersion > this.latestVersion) {
          return +1; // The version running is NEWER than the one listed on firebase, which will happen if you have half-run the build script and then aborted it or (while it runs) are serving it locally via NPM RUN DEV.
        } else if (this.runningVersion < this.latestVersion) {
          return -1; // The version running is OLDER than the one listed on firebase, which means you should update
        } else {
          return 0; // You are up to date
        }
      }
      return undefined;
    },

    makeAutoUpdateDecision() {
      // console.log("Making an auto-update decision, based on");
      // console.log("this.$route:", this.$route);

      if (this.runningVersionRelative() < 0) {
        if (
          typeof this.$route.meta.gracePeriodMinutesBeforeAutoUpdate ===
          "number"
        ) {
          this.waitGraceMinutesAndThenAutoUpdateIfOnSameRouteName(
            this.$route.name,
            this.$route.meta.gracePeriodMinutesBeforeAutoUpdate
          );
        }
      }
    },

    waitGraceMinutesAndThenAutoUpdateIfOnSameRouteName(
      routeNameAtStartOfGracePeriod,
      gracePeriodMinutes
    ) {
      if (routeNameAtStartOfGracePeriod) {
        console.log(
          "STARTING TIMER",
          gracePeriodMinutes + " min",
          routeNameAtStartOfGracePeriod
        );

        setTimeout(() => {
          if (this.$route.name === routeNameAtStartOfGracePeriod) {
            console.log(
              "Do auto update based on passage of ",
              gracePeriodMinutes,
              " min and still being in ",
              routeNameAtStartOfGracePeriod
            );
            this.warnAndDoAutoUpdate();
          } else {
            console.log(
              "Had  ",
              gracePeriodMinutes,
              "minutes but now have moved from ",
              routeNameAtStartOfGracePeriod,
              "to ",
              this.$route.name,
              " so am not autoupdating. A separate timer was started when we moved to this page."
            );
          }
        }, gracePeriodMinutes * 60 * 1000);
      }
    },

    warnAndDoAutoUpdate() {
      this.aboutToAutoUpdateNow = true;
      setTimeout(getNewest, 2000);
    },

    //
    getNewest, // Copy from GLOBAL (imported area) to THIS, so the TEMPLATE can use this in @click
  },
};
</script>

<style>
</style>

<template>
  <div
    class="p-2 page-scroll-y d-flex flex-column"
    style="background-color:white; color:black;height:100vh;"
  >

    <div v-if="showSpinner">
      <spinner></spinner>
    </div>
    <div v-else>

      <section
        id="head"
        class="mb-2"
      >
        <div style="display:flex;flex-direction:row; justify-content:space-between; align-items:center;">

          <b-button
            v-on:click="goBack"
            variant="light"
          >
            <svg class="ion">
              <use xlink:href="#ion-chevron-left"></use>
            </svg>
          </b-button>
          &nbsp;
          <div>
            <h1>
              Set status
            </h1>
            <div
              class="mt-0 pt-0"
              style="font-size:70%; color:gray;"
            >Keeps referrer and managers informed</div>
          </div>
          &nbsp;
        </div>
      </section>

      <section style="overflow-y: scroll; max-height: 100%; max-width:100%; color:black;">
        <div>
          <div
            v-for="option in statusOptionsToDisplay"
            :key="option.id"
          >
            <h3
              v-if="option.groupName"
              class="mt-2 mb-0 pb-1"
            >{{option.groupName}}</h3>

            <div>
              <transition name="swish">
                <div v-if="option.id===optionIdWithOpenReason">
                  <br><br>
                </div>
              </transition>
              <b-button
                :style="{'background-color':option.background,'color':option.color,'min-width':'100%','border-width':'0.5px'}"
                class="mb-1 d-flex flex-row align-items-center"
                @click="onClickOption(option.id,'main')"
              >
                <div style="width:90%; flex-grow:1;">{{option.long}}</div>
                <div
                  v-if="option.allowReason"
                  style=" min-width:10%;flex-grow:0;font-size:70%;padding-left:0.5em;"
                >Can&nbsp;add<br>details</div>
              </b-button>

              <transition name="swish">
                <div v-if="option.id===optionIdWithOpenReason">
                  <b-form-textarea
                    v-model="reasonText"
                    placeholder="Any additional details, e.g. if needed for your screening log."
                    :rows="3"
                    :max-rows="9"
                  >
                  </b-form-textarea>
                  <div class="d-flex flex-row justify-content-end">
                    <b-button
                      class="mt-1"
                      variant="primary"
                      @click="onClickOption(option.id,'reason')"
                    >&nbsp;&nbsp;OK&nbsp;&nbsp;</b-button>

                  </div>
                  <br>
                  <br>
                </div>
              </transition>
            </div>

          </div>
        </div>
      </section>

      <!-- the /div below is the end of the "if not show spinner" -->
    </div>

  </div>
</template>

<script>
import {
  // Data
  STATUS_OPTIONS,
  // Functions
  statusObjFromId,
  setStatus
} from "../util/firebase.js";
import Spinner from "./ext/Spinner";

export default {
  components: { Spinner },

  props: {
    user: { type: Object, required: true },
    trialId: { type: String, required: true },
    referralId: { type: String, required: true },
    referrerId: { type: String, required: true }
  },

  data() {
    return {
      showSpinner: false,
      statusOptionsToDisplay: [],
      optionIdWithOpenReason: null,
      reasonText: ""
    };
  },

  computed: {},

  created() {
    this.statusOptionsToDisplay = STATUS_OPTIONS.filter(
      option => option.id !== "sta~100100" || this.user.privilegesObj.hq
    );
  },

  methods: {
    statusObjFromId(x) {
      // make accessible in THIS
      return statusObjFromId(x);
    },

    onClickOption(id, buttonPressed) {
      if (this.statusObjFromId(id).allowReason) {
        if (this.optionIdWithOpenReason === id && buttonPressed === "reason") {
          this.definitivelySelectOption(id);
        } else {
          this.optionIdWithOpenReason = id;
        }
      } else {
        this.definitivelySelectOption(id);
      }
    },

    definitivelySelectOption(id) {
      const statusObj = {
        trialId: this.trialId,
        referralId: this.referralId,
        referrerId: this.referrerId,
        statusId: id,
        reason: this.reasonText || null
      };

      this.showSpinner = true;

      setStatus(statusObj)
        .then(() => {
          this.goBack();
          return null; // Dummy to keep in habit of returning promises
        })
        .catch(e => {
          console.error(
            `Firebase error ${e.code} (${
              e.message
            }) when writing status ${id} to referral ${this.referralId}.`
          );
        });
    },

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>

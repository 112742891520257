<template>
  <div
    id="app"
    class="m-0 p-0 d-flex flex-column"
    style="height: 100vh; overflow: hidden"
  >
    <section v-if="amOnline === false" style="position: absolute">
      <AppOfflineWarning></AppOfflineWarning>
    </section>

    <section>
      <AppAutoupdate :onlyShowCopyright="false"></AppAutoupdate>
    </section>

    <!-- Option 1. Bootup splash screen -->
    <section
      v-if="deviceToPromptInstallation.length > 0"
      style="height: 100%; background-color: white; color: black"
      class="d-flex flex-column justify-content-center align-content-center align-items-center"
    >
      <!-- <div v-if="deviceToPromptInstallation===''">
        <h1>{{deviceToPromptInstallation}}</h1>
        <br>
        {{device}}
      </div>-->
      <div v-if="deviceToPromptInstallation === 'Android'">
        <!-- in VUE, the "/" means copy direct from the "public" folder (outside the "src" folder) -->
        <img
          src="/install-android.png"
          style="max-width: 100vw; max-height: 95vh"
          alt="Click the triple dot menu at the top right, choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."
        />
        <AppAutoupdate :onlyShowCopyright="true"></AppAutoupdate>
      </div>
      <div v-if="deviceToPromptInstallation === 'iPhone'">
        <!-- in VUE, the "/" means copy direct from the "public" folder (outside the "src" folder) -->
        <img
          src="/install-iPhone.png"
          style="max-width: 100vw; max-height: 95vh"
          alt="Click the SHARE menu at the bottom of the screen (box with upward arrow), choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."
        />
        <AppAutoupdate :onlyShowCopyright="true"></AppAutoupdate>
      </div>
      <div v-if="deviceToPromptInstallation === 'iPad'">
        <!-- in VUE, the "/" means copy direct from the "public" folder (outside the "src" folder) -->
        <img
          src="/install-iPad.png"
          style="max-width: 100vw; max-height: 95vh"
          alt="Click the SHARE menu at the top of the screen (box with upward arrow), choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."
        />
        <AppAutoupdate :onlyShowCopyright="true"></AppAutoupdate>
      </div>
    </section>

    <section
      v-if="
        deviceToPromptInstallation.length === 0 && loggedState === undefined
      "
      class="center-container"
    >
      <img
        style="max-width: 70vw; max-height: 70vh"
        src="./assets/logo.svg"
        alt="Logo"
      />
    </section>

    <!-- Option 2. We know for sure not logged in. -->
    <section
      v-if="deviceToPromptInstallation.length === 0 && loggedState === 'out'"
    >
      <Login :auth="auth"></Login>
    </section>

    <!-- Option 3. Logged in, so use Router -->
    <section
      v-if="deviceToPromptInstallation.length === 0 && loggedState === 'in'"
    >
      <!-- Option 3A. If got the user data in, we can really show the pages, otherwise we have to show spinner -->
      <div
        v-if="
          user.privilegesIsLoaded &&
          user.contactIsLoaded &&
          user.presenceIsLoaded &&
          trials.publicIsLoaded &&
          trials.criterionIsLoaded &&
          trials.factIsLoaded
        "
      >
        <TransitionPage>
          <router-view
            style="overflow-y: scroll"
            :user="user"
            :trials="trials"
            :georestrictedTrialIdArray="georestrictedTrialIdArray"
            @checkUserGivenEnoughInfo="forceInfoToBeGiven()"
          ></router-view>
        </TransitionPage>

        <AppHelp :userId="user.id" :privileges="user.privilegesObj"></AppHelp>
      </div>
      <div v-else>
        <!-- Option 3B. Logged in, but data not loaded, so show spinner -->
        <div
          class="d-flex flex-row"
          style="width: 100%; justify-content: space-evenly; opacity: 0.2"
        >
          <div>{{ user.presenceIsLoaded ? "■" : "□" }}</div>
          <div>
            {{ user.contactIsLoaded ? "■" : "□" }}
          </div>
          <div>{{ user.privilegesIsLoaded ? "■" : "□" }}</div>
          <div>{{ trials.factIsLoaded ? "■" : "□" }}</div>
          <div>{{ trials.publicIsLoaded ? "■" : "□" }}</div>
          <div>{{ trials.criterionIsLoaded ? "■" : "□" }}</div>
        </div>

        <Spinner></Spinner>
      </div>
    </section>

    <!-- Zero-size component which creates the SVG icon *definitions*, so they can be used  in any components  -->
    <Icons></Icons>
  </div>
</template>

<script>
/* eslint-disable standard/computed-property-even-spacing */

import * as bowser from "bowser";

// Pages
import Login from "./components/Login.vue";
import TransitionPage from "./components/TransitionPage.vue"; // Enables page transitions

// Components of page
import AppAutoupdate from "./components/AppAutoupdate.vue";
import AppHelp from "./components/AppHelp.vue";
import AppOfflineWarning from "./components/AppOfflineWarning.vue";
const amOnlineSecondsBeforeCommenting = 10; // Listed here because acted upon here in the App, not in the component

// Odd bits
import Icons from "./components/Icons.vue"; // Explicitly contains SVG icons

// From external sources
import Spinner from "./components/ext/Spinner.vue";

import {
  //  systemStatusRef,
  amOnlineRef,
  // Non- "this."
  auth,
  // DATABASE branches accessed for ordinary users. (Staff-only database branches opened elsewhere)
  // 1. User-based (each user has his own branch)
  contactRootRef,
  presenceRootRef,
  privilegesRootRef,
  // 3. Each science fact has its own branch
  scienceFactRootRef,
  //  userReferralRootRef
  ServerValueTIMESTAMP,
  // 2. Trial-based (each trial has its own branch)
  trialCriterionRootRef,
  trialPublicRootRef,
} from "./util/firebase.js";

import { getOneNhsLocationObjByLongName, matchGeo } from "./util/geography.js";

export default {
  components: {
    AppOfflineWarning,
    AppAutoupdate,

    AppHelp,
    Icons,
    TransitionPage,
    Login,
    Spinner,
  },
  data() {
    return {
      // Forced installation info
      device: {},
      standalone: false,
      deviceToPromptInstallation: "",

      // Firebase
      auth: auth, // This makes "AUTH" from firebase available to the template as "(this.)auth", so it can be passed as props to children such as Login.vue

      jsActiveLongEnoughToTellOnlineStatus: false,
      loggedState: undefined,
      amOnline: undefined,

      user: {
        id: null,
        fromAuth: {}, // We will copy in the USER object from Auth, into here.

        // ----- PRESENCE
        // List the branches we want to be reactive to. For each there is a REFERENCE, an OBJECT, and an ISLOADED
        presenceRef: null,
        presenceObj: {},
        presenceIsLoaded: false,

        // ----- PRIVILEGES
        privilegesRef: null,
        privilegesObj: {
          clinician: false,
          researcher: false,
          manager: false,
        },
        privilegesIsLoaded: false,

        // ----- CONTACT
        contactRef: null,
        contactObj: {
          location: {
            longName: "",
            shortName: "",
            postcode: "",
            recordLocations: [],
          },
        },
        contactIsLoaded: false,

        referralRef: null, // Not used here but used later
        referralObj: {},
        referralIsLoaded: false,
      },

      trials: {
        publicRef: null,
        publicObj: {},
        publicIsLoaded: false,
        criterionRef: null,
        criterionObj: {},
        criterionIsLoaded: false,
        factRef: null,
        factObj: {},
        factIsLoaded: false,
      },

      georestrictedTrialIdArray: ["notReady"], // This will be replaced by an array once actually ready
    };
  },

  computed: {
    // allowRealReferral() {
    //   // Can only do a real referral if has LOCATION and EMAILVERIFIED
    //   return (
    //     this.user &&
    //     this.user.contactObj &&
    //     this.user.contactObj.location &&
    //     this.user.contactObj.location.longName &&
    //     this.user &&
    //     this.user.contactObj &&
    //     this.user.contactObj.emailVerified
    //   );
    // }
  },

  created() {
    auth.onAuthStateChanged(user =>
      this.updateLoggedStateP(user).then(() =>
        // Force install
        {
          this.readDevice();
          this.readStandaloneStatus();
          this.takeActionBasedOnDeviceAndStandalone();
          //    this.forceInfoToBeGiven();
        }
      )
    );
  },
  methods: {
    async updateLoggedStateP(userFromAuth) {
      // Have to use this type of function definition and not fat arrow, because fat arrow fails to make "this" equal to the Vue instance

      // In the end I did this instead of vueFire, because it is not so difficult to do one's own firebase bindings

      // -------------------------------------------------

      if (userFromAuth) {
        this.password = ""; // remove password after login
        this.loggedState = "in";
        this.user.fromAuth = userFromAuth;
        this.user.id = userFromAuth.uid;

        //  ##     ##  ######  ######## ########
        //  ##     ## ##    ## ##       ##     ##
        //  ##     ## ##       ##       ##     ##
        //  ##     ##  ######  ######   ########
        //  ##     ##       ## ##       ##   ##
        //  ##     ## ##    ## ##       ##    ##
        //   #######   ######  ######## ##     ##
        // Load firebase stuff into the branches of "user"

        // 1. Contact. Bind and update
        this.bindBranchAsREFandOBJandISLOADED(
          this.user,
          "contact",
          contactRootRef.child(this.user.id),
          {
            // This option says that whenever the contact is updated, trigger an update of georestrictedTrialIdArray
            callback:
              this
                .createGeorestrictedTrialIdArrayOnceUserLocationAndTrialPublicAreAvailable,
          }
        );
        await this.user.contactRef
          .child("emailVerified")
          .set(userFromAuth.emailVerified); // This is also done "live" in the MyAccount module in the button where the user says he has just clicked the link.

        await this.user.contactRef.update({
          doneTour: true,
          inTour: false,
          inTourStage: null,
        }); // Added Aug 2024 to no longer require the tour

        // 2. Privileges. Bind (can't write)
        this.bindBranchAsREFandOBJandISLOADED(
          this.user,
          "privileges",
          privilegesRootRef.child(this.user.id)
        );

        // 3. Presence. Bind and update
        this.bindBranchAsREFandOBJandISLOADED(
          this.user,
          "presence",
          presenceRootRef.child(this.user.id)
        );
        await this.user.presenceRef.update({
          dLastLogin: ServerValueTIMESTAMP,
          emailAtLastLogin: userFromAuth.email,
        });

        amOnlineRef.on("value", async snapshot => {
          if (snapshot.val()) {
            const instanceId =
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15);

            try {
              // First, mark the user as online
              await this.user.presenceRef.update({
                lastChangeWasOn: true,
                ["currentSessions/" + instanceId]: true,
              });

              // Then, set up the onDisconnect hook
              this.user.presenceRef.onDisconnect().update({
                lastChangeWasOn: null,
                lastWentOff: ServerValueTIMESTAMP,
                ["currentSessions/" + instanceId]: null,
              });

              // Mark the local state as online
              this.amOnline = true;
            } catch (error) {
              console.error("Error updating presence:", error);
              this.amOnline = false;
            }
          } else {
            // Mark the local state as offline
            this.amOnline = false;
          }
        });

        setTimeout(() => {
          this.jsActiveLongEnoughToTellOnlineStatus = true;
        }, amOnlineSecondsBeforeCommenting * 1000);

        //        this.user.privilegesRef.on("value", dummySnapshot => {});

        //  ######## ########  ####    ###    ##        ######
        //     ##    ##     ##  ##    ## ##   ##       ##    ##
        //     ##    ##     ##  ##   ##   ##  ##       ##
        //     ##    ########   ##  ##     ## ##        ######
        //     ##    ##   ##    ##  ######### ##             ##
        //     ##    ##    ##   ##  ##     ## ##       ##    ##
        //     ##    ##     ## #### ##     ## ########  ######

        this.bindBranchAsREFandOBJandISLOADED(
          this.trials,
          "fact",
          scienceFactRootRef
        );
        this.bindBranchAsREFandOBJandISLOADED(
          this.trials,
          "criterion",
          trialCriterionRootRef
        );
        // For trials.public, we do TWO special things. First, we make an extra version as an Array. Second, we provide a callback
        this.bindBranchAsREFandOBJandISLOADED(
          this.trials,
          "public",
          trialPublicRootRef,
          {
            addArray: true,
            callback:
              this
                .createGeorestrictedTrialIdArrayOnceUserLocationAndTrialPublicAreAvailable,
          }
        );
      } else {
        this.loggedState = "out";
      }
    },

    bindBranchAsREFandOBJandISLOADED(
      containerObject,
      keyPrefix,
      firebaseRef,
      options
    ) {
      //Use this to put userContact database into user.contactObj etc.
      // and then separately to put trialPublic database into trial.publicObj etc.
      // OPTIONS:
      //   addArray: true if you want an Array version as well as the usual Obj
      //   callBack: function that you want executed once data loaded

      //--------------------------------
      // CONTAINEROBJECT is either this.user or this.trial

      // "keyPrefix" can be, for example, "status"
      // That would create in the "this.user" object, the following:
      //   this.user.statusRef    which would be the firebase Ref for the status
      //   this.user.statusObj    which would be the firebase-bound status
      //   this.user.statusIsLoaded    which would be true once read from firebase

      // Step 1. Open up the pathway to that point in the table
      if (!this.branchBindingTable) {
        this.branchBindingTable = {};
      }

      // Step 2. If this variable is already bound to a firebase node, unbind
      if (this.branchBindingTable[keyPrefix]) {
        // If this keyPrefix is already bound to a firebase node, unbind it. Otherwise updates to the OLD node will wrongly cause themselves to be read in and trigger an incorrect "update" to the new node, i.e. overwrite your new patient's data with the previous patient's data (just because the previous patient happened to update their data)
        this.branchBindingTable[keyPrefix].off();
        containerObject[keyPrefix + "IsLoaded"] = false;
      }

      // Step 3. Now bind to the new firebase node and store the three things, "REF","OBJ" itself, and "ISLOADED".
      this.branchBindingTable[keyPrefix] = firebaseRef;
      containerObject[keyPrefix + "Ref"] = firebaseRef;

      firebaseRef.on("value", snapshot => {
        // Every time firebase value changes (including the first where firebase just comes live) this will cause an Object.assign, causing a re-render of the object in this and the children components.

        let newKeyValuePairOrPairs = {};
        let newValueAsObj = snapshot.val() || {};
        newKeyValuePairOrPairs[keyPrefix + "Obj"] = newValueAsObj;
        if (options && options.addArray) {
          const newValueAsArray = Object.keys(newValueAsObj).map(key => ({
            ...newValueAsObj[key],
            id: key,
          }));
          newKeyValuePairOrPairs[keyPrefix + "Array"] = newValueAsArray;
        }
        Object.assign(containerObject, newKeyValuePairOrPairs);

        containerObject[keyPrefix + "IsLoaded"] = true;
        if (options && options.callback) {
          options.callback();
        }
      });
    },

    createGeorestrictedTrialIdArrayOnceUserLocationAndTrialPublicAreAvailable() {
      // Call this when USER LOCATION GEOGRAPHY or TRIALS is updated,
      // to recalculate the georestricted trials list, as long as BOTH are loaded

      if (
        this.user.contactIsLoaded &&
        this.user.contactObj.location &&
        this.trials.publicIsLoaded
      ) {
        this.georestrictedTrialIdArray = Object.keys(
          this.trials.publicObj
        ).filter(oneTrialId =>
          matchGeo(
            this.user.contactObj.location.postcode
              ? "p" + this.user.contactObj.location.postcode
              : this.user.contactObj.location.country
              ? "c" + this.user.contactObj.location.country
              : "",
            this.trials.publicObj[oneTrialId].geographies
          )
        );
      }
    },

    // ______                   _           _        _ _
    // |  ___|                 (_)         | |      | | |
    // | |_ ___  _ __ ___ ___   _ _ __  ___| |_ __ _| | |
    // |  _/ _ \| '__/ __/ _ \ | | '_ \/ __| __/ _` | | |
    // | || (_) | | | (_|  __/ | | | | \__ \ || (_| | | |
    // \_| \___/|_|  \___\___| |_|_| |_|___/\__\__,_|_|_|

    readDevice() {
      const parser = bowser.getParser(window.navigator.userAgent);
      this.device = parser.parse().parsedResult;
    },

    readStandaloneStatus() {
      // Detect standalone state (PWA)
      this.standalone = false;
      if (window.matchMedia("(display-mode: standalone)").matches) {
        // Chromium-based
        this.standalone = true;
      }
      if (window.navigator && window.navigator.standalone) {
        // iOS
        this.standalone = true;
      }
    },

    takeActionBasedOnDeviceAndStandalone() {
      if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "mobile" &&
        this.device.os &&
        this.device.os.name === "Android" &&
        this.standalone === false
      ) {
        this.deviceToPromptInstallation = "Android";
      } else if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "mobile" &&
        this.device.platform.model === "iPhone" &&
        this.standalone === false
      ) {
        this.deviceToPromptInstallation = "iPhone";
      } else if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "tablet" &&
        this.device.platform.model === "iPad" &&
        this.standalone === false
      ) {
        this.deviceToPromptInstallation = "iPad";
      } else {
        // EITHER PWA of the above, or not Android/iPhone, i.e. iPad or Desktop
      }
    },

    // ______                   _                 _   _
    // |  ___|                 | |               | | (_)
    // | |_ ___  _ __ ___ ___  | | ___   ___ __ _| |_ _  ___  _ __
    // |  _/ _ \| '__/ __/ _ \ | |/ _ \ / __/ _` | __| |/ _ \| '_ \
    // | || (_) | | | (_|  __/ | | (_) | (_| (_| | |_| | (_) | | | |
    // \_| \___/|_|  \___\___| |_|\___/ \___\__,_|\__|_|\___/|_| |_|

    forceInfoToBeGiven() {
      // These informations are also looked for in CLINICIAN to decide whetehr you are allowed to make a real referral

      if (
        !(
          this.user &&
          this.user.contactObj &&
          this.user.contactObj.emailVerified
        )
      ) {
        this.$router.replace({ name: "myAccount" });
      }

      const longNameOrNull =
        (this.user.contactObj &&
          this.user.contactObj.location &&
          this.user.contactObj.location.longName) ||
        null;
      if (!(longNameOrNull && longNameOrNull.length > 1)) {
        this.$router.replace({ name: "myLocation" });
      }

      const myLocationObj = getOneNhsLocationObjByLongName(longNameOrNull);

      if (myLocationObj) {
        this.user.contactRef.child("location").set(myLocationObj);
      } else {
        this.user.contactRef
          .child("location")
          .set(null)
          .then(this.$router.replace({ name: "myLocation" }));
      }
    },
  },
};
</script>

<style>
/* From the originally working FBMV. Do not "SCOPE" these as we do want them globally available */

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

#app {
  font-family: "Open Sans", "Segoe UI", "Segoe", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin: 0.5rem;
}

.page-scroll-y {
  height: 100vh;
  overflow-y: auto; /* overflow-y:scroll works but creates ugly dimmed bars on Chrome and Edge, whenever there is too little data to need a scroll bar */
  -webkit-overflow-scrolling: touch;
}

.iphone-scroll-fudge {
  -webkit-overflow-scrolling: touch;
}

.header-logged-in {
  margin: 0;
  height: 8vh;
  padding: 0 16px 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.center-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* general utility classes for project */

.ellipsis-must-set-width {
  /* apply this class to a div to set ellipsis truncation but you MUST set a width on the element, and the text MUST be the immediate contents of the DIV, not of children.
  A good way to set the width is
    width: calc( 80% - 32px or whatever);
    The space before/after the "-" is mandatory
  */
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.officeUseOnly {
  font-size: 50%;
  font-weight: 100;
  color: lightgray;
}

pre {
  font-family: "Encode Sans", "Courier New", monospace, sans-serif;
}

.swish-enter,
.swish-leave-to {
  opacity: 0;
  max-height: 0;
}

.swish-leave,
.swish-enter-to {
  opacity: 1;
  max-height: 100vh;
}

.swish-enter-active {
  transition: max-height 1s, opacity 2s;
}

.swish-leave-active {
  transition: max-height 2s, opacity 1s;
}

.blinking {
  animation: blinking-keyframes 1s linear infinite;
}

@keyframes blinking-keyframes {
  50% {
    opacity: 0;
  }
}
</style>

export const geographyPostcodeOutcodes = {
  AB10: { y: 57.13514, x: -2.11731 },
  AB11: { y: 57.13875, x: -2.09089 },
  AB12: { y: 57.101, x: -2.1106 },
  AB13: { y: 57.10801, x: -2.23776 },
  AB14: { y: 57.10076, x: -2.27073 },
  AB15: { y: 57.13868, x: -2.16525 },
  AB16: { y: 57.16115, x: -2.15543 },
  AB21: { y: 57.2096, x: -2.20033 },
  AB22: { y: 57.18724, x: -2.11913 },
  AB23: { y: 57.21242, x: -2.08776 },
  AB24: { y: 57.16284, x: -2.11228 },
  AB25: { y: 57.15311, x: -2.11241 },
  AB30: { y: 56.84678, x: -2.47712 },
  AB31: { y: 57.07479, x: -2.52623 },
  AB32: { y: 57.15545, x: -2.31742 },
  AB33: { y: 57.22464, x: -2.74203 },
  AB34: { y: 57.09393, x: -2.81204 },
  AB35: { y: 57.03829, x: -3.14869 },
  AB36: { y: 57.19715, x: -3.06589 },
  AB37: { y: 57.33098, x: -3.35051 },
  AB38: { y: 57.48589, x: -3.2261 },
  AB39: { y: 56.97778, x: -2.21718 },
  AB41: { y: 57.37592, x: -2.10478 },
  AB42: { y: 57.50079, x: -1.88819 },
  AB43: { y: 57.65714, x: -2.04358 },
  AB44: { y: 57.66877, x: -2.49122 },
  AB45: { y: 57.65139, x: -2.56639 },
  AB51: { y: 57.28907, x: -2.40485 },
  AB52: { y: 57.34396, x: -2.60651 },
  AB53: { y: 57.52537, x: -2.3941 },
  AB54: { y: 57.45361, x: -2.76333 },
  AB55: { y: 57.5239, x: -2.99024 },
  AB56: { y: 57.67442, x: -2.92559 },
  AL1: { y: 51.74836, x: -0.32237 },
  AL10: { y: 51.75958, x: -0.2292 },
  AL2: { y: 51.72064, x: -0.33353 },
  AL3: { y: 51.77781, x: -0.37057 },
  AL4: { y: 51.77133, x: -0.29398 },
  AL5: { y: 51.81622, x: -0.35177 },
  AL6: { y: 51.83104, x: -0.19737 },
  AL7: { y: 51.79669, x: -0.18569 },
  AL8: { y: 51.80334, x: -0.20863 },
  AL9: { y: 51.74472, x: -0.19791 },
  B1: { y: 52.47872, x: -1.90723 },
  B10: { y: 52.46967, x: -1.8513 },
  B11: { y: 52.4544, x: -1.85871 },
  B12: { y: 52.45982, x: -1.88225 },
  B13: { y: 52.43644, x: -1.8779 },
  B14: { y: 52.41757, x: -1.88824 },
  B15: { y: 52.46615, x: -1.92345 },
  B16: { y: 52.47604, x: -1.93374 },
  B17: { y: 52.46077, x: -1.96026 },
  B18: { y: 52.49132, x: -1.92341 },
  B19: { y: 52.4967, x: -1.90425 },
  B2: { y: 52.47871, x: -1.89692 },
  B20: { y: 52.51379, x: -1.91747 },
  B21: { y: 52.50661, x: -1.93959 },
  B23: { y: 52.52812, x: -1.85405 },
  B24: { y: 52.51909, x: -1.82755 },
  B25: { y: 52.46513, x: -1.8204 },
  B26: { y: 52.46059, x: -1.78951 },
  B27: { y: 52.44446, x: -1.82196 },
  B28: { y: 52.4265, x: -1.84115 },
  B29: { y: 52.43739, x: -1.94704 },
  B3: { y: 52.48051, x: -1.90134 },
  B30: { y: 52.42209, x: -1.92794 },
  B31: { y: 52.40772, x: -1.97207 },
  B32: { y: 52.44819, x: -1.99264 },
  B33: { y: 52.48036, x: -1.78647 },
  B34: { y: 52.49564, x: -1.78051 },
  B35: { y: 52.51903, x: -1.78776 },
  B36: { y: 52.50373, x: -1.77899 },
  B37: { y: 52.47847, x: -1.74083 },
  B38: { y: 52.39962, x: -1.93385 },
  B4: { y: 52.48231, x: -1.89397 },
  B40: { y: 52.45865, x: -1.72181 },
  B42: { y: 52.53357, x: -1.91154 },
  B43: { y: 52.54797, x: -1.92921 },
  B44: { y: 52.54523, x: -1.88349 },
  B45: { y: 52.38795, x: -2.00882 },
  B46: { y: 52.50803, x: -1.69351 },
  B47: { y: 52.38699, x: -1.8795 },
  B48: { y: 52.35557, x: -1.9486 },
  B49: { y: 52.21704, x: -1.86679 },
  B5: { y: 52.46972, x: -1.89547 },
  B50: { y: 52.16847, x: -1.85377 },
  B6: { y: 52.50388, x: -1.88508 },
  B60: { y: 52.3259, x: -2.04989 },
  B61: { y: 52.34477, x: -2.06753 },
  B62: { y: 52.45628, x: -2.03238 },
  B63: { y: 52.45177, x: -2.06622 },
  B64: { y: 52.47244, x: -2.06773 },
  B65: { y: 52.48325, x: -2.04271 },
  B66: { y: 52.49314, x: -1.96465 },
  B67: { y: 52.48595, x: -1.97644 },
  B68: { y: 52.47876, x: -2 },
  B69: { y: 52.50213, x: -2.02799 },
  B7: { y: 52.49307, x: -1.87185 },
  B70: { y: 52.52012, x: -2.00295 },
  B71: { y: 52.5354, x: -1.98968 },
  B72: { y: 52.55145, x: -1.82153 },
  B73: { y: 52.55238, x: -1.84217 },
  B74: { y: 52.58297, x: -1.8583 },
  B75: { y: 52.5784, x: -1.80813 },
  B76: { y: 52.5451, x: -1.78616 },
  B77: { y: 52.61855, x: -1.66762 },
  B78: { y: 52.60598, x: -1.67362 },
  B79: { y: 52.65005, x: -1.68069 },
  B8: { y: 52.49034, x: -1.84092 },
  B80: { y: 52.27641, x: -1.89593 },
  B9: { y: 52.47776, x: -1.84833 },
  B90: { y: 52.4004, x: -1.82361 },
  B91: { y: 52.41382, x: -1.78238 },
  B92: { y: 52.43628, x: -1.77345 },
  B93: { y: 52.38048, x: -1.74288 },
  B94: { y: 52.3509, x: -1.7915 },
  B95: { y: 52.28524, x: -1.78008 },
  B96: { y: 52.25217, x: -1.96044 },
  B97: { y: 52.29893, x: -1.95454 },
  B98: { y: 52.3034, x: -1.91346 },
  BA1: { y: 51.39109, x: -2.35934 },
  BA10: { y: 51.11291, x: -2.44576 },
  BA11: { y: 51.23652, x: -2.32519 },
  BA12: { y: 51.17117, x: -2.1874 },
  BA13: { y: 51.26201, x: -2.17917 },
  BA14: { y: 51.31863, x: -2.20092 },
  BA15: { y: 51.35088, x: -2.26425 },
  BA16: { y: 51.12489, x: -2.74313 },
  BA2: { y: 51.35957, x: -2.37633 },
  BA20: { y: 50.93753, x: -2.64193 },
  BA21: { y: 50.95107, x: -2.63358 },
  BA22: { y: 50.96814, x: -2.63666 },
  BA3: { y: 51.27468, x: -2.47313 },
  BA4: { y: 51.17102, x: -2.53073 },
  BA5: { y: 51.21266, x: -2.65291 },
  BA6: { y: 51.14042, x: -2.70478 },
  BA7: { y: 51.08833, x: -2.51835 },
  BA8: { y: 50.99883, x: -2.41046 },
  BA9: { y: 51.05729, x: -2.40955 },
  BB1: { y: 53.75625, x: -2.46265 },
  BB10: { y: 53.79919, x: -2.21865 },
  BB11: { y: 53.78294, x: -2.25196 },
  BB12: { y: 53.80353, x: -2.29005 },
  BB18: { y: 53.91433, x: -2.17053 },
  BB2: { y: 53.74171, x: -2.50039 },
  BB3: { y: 53.69781, x: -2.46503 },
  BB4: { y: 53.70376, x: -2.28936 },
  BB5: { y: 53.75477, x: -2.37162 },
  BB6: { y: 53.79238, x: -2.41598 },
  BB7: { y: 53.87338, x: -2.38636 },
  BB8: { y: 53.85771, x: -2.1627 },
  BB9: { y: 53.83695, x: -2.21429 },
  BD1: { y: 53.79554, x: -1.75252 },
  BD10: { y: 53.83143, x: -1.72496 },
  BD11: { y: 53.75131, x: -1.67543 },
  BD12: { y: 53.74611, x: -1.76039 },
  BD13: { y: 53.78582, x: -1.85883 },
  BD14: { y: 53.78217, x: -1.81635 },
  BD15: { y: 53.80917, x: -1.83597 },
  BD16: { y: 53.84871, x: -1.83278 },
  BD17: { y: 53.84681, x: -1.76742 },
  BD18: { y: 53.83154, x: -1.77662 },
  BD19: { y: 53.72713, x: -1.712 },
  BD2: { y: 53.81438, x: -1.73418 },
  BD20: { y: 53.89646, x: -1.94673 },
  BD21: { y: 53.86407, x: -1.90571 },
  BD22: { y: 53.84522, x: -1.95288 },
  BD23: { y: 53.99264, x: -2.05339 },
  BD24: { y: 54.07773, x: -2.27361 },
  BD3: { y: 53.79818, x: -1.72669 },
  BD4: { y: 53.77569, x: -1.71773 },
  BD5: { y: 53.77847, x: -1.75717 },
  BD6: { y: 53.76414, x: -1.78305 },
  BD7: { y: 53.78301, x: -1.78143 },
  BD8: { y: 53.80099, x: -1.78134 },
  BD9: { y: 53.81179, x: -1.78888 },
  BH1: { y: 50.72427, x: -1.85973 },
  BH10: { y: 50.75847, x: -1.89082 },
  BH11: { y: 50.76119, x: -1.91634 },
  BH12: { y: 50.73692, x: -1.9263 },
  BH13: { y: 50.70993, x: -1.91785 },
  BH14: { y: 50.72164, x: -1.94333 },
  BH15: { y: 50.72165, x: -1.98442 },
  BH16: { y: 50.73783, x: -2.04394 },
  BH17: { y: 50.74593, x: -1.97449 },
  BH18: { y: 50.75853, x: -1.99291 },
  BH19: { y: 50.61102, x: -1.9689 },
  BH2: { y: 50.72159, x: -1.88099 },
  BH20: { y: 50.68648, x: -2.14582 },
  BH21: { y: 50.81248, x: -1.97303 },
  BH22: { y: 50.80524, x: -1.88787 },
  BH23: { y: 50.74567, x: -1.75335 },
  BH24: { y: 50.84377, x: -1.78551 },
  BH25: { y: 50.75173, x: -1.65692 },
  BH3: { y: 50.73508, x: -1.87954 },
  BH31: { y: 50.87537, x: -1.87207 },
  BH4: { y: 50.72071, x: -1.89799 },
  BH5: { y: 50.72513, x: -1.83422 },
  BH6: { y: 50.72688, x: -1.80446 },
  BH7: { y: 50.73502, x: -1.83135 },
  BH8: { y: 50.74044, x: -1.85118 },
  BH9: { y: 50.74946, x: -1.871 },
  BL0: { y: 53.64526, x: -2.31468 },
  BL1: { y: 53.59002, x: -2.44421 },
  BL2: { y: 53.58749, x: -2.39432 },
  BL3: { y: 53.56489, x: -2.43186 },
  BL4: { y: 53.54702, x: -2.39999 },
  BL5: { y: 53.54928, x: -2.51473 },
  BL6: { y: 53.59142, x: -2.53941 },
  BL7: { y: 53.62965, x: -2.42194 },
  BL8: { y: 53.60478, x: -2.32647 },
  BL9: { y: 53.5914, x: -2.28557 },
  BN1: { y: 50.839, x: -0.13786 },
  BN10: { y: 50.7953, x: 0.00232 },
  BN11: { y: 50.81288, x: -0.37599 },
  BN12: { y: 50.81354, x: -0.42423 },
  BN13: { y: 50.83037, x: -0.40521 },
  BN14: { y: 50.83181, x: -0.37959 },
  BN15: { y: 50.83013, x: -0.32426 },
  BN16: { y: 50.81446, x: -0.49377 },
  BN17: { y: 50.81408, x: -0.53495 },
  BN18: { y: 50.84881, x: -0.57933 },
  BN2: { y: 50.82405, x: -0.10296 },
  BN20: { y: 50.77449, x: 0.25536 },
  BN21: { y: 50.77228, x: 0.27653 },
  BN22: { y: 50.78832, x: 0.2844 },
  BN23: { y: 50.79764, x: 0.31325 },
  BN24: { y: 50.81618, x: 0.3312 },
  BN25: { y: 50.77633, x: 0.10932 },
  BN26: { y: 50.82098, x: 0.22352 },
  BN27: { y: 50.87146, x: 0.2643 },
  BN3: { y: 50.83419, x: -0.17498 },
  BN41: { y: 50.8402, x: -0.21452 },
  BN42: { y: 50.83686, x: -0.23169 },
  BN43: { y: 50.83557, x: -0.26583 },
  BN44: { y: 50.89036, x: -0.3221 },
  BN45: { y: 50.8892, x: -0.18421 },
  BN5: { y: 50.92645, x: -0.26815 },
  BN6: { y: 50.92918, x: -0.15276 },
  BN7: { y: 50.87719, x: 0.00014 },
  BN8: { y: 50.91318, x: 0.05148 },
  BN9: { y: 50.79536, x: 0.05058 },
  BR1: { y: 51.41107, x: 0.02192 },
  BR2: { y: 51.38858, x: 0.02237 },
  BR3: { y: 51.40297, x: -0.0302 },
  BR4: { y: 51.37559, x: -0.00695 },
  BR5: { y: 51.38983, x: 0.10436 },
  BR6: { y: 51.36575, x: 0.0932 },
  BR7: { y: 51.41019, x: 0.07222 },
  BR8: { y: 51.39751, x: 0.1766 },
  BS1: { y: 51.45309, x: -2.593 },
  BS10: { y: 51.50606, x: -2.60954 },
  BS11: { y: 51.49672, x: -2.67425 },
  BS13: { y: 51.41163, x: -2.61116 },
  BS14: { y: 51.41278, x: -2.56084 },
  BS15: { y: 51.4589, x: -2.50527 },
  BS16: { y: 51.48496, x: -2.50988 },
  BS2: { y: 51.45945, x: -2.58013 },
  BS20: { y: 51.47916, x: -2.75176 },
  BS21: { y: 51.43443, x: -2.84886 },
  BS22: { y: 51.36008, x: -2.92936 },
  BS23: { y: 51.34266, x: -2.97065 },
  BS24: { y: 51.32769, x: -2.93158 },
  BS25: { y: 51.32039, x: -2.82522 },
  BS26: { y: 51.27971, x: -2.85461 },
  BS27: { y: 51.27131, x: -2.76987 },
  BS28: { y: 51.22515, x: -2.81349 },
  BS29: { y: 51.33081, x: -2.87709 },
  BS3: { y: 51.43776, x: -2.60144 },
  BS30: { y: 51.44465, x: -2.47201 },
  BS31: { y: 51.4077, x: -2.49176 },
  BS32: { y: 51.54228, x: -2.55955 },
  BS34: { y: 51.52338, x: -2.56364 },
  BS35: { y: 51.59629, x: -2.54866 },
  BS36: { y: 51.52553, x: -2.48582 },
  BS37: { y: 51.53928, x: -2.41675 },
  BS39: { y: 51.32749, x: -2.53109 },
  BS4: { y: 51.43437, x: -2.56111 },
  BS40: { y: 51.34644, x: -2.69071 },
  BS41: { y: 51.42221, x: -2.65158 },
  BS48: { y: 51.42523, x: -2.748 },
  BS49: { y: 51.3798, x: -2.81767 },
  BS5: { y: 51.4614, x: -2.54992 },
  BS6: { y: 51.47015, x: -2.59898 },
  BS7: { y: 51.48643, x: -2.58047 },
  BS8: { y: 51.45745, x: -2.62041 },
  BS9: { y: 51.4871, x: -2.62513 },
  BS99: { y: 51.45769, x: -2.57291 },
  BT1: { y: 54.59941, x: -5.92838 },
  BT10: { y: 54.55983, x: -5.98338 },
  BT11: { y: 54.57556, x: -5.9973 },
  BT12: { y: 54.59223, x: -5.95557 },
  BT13: { y: 54.60563, x: -5.95842 },
  BT14: { y: 54.62082, x: -5.96144 },
  BT15: { y: 54.62362, x: -5.93071 },
  BT16: { y: 54.591, x: -5.79746 },
  BT17: { y: 54.55423, x: -6.01688 },
  BT18: { y: 54.64154, x: -5.82079 },
  BT19: { y: 54.6517, x: -5.66663 },
  BT2: { y: 54.59309, x: -5.92932 },
  BT20: { y: 54.65808, x: -5.6641 },
  BT21: { y: 54.63826, x: -5.54448 },
  BT22: { y: 54.5032, x: -5.51886 },
  BT23: { y: 54.56301, x: -5.71437 },
  BT24: { y: 54.41683, x: -5.87232 },
  BT25: { y: 54.39545, x: -6.11078 },
  BT26: { y: 54.45201, x: -6.06088 },
  BT27: { y: 54.51004, x: -6.02027 },
  BT28: { y: 54.52305, x: -6.08804 },
  BT29: { y: 54.62018, x: -6.1939 },
  BT3: { y: 54.61999, x: -5.90401 },
  BT30: { y: 54.33396, x: -5.70444 },
  BT31: { y: 54.27783, x: -5.96992 },
  BT32: { y: 54.33502, x: -6.24483 },
  BT33: { y: 54.22095, x: -5.89385 },
  BT34: { y: 54.15466, x: -6.18034 },
  BT35: { y: 54.16501, x: -6.44355 },
  BT36: { y: 54.67682, x: -5.95448 },
  BT37: { y: 54.67658, x: -5.90634 },
  BT38: { y: 54.7295, x: -5.79799 },
  BT39: { y: 54.74491, x: -6.0171 },
  BT4: { y: 54.6023, x: -5.86668 },
  BT40: { y: 54.84659, x: -5.82146 },
  BT41: { y: 54.73301, x: -6.25997 },
  BT42: { y: 54.85458, x: -6.28215 },
  BT43: { y: 54.89024, x: -6.26565 },
  BT44: { y: 54.97416, x: -6.28394 },
  BT45: { y: 54.76045, x: -6.63935 },
  BT46: { y: 54.86568, x: -6.66393 },
  BT47: { y: 54.97386, x: -7.21151 },
  BT48: { y: 55.00933, x: -7.32731 },
  BT49: { y: 55.04774, x: -6.95153 },
  BT5: { y: 54.5887, x: -5.87004 },
  BT51: { y: 55.05761, x: -6.68 },
  BT52: { y: 55.13423, x: -6.65285 },
  BT53: { y: 55.08519, x: -6.47305 },
  BT54: { y: 55.19997, x: -6.25787 },
  BT55: { y: 55.17971, x: -6.71005 },
  BT56: { y: 55.19824, x: -6.64932 },
  BT57: { y: 55.2014, x: -6.51755 },
  BT6: { y: 54.57974, x: -5.89706 },
  BT60: { y: 54.29219, x: -6.64951 },
  BT61: { y: 54.3679, x: -6.62264 },
  BT62: { y: 54.41256, x: -6.46416 },
  BT63: { y: 54.40362, x: -6.38916 },
  BT64: { y: 54.44938, x: -6.39252 },
  BT65: { y: 54.44496, x: -6.36581 },
  BT66: { y: 54.45514, x: -6.33292 },
  BT67: { y: 54.48713, x: -6.26832 },
  BT68: { y: 54.36626, x: -6.84743 },
  BT69: { y: 54.42425, x: -6.94843 },
  BT7: { y: 54.58158, x: -5.92356 },
  BT70: { y: 54.51495, x: -6.88672 },
  BT71: { y: 54.51322, x: -6.70402 },
  BT74: { y: 54.3432, x: -7.66425 },
  BT75: { y: 54.37531, x: -7.31559 },
  BT76: { y: 54.41941, x: -7.19167 },
  BT77: { y: 54.42711, x: -7.11701 },
  BT78: { y: 54.57165, x: -7.37371 },
  BT79: { y: 54.62422, x: -7.20073 },
  BT8: { y: 54.53994, x: -5.90409 },
  BT80: { y: 54.64464, x: -6.74869 },
  BT81: { y: 54.69395, x: -7.61369 },
  BT82: { y: 54.82576, x: -7.42777 },
  BT9: { y: 54.57176, x: -5.94894 },
  BT92: { y: 54.22294, x: -7.46184 },
  BT93: { y: 54.4651, x: -7.8264 },
  BT94: { y: 54.37595, x: -7.54078 },
  CA1: { y: 54.88535, x: -2.91046 },
  CA10: { y: 54.61104, x: -2.65966 },
  CA11: { y: 54.66422, x: -2.78611 },
  CA12: { y: 54.60214, x: -3.13326 },
  CA13: { y: 54.65997, x: -3.36589 },
  CA14: { y: 54.63454, x: -3.53704 },
  CA15: { y: 54.71165, x: -3.48096 },
  CA16: { y: 54.57413, x: -2.48269 },
  CA17: { y: 54.47841, x: -2.35037 },
  CA18: { y: 54.35408, x: -3.38958 },
  CA19: { y: 54.38651, x: -3.38297 },
  CA2: { y: 54.88415, x: -2.9494 },
  CA20: { y: 54.40987, x: -3.46081 },
  CA21: { y: 54.44516, x: -3.51451 },
  CA22: { y: 54.48001, x: -3.53123 },
  CA23: { y: 54.51465, x: -3.49236 },
  CA24: { y: 54.51493, x: -3.54181 },
  CA25: { y: 54.52243, x: -3.51737 },
  CA26: { y: 54.54445, x: -3.48108 },
  CA27: { y: 54.49091, x: -3.59186 },
  CA28: { y: 54.54324, x: -3.57689 },
  CA3: { y: 54.9076, x: -2.93904 },
  CA4: { y: 54.84636, x: -2.83326 },
  CA5: { y: 54.85485, x: -3.0157 },
  CA6: { y: 54.99423, x: -2.8926 },
  CA7: { y: 54.80696, x: -3.23702 },
  CA8: { y: 54.93895, x: -2.68531 },
  CA9: { y: 54.80176, x: -2.4185 },
  CB1: { y: 52.17611, x: 0.18955 },
  CB10: { y: 52.02989, x: 0.26551 },
  CB11: { y: 51.99938, x: 0.21443 },
  CB2: { y: 52.16276, x: 0.13333 },
  CB21: { y: 52.13194, x: 0.29553 },
  CB22: { y: 52.12718, x: 0.12898 },
  CB23: { y: 52.21349, x: -0.02215 },
  CB24: { y: 52.31538, x: 0.02762 },
  CB25: { y: 52.26083, x: 0.23615 },
  CB3: { y: 52.21324, x: 0.02448 },
  CB4: { y: 52.24312, x: 0.11521 },
  CB5: { y: 52.23868, x: 0.21021 },
  CB6: { y: 52.40388, x: 0.22434 },
  CB7: { y: 52.36606, x: 0.32087 },
  CB8: { y: 52.23083, x: 0.42659 },
  CB9: { y: 52.08212, x: 0.44187 },
  CF10: { y: 51.47387, x: -3.17648 },
  CF11: { y: 51.47281, x: -3.19229 },
  CF14: { y: 51.51947, x: -3.20216 },
  CF15: { y: 51.53401, x: -3.27319 },
  CF23: { y: 51.5155, x: -3.15016 },
  CF24: { y: 51.48479, x: -3.16379 },
  CF3: { y: 51.51947, x: -3.11134 },
  CF31: { y: 51.50876, x: -3.57514 },
  CF32: { y: 51.55992, x: -3.58268 },
  CF33: { y: 51.52424, x: -3.69101 },
  CF34: { y: 51.61024, x: -3.65087 },
  CF35: { y: 51.52211, x: -3.51793 },
  CF36: { y: 51.48456, x: -3.69818 },
  CF37: { y: 51.6026, x: -3.33288 },
  CF38: { y: 51.56034, x: -3.33164 },
  CF39: { y: 51.60153, x: -3.42382 },
  CF40: { y: 51.62009, x: -3.45041 },
  CF41: { y: 51.64758, x: -3.48164 },
  CF42: { y: 51.66685, x: -3.52132 },
  CF43: { y: 51.65788, x: -3.44872 },
  CF44: { y: 51.71802, x: -3.45788 },
  CF45: { y: 51.66882, x: -3.36085 },
  CF46: { y: 51.66501, x: -3.3 },
  CF47: { y: 51.75139, x: -3.37492 },
  CF48: { y: 51.74067, x: -3.3688 },
  CF5: { y: 51.48219, x: -3.2415 },
  CF61: { y: 51.40748, x: -3.47962 },
  CF62: { y: 51.40412, x: -3.30841 },
  CF63: { y: 51.4119, x: -3.25542 },
  CF64: { y: 51.43328, x: -3.18838 },
  CF71: { y: 51.46176, x: -3.45402 },
  CF72: { y: 51.52989, x: -3.39852 },
  CF81: { y: 51.6998, x: -3.24311 },
  CF82: { y: 51.65132, x: -3.236 },
  CF83: { y: 51.58587, x: -3.2169 },
  CH1: { y: 53.20173, x: -2.90732 },
  CH2: { y: 53.2164, x: -2.86869 },
  CH3: { y: 53.1664, x: -2.8213 },
  CH4: { y: 53.16823, x: -2.93803 },
  CH41: { y: 53.39402, x: -3.03022 },
  CH42: { y: 53.37433, x: -3.02073 },
  CH43: { y: 53.38299, x: -3.05853 },
  CH44: { y: 53.41554, x: -3.03676 },
  CH45: { y: 53.42801, x: -3.05061 },
  CH46: { y: 53.40319, x: -3.11018 },
  CH47: { y: 53.39634, x: -3.16866 },
  CH48: { y: 53.37116, x: -3.16947 },
  CH49: { y: 53.38079, x: -3.10208 },
  CH5: { y: 53.20424, x: -3.04064 },
  CH6: { y: 53.25003, x: -3.14518 },
  CH60: { y: 53.32602, x: -3.09466 },
  CH61: { y: 53.34754, x: -3.10122 },
  CH62: { y: 53.33421, x: -2.98072 },
  CH63: { y: 53.34474, x: -3.01101 },
  CH64: { y: 53.28779, x: -3.04717 },
  CH65: { y: 53.27729, x: -2.90142 },
  CH66: { y: 53.27704, x: -2.93442 },
  CH7: { y: 53.16924, x: -3.13256 },
  CH8: { y: 53.28144, x: -3.24053 },
  CM0: { y: 51.66221, x: 0.83719 },
  CM1: { y: 51.74454, x: 0.45694 },
  CM11: { y: 51.62335, x: 0.44605 },
  CM12: { y: 51.62757, x: 0.41593 },
  CM13: { y: 51.61564, x: 0.33872 },
  CM14: { y: 51.61835, x: 0.29263 },
  CM15: { y: 51.64328, x: 0.30545 },
  CM16: { y: 51.69981, x: 0.12161 },
  CM17: { y: 51.77043, x: 0.14522 },
  CM18: { y: 51.75584, x: 0.10685 },
  CM19: { y: 51.76, x: 0.07515 },
  CM2: { y: 51.72141, x: 0.48754 },
  CM20: { y: 51.77476, x: 0.10483 },
  CM21: { y: 51.81177, x: 0.14718 },
  CM22: { y: 51.86164, x: 0.22218 },
  CM23: { y: 51.87178, x: 0.16021 },
  CM24: { y: 51.8988, x: 0.20657 },
  CM3: { y: 51.71932, x: 0.58445 },
  CM4: { y: 51.67392, x: 0.39235 },
  CM5: { y: 51.72083, x: 0.24857 },
  CM6: { y: 51.8768, x: 0.36678 },
  CM7: { y: 51.89752, x: 0.53797 },
  CM77: { y: 51.86224, x: 0.52554 },
  CM8: { y: 51.80536, x: 0.6387 },
  CM9: { y: 51.74097, x: 0.70159 },
  CO1: { y: 51.88891, x: 0.9096 },
  CO10: { y: 52.05706, x: 0.72355 },
  CO11: { y: 51.94126, x: 1.0789 },
  CO12: { y: 51.93115, x: 1.25427 },
  CO13: { y: 51.83802, x: 1.23884 },
  CO14: { y: 51.84979, x: 1.26873 },
  CO15: { y: 51.7962, x: 1.15605 },
  CO16: { y: 51.82039, x: 1.1258 },
  CO2: { y: 51.86947, x: 0.89527 },
  CO3: { y: 51.88466, x: 0.86281 },
  CO4: { y: 51.90654, x: 0.92383 },
  CO5: { y: 51.81459, x: 0.82209 },
  CO6: { y: 51.92419, x: 0.78968 },
  CO7: { y: 51.8838, x: 1.0052 },
  CO8: { y: 51.97328, x: 0.76797 },
  CO9: { y: 51.96878, x: 0.61043 },
  CR0: { y: 51.37223, x: -0.07463 },
  CR2: { y: 51.34983, x: -0.07988 },
  CR3: { y: 51.28687, x: -0.07964 },
  CR4: { y: 51.40415, x: -0.15813 },
  CR5: { y: 51.31211, x: -0.13886 },
  CR6: { y: 51.30799, x: -0.05149 },
  CR7: { y: 51.39517, x: -0.10242 },
  CR8: { y: 51.33594, x: -0.11061 },
  CR9: { y: 51.37223, x: -0.07463 },
  CT1: { y: 51.27719, x: 1.08733 },
  CT10: { y: 51.36208, x: 1.43073 },
  CT11: { y: 51.33557, x: 1.41438 },
  CT12: { y: 51.34216, x: 1.37322 },
  CT13: { y: 51.26692, x: 1.33042 },
  CT14: { y: 51.21582, x: 1.38689 },
  CT15: { y: 51.17109, x: 1.28343 },
  CT16: { y: 51.13996, x: 1.30267 },
  CT17: { y: 51.12845, x: 1.29613 },
  CT18: { y: 51.11447, x: 1.15078 },
  CT19: { y: 51.08785, x: 1.1704 },
  CT2: { y: 51.29261, x: 1.08262 },
  CT20: { y: 51.07899, x: 1.16551 },
  CT21: { y: 51.07233, x: 1.07795 },
  CT3: { y: 51.27743, x: 1.21213 },
  CT4: { y: 51.22462, x: 1.06805 },
  CT5: { y: 51.35318, x: 1.03641 },
  CT6: { y: 51.3642, x: 1.13054 },
  CT7: { y: 51.37122, x: 1.30062 },
  CT8: { y: 51.37996, x: 1.3415 },
  CT9: { y: 51.38304, x: 1.3906 },
  CV1: { y: 52.40939, x: -1.506 },
  CV10: { y: 52.52535, x: -1.50028 },
  CV11: { y: 52.51885, x: -1.45318 },
  CV12: { y: 52.47399, x: -1.47288 },
  CV13: { y: 52.61217, x: -1.41362 },
  CV2: { y: 52.4227, x: -1.46467 },
  CV21: { y: 52.36774, x: -1.27438 },
  CV22: { y: 52.35522, x: -1.28633 },
  CV23: { y: 52.36165, x: -1.30826 },
  CV3: { y: 52.3931, x: -1.48119 },
  CV31: { y: 52.27819, x: -1.52358 },
  CV32: { y: 52.2962, x: -1.53072 },
  CV33: { y: 52.25735, x: -1.48424 },
  CV34: { y: 52.2829, x: -1.57924 },
  CV35: { y: 52.229, x: -1.5944 },
  CV36: { y: 52.06726, x: -1.62504 },
  CV37: { y: 52.1853, x: -1.71328 },
  CV4: { y: 52.39794, x: -1.56492 },
  CV47: { y: 52.23349, x: -1.38494 },
  CV5: { y: 52.41227, x: -1.55008 },
  CV6: { y: 52.43188, x: -1.50722 },
  CV7: { y: 52.44378, x: -1.563 },
  CV8: { y: 52.3511, x: -1.54336 },
  CV9: { y: 52.58493, x: -1.55864 },
  CW1: { y: 53.10281, x: -2.43469 },
  CW10: { y: 53.18907, x: -2.44604 },
  CW11: { y: 53.1426, x: -2.36482 },
  CW12: { y: 53.16455, x: -2.21093 },
  CW2: { y: 53.08119, x: -2.44642 },
  CW3: { y: 52.99952, x: -2.40534 },
  CW4: { y: 53.20648, x: -2.34739 },
  CW5: { y: 53.062, x: -2.52084 },
  CW6: { y: 53.16197, x: -2.66565 },
  CW7: { y: 53.19054, x: -2.52688 },
  CW8: { y: 53.2533, x: -2.56363 },
  CW9: { y: 53.25897, x: -2.50074 },
  DA1: { y: 51.44637, x: 0.20915 },
  DA10: { y: 51.44445, x: 0.30837 },
  DA11: { y: 51.43455, x: 0.35392 },
  DA12: { y: 51.42757, x: 0.38809 },
  DA13: { y: 51.38235, x: 0.35555 },
  DA14: { y: 51.42475, x: 0.11316 },
  DA15: { y: 51.44029, x: 0.09948 },
  DA16: { y: 51.4644, x: 0.10923 },
  DA17: { y: 51.48706, x: 0.14917 },
  DA18: { y: 51.49165, x: 0.14363 },
  DA2: { y: 51.4315, x: 0.23433 },
  DA3: { y: 51.38417, x: 0.30965 },
  DA4: { y: 51.39106, x: 0.23236 },
  DA5: { y: 51.44033, x: 0.14698 },
  DA6: { y: 51.45484, x: 0.14046 },
  DA7: { y: 51.46552, x: 0.14672 },
  DA8: { y: 51.47574, x: 0.17745 },
  DA9: { y: 51.44771, x: 0.27975 },
  DD1: { y: 56.4611, x: -2.97702 },
  DD10: { y: 56.74724, x: -2.4268 },
  DD11: { y: 56.57312, x: -2.59738 },
  DD2: { y: 56.46968, x: -3.02756 },
  DD3: { y: 56.48257, x: -2.98894 },
  DD4: { y: 56.48209, x: -2.93534 },
  DD5: { y: 56.47812, x: -2.86217 },
  DD6: { y: 56.43723, x: -2.92612 },
  DD7: { y: 56.5033, x: -2.71814 },
  DD8: { y: 56.6565, x: -2.92171 },
  DD9: { y: 56.74798, x: -2.6672 },
  DE1: { y: 52.91994, x: -1.47492 },
  DE11: { y: 52.77372, x: -1.55377 },
  DE12: { y: 52.72516, x: -1.55279 },
  DE13: { y: 52.80741, x: -1.68844 },
  DE14: { y: 52.80457, x: -1.6395 },
  DE15: { y: 52.79908, x: -1.6084 },
  DE21: { y: 52.93322, x: -1.43161 },
  DE22: { y: 52.93351, x: -1.49559 },
  DE23: { y: 52.90114, x: -1.49448 },
  DE24: { y: 52.89016, x: -1.4515 },
  DE3: { y: 52.90852, x: -1.54049 },
  DE4: { y: 53.12707, x: -1.56357 },
  DE45: { y: 53.21464, x: -1.6765 },
  DE5: { y: 53.04456, x: -1.4018 },
  DE55: { y: 53.10104, x: -1.37114 },
  DE56: { y: 53.02152, x: -1.47369 },
  DE6: { y: 52.99358, x: -1.7169 },
  DE65: { y: 52.86651, x: -1.60927 },
  DE7: { y: 52.9722, x: -1.32089 },
  DE72: { y: 52.8933, x: -1.35929 },
  DE73: { y: 52.84694, x: -1.43571 },
  DE74: { y: 52.84368, x: -1.32884 },
  DE75: { y: 53.01284, x: -1.35305 },
  DG1: { y: 55.07353, x: -3.58045 },
  DG10: { y: 55.31977, x: -3.43735 },
  DG11: { y: 55.11331, x: -3.33584 },
  DG12: { y: 54.98928, x: -3.25044 },
  DG13: { y: 55.17291, x: -3.02849 },
  DG14: { y: 55.08069, x: -2.98539 },
  DG16: { y: 54.99731, x: -3.06777 },
  DG2: { y: 55.06442, x: -3.65683 },
  DG3: { y: 55.23678, x: -3.79927 },
  DG4: { y: 55.37555, x: -3.95236 },
  DG5: { y: 54.92201, x: -3.81016 },
  DG6: { y: 54.83721, x: -4.05555 },
  DG7: { y: 54.96738, x: -4.01219 },
  DG8: { y: 54.87259, x: -4.51567 },
  DG9: { y: 54.87815, x: -5.02123 },
  DH1: { y: 54.78103, x: -1.56616 },
  DH2: { y: 54.85933, x: -1.59648 },
  DH3: { y: 54.87181, x: -1.56675 },
  DH4: { y: 54.85444, x: -1.4906 },
  DH5: { y: 54.82372, x: -1.45363 },
  DH6: { y: 54.75276, x: -1.46391 },
  DH7: { y: 54.78943, x: -1.66406 },
  DH8: { y: 54.85541, x: -1.84422 },
  DH9: { y: 54.86773, x: -1.70704 },
  DL1: { y: 54.53016, x: -1.53637 },
  DL10: { y: 54.41381, x: -1.69025 },
  DL11: { y: 54.43841, x: -1.8751 },
  DL12: { y: 54.57239, x: -1.98453 },
  DL13: { y: 54.71619, x: -1.95343 },
  DL14: { y: 54.65199, x: -1.68999 },
  DL15: { y: 54.7096, x: -1.72836 },
  DL16: { y: 54.69848, x: -1.60429 },
  DL17: { y: 54.68118, x: -1.54086 },
  DL2: { y: 54.5286, x: -1.6013 },
  DL3: { y: 54.53209, x: -1.57035 },
  DL4: { y: 54.63029, x: -1.64368 },
  DL5: { y: 54.61749, x: -1.57565 },
  DL6: { y: 54.37042, x: -1.38885 },
  DL7: { y: 54.33761, x: -1.48471 },
  DL8: { y: 54.29267, x: -1.78489 },
  DL9: { y: 54.37521, x: -1.70902 },
  DN1: { y: 53.52205, x: -1.1296 },
  DN10: { y: 53.42705, x: -0.94339 },
  DN11: { y: 53.45158, x: -1.08284 },
  DN12: { y: 53.48313, x: -1.22082 },
  DN14: { y: 53.70747, x: -0.93638 },
  DN15: { y: 53.61469, x: -0.64994 },
  DN16: { y: 53.56782, x: -0.63935 },
  DN17: { y: 53.5721, x: -0.70114 },
  DN18: { y: 53.68055, x: -0.44643 },
  DN19: { y: 53.68387, x: -0.35847 },
  DN2: { y: 53.53264, x: -1.10222 },
  DN20: { y: 53.55723, x: -0.50531 },
  DN21: { y: 53.41927, x: -0.70129 },
  DN22: { y: 53.32352, x: -0.92643 },
  DN3: { y: 53.54578, x: -1.05817 },
  DN31: { y: 53.57014, x: -0.08648 },
  DN32: { y: 53.56266, x: -0.06869 },
  DN33: { y: 53.53974, x: -0.09691 },
  DN34: { y: 53.55705, x: -0.11123 },
  DN35: { y: 53.55494, x: -0.03583 },
  DN36: { y: 53.51089, x: -0.03636 },
  DN37: { y: 53.54033, x: -0.13461 },
  DN38: { y: 53.56115, x: -0.39192 },
  DN39: { y: 53.617, x: -0.33385 },
  DN4: { y: 53.50581, x: -1.12088 },
  DN40: { y: 53.6208, x: -0.22331 },
  DN41: { y: 53.58351, x: -0.19466 },
  DN5: { y: 53.53763, x: -1.17153 },
  DN6: { y: 53.5961, x: -1.17643 },
  DN7: { y: 53.58139, x: -1.01508 },
  DN8: { y: 53.61415, x: -0.95535 },
  DN9: { y: 53.50661, x: -0.89316 },
  DT1: { y: 50.71093, x: -2.44052 },
  DT10: { y: 50.93702, x: -2.33306 },
  DT11: { y: 50.86091, x: -2.18047 },
  DT2: { y: 50.74955, x: -2.45365 },
  DT3: { y: 50.64067, x: -2.46674 },
  DT4: { y: 50.60921, x: -2.4636 },
  DT5: { y: 50.54724, x: -2.44041 },
  DT6: { y: 50.73886, x: -2.77528 },
  DT7: { y: 50.7286, x: -2.94657 },
  DT8: { y: 50.81632, x: -2.7581 },
  DT9: { y: 50.94086, x: -2.51529 },
  DY1: { y: 52.51648, x: -2.0958 },
  DY10: { y: 52.38594, x: -2.22483 },
  DY11: { y: 52.38496, x: -2.26009 },
  DY12: { y: 52.38569, x: -2.32916 },
  DY13: { y: 52.33637, x: -2.28035 },
  DY14: { y: 52.37985, x: -2.46282 },
  DY2: { y: 52.49671, x: -2.08102 },
  DY3: { y: 52.52994, x: -2.12826 },
  DY4: { y: 52.53269, x: -2.05308 },
  DY5: { y: 52.48049, x: -2.12223 },
  DY6: { y: 52.49842, x: -2.16353 },
  DY7: { y: 52.46596, x: -2.22376 },
  DY8: { y: 52.45978, x: -2.15455 },
  DY9: { y: 52.43554, x: -2.1221 },
  E1: { y: 51.51766, x: -0.05841 },
  E10: { y: 51.56814, x: -0.01153 },
  E11: { y: 51.56769, x: 0.01443 },
  E12: { y: 51.54992, x: 0.05404 },
  E13: { y: 51.527, x: 0.02705 },
  E14: { y: 51.5062, x: -0.01855 },
  E15: { y: 51.53911, x: 0.00307 },
  E16: { y: 51.51083, x: 0.02633 },
  E17: { y: 51.58623, x: -0.01796 },
  E18: { y: 51.59176, x: 0.02705 },
  E1W: { y: 51.50775, x: -0.05739 },
  E2: { y: 51.52939, x: -0.0608 },
  E3: { y: 51.52789, x: -0.02482 },
  E4: { y: 51.62196, x: -0.00339 },
  E5: { y: 51.55893, x: -0.05233 },
  E6: { y: 51.5256, x: 0.05583 },
  E7: { y: 51.54678, x: 0.02793 },
  E8: { y: 51.54202, x: -0.06315 },
  E9: { y: 51.54258, x: -0.04293 },
  EC1: { y: 51.52286, x: -0.10144 },
  EC1A: { y: 51.52019, x: -0.103 },
  EC1M: { y: 51.52106, x: -0.10152 },
  EC1N: { y: 51.52026, x: -0.10732 },
  EC1R: { y: 51.52475, x: -0.10713 },
  EC1V: { y: 51.52549, x: -0.09701 },
  EC1Y: { y: 51.52272, x: -0.0928 },
  EC2: { y: 51.51995, x: -0.08859 },
  EC2A: { y: 51.5226, x: -0.0856 },
  EC2M: { y: 51.51811, x: -0.08578 },
  EC2N: { y: 51.51631, x: -0.08586 },
  EC2P: { y: 51.5167, x: -0.09356 },
  EC2R: { y: 51.5164, x: -0.09162 },
  EC2V: { y: 51.51553, x: -0.0931 },
  EC2Y: { y: 51.52007, x: -0.09579 },
  EC3: { y: 51.51357, x: -0.08309 },
  EC3A: { y: 51.51534, x: -0.08158 },
  EC3M: { y: 51.51267, x: -0.08313 },
  EC3N: { y: 51.5126, x: -0.07881 },
  EC3P: { y: 51.52016, x: -0.10156 },
  EC3R: { y: 51.51179, x: -0.08461 },
  EC3V: { y: 51.51361, x: -0.08597 },
  EC4: { y: 51.51475, x: -0.10034 },
  EC4A: { y: 51.51576, x: -0.10751 },
  EC4M: { y: 51.51475, x: -0.10034 },
  EC4N: { y: 51.51463, x: -0.09314 },
  EC4R: { y: 51.51191, x: -0.09181 },
  EC4V: { y: 51.51293, x: -0.09897 },
  EC4Y: { y: 51.51397, x: -0.10758 },
  EH1: { y: 55.95243, x: -3.1884 },
  EH10: { y: 55.92077, x: -3.20984 },
  EH11: { y: 55.93387, x: -3.24867 },
  EH12: { y: 55.94262, x: -3.27137 },
  EH13: { y: 55.90788, x: -3.24144 },
  EH14: { y: 55.90925, x: -3.28308 },
  EH15: { y: 55.94686, x: -3.11136 },
  EH16: { y: 55.92221, x: -3.15387 },
  EH17: { y: 55.90704, x: -3.14222 },
  EH18: { y: 55.87667, x: -3.12215 },
  EH19: { y: 55.87324, x: -3.10446 },
  EH2: { y: 55.95417, x: -3.19486 },
  EH20: { y: 55.87905, x: -3.15579 },
  EH21: { y: 55.93845, x: -3.04548 },
  EH22: { y: 55.88441, x: -3.06001 },
  EH23: { y: 55.83956, x: -3.05081 },
  EH24: { y: 55.85051, x: -3.13257 },
  EH25: { y: 55.85821, x: -3.17434 },
  EH26: { y: 55.83257, x: -3.22307 },
  EH27: { y: 55.89063, x: -3.42319 },
  EH28: { y: 55.9306, x: -3.38624 },
  EH29: { y: 55.95652, x: -3.39838 },
  EH3: { y: 55.95412, x: -3.19967 },
  EH30: { y: 55.98455, x: -3.38336 },
  EH31: { y: 56.03627, x: -2.82661 },
  EH32: { y: 55.96799, x: -2.94853 },
  EH33: { y: 55.94196, x: -2.94469 },
  EH34: { y: 55.90919, x: -2.8799 },
  EH35: { y: 55.91053, x: -2.94233 },
  EH36: { y: 55.85637, x: -2.85155 },
  EH37: { y: 55.86188, x: -2.95873 },
  EH38: { y: 55.78638, x: -2.96006 },
  EH39: { y: 56.04766, x: -2.73052 },
  EH4: { y: 55.96253, x: -3.25761 },
  EH40: { y: 55.99057, x: -2.6557 },
  EH41: { y: 55.94854, x: -2.7751 },
  EH42: { y: 55.99389, x: -2.52428 },
  EH43: { y: 55.62422, x: -3.0101 },
  EH44: { y: 55.62015, x: -3.06558 },
  EH45: { y: 55.65494, x: -3.19209 },
  EH46: { y: 55.73689, x: -3.3491 },
  EH47: { y: 55.85976, x: -3.66336 },
  EH48: { y: 55.9011, x: -3.66353 },
  EH49: { y: 55.97656, x: -3.59785 },
  EH5: { y: 55.97463, x: -3.21633 },
  EH51: { y: 56.01153, x: -3.6041 },
  EH52: { y: 55.93756, x: -3.48256 },
  EH53: { y: 55.8936, x: -3.47608 },
  EH54: { y: 55.89212, x: -3.524 },
  EH55: { y: 55.84748, x: -3.57178 },
  EH6: { y: 55.97144, x: -3.17456 },
  EH7: { y: 55.96075, x: -3.16463 },
  EH8: { y: 55.94909, x: -3.16268 },
  EH9: { y: 55.9336, x: -3.18463 },
  EN1: { y: 51.6536, x: -0.06707 },
  EN10: { y: 51.74005, x: -0.01992 },
  EN11: { y: 51.76502, x: -0.00723 },
  EN2: { y: 51.6585, x: -0.09144 },
  EN3: { y: 51.65852, x: -0.03794 },
  EN4: { y: 51.6479, x: -0.15983 },
  EN5: { y: 51.64934, x: -0.19447 },
  EN6: { y: 51.69942, x: -0.17656 },
  EN7: { y: 51.71025, x: -0.0661 },
  EN8: { y: 51.69799, x: -0.03189 },
  EN9: { y: 51.69451, x: 0.01427 },
  EX1: { y: 50.72543, x: -3.50479 },
  EX10: { y: 50.69252, x: -3.24461 },
  EX11: { y: 50.74603, x: -3.28714 },
  EX12: { y: 50.70585, x: -3.08066 },
  EX13: { y: 50.78655, x: -3.00733 },
  EX14: { y: 50.81272, x: -3.1896 },
  EX15: { y: 50.87675, x: -3.33907 },
  EX16: { y: 50.92258, x: -3.49122 },
  EX17: { y: 50.81176, x: -3.70205 },
  EX18: { y: 50.90155, x: -3.88739 },
  EX19: { y: 50.87712, x: -4.0044 },
  EX2: { y: 50.70998, x: -3.51705 },
  EX20: { y: 50.75004, x: -4.01882 },
  EX21: { y: 50.80507, x: -4.20857 },
  EX22: { y: 50.82804, x: -4.3673 },
  EX23: { y: 50.82366, x: -4.53323 },
  EX24: { y: 50.73816, x: -3.08849 },
  EX3: { y: 50.68645, x: -3.45824 },
  EX31: { y: 51.08566, x: -4.06339 },
  EX32: { y: 51.07115, x: -4.01849 },
  EX33: { y: 51.11343, x: -4.16894 },
  EX34: { y: 51.19829, x: -4.10278 },
  EX35: { y: 51.2236, x: -3.82749 },
  EX36: { y: 51.01638, x: -3.79224 },
  EX37: { y: 50.97875, x: -3.95179 },
  EX38: { y: 50.94376, x: -4.15107 },
  EX39: { y: 51.01394, x: -4.24557 },
  EX4: { y: 50.73055, x: -3.52621 },
  EX5: { y: 50.75684, x: -3.43916 },
  EX6: { y: 50.67744, x: -3.59668 },
  EX7: { y: 50.59183, x: -3.47227 },
  EX8: { y: 50.62958, x: -3.40133 },
  EX9: { y: 50.6386, x: -3.32239 },
  FK1: { y: 55.98555, x: -3.79381 },
  FK10: { y: 56.11516, x: -3.78053 },
  FK11: { y: 56.15009, x: -3.84977 },
  FK12: { y: 56.15263, x: -3.79998 },
  FK13: { y: 56.15078, x: -3.74032 },
  FK14: { y: 56.16536, x: -3.66044 },
  FK15: { y: 56.20249, x: -3.94903 },
  FK16: { y: 56.19081, x: -4.05966 },
  FK17: { y: 56.24019, x: -4.21887 },
  FK18: { y: 56.32448, x: -4.32728 },
  FK19: { y: 56.37057, x: -4.3139 },
  FK2: { y: 56.00499, x: -3.75461 },
  FK20: { y: 56.4065, x: -4.63059 },
  FK21: { y: 56.46574, x: -4.31969 },
  FK3: { y: 56.01087, x: -3.71958 },
  FK4: { y: 55.99734, x: -3.90662 },
  FK5: { y: 56.02564, x: -3.81814 },
  FK6: { y: 56.02149, x: -3.91423 },
  FK7: { y: 56.09695, x: -3.91636 },
  FK8: { y: 56.13075, x: -4.05001 },
  FK9: { y: 56.14242, x: -3.93955 },
  FY1: { y: 53.81551, x: -3.04509 },
  FY2: { y: 53.84524, x: -3.03823 },
  FY3: { y: 53.82111, x: -3.02092 },
  FY4: { y: 53.79139, x: -3.02779 },
  FY5: { y: 53.87415, x: -3.02069 },
  FY6: { y: 53.86819, x: -2.981 },
  FY7: { y: 53.91635, x: -3.02629 },
  FY8: { y: 53.75026, x: -3.001 },
  G1: { y: 55.86038, x: -4.24671 },
  G11: { y: 55.87356, x: -4.31142 },
  G12: { y: 55.88006, x: -4.30061 },
  G13: { y: 55.89358, x: -4.3462 },
  G14: { y: 55.88095, x: -4.34864 },
  G15: { y: 55.9094, x: -4.36476 },
  G2: { y: 55.86382, x: -4.2549 },
  G20: { y: 55.8858, x: -4.28176 },
  G21: { y: 55.88063, x: -4.22069 },
  G22: { y: 55.88998, x: -4.25002 },
  G23: { y: 55.90193, x: -4.28431 },
  G3: { y: 55.86619, x: -4.27262 },
  G31: { y: 55.85748, x: -4.20819 },
  G32: { y: 55.8484, x: -4.16293 },
  G33: { y: 55.87351, x: -4.16593 },
  G34: { y: 55.86817, x: -4.11128 },
  G4: { y: 55.86837, x: -4.25196 },
  G40: { y: 55.84824, x: -4.22204 },
  G41: { y: 55.83815, x: -4.28216 },
  G42: { y: 55.83234, x: -4.25627 },
  G43: { y: 55.81825, x: -4.28898 },
  G44: { y: 55.81438, x: -4.25523 },
  G45: { y: 55.80494, x: -4.23074 },
  G46: { y: 55.80446, x: -4.30573 },
  G5: { y: 55.84769, x: -4.25237 },
  G51: { y: 55.85734, x: -4.31365 },
  G52: { y: 55.8477, x: -4.34823 },
  G53: { y: 55.82157, x: -4.35145 },
  G60: { y: 55.92205, x: -4.45358 },
  G61: { y: 55.91905, x: -4.33014 },
  G62: { y: 55.94261, x: -4.32034 },
  G63: { y: 56.04243, x: -4.36487 },
  G64: { y: 55.91127, x: -4.21604 },
  G65: { y: 55.97334, x: -4.0577 },
  G66: { y: 55.94024, x: -4.15364 },
  G67: { y: 55.94755, x: -3.98586 },
  G68: { y: 55.95345, x: -4.01019 },
  G69: { y: 55.87372, x: -4.10199 },
  G71: { y: 55.8221, x: -4.07206 },
  G72: { y: 55.80406, x: -4.12856 },
  G73: { y: 55.81977, x: -4.20605 },
  G74: { y: 55.76912, x: -4.17289 },
  G76: { y: 55.77811, x: -4.27227 },
  G77: { y: 55.77345, x: -4.32941 },
  G78: { y: 55.79259, x: -4.40873 },
  G81: { y: 55.91309, x: -4.4066 },
  G82: { y: 55.95195, x: -4.57403 },
  G83: { y: 56.01196, x: -4.58444 },
  G84: { y: 56.0137, x: -4.75306 },
  GL1: { y: 51.8599, x: -2.23962 },
  GL10: { y: 51.7429, x: -2.28391 },
  GL11: { y: 51.68875, x: -2.35591 },
  GL12: { y: 51.62482, x: -2.3843 },
  GL13: { y: 51.69289, x: -2.45867 },
  GL14: { y: 51.81691, x: -2.47879 },
  GL15: { y: 51.73656, x: -2.55036 },
  GL16: { y: 51.79561, x: -2.61054 },
  GL17: { y: 51.85542, x: -2.51405 },
  GL18: { y: 51.94124, x: -2.41464 },
  GL19: { y: 51.94252, x: -2.28662 },
  GL2: { y: 51.84635, x: -2.26714 },
  GL20: { y: 52.00304, x: -2.12966 },
  GL3: { y: 51.86451, x: -2.17719 },
  GL4: { y: 51.84196, x: -2.2163 },
  GL5: { y: 51.73855, x: -2.2187 },
  GL50: { y: 51.89968, x: -2.07995 },
  GL51: { y: 51.89876, x: -2.10756 },
  GL52: { y: 51.91588, x: -2.05816 },
  GL53: { y: 51.88081, x: -2.06684 },
  GL54: { y: 51.89693, x: -1.86338 },
  GL55: { y: 52.06043, x: -1.76806 },
  GL56: { y: 51.98836, x: -1.70435 },
  GL6: { y: 51.735, x: -2.19117 },
  GL7: { y: 51.71263, x: -1.89723 },
  GL8: { y: 51.64151, x: -2.17054 },
  GL9: { y: 51.55763, x: -2.29718 },
  GU1: { y: 51.24255, x: -0.56436 },
  GU10: { y: 51.1975, x: -0.79622 },
  GU11: { y: 51.24841, x: -0.76194 },
  GU12: { y: 51.25167, x: -0.73032 },
  GU13: { y: 51.27436, x: -0.83723 },
  GU14: { y: 51.29246, x: -0.76075 },
  GU15: { y: 51.3345, x: -0.73952 },
  GU16: { y: 51.311, x: -0.72868 },
  GU17: { y: 51.32959, x: -0.78559 },
  GU18: { y: 51.34813, x: -0.67021 },
  GU19: { y: 51.35914, x: -0.69 },
  GU2: { y: 51.24468, x: -0.59152 },
  GU20: { y: 51.36776, x: -0.65671 },
  GU21: { y: 51.32006, x: -0.57773 },
  GU22: { y: 51.31162, x: -0.54929 },
  GU23: { y: 51.29671, x: -0.50816 },
  GU24: { y: 51.32963, x: -0.62625 },
  GU25: { y: 51.4009, x: -0.56948 },
  GU26: { y: 51.11243, x: -0.74557 },
  GU27: { y: 51.08155, x: -0.71785 },
  GU28: { y: 50.99061, x: -0.62915 },
  GU29: { y: 50.98383, x: -0.74619 },
  GU3: { y: 51.24596, x: -0.62301 },
  GU30: { y: 51.07435, x: -0.80228 },
  GU31: { y: 50.9962, x: -0.90263 },
  GU32: { y: 51.00849, x: -0.96934 },
  GU33: { y: 51.04915, x: -0.89139 },
  GU34: { y: 51.13902, x: -0.98359 },
  GU35: { y: 51.11617, x: -0.84692 },
  GU4: { y: 51.24315, x: -0.53999 },
  GU46: { y: 51.3372, x: -0.82559 },
  GU47: { y: 51.34761, x: -0.78799 },
  GU5: { y: 51.20237, x: -0.51551 },
  GU51: { y: 51.28338, x: -0.83987 },
  GU52: { y: 51.26447, x: -0.83748 },
  GU6: { y: 51.14078, x: -0.48174 },
  GU7: { y: 51.18915, x: -0.61039 },
  GU8: { y: 51.15082, x: -0.64014 },
  GU9: { y: 51.21815, x: -0.79282 },
  GY1: { y: 49.45856, x: -2.53684 },
  GY2: { y: 49.57871, x: -2.49362 },
  GY3: { y: 49.52875, x: -2.45821 },
  GY4: { y: 49.52875, x: -2.45821 },
  GY5: { y: 49.4826, x: -2.58101 },
  GY6: { y: 49.52875, x: -2.45821 },
  GY7: { y: 49.52875, x: -2.45821 },
  GY8: { y: 49.52875, x: -2.45821 },
  GY9: { y: 49.57183, x: -2.28244 },
  HA0: { y: 51.55024, x: -0.3037 },
  HA1: { y: 51.57948, x: -0.33581 },
  HA2: { y: 51.57349, x: -0.35768 },
  HA3: { y: 51.59274, x: -0.31945 },
  HA4: { y: 51.56971, x: -0.40977 },
  HA5: { y: 51.59455, x: -0.38436 },
  HA6: { y: 51.61032, x: -0.41991 },
  HA7: { y: 51.60965, x: -0.30727 },
  HA8: { y: 51.61094, x: -0.27255 },
  HA9: { y: 51.55898, x: -0.28606 },
  HD1: { y: 53.64639, x: -1.78819 },
  HD2: { y: 53.66794, x: -1.77446 },
  HD3: { y: 53.65274, x: -1.8275 },
  HD4: { y: 53.62662, x: -1.79585 },
  HD5: { y: 53.6472, x: -1.74583 },
  HD6: { y: 53.70121, x: -1.78186 },
  HD7: { y: 53.62404, x: -1.87601 },
  HD8: { y: 53.6012, x: -1.6796 },
  HD9: { y: 53.57988, x: -1.79457 },
  HG1: { y: 53.99983, x: -1.53313 },
  HG2: { y: 53.98722, x: -1.52565 },
  HG3: { y: 54.03069, x: -1.61676 },
  HG4: { y: 54.15636, x: -1.5666 },
  HG5: { y: 54.013, x: -1.45668 },
  HP1: { y: 51.75509, x: -0.48731 },
  HP10: { y: 51.60928, x: -0.70159 },
  HP11: { y: 51.62413, x: -0.74451 },
  HP12: { y: 51.62538, x: -0.77771 },
  HP13: { y: 51.63309, x: -0.74137 },
  HP14: { y: 51.64839, x: -0.82913 },
  HP15: { y: 51.65615, x: -0.71182 },
  HP16: { y: 51.70208, x: -0.71775 },
  HP17: { y: 51.77558, x: -0.86933 },
  HP18: { y: 51.81347, x: -0.98155 },
  HP19: { y: 51.8219, x: -0.82464 },
  HP2: { y: 51.76273, x: -0.45227 },
  HP20: { y: 51.81898, x: -0.80295 },
  HP21: { y: 51.80641, x: -0.80473 },
  HP22: { y: 51.80601, x: -0.76558 },
  HP23: { y: 51.79496, x: -0.66001 },
  HP27: { y: 51.71944, x: -0.83019 },
  HP3: { y: 51.73689, x: -0.47054 },
  HP4: { y: 51.76688, x: -0.56663 },
  HP5: { y: 51.71251, x: -0.60744 },
  HP6: { y: 51.67823, x: -0.59836 },
  HP7: { y: 51.66487, x: -0.6089 },
  HP8: { y: 51.64286, x: -0.57344 },
  HP9: { y: 51.61035, x: -0.63801 },
  HR1: { y: 52.06415, x: -2.66963 },
  HR2: { y: 52.0095, x: -2.78393 },
  HR3: { y: 52.09353, x: -3.07446 },
  HR4: { y: 52.08784, x: -2.76632 },
  HR5: { y: 52.19196, x: -3.02708 },
  HR6: { y: 52.23258, x: -2.77028 },
  HR7: { y: 52.18629, x: -2.52079 },
  HR8: { y: 52.04454, x: -2.44331 },
  HR9: { y: 51.90816, x: -2.58153 },
  HS1: { y: 58.21347, x: -6.38138 },
  HS2: { y: 58.24916, x: -6.46768 },
  HS3: { y: 57.87876, x: -6.85387 },
  HS4: { y: 57.86961, x: -6.69056 },
  HS5: { y: 57.79992, x: -6.96123 },
  HS6: { y: 57.6008, x: -7.29943 },
  HS7: { y: 57.44703, x: -7.33888 },
  HS8: { y: 57.23253, x: -7.34593 },
  HS9: { y: 56.97148, x: -7.47153 },
  HU1: { y: 53.74288, x: -0.33494 },
  HU10: { y: 53.75149, x: -0.44078 },
  HU11: { y: 53.82572, x: -0.21921 },
  HU12: { y: 53.72369, x: -0.13105 },
  HU13: { y: 53.72446, x: -0.43572 },
  HU14: { y: 53.72799, x: -0.50078 },
  HU15: { y: 53.74831, x: -0.62139 },
  HU16: { y: 53.78179, x: -0.41993 },
  HU17: { y: 53.8466, x: -0.42661 },
  HU18: { y: 53.90944, x: -0.16692 },
  HU19: { y: 53.72277, x: 0.03566 },
  HU2: { y: 53.75011, x: -0.33769 },
  HU20: { y: 53.78836, x: -0.51229 },
  HU3: { y: 53.74148, x: -0.36381 },
  HU4: { y: 53.73568, x: -0.40043 },
  HU5: { y: 53.75961, x: -0.37524 },
  HU6: { y: 53.7811, x: -0.36834 },
  HU7: { y: 53.7886, x: -0.32554 },
  HU8: { y: 53.76845, x: -0.29903 },
  HU9: { y: 53.75567, x: -0.28589 },
  HX1: { y: 53.72111, x: -1.86966 },
  HX2: { y: 53.73733, x: -1.90448 },
  HX3: { y: 53.72648, x: -1.84387 },
  HX4: { y: 53.67978, x: -1.88039 },
  HX5: { y: 53.68601, x: -1.83494 },
  HX6: { y: 53.69779, x: -1.92426 },
  HX7: { y: 53.73916, x: -2.00758 },
  IG1: { y: 51.55855, x: 0.07463 },
  IG10: { y: 51.64775, x: 0.06569 },
  IG11: { y: 51.53573, x: 0.09378 },
  IG2: { y: 51.57373, x: 0.08109 },
  IG3: { y: 51.56168, x: 0.10074 },
  IG4: { y: 51.57688, x: 0.05525 },
  IG5: { y: 51.58749, x: 0.06584 },
  IG6: { y: 51.59247, x: 0.08917 },
  IG7: { y: 51.61316, x: 0.08867 },
  IG8: { y: 51.60694, x: 0.0335 },
  IG9: { y: 51.62391, x: 0.04004 },
  IM1: { y: 54.15048, x: -4.48174 },
  IM2: { y: 54.17891, x: -4.46857 },
  IM3: { y: 54.17219, x: -4.45155 },
  IM4: { y: 54.18407, x: -4.53302 },
  IM5: { y: 54.19316, x: -4.7065 },
  IM6: { y: 54.28659, x: -4.58037 },
  IM7: { y: 54.32613, x: -4.41041 },
  IM8: { y: 54.278, x: -4.08474 },
  IM9: { y: 54.09184, x: -4.68352 },
  IP1: { y: 52.06644, x: 1.14151 },
  IP10: { y: 52.01888, x: 1.26939 },
  IP11: { y: 51.97028, x: 1.3343 },
  IP12: { y: 52.09243, x: 1.37262 },
  IP13: { y: 52.18475, x: 1.32107 },
  IP14: { y: 52.20597, x: 1.03423 },
  IP15: { y: 52.15686, x: 1.59688 },
  IP16: { y: 52.20397, x: 1.58604 },
  IP17: { y: 52.22985, x: 1.50316 },
  IP18: { y: 52.32995, x: 1.67259 },
  IP19: { y: 52.33982, x: 1.4957 },
  IP2: { y: 52.04501, x: 1.13563 },
  IP20: { y: 52.40601, x: 1.3215 },
  IP21: { y: 52.36431, x: 1.23608 },
  IP22: { y: 52.37548, x: 1.05319 },
  IP23: { y: 52.30615, x: 1.12472 },
  IP24: { y: 52.41869, x: 0.75903 },
  IP25: { y: 52.58588, x: 0.83892 },
  IP26: { y: 52.50733, x: 0.57739 },
  IP27: { y: 52.4298, x: 0.5861 },
  IP28: { y: 52.32627, x: 0.54924 },
  IP29: { y: 52.19245, x: 0.66303 },
  IP3: { y: 52.0418, x: 1.18792 },
  IP30: { y: 52.20909, x: 0.83679 },
  IP31: { y: 52.29023, x: 0.82877 },
  IP32: { y: 52.25326, x: 0.71797 },
  IP33: { y: 52.24178, x: 0.70848 },
  IP4: { y: 52.06, x: 1.18046 },
  IP5: { y: 52.06102, x: 1.24182 },
  IP6: { y: 52.131, x: 1.11536 },
  IP7: { y: 52.06872, x: 0.94901 },
  IP8: { y: 52.06169, x: 1.0828 },
  IP9: { y: 51.99067, x: 1.14931 },
  IV1: { y: 57.4874, x: -4.23059 },
  IV10: { y: 57.58536, x: -4.12616 },
  IV11: { y: 57.67758, x: -4.03762 },
  IV12: { y: 57.57422, x: -3.86458 },
  IV13: { y: 57.3428, x: -4.01239 },
  IV14: { y: 57.58564, x: -4.54783 },
  IV15: { y: 57.59965, x: -4.42996 },
  IV16: { y: 57.66431, x: -4.33703 },
  IV17: { y: 57.69985, x: -4.26043 },
  IV18: { y: 57.70169, x: -4.15479 },
  IV19: { y: 57.80478, x: -4.06162 },
  IV2: { y: 57.46833, x: -4.19107 },
  IV20: { y: 57.78653, x: -3.90748 },
  IV21: { y: 57.72377, x: -5.72019 },
  IV22: { y: 57.74156, x: -5.50682 },
  IV23: { y: 57.77322, x: -5.01018 },
  IV24: { y: 57.89133, x: -4.35512 },
  IV25: { y: 57.89135, x: -4.04295 },
  IV26: { y: 57.91578, x: -5.17243 },
  IV27: { y: 58.27684, x: -4.80032 },
  IV28: { y: 58.01527, x: -4.15668 },
  IV3: { y: 57.47726, x: -4.24499 },
  IV30: { y: 57.65468, x: -3.32732 },
  IV31: { y: 57.71706, x: -3.28931 },
  IV32: { y: 57.63161, x: -3.11041 },
  IV36: { y: 57.61104, x: -3.60363 },
  IV4: { y: 57.44843, x: -4.53328 },
  IV40: { y: 57.27446, x: -5.64981 },
  IV41: { y: 57.27372, x: -5.73605 },
  IV42: { y: 57.23946, x: -5.82877 },
  IV43: { y: 57.1555, x: -5.81015 },
  IV44: { y: 57.11488, x: -5.87374 },
  IV45: { y: 57.06714, x: -5.90508 },
  IV46: { y: 57.1141, x: -5.98441 },
  IV47: { y: 57.29467, x: -6.34284 },
  IV48: { y: 57.31102, x: -6.09713 },
  IV49: { y: 57.2277, x: -5.94693 },
  IV5: { y: 57.4679, x: -4.4112 },
  IV51: { y: 57.48055, x: -6.24636 },
  IV52: { y: 57.33737, x: -5.65106 },
  IV53: { y: 57.34465, x: -5.55367 },
  IV54: { y: 57.43101, x: -5.61538 },
  IV55: { y: 57.45873, x: -6.611 },
  IV56: { y: 57.36636, x: -6.4279 },
  IV6: { y: 57.52634, x: -4.45682 },
  IV63: { y: 57.32228, x: -4.50465 },
  IV7: { y: 57.58435, x: -4.38376 },
  IV8: { y: 57.55419, x: -4.26477 },
  IV9: { y: 57.56922, x: -4.17706 },
  JE1: { y: 49.19094, x: -2.11961 },
  JE2: { y: 49.20183, x: -2.10719 },
  JE3: { y: 49.18581, x: -2.13418 },
  JE4: { y: 49.19392, x: -2.09104 },
  KA1: { y: 55.5958, x: -4.49509 },
  KA10: { y: 55.54945, x: -4.64755 },
  KA11: { y: 55.61736, x: -4.62512 },
  KA12: { y: 55.61923, x: -4.66337 },
  KA13: { y: 55.6553, x: -4.69921 },
  KA14: { y: 55.74041, x: -4.67162 },
  KA15: { y: 55.74859, x: -4.62596 },
  KA16: { y: 55.60713, x: -4.33065 },
  KA17: { y: 55.61075, x: -4.28164 },
  KA18: { y: 55.45063, x: -4.23917 },
  KA19: { y: 55.34585, x: -4.66707 },
  KA2: { y: 55.59619, x: -4.56338 },
  KA20: { y: 55.64157, x: -4.75072 },
  KA21: { y: 55.64176, x: -4.78253 },
  KA22: { y: 55.65019, x: -4.80697 },
  KA23: { y: 55.69135, x: -4.85449 },
  KA24: { y: 55.70981, x: -4.71412 },
  KA25: { y: 55.75263, x: -4.6884 },
  KA26: { y: 55.21894, x: -4.83313 },
  KA27: { y: 55.5388, x: -5.17162 },
  KA28: { y: 55.75447, x: -4.92287 },
  KA29: { y: 55.75701, x: -4.85291 },
  KA3: { y: 55.64435, x: -4.49499 },
  KA30: { y: 55.79725, x: -4.86224 },
  KA4: { y: 55.59894, x: -4.38256 },
  KA5: { y: 55.50987, x: -4.38668 },
  KA6: { y: 55.41582, x: -4.50427 },
  KA7: { y: 55.44739, x: -4.62805 },
  KA8: { y: 55.4711, x: -4.61222 },
  KA9: { y: 55.49734, x: -4.60446 },
  KT1: { y: 51.40713, x: -0.29606 },
  KT10: { y: 51.36749, x: -0.35357 },
  KT11: { y: 51.32769, x: -0.40237 },
  KT12: { y: 51.37721, x: -0.40639 },
  KT13: { y: 51.36788, x: -0.44838 },
  KT14: { y: 51.33869, x: -0.48526 },
  KT15: { y: 51.36309, x: -0.49451 },
  KT16: { y: 51.38312, x: -0.51398 },
  KT17: { y: 51.34078, x: -0.24827 },
  KT18: { y: 51.3167, x: -0.2621 },
  KT19: { y: 51.35096, x: -0.26799 },
  KT2: { y: 51.41602, x: -0.28854 },
  KT20: { y: 51.28564, x: -0.23029 },
  KT21: { y: 51.31098, x: -0.30106 },
  KT22: { y: 51.3007, x: -0.33731 },
  KT23: { y: 51.28137, x: -0.36956 },
  KT24: { y: 51.26869, x: -0.42879 },
  KT3: { y: 51.39935, x: -0.25609 },
  KT4: { y: 51.37757, x: -0.24255 },
  KT5: { y: 51.39171, x: -0.28657 },
  KT6: { y: 51.3892, x: -0.2996 },
  KT7: { y: 51.38959, x: -0.3269 },
  KT8: { y: 51.40089, x: -0.36243 },
  KT9: { y: 51.36405, x: -0.30197 },
  KW1: { y: 58.45801, x: -3.12092 },
  KW10: { y: 57.97509, x: -3.975 },
  KW11: { y: 58.30505, x: -4.12991 },
  KW12: { y: 58.50634, x: -3.49149 },
  KW13: { y: 58.45518, x: -3.89556 },
  KW14: { y: 58.58913, x: -3.55523 },
  KW15: { y: 58.9803, x: -2.95862 },
  KW16: { y: 58.95963, x: -3.27278 },
  KW17: { y: 59.04195, x: -3.00217 },
  KW2: { y: 58.34893, x: -3.16359 },
  KW3: { y: 58.30741, x: -3.27829 },
  KW5: { y: 58.28747, x: -3.38163 },
  KW6: { y: 58.24908, x: -3.44148 },
  KW7: { y: 58.18641, x: -3.50018 },
  KW8: { y: 58.11611, x: -3.6636 },
  KW9: { y: 58.0137, x: -3.85692 },
  KY1: { y: 56.12815, x: -3.1375 },
  KY10: { y: 56.23024, x: -2.70011 },
  KY11: { y: 56.0471, x: -3.4145 },
  KY12: { y: 56.07674, x: -3.49272 },
  KY13: { y: 56.20866, x: -3.43173 },
  KY14: { y: 56.31048, x: -3.23992 },
  KY15: { y: 56.30055, x: -3.0505 },
  KY16: { y: 56.34184, x: -2.81865 },
  KY2: { y: 56.12234, x: -3.18238 },
  KY3: { y: 56.06346, x: -3.23037 },
  KY4: { y: 56.11421, x: -3.36066 },
  KY5: { y: 56.14548, x: -3.29416 },
  KY6: { y: 56.19678, x: -3.19756 },
  KY7: { y: 56.19719, x: -3.15405 },
  KY8: { y: 56.19755, x: -3.01061 },
  KY9: { y: 56.20965, x: -2.83356 },
  L1: { y: 53.40254, x: -2.97928 },
  L10: { y: 53.47398, x: -2.92668 },
  L11: { y: 53.44801, x: -2.91407 },
  L12: { y: 53.43467, x: -2.89421 },
  L13: { y: 53.4174, x: -2.91943 },
  L14: { y: 53.41861, x: -2.87883 },
  L15: { y: 53.39763, x: -2.91901 },
  L16: { y: 53.39876, x: -2.88744 },
  L17: { y: 53.37769, x: -2.93962 },
  L18: { y: 53.38064, x: -2.90661 },
  L19: { y: 53.3591, x: -2.90165 },
  L2: { y: 53.40696, x: -2.98841 },
  L20: { y: 53.45281, x: -2.98797 },
  L21: { y: 53.4707, x: -2.99893 },
  L22: { y: 53.47676, x: -3.0262 },
  L23: { y: 53.49118, x: -3.02203 },
  L24: { y: 53.34339, x: -2.83672 },
  L25: { y: 53.37827, x: -2.86296 },
  L26: { y: 53.3641, x: -2.83262 },
  L27: { y: 53.38744, x: -2.83758 },
  L28: { y: 53.4349, x: -2.86411 },
  L29: { y: 53.50679, x: -2.98319 },
  L3: { y: 53.4088, x: -2.98394 },
  L30: { y: 53.48442, x: -2.97062 },
  L31: { y: 53.51346, x: -2.93659 },
  L32: { y: 53.47786, x: -2.88909 },
  L33: { y: 53.48965, x: -2.87577 },
  L34: { y: 53.43614, x: -2.81445 },
  L35: { y: 53.41478, x: -2.78244 },
  L36: { y: 53.41439, x: -2.83962 },
  L37: { y: 53.55735, x: -3.06288 },
  L38: { y: 53.5242, x: -3.04998 },
  L39: { y: 53.56142, x: -2.89537 },
  L4: { y: 53.43775, x: -2.96052 },
  L40: { y: 53.60227, x: -2.84032 },
  L5: { y: 53.42505, x: -2.97529 },
  L6: { y: 53.41896, x: -2.94956 },
  L69: { y: 53.40709, x: -2.97337 },
  L7: { y: 53.40549, x: -2.94776 },
  L70: { y: 53.40891, x: -2.9704 },
  L8: { y: 53.39009, x: -2.96246 },
  L9: { y: 53.4612, x: -2.95201 },
  LA1: { y: 54.04663, x: -2.79885 },
  LA10: { y: 54.31433, x: -2.49348 },
  LA11: { y: 54.20034, x: -2.92446 },
  LA12: { y: 54.20351, x: -3.08246 },
  LA13: { y: 54.11792, x: -3.19805 },
  LA14: { y: 54.1185, x: -3.2302 },
  LA15: { y: 54.15588, x: -3.1777 },
  LA16: { y: 54.1862, x: -3.20155 },
  LA17: { y: 54.2386, x: -3.17545 },
  LA18: { y: 54.21512, x: -3.27448 },
  LA19: { y: 54.29312, x: -3.37523 },
  LA2: { y: 54.06537, x: -2.6739 },
  LA20: { y: 54.29404, x: -3.20469 },
  LA21: { y: 54.3645, x: -3.07283 },
  LA22: { y: 54.42276, x: -2.98649 },
  LA23: { y: 54.37122, x: -2.91136 },
  LA3: { y: 54.05058, x: -2.87683 },
  LA4: { y: 54.06964, x: -2.85125 },
  LA5: { y: 54.14287, x: -2.78998 },
  LA6: { y: 54.17537, x: -2.61134 },
  LA7: { y: 54.22568, x: -2.77162 },
  LA8: { y: 54.32373, x: -2.75961 },
  LA9: { y: 54.32563, x: -2.74273 },
  LD1: { y: 52.26047, x: -3.3657 },
  LD2: { y: 52.14145, x: -3.39275 },
  LD3: { y: 51.96006, x: -3.37111 },
  LD4: { y: 52.11786, x: -3.55416 },
  LD5: { y: 52.12692, x: -3.61584 },
  LD6: { y: 52.30909, x: -3.50804 },
  LD7: { y: 52.3524, x: -3.08513 },
  LD8: { y: 52.26735, x: -3.04348 },
  LE1: { y: 52.63379, x: -1.12812 },
  LE10: { y: 52.53641, x: -1.36744 },
  LE11: { y: 52.76927, x: -1.21584 },
  LE12: { y: 52.76641, x: -1.19217 },
  LE13: { y: 52.76572, x: -0.88536 },
  LE14: { y: 52.7838, x: -0.89527 },
  LE15: { y: 52.65594, x: -0.70037 },
  LE16: { y: 52.48616, x: -0.89244 },
  LE17: { y: 52.4669, x: -1.17706 },
  LE18: { y: 52.58332, x: -1.11141 },
  LE19: { y: 52.57718, x: -1.21047 },
  LE2: { y: 52.60942, x: -1.11531 },
  LE3: { y: 52.62876, x: -1.17993 },
  LE4: { y: 52.66342, x: -1.12309 },
  LE5: { y: 52.63616, x: -1.08521 },
  LE6: { y: 52.66244, x: -1.24141 },
  LE65: { y: 52.74819, x: -1.4681 },
  LE67: { y: 52.71619, x: -1.36041 },
  LE7: { y: 52.67739, x: -1.06808 },
  LE8: { y: 52.55264, x: -1.09728 },
  LE9: { y: 52.57012, x: -1.28728 },
  LL11: { y: 53.06314, x: -3.03425 },
  LL12: { y: 53.08601, x: -2.98851 },
  LL13: { y: 53.0359, x: -2.95902 },
  LL14: { y: 52.99198, x: -3.05043 },
  LL15: { y: 53.10263, x: -3.31455 },
  LL16: { y: 53.18489, x: -3.43231 },
  LL17: { y: 53.25319, x: -3.4346 },
  LL18: { y: 53.31023, x: -3.47554 },
  LL19: { y: 53.33082, x: -3.40866 },
  LL20: { y: 52.95949, x: -3.15982 },
  LL21: { y: 52.99006, x: -3.40947 },
  LL22: { y: 53.26827, x: -3.59257 },
  LL23: { y: 52.90493, x: -3.60003 },
  LL24: { y: 53.05695, x: -3.78474 },
  LL25: { y: 53.0546, x: -3.87866 },
  LL26: { y: 53.13877, x: -3.78514 },
  LL27: { y: 53.14718, x: -3.82288 },
  LL28: { y: 53.28749, x: -3.75983 },
  LL29: { y: 53.28916, x: -3.70738 },
  LL30: { y: 53.31813, x: -3.81514 },
  LL31: { y: 53.29035, x: -3.80946 },
  LL32: { y: 53.26022, x: -3.83968 },
  LL33: { y: 53.24987, x: -3.98015 },
  LL34: { y: 53.27076, x: -3.91212 },
  LL35: { y: 52.54657, x: -4.04127 },
  LL36: { y: 52.60374, x: -4.06313 },
  LL37: { y: 52.66546, x: -4.0823 },
  LL38: { y: 52.69767, x: -4.03943 },
  LL39: { y: 52.71076, x: -4.01043 },
  LL40: { y: 52.75614, x: -3.87468 },
  LL41: { y: 52.96914, x: -3.9375 },
  LL42: { y: 52.7253, x: -4.05405 },
  LL43: { y: 52.77409, x: -4.09193 },
  LL44: { y: 52.79111, x: -4.09572 },
  LL45: { y: 52.81989, x: -4.09562 },
  LL46: { y: 52.85477, x: -4.10621 },
  LL47: { y: 52.9014, x: -4.06237 },
  LL48: { y: 52.93544, x: -4.06994 },
  LL49: { y: 52.92883, x: -4.13808 },
  LL51: { y: 52.97377, x: -4.23712 },
  LL52: { y: 52.92425, x: -4.24052 },
  LL53: { y: 52.88418, x: -4.49117 },
  LL54: { y: 53.05829, x: -4.28478 },
  LL55: { y: 53.13355, x: -4.20355 },
  LL56: { y: 53.18669, x: -4.19879 },
  LL57: { y: 53.20718, x: -4.10998 },
  LL58: { y: 53.28022, x: -4.09857 },
  LL59: { y: 53.23417, x: -4.15926 },
  LL60: { y: 53.21505, x: -4.26915 },
  LL61: { y: 53.1954, x: -4.26212 },
  LL62: { y: 53.21007, x: -4.38572 },
  LL63: { y: 53.21824, x: -4.47007 },
  LL64: { y: 53.22805, x: -4.51709 },
  LL65: { y: 53.30067, x: -4.5694 },
  LL66: { y: 53.38055, x: -4.40427 },
  LL67: { y: 53.41097, x: -4.45565 },
  LL68: { y: 53.39818, x: -4.37668 },
  LL69: { y: 53.38583, x: -4.31883 },
  LL70: { y: 53.36396, x: -4.28758 },
  LL71: { y: 53.32732, x: -4.36823 },
  LL72: { y: 53.34957, x: -4.24023 },
  LL73: { y: 53.33511, x: -4.24397 },
  LL74: { y: 53.31645, x: -4.23248 },
  LL75: { y: 53.28343, x: -4.21876 },
  LL76: { y: 53.30017, x: -4.23764 },
  LL77: { y: 53.26011, x: -4.31053 },
  LL78: { y: 53.31678, x: -4.26253 },
  LN1: { y: 53.25883, x: -0.58621 },
  LN10: { y: 53.15858, x: -0.21703 },
  LN11: { y: 53.3732, x: 0.02496 },
  LN12: { y: 53.3293, x: 0.2662 },
  LN13: { y: 53.26518, x: 0.18482 },
  LN2: { y: 53.25429, x: -0.5084 },
  LN3: { y: 53.24325, x: -0.41885 },
  LN4: { y: 53.15018, x: -0.38489 },
  LN5: { y: 53.17126, x: -0.55617 },
  LN6: { y: 53.20319, x: -0.59403 },
  LN7: { y: 53.49216, x: -0.34327 },
  LN8: { y: 53.37321, x: -0.32384 },
  LN9: { y: 53.21719, x: -0.11124 },
  LS1: { y: 53.79674, x: -1.54754 },
  LS10: { y: 53.76162, x: -1.53123 },
  LS11: { y: 53.77609, x: -1.55535 },
  LS12: { y: 53.79062, x: -1.59618 },
  LS13: { y: 53.81052, x: -1.63396 },
  LS14: { y: 53.82782, x: -1.45604 },
  LS15: { y: 53.80619, x: -1.44417 },
  LS16: { y: 53.85086, x: -1.60169 },
  LS17: { y: 53.85958, x: -1.52709 },
  LS18: { y: 53.8402, x: -1.6413 },
  LS19: { y: 53.8592, x: -1.6822 },
  LS2: { y: 53.80123, x: -1.54597 },
  LS20: { y: 53.87365, x: -1.71251 },
  LS21: { y: 53.91132, x: -1.67876 },
  LS22: { y: 53.93179, x: -1.39375 },
  LS23: { y: 53.90372, x: -1.35458 },
  LS24: { y: 53.86987, x: -1.25168 },
  LS25: { y: 53.78584, x: -1.33059 },
  LS26: { y: 53.75227, x: -1.44791 },
  LS27: { y: 53.74839, x: -1.60266 },
  LS28: { y: 53.80163, x: -1.66897 },
  LS29: { y: 53.92058, x: -1.80356 },
  LS3: { y: 53.80128, x: -1.55964 },
  LS4: { y: 53.80944, x: -1.58082 },
  LS5: { y: 53.81851, x: -1.60199 },
  LS6: { y: 53.81928, x: -1.56704 },
  LS7: { y: 53.81828, x: -1.53971 },
  LS8: { y: 53.82445, x: -1.50926 },
  LS9: { y: 53.79928, x: -1.50803 },
  LU1: { y: 51.87476, x: -0.42373 },
  LU2: { y: 51.89147, x: -0.39553 },
  LU3: { y: 51.90829, x: -0.44291 },
  LU4: { y: 51.89871, x: -0.4665 },
  LU5: { y: 51.90829, x: -0.5127 },
  LU6: { y: 51.87711, x: -0.53552 },
  LU7: { y: 51.90921, x: -0.66388 },
  M1: { y: 53.47734, x: -2.23508 },
  M11: { y: 53.47834, x: -2.17933 },
  M12: { y: 53.46482, x: -2.20187 },
  M13: { y: 53.4603, x: -2.21389 },
  M14: { y: 53.4477, x: -2.22437 },
  M15: { y: 53.46563, x: -2.25008 },
  M16: { y: 53.45481, x: -2.26357 },
  M17: { y: 53.46906, x: -2.31789 },
  M18: { y: 53.46127, x: -2.16871 },
  M19: { y: 53.43696, x: -2.19421 },
  M2: { y: 53.48003, x: -2.24263 },
  M20: { y: 53.42521, x: -2.23027 },
  M21: { y: 53.43771, x: -2.27099 },
  M22: { y: 53.3856, x: -2.25863 },
  M23: { y: 53.39902, x: -2.28729 },
  M24: { y: 53.55113, x: -2.19624 },
  M25: { y: 53.5285, x: -2.27459 },
  M26: { y: 53.56161, x: -2.33369 },
  M27: { y: 53.51216, x: -2.33631 },
  M28: { y: 53.51467, x: -2.39666 },
  M29: { y: 53.50816, x: -2.45692 },
  M3: { y: 53.48361, x: -2.25018 },
  M30: { y: 53.48424, x: -2.35418 },
  M31: { y: 53.4193, x: -2.42136 },
  M32: { y: 53.45021, x: -2.30872 },
  M33: { y: 53.4205, x: -2.32506 },
  M34: { y: 53.45594, x: -2.11748 },
  M35: { y: 53.50714, x: -2.1523 },
  M38: { y: 53.53167, x: -2.42096 },
  M4: { y: 53.48455, x: -2.22909 },
  M40: { y: 53.50349, x: -2.18999 },
  M41: { y: 53.45095, x: -2.36294 },
  M43: { y: 53.48378, x: -2.1477 },
  M44: { y: 53.44086, x: -2.42609 },
  M45: { y: 53.54734, x: -2.28829 },
  M46: { y: 53.52601, x: -2.49031 },
  M5: { y: 53.47903, x: -2.28482 },
  M6: { y: 53.49159, x: -2.29696 },
  M60: { y: 53.48635, x: -2.22759 },
  M7: { y: 53.50516, x: -2.26086 },
  M8: { y: 53.5088, x: -2.23827 },
  M9: { y: 53.52143, x: -2.21269 },
  M90: { y: 53.36309, x: -2.27653 },
  ME1: { y: 51.37216, x: 0.50017 },
  ME10: { y: 51.34258, x: 0.73697 },
  ME11: { y: 51.41247, x: 0.74833 },
  ME12: { y: 51.42282, x: 0.80507 },
  ME13: { y: 51.30275, x: 0.89675 },
  ME14: { y: 51.28043, x: 0.54108 },
  ME15: { y: 51.2545, x: 0.53392 },
  ME16: { y: 51.27414, x: 0.49915 },
  ME17: { y: 51.23484, x: 0.61021 },
  ME18: { y: 51.24426, x: 0.42301 },
  ME19: { y: 51.29307, x: 0.41123 },
  ME2: { y: 51.39136, x: 0.4854 },
  ME20: { y: 51.30467, x: 0.45919 },
  ME3: { y: 51.43322, x: 0.54668 },
  ME4: { y: 51.3805, x: 0.53081 },
  ME5: { y: 51.34896, x: 0.53338 },
  ME6: { y: 51.32662, x: 0.4417 },
  ME7: { y: 51.37904, x: 0.5566 },
  ME8: { y: 51.36102, x: 0.5987 },
  ME9: { y: 51.33297, x: 0.72491 },
  MK1: { y: 52.00522, x: -0.72659 },
  MK10: { y: 52.03908, x: -0.69792 },
  MK11: { y: 52.05226, x: -0.84048 },
  MK12: { y: 52.05741, x: -0.81555 },
  MK13: { y: 52.05258, x: -0.78359 },
  MK14: { y: 52.05955, x: -0.76152 },
  MK15: { y: 52.05739, x: -0.72802 },
  MK16: { y: 52.08879, x: -0.72129 },
  MK17: { y: 51.99235, x: -0.70074 },
  MK18: { y: 51.97972, x: -0.96904 },
  MK19: { y: 52.07215, x: -0.85164 },
  MK2: { y: 51.98991, x: -0.72411 },
  MK3: { y: 51.99651, x: -0.75306 },
  MK4: { y: 52.00397, x: -0.77762 },
  MK40: { y: 52.13797, x: -0.47145 },
  MK41: { y: 52.15113, x: -0.44614 },
  MK42: { y: 52.12002, x: -0.47352 },
  MK43: { y: 52.11837, x: -0.55684 },
  MK44: { y: 52.19402, x: -0.42563 },
  MK45: { y: 52.02462, x: -0.46803 },
  MK46: { y: 52.15411, x: -0.69165 },
  MK5: { y: 52.02021, x: -0.78301 },
  MK6: { y: 52.02967, x: -0.74193 },
  MK7: { y: 52.01921, x: -0.68976 },
  MK8: { y: 52.0357, x: -0.803 },
  MK9: { y: 52.04064, x: -0.75912 },
  ML1: { y: 55.79943, x: -3.97512 },
  ML10: { y: 55.68013, x: -4.06932 },
  ML11: { y: 55.66661, x: -3.78555 },
  ML12: { y: 55.56677, x: -3.59544 },
  ML2: { y: 55.77706, x: -3.91179 },
  ML3: { y: 55.77028, x: -4.05337 },
  ML4: { y: 55.81846, x: -4.02078 },
  ML5: { y: 55.85964, x: -4.03091 },
  ML6: { y: 55.86704, x: -3.96257 },
  ML7: { y: 55.83008, x: -3.79303 },
  ML8: { y: 55.7305, x: -3.84103 },
  ML9: { y: 55.72496, x: -3.96658 },
  N1: { y: 51.53717, x: -0.09652 },
  N10: { y: 51.59368, x: -0.14325 },
  N11: { y: 51.61338, x: -0.13812 },
  N12: { y: 51.61487, x: -0.17561 },
  N13: { y: 51.6191, x: -0.10176 },
  N14: { y: 51.63392, x: -0.1286 },
  N15: { y: 51.5819, x: -0.08166 },
  N16: { y: 51.5629, x: -0.07525 },
  N17: { y: 51.59791, x: -0.07088 },
  N18: { y: 51.61309, x: -0.06446 },
  N19: { y: 51.56464, x: -0.12712 },
  N2: { y: 51.58957, x: -0.16797 },
  N20: { y: 51.62923, x: -0.17359 },
  N21: { y: 51.63612, x: -0.09816 },
  N22: { y: 51.59944, x: -0.1098 },
  N3: { y: 51.59984, x: -0.1921 },
  N4: { y: 51.57052, x: -0.1009 },
  N5: { y: 51.55337, x: -0.09729 },
  N6: { y: 51.57116, x: -0.14129 },
  N7: { y: 51.55277, x: -0.11607 },
  N8: { y: 51.58335, x: -0.11625 },
  N9: { y: 51.62825, x: -0.05659 },
  NE1: { y: 54.97262, x: -1.61097 },
  NE10: { y: 54.94726, x: -1.55345 },
  NE11: { y: 54.94123, x: -1.63157 },
  NE12: { y: 55.0246, x: -1.56823 },
  NE13: { y: 55.04642, x: -1.64469 },
  NE15: { y: 54.9837, x: -1.72026 },
  NE16: { y: 54.9324, x: -1.69096 },
  NE17: { y: 54.91378, x: -1.81591 },
  NE18: { y: 55.03787, x: -1.86072 },
  NE19: { y: 55.17901, x: -2.0958 },
  NE2: { y: 54.98698, x: -1.60458 },
  NE20: { y: 55.05304, x: -1.78553 },
  NE21: { y: 54.95764, x: -1.71888 },
  NE22: { y: 55.1361, x: -1.58428 },
  NE23: { y: 55.08037, x: -1.58173 },
  NE24: { y: 55.12238, x: -1.52013 },
  NE25: { y: 55.04945, x: -1.48656 },
  NE26: { y: 55.05201, x: -1.45679 },
  NE27: { y: 55.03247, x: -1.51025 },
  NE28: { y: 55.00015, x: -1.51846 },
  NE29: { y: 55.0116, x: -1.46359 },
  NE3: { y: 55.01043, x: -1.6325 },
  NE30: { y: 55.02138, x: -1.44 },
  NE31: { y: 54.97136, x: -1.511 },
  NE32: { y: 54.96765, x: -1.48292 },
  NE33: { y: 54.99077, x: -1.42792 },
  NE34: { y: 54.97186, x: -1.42037 },
  NE35: { y: 54.95227, x: -1.4597 },
  NE36: { y: 54.94498, x: -1.43794 },
  NE37: { y: 54.913, x: -1.52574 },
  NE38: { y: 54.89412, x: -1.52441 },
  NE39: { y: 54.92357, x: -1.75813 },
  NE4: { y: 54.97361, x: -1.64221 },
  NE40: { y: 54.96584, x: -1.77193 },
  NE41: { y: 54.9758, x: -1.81875 },
  NE42: { y: 54.96237, x: -1.85474 },
  NE43: { y: 54.94804, x: -1.90475 },
  NE44: { y: 54.94268, x: -1.97814 },
  NE45: { y: 54.97683, x: -2.01563 },
  NE46: { y: 54.97948, x: -2.10626 },
  NE47: { y: 54.92894, x: -2.25127 },
  NE48: { y: 55.13114, x: -2.25724 },
  NE49: { y: 54.96698, x: -2.45927 },
  NE5: { y: 54.99621, x: -1.68735 },
  NE6: { y: 54.97695, x: -1.56405 },
  NE61: { y: 55.18045, x: -1.68905 },
  NE62: { y: 55.16129, x: -1.59187 },
  NE63: { y: 55.17736, x: -1.56187 },
  NE64: { y: 55.18437, x: -1.51467 },
  NE65: { y: 55.3127, x: -1.75104 },
  NE66: { y: 55.42858, x: -1.73135 },
  NE67: { y: 55.53363, x: -1.6926 },
  NE68: { y: 55.57398, x: -1.65739 },
  NE69: { y: 55.60468, x: -1.71586 },
  NE7: { y: 54.99947, x: -1.57788 },
  NE70: { y: 55.59409, x: -1.81591 },
  NE71: { y: 55.55558, x: -2.04439 },
  NE8: { y: 54.95552, x: -1.60333 },
  NE9: { y: 54.93118, x: -1.58328 },
  NE99: { y: 54.96723, x: -1.61259 },
  NG1: { y: 52.954, x: -1.147 },
  NG10: { y: 52.90005, x: -1.28187 },
  NG11: { y: 52.89934, x: -1.17335 },
  NG12: { y: 52.91104, x: -1.05415 },
  NG13: { y: 52.94767, x: -0.92087 },
  NG14: { y: 53.01519, x: -1.03696 },
  NG15: { y: 53.04519, x: -1.20338 },
  NG16: { y: 53.02688, x: -1.29319 },
  NG17: { y: 53.11569, x: -1.26035 },
  NG18: { y: 53.13766, x: -1.18373 },
  NG19: { y: 53.15933, x: -1.19678 },
  NG2: { y: 52.935, x: -1.131 },
  NG20: { y: 53.20958, x: -1.18386 },
  NG21: { y: 53.14069, x: -1.10443 },
  NG22: { y: 53.17826, x: -0.97046 },
  NG23: { y: 53.11553, x: -0.80012 },
  NG24: { y: 53.06966, x: -0.79841 },
  NG25: { y: 53.07294, x: -0.95506 },
  NG3: { y: 52.96735, x: -1.12887 },
  NG31: { y: 52.9142, x: -0.6391 },
  NG32: { y: 52.94939, x: -0.64991 },
  NG33: { y: 52.81819, x: -0.57829 },
  NG34: { y: 52.98753, x: -0.39096 },
  NG4: { y: 52.97062, x: -1.08561 },
  NG5: { y: 52.99713, x: -1.14317 },
  NG6: { y: 52.99746, x: -1.19085 },
  NG7: { y: 52.95779, x: -1.17521 },
  NG8: { y: 52.96434, x: -1.21231 },
  NG9: { y: 52.92583, x: -1.23533 },
  NN1: { y: 52.24154, x: -0.88682 },
  NN10: { y: 52.2915, x: -0.59668 },
  NN11: { y: 52.24033, x: -1.17833 },
  NN12: { y: 52.12744, x: -0.99778 },
  NN13: { y: 52.0324, x: -1.14715 },
  NN14: { y: 52.40948, x: -0.69145 },
  NN15: { y: 52.38446, x: -0.70542 },
  NN16: { y: 52.40531, x: -0.72098 },
  NN17: { y: 52.50026, x: -0.68876 },
  NN18: { y: 52.47986, x: -0.7144 },
  NN2: { y: 52.2623, x: -0.89509 },
  NN29: { y: 52.2599, x: -0.66362 },
  NN3: { y: 52.26182, x: -0.84528 },
  NN4: { y: 52.21645, x: -0.89623 },
  NN5: { y: 52.24732, x: -0.92916 },
  NN6: { y: 52.32208, x: -0.9435 },
  NN7: { y: 52.20954, x: -0.92714 },
  NN8: { y: 52.30254, x: -0.69754 },
  NN9: { y: 52.33288, x: -0.59831 },
  NP10: { y: 51.57943, x: -3.04497 },
  NP11: { y: 51.64249, x: -3.13025 },
  NP12: { y: 51.6688, x: -3.19743 },
  NP13: { y: 51.73412, x: -3.13977 },
  NP15: { y: 51.72552, x: -2.88616 },
  NP16: { y: 51.64951, x: -2.68808 },
  NP18: { y: 51.60637, x: -2.94305 },
  NP19: { y: 51.58913, x: -2.9629 },
  NP20: { y: 51.59057, x: -3.0048 },
  NP22: { y: 51.7707, x: -3.25665 },
  NP23: { y: 51.78482, x: -3.19615 },
  NP24: { y: 51.71964, x: -3.23786 },
  NP25: { y: 51.80307, x: -2.72232 },
  NP26: { y: 51.59051, x: -2.77669 },
  NP4: { y: 51.71882, x: -3.04528 },
  NP44: { y: 51.65064, x: -3.02637 },
  NP7: { y: 51.82884, x: -3.00864 },
  NP8: { y: 51.85996, x: -3.14586 },
  NR1: { y: 52.62671, x: 1.31012 },
  NR10: { y: 52.73642, x: 1.24582 },
  NR11: { y: 52.84173, x: 1.27596 },
  NR12: { y: 52.75971, x: 1.47144 },
  NR13: { y: 52.63372, x: 1.47327 },
  NR14: { y: 52.56445, x: 1.3807 },
  NR15: { y: 52.50639, x: 1.2701 },
  NR16: { y: 52.476, x: 1.06162 },
  NR17: { y: 52.51544, x: 0.99655 },
  NR18: { y: 52.57095, x: 1.11105 },
  NR19: { y: 52.67542, x: 0.92907 },
  NR2: { y: 52.6301, x: 1.28524 },
  NR20: { y: 52.72449, x: 0.98124 },
  NR21: { y: 52.84097, x: 0.85257 },
  NR22: { y: 52.89366, x: 0.86793 },
  NR23: { y: 52.94872, x: 0.85966 },
  NR24: { y: 52.85851, x: 1.05135 },
  NR25: { y: 52.91969, x: 1.08541 },
  NR26: { y: 52.93511, x: 1.21602 },
  NR27: { y: 52.92467, x: 1.30157 },
  NR28: { y: 52.82203, x: 1.40068 },
  NR29: { y: 52.6926, x: 1.63932 },
  NR3: { y: 52.64332, x: 1.29511 },
  NR30: { y: 52.61874, x: 1.72926 },
  NR31: { y: 52.57614, x: 1.71087 },
  NR32: { y: 52.48903, x: 1.73448 },
  NR33: { y: 52.45441, x: 1.71976 },
  NR34: { y: 52.43791, x: 1.58442 },
  NR35: { y: 52.46125, x: 1.43463 },
  NR4: { y: 52.61357, x: 1.26479 },
  NR5: { y: 52.6405, x: 1.23427 },
  NR6: { y: 52.66074, x: 1.2831 },
  NR7: { y: 52.65063, x: 1.33521 },
  NR8: { y: 52.67798, x: 1.21188 },
  NR9: { y: 52.63574, x: 1.10973 },
  NW1: { y: 51.53252, x: -0.14286 },
  NW10: { y: 51.53951, x: -0.24641 },
  NW11: { y: 51.57831, x: -0.19584 },
  NW2: { y: 51.55797, x: -0.21829 },
  NW3: { y: 51.55184, x: -0.17093 },
  NW4: { y: 51.58682, x: -0.22294 },
  NW5: { y: 51.55139, x: -0.14209 },
  NW6: { y: 51.54142, x: -0.19442 },
  NW7: { y: 51.61488, x: -0.23484 },
  NW8: { y: 51.53117, x: -0.17175 },
  NW9: { y: 51.5864, x: -0.25472 },
  OL1: { y: 53.54944, x: -2.10566 },
  OL10: { y: 53.59064, x: -2.22211 },
  OL11: { y: 53.606, x: -2.17382 },
  OL12: { y: 53.63567, x: -2.16487 },
  OL13: { y: 53.70034, x: -2.20299 },
  OL14: { y: 53.71395, x: -2.0985 },
  OL15: { y: 53.64384, x: -2.09985 },
  OL16: { y: 53.61234, x: -2.13152 },
  OL2: { y: 53.57191, x: -2.10874 },
  OL3: { y: 53.55309, x: -2.00906 },
  OL4: { y: 53.54228, x: -2.07244 },
  OL5: { y: 53.51712, x: -2.03771 },
  OL6: { y: 53.49372, x: -2.08593 },
  OL7: { y: 53.49011, x: -2.10552 },
  OL8: { y: 53.52606, x: -2.11616 },
  OL9: { y: 53.53952, x: -2.14186 },
  OX1: { y: 51.74451, x: -1.25978 },
  OX10: { y: 51.60781, x: -1.12625 },
  OX11: { y: 51.59965, x: -1.24626 },
  OX12: { y: 51.59437, x: -1.42537 },
  OX13: { y: 51.67849, x: -1.34765 },
  OX14: { y: 51.66728, x: -1.2755 },
  OX15: { y: 52.03219, x: -1.40666 },
  OX16: { y: 52.06241, x: -1.34061 },
  OX17: { y: 52.07291, x: -1.28938 },
  OX18: { y: 51.76232, x: -1.59134 },
  OX2: { y: 51.7644, x: -1.27685 },
  OX20: { y: 51.85659, x: -1.35814 },
  OX25: { y: 51.90067, x: -1.21216 },
  OX26: { y: 51.90114, x: -1.15109 },
  OX27: { y: 51.93709, x: -1.14751 },
  OX28: { y: 51.78529, x: -1.48675 },
  OX29: { y: 51.79403, x: -1.43154 },
  OX3: { y: 51.76131, x: -1.21458 },
  OX33: { y: 51.75813, x: -1.14653 },
  OX39: { y: 51.70045, x: -0.91317 },
  OX4: { y: 51.72984, x: -1.21513 },
  OX44: { y: 51.70046, x: -1.13025 },
  OX49: { y: 51.64996, x: -0.99967 },
  OX5: { y: 51.82917, x: -1.28016 },
  OX6: { y: 51.90845, x: -1.16695 },
  OX7: { y: 51.91135, x: -1.52747 },
  OX8: { y: 51.78964, x: -1.4548 },
  OX9: { y: 51.73794, x: -0.9818 },
  PA1: { y: 55.84457, x: -4.41674 },
  PA10: { y: 55.83375, x: -4.55184 },
  PA11: { y: 55.85649, x: -4.5821 },
  PA12: { y: 55.7954, x: -4.62273 },
  PA13: { y: 55.89235, x: -4.62927 },
  PA14: { y: 55.92757, x: -4.66368 },
  PA15: { y: 55.94279, x: -4.74802 },
  PA16: { y: 55.94351, x: -4.79613 },
  PA17: { y: 55.86512, x: -4.88162 },
  PA18: { y: 55.88928, x: -4.88661 },
  PA19: { y: 55.9528, x: -4.82244 },
  PA2: { y: 55.82896, x: -4.43334 },
  PA20: { y: 55.83482, x: -5.0567 },
  PA21: { y: 55.90186, x: -5.24762 },
  PA22: { y: 55.97074, x: -5.1572 },
  PA23: { y: 55.95474, x: -4.93154 },
  PA24: { y: 56.15675, x: -4.90504 },
  PA25: { y: 56.21553, x: -5.04017 },
  PA26: { y: 56.25758, x: -4.9369 },
  PA27: { y: 56.15422, x: -5.08365 },
  PA28: { y: 55.43603, x: -5.60305 },
  PA29: { y: 55.80476, x: -5.47741 },
  PA3: { y: 55.85205, x: -4.44757 },
  PA30: { y: 56.0133, x: -5.44799 },
  PA31: { y: 56.05245, x: -5.46273 },
  PA32: { y: 56.20284, x: -5.11658 },
  PA33: { y: 56.36978, x: -5.05569 },
  PA34: { y: 56.4021, x: -5.49931 },
  PA35: { y: 56.40507, x: -5.22391 },
  PA36: { y: 56.52032, x: -4.77019 },
  PA37: { y: 56.46816, x: -5.38681 },
  PA38: { y: 56.59186, x: -5.33433 },
  PA4: { y: 55.87464, x: -4.39623 },
  PA41: { y: 55.6747, x: -5.74115 },
  PA42: { y: 55.63962, x: -6.18624 },
  PA43: { y: 55.7559, x: -6.28324 },
  PA44: { y: 55.79785, x: -6.29265 },
  PA45: { y: 55.81932, x: -6.1656 },
  PA46: { y: 55.86226, x: -6.11902 },
  PA47: { y: 55.68236, x: -6.50288 },
  PA48: { y: 55.73797, x: -6.38332 },
  PA49: { y: 55.7826, x: -6.39313 },
  PA5: { y: 55.83105, x: -4.50854 },
  PA6: { y: 55.86377, x: -4.53462 },
  PA60: { y: 55.87979, x: -5.91287 },
  PA61: { y: 56.07177, x: -6.20088 },
  PA62: { y: 56.35932, x: -5.85014 },
  PA63: { y: 56.38234, x: -5.71469 },
  PA64: { y: 56.43838, x: -5.67471 },
  PA65: { y: 56.46916, x: -5.72805 },
  PA66: { y: 56.32913, x: -6.35382 },
  PA67: { y: 56.31356, x: -6.23228 },
  PA68: { y: 56.43624, x: -6.14202 },
  PA69: { y: 56.38044, x: -6.08894 },
  PA7: { y: 55.90944, x: -4.49919 },
  PA70: { y: 56.36426, x: -6.03373 },
  PA71: { y: 56.48108, x: -5.98112 },
  PA72: { y: 56.51761, x: -5.96381 },
  PA73: { y: 56.49693, x: -6.18279 },
  PA74: { y: 56.53313, x: -6.22911 },
  PA75: { y: 56.61228, x: -6.12216 },
  PA76: { y: 56.33403, x: -6.39324 },
  PA77: { y: 56.49469, x: -6.8835 },
  PA78: { y: 56.62454, x: -6.54449 },
  PA8: { y: 55.9014, x: -4.45228 },
  PA9: { y: 55.80948, x: -4.55184 },
  PE1: { y: 52.58704, x: -0.23885 },
  PE10: { y: 52.77693, x: -0.3765 },
  PE11: { y: 52.80714, x: -0.16616 },
  PE12: { y: 52.78328, x: 0.0286 },
  PE13: { y: 52.66895, x: 0.13868 },
  PE14: { y: 52.63884, x: 0.21112 },
  PE15: { y: 52.53589, x: 0.08649 },
  PE16: { y: 52.45379, x: 0.05022 },
  PE19: { y: 52.24203, x: -0.2584 },
  PE2: { y: 52.56043, x: -0.26352 },
  PE20: { y: 52.932, x: -0.10136 },
  PE21: { y: 52.97477, x: -0.02203 },
  PE22: { y: 53.04826, x: 0.0455 },
  PE23: { y: 53.17244, x: 0.09181 },
  PE24: { y: 53.16561, x: 0.26804 },
  PE25: { y: 53.15261, x: 0.33616 },
  PE26: { y: 52.45292, x: -0.1132 },
  PE27: { y: 52.33361, x: -0.07572 },
  PE28: { y: 52.35784, x: -0.18629 },
  PE29: { y: 52.33345, x: -0.17848 },
  PE3: { y: 52.58758, x: -0.27573 },
  PE30: { y: 52.75978, x: 0.41884 },
  PE31: { y: 52.87538, x: 0.56647 },
  PE32: { y: 52.73016, x: 0.62756 },
  PE33: { y: 52.64814, x: 0.46886 },
  PE34: { y: 52.7245, x: 0.33986 },
  PE35: { y: 52.8253, x: 0.51155 },
  PE36: { y: 52.936, x: 0.50754 },
  PE37: { y: 52.64414, x: 0.69336 },
  PE38: { y: 52.58977, x: 0.37698 },
  PE4: { y: 52.61262, x: -0.26589 },
  PE5: { y: 52.57867, x: -0.34398 },
  PE6: { y: 52.65562, x: -0.25531 },
  PE7: { y: 52.53877, x: -0.19801 },
  PE8: { y: 52.52694, x: -0.44176 },
  PE9: { y: 52.65422, x: -0.48012 },
  PH1: { y: 56.42113, x: -3.47537 },
  PH10: { y: 56.60845, x: -3.36046 },
  PH11: { y: 56.63942, x: -3.23438 },
  PH12: { y: 56.57551, x: -3.15253 },
  PH13: { y: 56.53749, x: -3.27335 },
  PH14: { y: 56.45299, x: -3.18614 },
  PH15: { y: 56.60974, x: -3.94063 },
  PH16: { y: 56.70697, x: -3.81003 },
  PH17: { y: 56.68745, x: -4.3823 },
  PH18: { y: 56.7741, x: -3.88855 },
  PH19: { y: 56.93428, x: -4.255 },
  PH2: { y: 56.38691, x: -3.40439 },
  PH20: { y: 57.05377, x: -4.15504 },
  PH21: { y: 57.08834, x: -4.02169 },
  PH22: { y: 57.19857, x: -3.80587 },
  PH23: { y: 57.28761, x: -3.80028 },
  PH24: { y: 57.25419, x: -3.74891 },
  PH25: { y: 57.26187, x: -3.64646 },
  PH26: { y: 57.3298, x: -3.60464 },
  PH3: { y: 56.30228, x: -3.70355 },
  PH30: { y: 56.79185, x: -4.6002 },
  PH31: { y: 56.89234, x: -4.81575 },
  PH32: { y: 57.14424, x: -4.68275 },
  PH33: { y: 56.82148, x: -5.11207 },
  PH34: { y: 56.92574, x: -4.92676 },
  PH35: { y: 57.07578, x: -4.93032 },
  PH36: { y: 56.72124, x: -5.86584 },
  PH38: { y: 56.84536, x: -5.74736 },
  PH39: { y: 56.91265, x: -5.84124 },
  PH4: { y: 56.26072, x: -3.78244 },
  PH40: { y: 56.96381, x: -5.7856 },
  PH41: { y: 57.00374, x: -5.83251 },
  PH42: { y: 56.90217, x: -6.14094 },
  PH43: { y: 57.01529, x: -6.28049 },
  PH44: { y: 57.05693, x: -6.50319 },
  PH49: { y: 56.675, x: -5.10977 },
  PH5: { y: 56.32927, x: -3.8277 },
  PH50: { y: 56.71451, x: -4.96427 },
  PH6: { y: 56.37162, x: -3.99491 },
  PH7: { y: 56.37332, x: -3.82657 },
  PH8: { y: 56.56249, x: -3.59805 },
  PH9: { y: 56.65112, x: -3.69152 },
  PL1: { y: 50.37074, x: -4.15317 },
  PL10: { y: 50.34537, x: -4.21106 },
  PL11: { y: 50.3725, x: -4.2503 },
  PL12: { y: 50.41932, x: -4.2483 },
  PL13: { y: 50.35715, x: -4.47456 },
  PL14: { y: 50.46351, x: -4.4646 },
  PL15: { y: 50.63218, x: -4.39987 },
  PL16: { y: 50.64562, x: -4.26757 },
  PL17: { y: 50.51625, x: -4.3051 },
  PL18: { y: 50.51523, x: -4.21899 },
  PL19: { y: 50.55602, x: -4.15454 },
  PL2: { y: 50.38856, x: -4.16242 },
  PL20: { y: 50.50125, x: -4.09986 },
  PL21: { y: 50.38566, x: -3.91748 },
  PL22: { y: 50.40273, x: -4.65146 },
  PL23: { y: 50.33653, x: -4.63513 },
  PL24: { y: 50.35461, x: -4.70925 },
  PL25: { y: 50.34038, x: -4.77872 },
  PL26: { y: 50.34196, x: -4.8252 },
  PL27: { y: 50.52248, x: -4.87408 },
  PL28: { y: 50.53366, x: -4.96651 },
  PL29: { y: 50.58564, x: -4.82988 },
  PL3: { y: 50.38744, x: -4.12578 },
  PL30: { y: 50.49992, x: -4.72743 },
  PL31: { y: 50.46858, x: -4.7214 },
  PL32: { y: 50.63282, x: -4.66015 },
  PL33: { y: 50.6213, x: -4.73021 },
  PL34: { y: 50.6597, x: -4.74234 },
  PL35: { y: 50.68444, x: -4.68431 },
  PL4: { y: 50.3748, x: -4.12803 },
  PL5: { y: 50.41008, x: -4.16621 },
  PL6: { y: 50.4173, x: -4.11586 },
  PL7: { y: 50.3916, x: -4.04436 },
  PL8: { y: 50.33548, x: -4.01243 },
  PL9: { y: 50.35394, x: -4.08773 },
  PO1: { y: 50.79903, x: -1.08749 },
  PO10: { y: 50.85074, x: -0.92878 },
  PO11: { y: 50.7918, x: -0.97553 },
  PO12: { y: 50.79764, x: -1.14144 },
  PO13: { y: 50.81229, x: -1.17808 },
  PO14: { y: 50.83685, x: -1.21884 },
  PO15: { y: 50.86203, x: -1.21842 },
  PO16: { y: 50.84993, x: -1.15895 },
  PO17: { y: 50.88957, x: -1.16819 },
  PO18: { y: 50.86403, x: -0.81478 },
  PO19: { y: 50.83756, x: -0.7771 },
  PO2: { y: 50.81603, x: -1.07722 },
  PO20: { y: 50.78278, x: -0.78563 },
  PO21: { y: 50.78362, x: -0.69622 },
  PO22: { y: 50.79835, x: -0.64756 },
  PO3: { y: 50.81589, x: -1.05877 },
  PO30: { y: 50.69085, x: -1.3133 },
  PO31: { y: 50.75286, x: -1.30531 },
  PO32: { y: 50.75091, x: -1.27982 },
  PO33: { y: 50.72139, x: -1.16976 },
  PO34: { y: 50.71557, x: -1.11178 },
  PO35: { y: 50.68657, x: -1.08401 },
  PO36: { y: 50.65748, x: -1.1624 },
  PO37: { y: 50.63151, x: -1.177 },
  PO38: { y: 50.60406, x: -1.24108 },
  PO39: { y: 50.68027, x: -1.53853 },
  PO4: { y: 50.78983, x: -1.06213 },
  PO40: { y: 50.68199, x: -1.52011 },
  PO41: { y: 50.69979, x: -1.47744 },
  PO5: { y: 50.78821, x: -1.08486 },
  PO6: { y: 50.84564, x: -1.06811 },
  PO7: { y: 50.88227, x: -1.03753 },
  PO8: { y: 50.91084, x: -1.01275 },
  PO9: { y: 50.86291, x: -0.9825 },
  PR1: { y: 53.75779, x: -2.70082 },
  PR2: { y: 53.77753, x: -2.70722 },
  PR25: { y: 53.69402, x: -2.69219 },
  PR26: { y: 53.68743, x: -2.74054 },
  PR3: { y: 53.86826, x: -2.71636 },
  PR4: { y: 53.75335, x: -2.83271 },
  PR5: { y: 53.73107, x: -2.65641 },
  PR6: { y: 53.66749, x: -2.61001 },
  PR7: { y: 53.6448, x: -2.65205 },
  PR8: { y: 53.62613, x: -3.01016 },
  PR9: { y: 53.65974, x: -2.96859 },
  RG1: { y: 51.45366, x: -0.96942 },
  RG10: { y: 51.48055, x: -0.86224 },
  RG12: { y: 51.40655, x: -0.74904 },
  RG14: { y: 51.39957, x: -1.32143 },
  RG17: { y: 51.43998, x: -1.48775 },
  RG18: { y: 51.43326, x: -1.2418 },
  RG19: { y: 51.39368, x: -1.24102 },
  RG2: { y: 51.42655, x: -0.95421 },
  RG20: { y: 51.40325, x: -1.33575 },
  RG21: { y: 51.2658, x: -1.08974 },
  RG22: { y: 51.24986, x: -1.12159 },
  RG23: { y: 51.26083, x: -1.14574 },
  RG24: { y: 51.28094, x: -1.0708 },
  RG25: { y: 51.22767, x: -1.16211 },
  RG26: { y: 51.34966, x: -1.11824 },
  RG27: { y: 51.29963, x: -0.94132 },
  RG28: { y: 51.23327, x: -1.33391 },
  RG29: { y: 51.24751, x: -0.94538 },
  RG30: { y: 51.45133, x: -1.01265 },
  RG31: { y: 51.45607, x: -1.04134 },
  RG4: { y: 51.48063, x: -0.96882 },
  RG40: { y: 51.39933, x: -0.83406 },
  RG41: { y: 51.41574, x: -0.85666 },
  RG42: { y: 51.42729, x: -0.75423 },
  RG45: { y: 51.37471, x: -0.8002 },
  RG5: { y: 51.45219, x: -0.90612 },
  RG6: { y: 51.43893, x: -0.9309 },
  RG7: { y: 51.3979, x: -1.07706 },
  RG8: { y: 51.50513, x: -1.10516 },
  RG9: { y: 51.54407, x: -0.92267 },
  RH1: { y: 51.23683, x: -0.15623 },
  RH10: { y: 51.11724, x: -0.15813 },
  RH11: { y: 51.11079, x: -0.20554 },
  RH12: { y: 51.07579, x: -0.33254 },
  RH13: { y: 51.03701, x: -0.32537 },
  RH14: { y: 51.03197, x: -0.48245 },
  RH15: { y: 50.95675, x: -0.13316 },
  RH16: { y: 51.00204, x: -0.09713 },
  RH17: { y: 51.02122, x: -0.11488 },
  RH18: { y: 51.09702, x: 0.03104 },
  RH19: { y: 51.12474, x: -0.01205 },
  RH2: { y: 51.23485, x: -0.20215 },
  RH20: { y: 50.93647, x: -0.4699 },
  RH3: { y: 51.23242, x: -0.2796 },
  RH4: { y: 51.22871, x: -0.33418 },
  RH5: { y: 51.19463, x: -0.34113 },
  RH6: { y: 51.17126, x: -0.1617 },
  RH7: { y: 51.17427, x: -0.01564 },
  RH8: { y: 51.2513, x: 0.00344 },
  RH9: { y: 51.23543, x: -0.06889 },
  RM1: { y: 51.58175, x: 0.18395 },
  RM10: { y: 51.54443, x: 0.15909 },
  RM11: { y: 51.56939, x: 0.21944 },
  RM12: { y: 51.55251, x: 0.20852 },
  RM13: { y: 51.52397, x: 0.19561 },
  RM14: { y: 51.55593, x: 0.26496 },
  RM15: { y: 51.508, x: 0.27843 },
  RM16: { y: 51.49148, x: 0.341 },
  RM17: { y: 51.4791, x: 0.33029 },
  RM18: { y: 51.46646, x: 0.37716 },
  RM19: { y: 51.4825, x: 0.24835 },
  RM2: { y: 51.5832, x: 0.20279 },
  RM20: { y: 51.47812, x: 0.28847 },
  RM3: { y: 51.60163, x: 0.22679 },
  RM4: { y: 51.63255, x: 0.16038 },
  RM5: { y: 51.59917, x: 0.16602 },
  RM6: { y: 51.57549, x: 0.13313 },
  RM7: { y: 51.5748, x: 0.17063 },
  RM8: { y: 51.55662, x: 0.13225 },
  RM9: { y: 51.54036, x: 0.13582 },
  S1: { y: 53.3802, x: -1.46777 },
  S10: { y: 53.37681, x: -1.51742 },
  S11: { y: 53.36148, x: -1.50557 },
  S12: { y: 53.34844, x: -1.40507 },
  S13: { y: 53.36451, x: -1.3823 },
  S14: { y: 53.34592, x: -1.44266 },
  S17: { y: 53.32111, x: -1.52556 },
  S18: { y: 53.29931, x: -1.47178 },
  S2: { y: 53.36932, x: -1.44686 },
  S20: { y: 53.33467, x: -1.35119 },
  S21: { y: 53.31213, x: -1.33802 },
  S25: { y: 53.36891, x: -1.21389 },
  S26: { y: 53.35678, x: -1.28624 },
  S3: { y: 53.38741, x: -1.47219 },
  S32: { y: 53.29454, x: -1.63839 },
  S33: { y: 53.3415, x: -1.72061 },
  S35: { y: 53.45763, x: -1.49542 },
  S36: { y: 53.50302, x: -1.61249 },
  S4: { y: 53.399, x: -1.44949 },
  S40: { y: 53.23356, x: -1.44562 },
  S41: { y: 53.24696, x: -1.42746 },
  S42: { y: 53.20019, x: -1.42209 },
  S43: { y: 53.2672, x: -1.34172 },
  S44: { y: 53.22478, x: -1.31241 },
  S45: { y: 53.16602, x: -1.42106 },
  S5: { y: 53.42152, x: -1.46124 },
  S6: { y: 53.40195, x: -1.50962 },
  S60: { y: 53.41376, x: -1.34698 },
  S61: { y: 53.44367, x: -1.3932 },
  S62: { y: 53.465, x: -1.34619 },
  S63: { y: 53.51794, x: -1.32879 },
  S64: { y: 53.4908, x: -1.29907 },
  S65: { y: 53.43518, x: -1.31955 },
  S66: { y: 53.42033, x: -1.24153 },
  S7: { y: 53.35422, x: -1.48913 },
  S70: { y: 53.54114, x: -1.4733 },
  S71: { y: 53.57342, x: -1.45629 },
  S72: { y: 53.57851, x: -1.39127 },
  S73: { y: 53.52458, x: -1.39355 },
  S74: { y: 53.50053, x: -1.43913 },
  S75: { y: 53.56201, x: -1.51985 },
  S8: { y: 53.33978, x: -1.47578 },
  S80: { y: 53.29205, x: -1.14928 },
  S81: { y: 53.3377, x: -1.12134 },
  S9: { y: 53.39794, x: -1.41641 },
  SA1: { y: 51.62742, x: -3.93905 },
  SA10: { y: 51.68722, x: -3.79836 },
  SA11: { y: 51.67428, x: -3.76168 },
  SA12: { y: 51.60725, x: -3.79375 },
  SA13: { y: 51.60559, x: -3.72436 },
  SA14: { y: 51.73245, x: -4.10721 },
  SA15: { y: 51.69989, x: -4.16648 },
  SA16: { y: 51.68922, x: -4.25569 },
  SA17: { y: 51.7579, x: -4.2852 },
  SA18: { y: 51.79798, x: -3.95796 },
  SA19: { y: 51.95284, x: -3.94723 },
  SA2: { y: 51.62021, x: -3.99364 },
  SA20: { y: 52.01025, x: -3.78651 },
  SA3: { y: 51.57965, x: -4.05105 },
  SA31: { y: 51.85639, x: -4.30762 },
  SA32: { y: 51.88597, x: -4.17106 },
  SA33: { y: 51.85022, x: -4.4351 },
  SA34: { y: 51.85258, x: -4.61822 },
  SA35: { y: 51.97349, x: -4.55974 },
  SA36: { y: 51.95717, x: -4.60684 },
  SA37: { y: 52.01684, x: -4.59282 },
  SA38: { y: 52.0401, x: -4.47312 },
  SA39: { y: 52.01955, x: -4.24166 },
  SA4: { y: 51.67054, x: -4.04792 },
  SA40: { y: 52.08378, x: -4.17335 },
  SA41: { y: 51.99718, x: -4.70679 },
  SA42: { y: 52.01534, x: -4.8522 },
  SA43: { y: 52.08619, x: -4.62896 },
  SA44: { y: 52.08625, x: -4.37201 },
  SA45: { y: 52.20794, x: -4.358 },
  SA46: { y: 52.23254, x: -4.24945 },
  SA47: { y: 52.19028, x: -4.29561 },
  SA48: { y: 52.14338, x: -4.11048 },
  SA5: { y: 51.64851, x: -3.96886 },
  SA6: { y: 51.67539, x: -3.92084 },
  SA61: { y: 51.79916, x: -4.97345 },
  SA62: { y: 51.84093, x: -5.06333 },
  SA63: { y: 51.87409, x: -4.85342 },
  SA64: { y: 52.00147, x: -5.01162 },
  SA65: { y: 51.98818, x: -4.96848 },
  SA66: { y: 51.88121, x: -4.74342 },
  SA67: { y: 51.78165, x: -4.73157 },
  SA68: { y: 51.72867, x: -4.76603 },
  SA69: { y: 51.71029, x: -4.70555 },
  SA7: { y: 51.6615, x: -3.88989 },
  SA70: { y: 51.67176, x: -4.73797 },
  SA71: { y: 51.66716, x: -4.92863 },
  SA72: { y: 51.6913, x: -4.93453 },
  SA73: { y: 51.71634, x: -5.01146 },
  SA8: { y: 51.72153, x: -3.8475 },
  SA9: { y: 51.78122, x: -3.76729 },
  SE1: { y: 51.49838, x: -0.08949 },
  SE10: { y: 51.48162, x: -0.00089 },
  SE11: { y: 51.4888, x: -0.10862 },
  SE12: { y: 51.4443, x: 0.02483 },
  SE13: { y: 51.45837, x: -0.0091 },
  SE14: { y: 51.47511, x: -0.0415 },
  SE15: { y: 51.47189, x: -0.06468 },
  SE16: { y: 51.49597, x: -0.05213 },
  SE17: { y: 51.48764, x: -0.09282 },
  SE18: { y: 51.48391, x: 0.07412 },
  SE19: { y: 51.41735, x: -0.08424 },
  SE2: { y: 51.48943, x: 0.11759 },
  SE20: { y: 51.41151, x: -0.05716 },
  SE21: { y: 51.43807, x: -0.08626 },
  SE22: { y: 51.45401, x: -0.07119 },
  SE23: { y: 51.44015, x: -0.04875 },
  SE24: { y: 51.45446, x: -0.09853 },
  SE25: { y: 51.39742, x: -0.07501 },
  SE26: { y: 51.42674, x: -0.05364 },
  SE27: { y: 51.43022, x: -0.10097 },
  SE28: { y: 51.50219, x: 0.10809 },
  SE3: { y: 51.46866, x: 0.02015 },
  SE4: { y: 51.46058, x: -0.03348 },
  SE5: { y: 51.47322, x: -0.09055 },
  SE6: { y: 51.43777, x: -0.01432 },
  SE7: { y: 51.48369, x: 0.03521 },
  SE8: { y: 51.48118, x: -0.02828 },
  SE9: { y: 51.44465, x: 0.05651 },
  SG1: { y: 51.91103, x: -0.19417 },
  SG10: { y: 51.8446, x: 0.07178 },
  SG11: { y: 51.88125, x: 0.03278 },
  SG12: { y: 51.81375, x: -0.01669 },
  SG13: { y: 51.78673, x: -0.06863 },
  SG14: { y: 51.80688, x: -0.09098 },
  SG15: { y: 52.01276, x: -0.26149 },
  SG16: { y: 52.01507, x: -0.29638 },
  SG17: { y: 52.03445, x: -0.33063 },
  SG18: { y: 52.08208, x: -0.26609 },
  SG19: { y: 52.13925, x: -0.24049 },
  SG2: { y: 51.8962, x: -0.16569 },
  SG3: { y: 51.86046, x: -0.18022 },
  SG4: { y: 51.921, x: -0.25922 },
  SG5: { y: 51.96727, x: -0.28654 },
  SG6: { y: 51.97888, x: -0.22057 },
  SG7: { y: 52.00335, x: -0.17443 },
  SG8: { y: 52.06369, x: -0.01293 },
  SG9: { y: 51.9458, x: -0.00796 },
  SK1: { y: 53.40736, x: -2.15044 },
  SK10: { y: 53.27793, x: -2.12899 },
  SK11: { y: 53.24646, x: -2.14238 },
  SK12: { y: 53.35169, x: -2.09165 },
  SK13: { y: 53.4479, x: -1.96386 },
  SK14: { y: 53.45149, x: -2.05422 },
  SK15: { y: 53.48655, x: -2.04672 },
  SK16: { y: 53.47395, x: -2.08137 },
  SK17: { y: 53.24378, x: -1.87861 },
  SK2: { y: 53.3948, x: -2.13235 },
  SK22: { y: 53.3724, x: -1.98497 },
  SK23: { y: 53.32744, x: -1.94895 },
  SK3: { y: 53.39835, x: -2.16996 },
  SK4: { y: 53.41901, x: -2.17908 },
  SK5: { y: 53.43433, x: -2.15204 },
  SK6: { y: 53.40563, x: -2.07973 },
  SK7: { y: 53.36962, x: -2.14129 },
  SK8: { y: 53.37941, x: -2.20748 },
  SK9: { y: 53.32723, x: -2.22975 },
  SL0: { y: 51.52431, x: -0.51371 },
  SL1: { y: 51.51751, x: -0.61916 },
  SL2: { y: 51.5335, x: -0.60281 },
  SL3: { y: 51.49961, x: -0.55198 },
  SL4: { y: 51.4753, x: -0.62475 },
  SL5: { y: 51.40468, x: -0.66138 },
  SL6: { y: 51.52231, x: -0.72569 },
  SL7: { y: 51.57411, x: -0.7762 },
  SL8: { y: 51.57786, x: -0.70682 },
  SL9: { y: 51.59677, x: -0.55467 },
  SM1: { y: 51.36599, x: -0.19127 },
  SM2: { y: 51.35258, x: -0.19611 },
  SM3: { y: 51.36992, x: -0.21267 },
  SM4: { y: 51.39219, x: -0.19886 },
  SM5: { y: 51.36741, x: -0.16679 },
  SM6: { y: 51.35984, x: -0.14267 },
  SM7: { y: 51.32296, x: -0.20014 },
  SN1: { y: 51.55689, x: -1.77351 },
  SN10: { y: 51.33589, x: -1.98708 },
  SN11: { y: 51.43931, x: -1.99856 },
  SN12: { y: 51.37178, x: -2.13649 },
  SN13: { y: 51.42383, x: -2.21001 },
  SN14: { y: 51.47511, x: -2.1944 },
  SN15: { y: 51.47255, x: -2.0792 },
  SN16: { y: 51.59304, x: -2.08951 },
  SN2: { y: 51.57939, x: -1.78206 },
  SN25: { y: 51.5911, x: -1.79644 },
  SN26: { y: 51.61086, x: -1.78335 },
  SN3: { y: 51.56132, x: -1.74319 },
  SN4: { y: 51.53089, x: -1.81978 },
  SN5: { y: 51.56509, x: -1.83696 },
  SN6: { y: 51.62067, x: -1.74141 },
  SN7: { y: 51.64892, x: -1.56345 },
  SN8: { y: 51.40741, x: -1.6851 },
  SN9: { y: 51.324, x: -1.78614 },
  SO14: { y: 50.90805, x: -1.39546 },
  SO15: { y: 50.91629, x: -1.42381 },
  SO16: { y: 50.93521, x: -1.43069 },
  SO17: { y: 50.92603, x: -1.3938 },
  SO18: { y: 50.92405, x: -1.35968 },
  SO19: { y: 50.90244, x: -1.35429 },
  SO20: { y: 51.11361, x: -1.50422 },
  SO21: { y: 51.07488, x: -1.31334 },
  SO22: { y: 51.06509, x: -1.33204 },
  SO23: { y: 51.06765, x: -1.30774 },
  SO24: { y: 51.08367, x: -1.14615 },
  SO30: { y: 50.91924, x: -1.30568 },
  SO31: { y: 50.86882, x: -1.29506 },
  SO32: { y: 50.9474, x: -1.20845 },
  SO40: { y: 50.91484, x: -1.50065 },
  SO41: { y: 50.75502, x: -1.56473 },
  SO42: { y: 50.80976, x: -1.53442 },
  SO43: { y: 50.88729, x: -1.58341 },
  SO45: { y: 50.84778, x: -1.3934 },
  SO50: { y: 50.9689, x: -1.33912 },
  SO51: { y: 50.994, x: -1.50407 },
  SO52: { y: 50.97663, x: -1.44015 },
  SO53: { y: 50.98442, x: -1.38022 },
  SP1: { y: 51.074, x: -1.78873 },
  SP10: { y: 51.21065, x: -1.48314 },
  SP11: { y: 51.22696, x: -1.51303 },
  SP2: { y: 51.07406, x: -1.82441 },
  SP3: { y: 51.10117, x: -2.00571 },
  SP4: { y: 51.16479, x: -1.764 },
  SP5: { y: 51.02271, x: -1.77185 },
  SP6: { y: 50.93553, x: -1.80074 },
  SP7: { y: 51.0039, x: -2.18245 },
  SP8: { y: 51.03696, x: -2.28956 },
  SP9: { y: 51.23018, x: -1.66053 },
  SR1: { y: 54.90695, x: -1.37919 },
  SR2: { y: 54.88716, x: -1.37638 },
  SR3: { y: 54.87747, x: -1.41549 },
  SR4: { y: 54.90088, x: -1.42451 },
  SR5: { y: 54.92334, x: -1.42419 },
  SR6: { y: 54.9348, x: -1.37876 },
  SR7: { y: 54.83046, x: -1.36168 },
  SR8: { y: 54.7665, x: -1.33471 },
  SR9: { y: 54.90243, x: -1.37458 },
  SS0: { y: 51.54602, x: 0.69146 },
  SS1: { y: 51.53697, x: 0.73275 },
  SS11: { y: 51.6151, x: 0.53807 },
  SS12: { y: 51.60556, x: 0.52164 },
  SS13: { y: 51.57433, x: 0.50981 },
  SS14: { y: 51.57507, x: 0.47521 },
  SS15: { y: 51.57687, x: 0.432 },
  SS16: { y: 51.56201, x: 0.4543 },
  SS17: { y: 51.52093, x: 0.44055 },
  SS2: { y: 51.54991, x: 0.71766 },
  SS3: { y: 51.54276, x: 0.79224 },
  SS4: { y: 51.59406, x: 0.71452 },
  SS5: { y: 51.60722, x: 0.6503 },
  SS6: { y: 51.58746, x: 0.60872 },
  SS7: { y: 51.56202, x: 0.57984 },
  SS8: { y: 51.5222, x: 0.59057 },
  SS9: { y: 51.55409, x: 0.65298 },
  ST1: { y: 53.02616, x: -2.17148 },
  ST10: { y: 52.98942, x: -1.96275 },
  ST11: { y: 52.96693, x: -2.06552 },
  ST12: { y: 52.94795, x: -2.17117 },
  ST13: { y: 53.1009, x: -2.01793 },
  ST14: { y: 52.90304, x: -1.86767 },
  ST15: { y: 52.90033, x: -2.14719 },
  ST16: { y: 52.81315, x: -2.11871 },
  ST17: { y: 52.7889, x: -2.09936 },
  ST18: { y: 52.81408, x: -2.08161 },
  ST19: { y: 52.71243, x: -2.14656 },
  ST2: { y: 53.02711, x: -2.1357 },
  ST20: { y: 52.79854, x: -2.26255 },
  ST21: { y: 52.86777, x: -2.25851 },
  ST3: { y: 52.98037, x: -2.12214 },
  ST4: { y: 52.99468, x: -2.18328 },
  ST5: { y: 53.01526, x: -2.23703 },
  ST6: { y: 53.0576, x: -2.18951 },
  ST7: { y: 53.08802, x: -2.2658 },
  ST8: { y: 53.11517, x: -2.16735 },
  ST9: { y: 53.05052, x: -2.09847 },
  SW1: { y: 51.49558, x: -0.1386 },
  SW10: { y: 51.48365, x: -0.18086 },
  SW11: { y: 51.4663, x: -0.16427 },
  SW12: { y: 51.44536, x: -0.14784 },
  SW13: { y: 51.47473, x: -0.24458 },
  SW14: { y: 51.46514, x: -0.26511 },
  SW15: { y: 51.45734, x: -0.22509 },
  SW16: { y: 51.42165, x: -0.12722 },
  SW17: { y: 51.4303, x: -0.16283 },
  SW18: { y: 51.4496, x: -0.18941 },
  SW19: { y: 51.42284, x: -0.20341 },
  SW1A: { y: 51.50265, x: -0.13111 },
  SW1E: { y: 51.49737, x: -0.13853 },
  SW1H: { y: 51.49818, x: -0.13273 },
  SW1P: { y: 51.49548, x: -0.13284 },
  SW1V: { y: 51.49016, x: -0.13738 },
  SW1W: { y: 51.49302, x: -0.14735 },
  SW1X: { y: 51.4976, x: -0.15293 },
  SW1Y: { y: 51.50539, x: -0.13388 },
  SW2: { y: 51.44939, x: -0.11889 },
  SW20: { y: 51.41058, x: -0.22547 },
  SW3: { y: 51.48969, x: -0.16477 },
  SW4: { y: 51.46045, x: -0.13571 },
  SW5: { y: 51.49005, x: -0.18781 },
  SW6: { y: 51.47675, x: -0.19986 },
  SW7: { y: 51.49614, x: -0.1746 },
  SW8: { y: 51.47566, x: -0.13077 },
  SW9: { y: 51.46907, x: -0.11232 },
  SY1: { y: 52.7228, x: -2.74039 },
  SY10: { y: 52.83579, x: -3.12089 },
  SY11: { y: 52.8663, x: -3.02808 },
  SY12: { y: 52.90335, x: -2.89364 },
  SY13: { y: 52.95147, x: -2.68921 },
  SY14: { y: 53.02924, x: -2.76203 },
  SY15: { y: 52.55518, x: -3.12995 },
  SY16: { y: 52.52004, x: -3.31183 },
  SY17: { y: 52.51644, x: -3.46501 },
  SY18: { y: 52.44076, x: -3.54784 },
  SY19: { y: 52.57388, x: -3.61156 },
  SY2: { y: 52.70669, x: -2.72827 },
  SY20: { y: 52.61405, x: -3.81838 },
  SY21: { y: 52.65784, x: -3.20505 },
  SY22: { y: 52.75604, x: -3.18553 },
  SY23: { y: 52.38184, x: -4.05129 },
  SY24: { y: 52.47045, x: -4.02302 },
  SY25: { y: 52.2381, x: -3.93626 },
  SY3: { y: 52.69923, x: -2.76959 },
  SY4: { y: 52.79556, x: -2.75052 },
  SY5: { y: 52.65299, x: -2.82791 },
  SY6: { y: 52.53906, x: -2.79038 },
  SY7: { y: 52.42508, x: -2.88688 },
  SY8: { y: 52.36523, x: -2.69474 },
  SY9: { y: 52.49803, x: -2.98558 },
  TA1: { y: 51.01234, x: -3.10203 },
  TA10: { y: 51.03262, x: -2.82438 },
  TA11: { y: 51.06039, x: -2.70214 },
  TA12: { y: 50.97452, x: -2.77207 },
  TA13: { y: 50.94638, x: -2.81004 },
  TA14: { y: 50.94588, x: -2.75024 },
  TA15: { y: 50.95058, x: -2.71757 },
  TA16: { y: 50.90693, x: -2.79229 },
  TA17: { y: 50.90753, x: -2.83497 },
  TA18: { y: 50.88269, x: -2.78619 },
  TA19: { y: 50.93392, x: -2.91373 },
  TA2: { y: 51.03303, x: -3.10109 },
  TA20: { y: 50.87329, x: -2.96087 },
  TA21: { y: 50.97673, x: -3.24507 },
  TA22: { y: 51.0451, x: -3.54509 },
  TA23: { y: 51.16171, x: -3.3516 },
  TA24: { y: 51.18137, x: -3.50957 },
  TA3: { y: 50.99403, x: -3.03889 },
  TA4: { y: 51.07092, x: -3.26758 },
  TA5: { y: 51.14633, x: -3.10379 },
  TA6: { y: 51.12479, x: -2.99895 },
  TA7: { y: 51.12999, x: -2.91187 },
  TA8: { y: 51.24445, x: -2.99294 },
  TA9: { y: 51.22588, x: -2.9553 },
  TD1: { y: 55.6239, x: -2.80997 },
  TD10: { y: 55.71203, x: -2.44887 },
  TD11: { y: 55.78792, x: -2.31578 },
  TD12: { y: 55.65331, x: -2.2384 },
  TD13: { y: 55.92975, x: -2.36975 },
  TD14: { y: 55.86735, x: -2.12305 },
  TD15: { y: 55.74699, x: -2.01275 },
  TD2: { y: 55.733, x: -2.7533 },
  TD3: { y: 55.70073, x: -2.57127 },
  TD4: { y: 55.64182, x: -2.67051 },
  TD5: { y: 55.58723, x: -2.41571 },
  TD6: { y: 55.58238, x: -2.6933 },
  TD7: { y: 55.53631, x: -2.87156 },
  TD8: { y: 55.47531, x: -2.54111 },
  TD9: { y: 55.39766, x: -2.77533 },
  TF1: { y: 52.70246, x: -2.50323 },
  TF10: { y: 52.76943, x: -2.38541 },
  TF11: { y: 52.66253, x: -2.35933 },
  TF12: { y: 52.61175, x: -2.48003 },
  TF13: { y: 52.57803, x: -2.5815 },
  TF2: { y: 52.70002, x: -2.43956 },
  TF3: { y: 52.66404, x: -2.44363 },
  TF4: { y: 52.66125, x: -2.46726 },
  TF5: { y: 52.7149, x: -2.53594 },
  TF6: { y: 52.7355, x: -2.55397 },
  TF7: { y: 52.63525, x: -2.44925 },
  TF8: { y: 52.62974, x: -2.47875 },
  TF9: { y: 52.8977, x: -2.46832 },
  TN1: { y: 51.1349, x: 0.27002 },
  TN10: { y: 51.21013, x: 0.28661 },
  TN11: { y: 51.20597, x: 0.26922 },
  TN12: { y: 51.17194, x: 0.44067 },
  TN13: { y: 51.27593, x: 0.18519 },
  TN14: { y: 51.29525, x: 0.16172 },
  TN15: { y: 51.29951, x: 0.26953 },
  TN16: { y: 51.29362, x: 0.05262 },
  TN17: { y: 51.0961, x: 0.5381 },
  TN18: { y: 51.04328, x: 0.52665 },
  TN19: { y: 51.00262, x: 0.40754 },
  TN2: { y: 51.13726, x: 0.28729 },
  TN20: { y: 51.02633, x: 0.25615 },
  TN21: { y: 50.95871, x: 0.26427 },
  TN22: { y: 50.98067, x: 0.10005 },
  TN23: { y: 51.13902, x: 0.86075 },
  TN24: { y: 51.14917, x: 0.88712 },
  TN25: { y: 51.14426, x: 0.93972 },
  TN26: { y: 51.09988, x: 0.80547 },
  TN27: { y: 51.16519, x: 0.70642 },
  TN28: { y: 50.98202, x: 0.95226 },
  TN29: { y: 50.99327, x: 0.93444 },
  TN3: { y: 51.12265, x: 0.25227 },
  TN30: { y: 51.06195, x: 0.69753 },
  TN31: { y: 50.96233, x: 0.68748 },
  TN32: { y: 50.9793, x: 0.4904 },
  TN33: { y: 50.91032, x: 0.47676 },
  TN34: { y: 50.86393, x: 0.58374 },
  TN35: { y: 50.88036, x: 0.61309 },
  TN36: { y: 50.92061, x: 0.70216 },
  TN37: { y: 50.87168, x: 0.55858 },
  TN38: { y: 50.86297, x: 0.54531 },
  TN39: { y: 50.84602, x: 0.45488 },
  TN4: { y: 51.1459, x: 0.25912 },
  TN40: { y: 50.84449, x: 0.48463 },
  TN5: { y: 51.06471, x: 0.36223 },
  TN6: { y: 51.05216, x: 0.17464 },
  TN7: { y: 51.09204, x: 0.10938 },
  TN8: { y: 51.1942, x: 0.07969 },
  TN9: { y: 51.19053, x: 0.27705 },
  TQ1: { y: 50.47156, x: -3.52209 },
  TQ10: { y: 50.42412, x: -3.81906 },
  TQ11: { y: 50.48137, x: -3.78179 },
  TQ12: { y: 50.52882, x: -3.61142 },
  TQ13: { y: 50.59296, x: -3.71817 },
  TQ14: { y: 50.55002, x: -3.50626 },
  TQ2: { y: 50.47398, x: -3.54331 },
  TQ3: { y: 50.44298, x: -3.57469 },
  TQ4: { y: 50.42326, x: -3.56982 },
  TQ5: { y: 50.39151, x: -3.52234 },
  TQ6: { y: 50.34655, x: -3.58837 },
  TQ7: { y: 50.28256, x: -3.78278 },
  TQ8: { y: 50.23686, x: -3.77125 },
  TQ9: { y: 50.41619, x: -3.68924 },
  TR1: { y: 50.26241, x: -5.05347 },
  TR10: { y: 50.16624, x: -5.11736 },
  TR11: { y: 50.14907, x: -5.08684 },
  TR12: { y: 50.0431, x: -5.17923 },
  TR13: { y: 50.10945, x: -5.28435 },
  TR14: { y: 50.20987, x: -5.29686 },
  TR15: { y: 50.23225, x: -5.23669 },
  TR16: { y: 50.23272, x: -5.21988 },
  TR17: { y: 50.12482, x: -5.46735 },
  TR18: { y: 50.1163, x: -5.54089 },
  TR19: { y: 50.10012, x: -5.62783 },
  TR2: { y: 50.25334, x: -4.95186 },
  TR20: { y: 50.13138, x: -5.48882 },
  TR21: { y: 49.92936, x: -6.25622 },
  TR22: { y: 49.89192, x: -6.34346 },
  TR23: { y: 49.95281, x: -6.35174 },
  TR24: { y: 49.95528, x: -6.33383 },
  TR25: { y: 49.96401, x: -6.29276 },
  TR26: { y: 50.20276, x: -5.4786 },
  TR27: { y: 50.18162, x: -5.4028 },
  TR3: { y: 50.21684, x: -5.10944 },
  TR4: { y: 50.28359, x: -5.13626 },
  TR5: { y: 50.30819, x: -5.19127 },
  TR6: { y: 50.34167, x: -5.15134 },
  TR7: { y: 50.41304, x: -5.073 },
  TR8: { y: 50.39869, x: -5.03689 },
  TR9: { y: 50.41387, x: -4.93648 },
  TS1: { y: 54.57179, x: -1.23733 },
  TS10: { y: 54.60656, x: -1.06791 },
  TS11: { y: 54.58734, x: -1.02501 },
  TS12: { y: 54.56258, x: -0.96528 },
  TS13: { y: 54.54989, x: -0.85427 },
  TS14: { y: 54.53189, x: -1.06034 },
  TS15: { y: 54.49144, x: -1.32992 },
  TS16: { y: 54.52393, x: -1.35257 },
  TS17: { y: 54.53713, x: -1.30289 },
  TS18: { y: 54.56241, x: -1.32257 },
  TS19: { y: 54.57781, x: -1.34398 },
  TS2: { y: 54.58436, x: -1.23555 },
  TS20: { y: 54.58572, x: -1.3129 },
  TS21: { y: 54.62676, x: -1.42529 },
  TS22: { y: 54.61809, x: -1.3139 },
  TS23: { y: 54.60803, x: -1.28465 },
  TS24: { y: 54.69655, x: -1.21016 },
  TS25: { y: 54.66338, x: -1.22321 },
  TS26: { y: 54.68768, x: -1.22895 },
  TS27: { y: 54.72309, x: -1.28728 },
  TS28: { y: 54.72536, x: -1.37265 },
  TS29: { y: 54.71032, x: -1.41945 },
  TS3: { y: 54.56073, x: -1.19578 },
  TS4: { y: 54.55551, x: -1.22217 },
  TS5: { y: 54.5512, x: -1.25008 },
  TS6: { y: 54.56583, x: -1.15236 },
  TS7: { y: 54.53369, x: -1.18394 },
  TS8: { y: 54.52141, x: -1.23054 },
  TS9: { y: 54.46614, x: -1.16368 },
  TW1: { y: 51.44982, x: -0.3247 },
  TW10: { y: 51.44949, x: -0.30168 },
  TW11: { y: 51.42653, x: -0.33131 },
  TW12: { y: 51.42166, x: -0.36888 },
  TW13: { y: 51.43828, x: -0.39995 },
  TW14: { y: 51.45202, x: -0.41818 },
  TW15: { y: 51.43002, x: -0.45491 },
  TW16: { y: 51.4151, x: -0.41514 },
  TW17: { y: 51.39753, x: -0.44594 },
  TW18: { y: 51.428, x: -0.50677 },
  TW19: { y: 51.45221, x: -0.50166 },
  TW2: { y: 51.44657, x: -0.34929 },
  TW20: { y: 51.42674, x: -0.54997 },
  TW3: { y: 51.46742, x: -0.36149 },
  TW4: { y: 51.46593, x: -0.38314 },
  TW5: { y: 51.48029, x: -0.3812 },
  TW6: { y: 51.46948, x: -0.44638 },
  TW7: { y: 51.47331, x: -0.33248 },
  TW8: { y: 51.48552, x: -0.30611 },
  TW9: { y: 51.46736, x: -0.29382 },
  UB1: { y: 51.51435, x: -0.37278 },
  UB10: { y: 51.5478, x: -0.45236 },
  UB11: { y: 51.51909, x: -0.45766 },
  UB2: { y: 51.49915, x: -0.37909 },
  UB3: { y: 51.50513, x: -0.42211 },
  UB4: { y: 51.52381, x: -0.40704 },
  UB5: { y: 51.54315, x: -0.37464 },
  UB6: { y: 51.53911, x: -0.34306 },
  UB7: { y: 51.50576, x: -0.46964 },
  UB8: { y: 51.53458, x: -0.473 },
  UB9: { y: 51.58157, x: -0.49019 },
  W1: { y: 51.51452, x: -0.14215 },
  W10: { y: 51.52103, x: -0.21397 },
  W11: { y: 51.51189, x: -0.20424 },
  W12: { y: 51.50777, x: -0.2289 },
  W13: { y: 51.5127, x: -0.31951 },
  W14: { y: 51.49488, x: -0.20923 },
  W1B: { y: 51.51357, x: -0.13931 },
  W1C: { y: 51.51371, x: -0.14795 },
  W1D: { y: 51.51344, x: -0.13066 },
  W1F: { y: 51.51261, x: -0.13502 },
  W1G: { y: 51.51818, x: -0.14633 },
  W1H: { y: 51.51659, x: -0.15936 },
  W1J: { y: 51.50735, x: -0.14388 },
  W1K: { y: 51.51104, x: -0.1495 },
  W1M: { y: 51.51823, x: -0.14921 },
  W1S: { y: 51.5109, x: -0.14086 },
  W1T: { y: 51.5198, x: -0.13473 },
  W1U: { y: 51.51827, x: -0.15209 },
  W1W: { y: 51.51897, x: -0.13909 },
  W2: { y: 51.51508, x: -0.17816 },
  W3: { y: 51.50925, x: -0.26775 },
  W4: { y: 51.49118, x: -0.26268 },
  W5: { y: 51.51243, x: -0.30078 },
  W6: { y: 51.49246, x: -0.22805 },
  W7: { y: 51.5111, x: -0.33398 },
  W8: { y: 51.50003, x: -0.19317 },
  W9: { y: 51.52607, x: -0.1907 },
  WA1: { y: 53.39442, x: -2.5685 },
  WA10: { y: 53.45362, x: -2.75454 },
  WA11: { y: 53.47721, x: -2.71879 },
  WA12: { y: 53.45343, x: -2.63104 },
  WA13: { y: 53.38226, x: -2.46911 },
  WA14: { y: 53.38624, x: -2.35788 },
  WA15: { y: 53.38364, x: -2.32478 },
  WA16: { y: 53.3026, x: -2.37068 },
  WA2: { y: 53.41054, x: -2.58075 },
  WA3: { y: 53.45384, x: -2.54821 },
  WA4: { y: 53.36472, x: -2.57412 },
  WA5: { y: 53.39857, x: -2.63624 },
  WA6: { y: 53.27222, x: -2.72285 },
  WA7: { y: 53.32987, x: -2.7028 },
  WA8: { y: 53.37189, x: -2.74107 },
  WA9: { y: 53.43676, x: -2.71811 },
  WC1: { y: 51.52139, x: -0.12169 },
  WC1A: { y: 51.51694, x: -0.12475 },
  WC1B: { y: 51.51876, x: -0.12612 },
  WC1E: { y: 51.52065, x: -0.13181 },
  WC1H: { y: 51.52415, x: -0.1259 },
  WC1N: { y: 51.52136, x: -0.12025 },
  WC1R: { y: 51.5186, x: -0.11604 },
  WC1V: { y: 51.51685, x: -0.11899 },
  WC1X: { y: 51.52489, x: -0.11578 },
  WC2: { y: 51.51332, x: -0.12346 },
  WC2A: { y: 51.51588, x: -0.11471 },
  WC2B: { y: 51.5151, x: -0.12195 },
  WC2E: { y: 51.51242, x: -0.1235 },
  WC2H: { y: 51.51339, x: -0.12778 },
  WC2N: { y: 51.50975, x: -0.12505 },
  WC2R: { y: 51.51145, x: -0.11921 },
  WD1: { y: 51.64692, x: -0.39984 },
  WD17: { y: 51.66138, x: -0.40512 },
  WD18: { y: 51.64801, x: -0.41426 },
  WD19: { y: 51.6315, x: -0.39027 },
  WD2: { y: 51.6673, x: -0.37743 },
  WD23: { y: 51.64554, x: -0.3652 },
  WD24: { y: 51.67027, x: -0.39757 },
  WD25: { y: 51.68363, x: -0.38842 },
  WD3: { y: 51.6453, x: -0.48085 },
  WD4: { y: 51.70882, x: -0.45556 },
  WD5: { y: 51.7047, x: -0.41662 },
  WD6: { y: 51.65684, x: -0.27514 },
  WD7: { y: 51.68611, x: -0.30876 },
  WF1: { y: 53.68684, x: -1.49117 },
  WF10: { y: 53.72388, x: -1.3437 },
  WF11: { y: 53.71078, x: -1.25602 },
  WF12: { y: 53.68373, x: -1.61992 },
  WF13: { y: 53.69279, x: -1.64559 },
  WF14: { y: 53.68034, x: -1.69263 },
  WF15: { y: 53.70822, x: -1.69849 },
  WF16: { y: 53.70994, x: -1.66817 },
  WF17: { y: 53.71885, x: -1.64082 },
  WF2: { y: 53.67254, x: -1.51102 },
  WF3: { y: 53.72294, x: -1.52408 },
  WF4: { y: 53.64741, x: -1.51888 },
  WF5: { y: 53.67998, x: -1.57605 },
  WF6: { y: 53.69997, x: -1.41375 },
  WF7: { y: 53.66639, x: -1.35065 },
  WF8: { y: 53.68497, x: -1.29887 },
  WF9: { y: 53.60599, x: -1.31983 },
  WN1: { y: 53.55414, x: -2.6265 },
  WN2: { y: 53.53639, x: -2.58248 },
  WN3: { y: 53.52888, x: -2.64423 },
  WN4: { y: 53.49473, x: -2.64221 },
  WN5: { y: 53.53135, x: -2.68501 },
  WN6: { y: 53.57369, x: -2.66908 },
  WN7: { y: 53.49803, x: -2.51712 },
  WN8: { y: 53.55242, x: -2.76838 },
  WR1: { y: 52.19713, x: -2.21656 },
  WR10: { y: 52.11459, x: -2.06426 },
  WR11: { y: 52.09481, x: -1.92847 },
  WR12: { y: 52.04261, x: -1.87314 },
  WR13: { y: 52.09433, x: -2.34161 },
  WR14: { y: 52.11328, x: -2.31839 },
  WR15: { y: 52.30476, x: -2.56764 },
  WR2: { y: 52.18989, x: -2.24139 },
  WR3: { y: 52.21603, x: -2.21079 },
  WR4: { y: 52.20438, x: -2.1844 },
  WR5: { y: 52.17738, x: -2.19891 },
  WR6: { y: 52.22732, x: -2.37045 },
  WR7: { y: 52.19642, x: -2.05268 },
  WR8: { y: 52.08207, x: -2.20287 },
  WR9: { y: 52.26826, x: -2.15682 },
  WS1: { y: 52.57945, x: -1.97786 },
  WS10: { y: 52.56057, x: -2.02065 },
  WS11: { y: 52.68914, x: -2.01776 },
  WS12: { y: 52.70623, x: -1.99852 },
  WS13: { y: 52.6917, x: -1.818 },
  WS14: { y: 52.66292, x: -1.81368 },
  WS15: { y: 52.75834, x: -1.91701 },
  WS2: { y: 52.58845, x: -2.00148 },
  WS3: { y: 52.61812, x: -1.98966 },
  WS4: { y: 52.60552, x: -1.95865 },
  WS5: { y: 52.56776, x: -1.95869 },
  WS6: { y: 52.65767, x: -2.02218 },
  WS7: { y: 52.68012, x: -1.91272 },
  WS8: { y: 52.64597, x: -1.93348 },
  WS9: { y: 52.6091, x: -1.91729 },
  WV1: { y: 52.58569, x: -2.11514 },
  WV10: { y: 52.61716, x: -2.11227 },
  WV11: { y: 52.61, x: -2.07089 },
  WV12: { y: 52.60552, x: -2.03987 },
  WV13: { y: 52.58484, x: -2.06052 },
  WV14: { y: 52.55695, x: -2.07818 },
  WV15: { y: 52.51593, x: -2.37435 },
  WV16: { y: 52.5112, x: -2.44062 },
  WV2: { y: 52.574, x: -2.11806 },
  WV3: { y: 52.57936, x: -2.15054 },
  WV4: { y: 52.56229, x: -2.14163 },
  WV5: { y: 52.53342, x: -2.20936 },
  WV6: { y: 52.5955, x: -2.18161 },
  WV7: { y: 52.63309, x: -2.27043 },
  WV8: { y: 52.62337, x: -2.1832 },
  WV9: { y: 52.63332, x: -2.14039 },
  YO1: { y: 53.95857, x: -1.0809 },
  YO10: { y: 53.95031, x: -1.05822 },
  YO11: { y: 54.26511, x: -0.39535 },
  YO12: { y: 54.27356, x: -0.42267 },
  YO13: { y: 54.28798, x: -0.49587 },
  YO14: { y: 54.19903, x: -0.29672 },
  YO15: { y: 54.0939, x: -0.17715 },
  YO16: { y: 54.09514, x: -0.20004 },
  YO17: { y: 54.13631, x: -0.75397 },
  YO18: { y: 54.2587, x: -0.76717 },
  YO19: { y: 53.91046, x: -1.02105 },
  YO21: { y: 54.47654, x: -0.71123 },
  YO22: { y: 54.45038, x: -0.62104 },
  YO23: { y: 53.92562, x: -1.12122 },
  YO24: { y: 53.94624, x: -1.11469 },
  YO25: { y: 54.00465, x: -0.41302 },
  YO26: { y: 53.97452, x: -1.17204 },
  YO30: { y: 53.98579, x: -1.11385 },
  YO31: { y: 53.96923, x: -1.06389 },
  YO32: { y: 54.01143, x: -1.05837 },
  YO41: { y: 53.96966, x: -0.90227 },
  YO42: { y: 53.92274, x: -0.7908 },
  YO43: { y: 53.84976, x: -0.68952 },
  YO51: { y: 54.08182, x: -1.37628 },
  YO60: { y: 54.08237, x: -0.94057 },
  YO61: { y: 54.11579, x: -1.1937 },
  YO62: { y: 54.24282, x: -0.99325 },
  YO7: { y: 54.2237, x: -1.3496 },
  YO8: { y: 53.7813, x: -1.05745 },
  ZE1: { y: 60.15232, x: -1.16779 },
  ZE2: { y: 60.33047, x: -1.22484 },
  ZE3: { y: 59.88369, x: -1.30316 },
  W1A: { y: 51.518562, x: -0.143799 },
  BL11: { y: 53.576178, x: -2.428677 },
  BL78: { y: 53.571716, x: -2.416294 },
  BN50: { y: 50.823442, x: -0.140396 },
  BN51: { y: 50.804823, x: -0.058672 },
  BN52: { y: 50.829256, x: -0.168416 },
  BN88: { y: 50.831246, x: -0.140438 },
  BN99: { y: 50.820239, x: -0.379944 },
  BS80: { y: 51.521874, x: -2.572469 },
  BS98: { y: 51.459068, x: -2.581532 },
  CA95: { y: 54.644668, x: -3.56645 },
  CF30: { y: 51.519635, x: -3.119562 },
  CF91: { y: 51.465745, x: -3.193634 },
  CF95: { y: 51.481196, x: -3.171039 },
  CF99: { y: 51.464366, x: -3.161654 },
  CH25: { y: 53.396972, x: -3.011914 },
  CH26: { y: 53.365697, x: -3.065921 },
  CH27: { y: 53.418339, x: -3.033129 },
  CH28: { y: 53.400433, x: -3.111956 },
  CH29: { y: 53.390338, x: -3.179391 },
  CH30: { y: 53.379056, x: -3.098456 },
  CH31: { y: 53.329738, x: -3.098885 },
  CH32: { y: 53.361945, x: -2.99765 },
  CH33: { y: 53.29055, x: -3.062639 },
  CH34: { y: 53.281708, x: -2.908126 },
  CH70: { y: 53.192746, x: -2.92259 },
  CH88: { y: 53.179571, x: -2.893891 },
  CH99: { y: 53.178328, x: -2.894104 },
  CM99: { y: 51.735856, x: 0.473009 },
  CT50: { y: 51.075701, x: 1.149115 },
  CW98: { y: 53.089331, x: -2.431667 },
  DE99: { y: 52.915455, x: -1.46108 },
  DH97: { y: 54.779259, x: -1.578053 },
  DH98: { y: 54.934641, x: -1.615103 },
  DH99: { y: 54.786173, x: -1.557144 },
  DN55: { y: 53.512922, x: -1.125721 },
  E77: { y: 51.513948, x: -0.069129 },
  E98: { y: 51.513678, x: -0.060885 },
  EC1P: { y: 51.524503, x: -0.112088 },
  EC4P: { y: 51.524503, x: -0.112088 },
  EC50: { y: 51.524503, x: -0.112088 },
  EH91: { y: 55.923228, x: -3.305118 },
  EH95: { y: 55.978621, x: -3.25352 },
  EH99: { y: 55.955591, x: -3.17611 },
  FY0: { y: 53.754603, x: -3.00801 },
  G58: { y: 55.848424, x: -4.277233 },
  G70: { y: 55.949409, x: -3.988955 },
  G75: { y: 55.729059, x: -4.186465 },
  G79: { y: 55.771004, x: -4.223076 },
  G9: { y: 55.868635, x: -4.241903 },
  G90: { y: 55.872226, x: -4.233913 },
  GU95: { y: 51.326293, x: -0.763075 },
  IP98: { y: 52.376705, x: 1.116422 },
  KY99: { y: 56.059361, x: -3.444143 },
  L67: { y: 53.42897, x: -2.958583 },
  L68: { y: 53.473055, x: -2.96069 },
  L71: { y: 53.439492, x: -2.971429 },
  L72: { y: 53.4803, x: -2.988368 },
  L73: { y: 53.406399, x: -2.975554 },
  L74: { y: 53.393801, x: -2.988061 },
  L75: { y: 53.438613, x: -2.985222 },
  LE21: { y: 52.632649, x: -1.125237 },
  LE41: { y: 52.654113, x: -1.121497 },
  LE55: { y: 52.690357, x: -1.290318 },
  LE87: { y: 52.596957, x: -1.154074 },
  LE94: { y: 52.483686, x: -0.905523 },
  LE95: { y: 52.606176, x: -1.19056 },
  LS88: { y: 53.750785, x: -1.538965 },
  LS98: { y: 53.778778, x: -1.525004 },
  LS99: { y: 53.78669, x: -1.592793 },
  M50: { y: 53.479393, x: -2.304275 },
  M99: { y: 53.484512, x: -2.234866 },
  MK77: { y: 52.042089, x: -0.81887 },
  N1P: { y: 51.538425, x: -0.103278 },
  NE82: { y: 54.938084, x: -1.615518 },
  NE83: { y: 54.934641, x: -1.615103 },
  NE85: { y: 54.921656, x: -1.554823 },
  NE88: { y: 54.941776, x: -1.614988 },
  NE92: { y: 54.941776, x: -1.614988 },
  NE98: { y: 54.972045, x: -1.598706 },
  NG70: { y: 53.154588, x: -1.186926 },
  NG80: { y: 52.916642, x: -1.159018 },
  NG90: { y: 52.927165, x: -1.191062 },
  NW1W: { y: 51.530784, x: -0.13543 },
  OL95: { y: 53.556338, x: -2.105401 },
  PH37: { y: 56.816195, x: -5.528405 },
  PL95: { y: 50.364357, x: -4.104633 },
  PR0: { y: 53.758805, x: -2.683907 },
  PR11: { y: 53.778126, x: -2.685436 },
  S49: { y: 53.234341, x: -1.435594 },
  S96: { y: 53.403018, x: -1.427504 },
  S97: { y: 53.456228, x: -1.382546 },
  S98: { y: 53.386626, x: -1.455184 },
  S99: { y: 53.404294, x: -1.430992 },
  SA80: { y: 51.647958, x: -3.924033 },
  SA99: { y: 51.669997, x: -3.945445 },
  SE1P: { y: 51.492762, x: -0.080152 },
  SL95: { y: 51.497866, x: -0.532544 },
  SN38: { y: 51.562764, x: -1.795298 },
  SN99: { y: 51.556458, x: -1.755879 },
  SS22: { y: 51.55698, x: 0.706823 },
  SS99: { y: 51.561368, x: 0.608499 },
  ST55: { y: 53.014751, x: -2.241151 },
  SW99: { y: 51.472256, x: -0.115797 },
  TR93: { y: 50.129387, x: -5.50275 },
  UB18: { y: 51.500719, x: -0.402684 },
  WA55: { y: 53.401776, x: -2.619046 },
  WA88: { y: 53.367973, x: -2.768674 },
  WR78: { y: 52.197683, x: -2.223496 },
  WR99: { y: 52.201033, x: -2.214399 },
  YO90: { y: 53.9601, x: -1.089339 },
  YO91: { y: 53.976598, x: -1.070367 },
  E20: { y: 51.538611, x: -0.016389 },
  B99: { y: 52.495953, x: -1.894214 },
  BB94: { y: 53.915243, x: -2.138033 },
  BD97: { y: 53.850509, x: -1.837421 },
  BD98: { y: 53.837717, x: -1.780013 },
  BD99: { y: 53.79447, x: -1.766539 },
  SL60: { y: 51.518556, x: -0.714794 },
  WF90: { y: 0, x: 0 },
  N81: { y: 0, x: 0 },
  SW95: { y: 0, x: 0 },
  RH77: { y: 0, x: 0 },
  WD99: { y: 0, x: 0 },
  CR90: { y: 0, x: 0 },
  SY99: { y: 0, x: 0 },
  M61: { y: 0, x: 0 },
  SO97: { y: 0, x: 0 },
  ME99: { y: 0, x: 0 },
  CR44: { y: 0, x: 0 },
  PE99: { y: 0, x: 0 },
  CM92: { y: 0, x: 0 },
  CM98: { y: 0, x: 0 },
  AB99: { y: 0, x: 0 },
  NW26: { y: 0, x: 0 },
  NR99: { y: 0, x: 0 },
  N1C: { y: 0, x: 0 },
  CA99: { y: 54.890923, x: -2.943847 },
  IV99: { y: 57.481338, x: -4.223951 },
  BN91: { y: 0, x: 0 },
  SO25: { y: 0, x: 0 },
  DL98: { y: 0, x: 0 },
  EN77: { y: 0, x: 0 },
  WV99: { y: 0, x: 0 },
  WV98: { y: 0, x: 0 },
  S95: { y: 0, x: 0 },
  PA80: { y: 0, x: 0 },
  L80: { y: 0, x: 0 },
  BS0: { y: 0, x: 0 },
  ZZ0:{y:0,x:0}
};

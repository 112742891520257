<template>
  <div style="height:100vh; " class="p-2 d-flex flex-column justify-content-between">
    <app-header-bar title></app-header-bar>

    <section id="middleScrolling" class="iphone-scroll-fudge" style="height:100%;overflow-y:auto;">
      ## whatevs ##
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
      <br />
      {{Math.random()}}
    </section>

    <section
      id="bottomBar"
      class="mt-2 d-flex justify-content-between"
      style="flex-shrink:0;"
    >## bottom ##</section>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { AppHeaderBar },
  props: {},
  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
</style>

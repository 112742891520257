<template>
  <div class="p-2 d-flex flex-column" style="height: 100vh">
    <app-header-bar title="Referrals to us"></app-header-bar>

    <!--
 _____            _
/  ___|          | |
\ `--.  ___  _ __| |_
 `--. \/ _ \| '__| __|
/\__/ / (_) | |  | |_
\____/ \___/|_|   \__|
    -->
    <section
      id="sortingReferrals"
      v-if="myReferralsArrayFilteredForDisplay.length > 1"
      class="mb-1 small"
    >
      <div
        v-if="
          currentSortContext.sortBy === 'statusId' &&
          currentSortContext.sortDesc === false
        "
      >
        In order of priority
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-2"
          @click="
            explainWhyOrderedByPriority = !explainWhyOrderedByPriority;
            choosingWhichOneToShow = false;
          "
          >Why?</b-button
        >
        <transition name="swish">
          <div
            v-if="explainWhyOrderedByPriority"
            style="
              background-color: white;
              color: black;
              border-radius: 1em;
              padding: 1em;
            "
          >
            <h5>Take control with priority ordering</h5>
            <p>
              Attend to the unopened and uncalled patients first. Once dealt
              with, they move down in priority.
            </p>
            <p>
              Patients progress through the stages until they enter the study
              (blue) or do not (grey).
            </p>
            <p>
              No patient can be lost, and you always spend your precious time on
              the highest-priority tasks.
            </p>

            <h5>Inbox-like alternative</h5>
            If you prefer the 'inbox-like' display, click twice on 'Time'. Now
            the most recent referrals are at the top, and older ones further
            down, where they may be forgotten.
            <div class="d-flex flex-row justify-content-end">
              <b-button
                variant="primary"
                @click="explainWhyOrderedByPriority = false"
                >OK</b-button
              >
            </div>
          </div>
        </transition>
      </div>
    </section>

    <!--
______ _ _ _
|  ___(_) | |
| |_   _| | |_ ___ _ __
|  _| | | | __/ _ \ '__|
| |   | | | ||  __/ |
\_|   |_|_|\__\___|_|
    -->
    <section
      id="filteringTrials"
      v-if="myTrialIds && myTrialIds.length > 1"
      class="mb-2"
    >
      <transition name="swish">
        <div v-if="choosingWhichOneToShow">
          Choose trial to display:
          <b-list-group style="z-index: 100">
            <b-list-group-item
              v-for="trialId in myTrialIds"
              :key="trialId"
              @click="
                displayTrialIds = [trialId];
                choosingWhichOneToShow = false;
              "
              style="
                border-radius: 0.8em;
                background-color: white;
                color: black;
              "
              class="mb-1"
              >{{ trials.publicObj[trialId].shortText }}</b-list-group-item
            >
            <b-list-group-item
              @click="
                displayTrialIds = myTrialIds;
                choosingWhichOneToShow = false;
              "
              style="
                border-radius: 0.8em;
                background-color: lightgray;
                color: black;
              "
              class="mb-3"
            >
              Return to showing
              <strong>all</strong> trials
            </b-list-group-item>
          </b-list-group>
        </div>
      </transition>

      <div
        v-if="!choosingWhichOneToShow && displayTrialIds.length === 1"
        class="d-flex flex-row justify-content-start align-items-baseline small"
      >
        Showing only {{ trials.publicObj[displayTrialIds[0]].shortText }} &nbsp;
        &nbsp;
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-1"
          @click="
            choosingWhichOneToShow = true;
            explainWhyOrderedByPriority = false;
          "
          >Change</b-button
        >
      </div>

      <div
        v-if="!choosingWhichOneToShow && displayTrialIds.length > 1"
        class="d-flex flex-row justify-content-start align-items-baseline small"
      >
        Showing all trials &nbsp; &nbsp;
        <b-button
          variant="outline-light"
          size="sm"
          style="font-size: 70%"
          class="ml-1"
          @click="
            choosingWhichOneToShow = true;
            explainWhyOrderedByPriority = false;
          "
          >Show only one</b-button
        >
      </div>
    </section>

    <!--
  _____     _     _        _ _            _  __
 |_   _|   | |   | |      (_) |          | |/ _|
   | | __ _| |__ | | ___   _| |_ ___  ___| | |_
   | |/ _` | '_ \| |/ _ \ | | __/ __|/ _ \ |  _|
   | | (_| | |_) | |  __/ | | |_\__ \  __/ | |
   \_/\__,_|_.__/|_|\___| |_|\__|___/\___|_|_|
    -->

    <section
      v-if="myReferralsArrayIsLoaded"
      style="height: 100%; width: 100%; overflow-y: auto"
    >
      <div
        v-if="myReferralsArrayFilteredForDisplay.length === 0"
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 100%"
      >
        <h1>None</h1>
      </div>
      <b-table
        v-if="myReferralsArrayFilteredForDisplay.length > 0"
        :items="myReferralsArrayFilteredForDisplay"
        :fields="fields"
        sort-by="priority"
        @row-clicked="onClickOneReferral"
        @sort-changed="sortingChanged"
        style="max-width: 100%"
      >
        <!--
          <template
          slot="uniqueReferralId"
          slot-scope="data"
        >
          <div class="d-flex flex-column align-items-center">
            <div style="font-size:90%;">
              {{dateParts(isoFromReferralId(data.item.sharedReferralId)).t.slice(0,5)}},
              {{dateParts(isoFromReferralId(data.item.sharedReferralId)).weekday}}
            </div>
            <div style="font-size:150%;"> {{
              dateParts(isoFromReferralId(data.item.sharedReferralId)).dateMonth
              }}</div>
            <div v-if="isoIsMoreThanNDaysAgo(isoFromReferralId(data.item.sharedReferralId),360)">{{
              dateParts(isoFromReferralId(data.item.sharedReferralId)).year
              }}</div>
          </div>
        </template>
        -->

        <template #cell(referralId)="data">
          <div class="d-flex flex-column align-items-center">
            <div style="font-size: 150%">
              {{ timeAgoTwoTierFromIso(data.item.referralIso).n }}
            </div>
            <div style="font-size: 90%">
              {{ timeAgoTwoTierFromIso(data.item.referralIso).units }}
            </div>
          </div>
        </template>

        <template #cell(statusId)="data">
          <div
            :style="{
              'text-align': 'center',
              'background-color': statusObjFromId(data.item.statusId)
                .background,
              color: statusObjFromId(data.item.statusId).color,
              'min-width': '100%',
              height: '5em',
            }"
          >
            <div
              class="d-flex flex-row justify-content-center align-items-center"
              style="width: 100%; height: 100%"
            >
              {{ statusObjFromId(data.item.statusId).short }}
            </div>
          </div>
        </template>

        <template #cell(trialId)="data">
          <span style="word-break: break-all">{{
            trials.publicObj[data.item.trialId].shortText
          }}</span>
        </template>

        <template #cell(HEAD_referralId)>
          <div style="text-align: center">
            <span style="textalign: center">Wait time</span>
          </div>
        </template>
      </b-table>
    </section>

    <section id="bottom">
      <transition name="swish">
        <div v-if="showNotResearcherMessage">
          When your researchers click on a referral, the personal information
          will be obtained and decrypted for them.
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import {
  // Data
  accessByUserThenTrialRef,
  trialReferralRootRef,
  // Functions
  statusObjFromId,
} from "../util/firebase.js";
import { bindBranchAsREFandOBJandISLOADED } from "../util/firebaseBind.js";
import {
  dateParts,
  isoFromReferralId,
  timeAgoHumanFromIso,
  timeAgoTwoTierFromIso,
  timeAgoMsFromIso,
  isoIsMoreThanNDaysAgo,
} from "../util/formatDate.js";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { AppHeaderBar },

  props: {
    user: { type: Object, required: true },
    trials: { type: Object, required: true },
  },

  data() {
    return {
      myAccess: {},
      myTrialIds: [],
      displayTrialIds: [], // The subset I have chosen for display
      choosingWhichOneToShow: false,
      choosingSortOrder: false,
      currentSortContext: {
        sortBy: "statusId",
        sortDesc: false, // Actual  sortContext returned by sortingChanged callback contains lots of other (irrelevant) stuff
      },
      explainWhyOrderedByPriority: false,

      myReferralsObj: {},
      myReferralsArray: [],
      myReferralsArrayIsLoaded: false,
      // Table

      fields: [
        // {
        //   label: "Referred",
        //   key: "uniqueReferralId",
        //   sortable: true
        // },
        { label: "Status", key: "statusId", sortable: true },
        {
          label: "Wait time",
          key: "referralId",
          sortable: true,
        },
        { label: "Trial", key: "trialId", sortable: true },
        //   { label: "priority", key: "priority", sortable: true }
      ],
      showNotResearcherMessage: false,
    };
  },

  computed: {
    clientWidth: () => document.documentElement.clientWidth,
    clientHeight: () => document.documentElement.clientHeight,
    myReferralsArrayFilteredForDisplay: function () {
      // must use the FUNCTION() format, otherwise THIS doesn't get set by Vue
      return this.myReferralsArray.filter((referral) =>
        this.displayTrialIds.includes(referral.trialId)
      );
    },
  },

  created() {
    this.watchAcccessAndBindTrialsReferral();
  },

  beforeDestroy() {
    this.undo_watchAccessAndBindTrialsReferral();
  },

  methods: {
    // Make available in THIS
    statusObjFromId,

    watchAcccessAndBindTrialsReferral() {
      if (!this.trials.referral) {
        this.trials.referral = {};
      }
      accessByUserThenTrialRef.child(this.user.id).on("value", (snapshot) => {
        this.myAccess = snapshot.val();
        this.myTrialIds = Object.keys(this.myAccess);
        this.displayTrialIds = JSON.parse(JSON.stringify(this.myTrialIds));
        this.myTrialIds.forEach((trialId) => {
          bindBranchAsREFandOBJandISLOADED(
            this.trials.referral,
            trialId,
            trialReferralRootRef.child(trialId),
            {
              callback: this.updateMyReferrals, // So, as each trial's referrals become available through this binding, they will appear on screen. Likewise, if new data arrives, they will be updated into THIS.MYREFERRALS
            }
          );
        });
      });
    },

    undo_watchAccessAndBindTrialsReferral() {
      accessByUserThenTrialRef.child(this.user.id).off("value");
      this.myTrialIds.forEach((trialId) => {
        bindBranchAsREFandOBJandISLOADED(this.trials.referral, trialId, null);
      });
    },

    updateMyReferrals() {
      let allReferrals = {};
      if (this.myTrialIds) {
        this.myTrialIds.forEach((trialId) => {
          if (this.trials.referral[trialId + "IsLoaded"]) {
            const oneTrialObj = this.trials.referral[trialId + "Obj"];
            const oneTrialRef = this.trials.referral[trialId + "Ref"];
            const sharedReferralIds = Object.keys(oneTrialObj);
            sharedReferralIds.forEach((sharedReferralId) => {
              const uniqueReferralId = sharedReferralId + "_" + trialId;

              const oneReferralObj = this.trials.referral[trialId + "Obj"][
                sharedReferralId
              ];
              const priority = oneReferralObj.statusId + "_" + sharedReferralId;

              allReferrals[uniqueReferralId] = Object.assign(
                {},
                this.trials.referral[trialId + "Obj"][sharedReferralId],
                {
                  sharedReferralId,
                  uniqueReferralId,
                  shortText: this.trials.publicObj[trialId].shortText,
                  trialReferralRefString: oneTrialRef
                    .child(sharedReferralId)
                    .toString(),
                  priority,
                }
              );
            });
          }
        });
      }
      this.myReferralsObj = allReferrals;
      this.myReferralsArray = Object.keys(allReferrals).map(
        (uniqueReferralId) => ({
          ...this.myReferralsObj[uniqueReferralId],
        })
      );
      this.myReferralsArray.sort((refA, refB) =>
        refA.priority < refB.priority
          ? -1
          : refA.priority > refB.priority
          ? 1
          : 0
      );
      this.myReferralsArrayIsLoaded = true;
    },

    sortingChanged(ctx) {
      this.currentSortContext = ctx;
      this.explainWhyOrderedByPriority = false;
    },

    // FORMATTING FUNCTIONS ======================

    // transduce from global (imported) scope to THIS
    timeAgoHumanFromIso,
    timeAgoTwoTierFromIso,
    timeAgoMsFromIso,
    isoIsMoreThanNDaysAgo,
    isoFromReferralId,
    dateParts,

    // NAVIGATION FUNCTIONS =====================

    onClickOneReferral: function (referralObj /*, index */) {
      if (this.user.privilegesObj.researcher === true) {
        this.$router.push({
          name: "resReferralOne",
          params: {
            trialReferralRefString: referralObj.trialReferralRefString,
          },
        });
      } else {
        this.showNotResearcherMessage = true;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.table > tbody > tr > td {
  vertical-align: middle;
  padding: 0;
}
</style>
// REMEMBER THAT ALL OF THESE MUST BE MANUALLY MATCHED TO THE FIREBASE FUNCTIONS paths
// in functions/index.js

// Idea for a separate firebase.js file came from here:
// https://www.youtube.com/watch?v=831zOI02Q_0

// Make version 9 compatible with version 8 https://firebase.google.com/docs/web/modular-upgrade
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA5bffc4aIXVcQkG4VlVVN_fJxTMJobPjg",
  authDomain: "fbmv-foundbyme.firebaseapp.com",
  databaseURL: "https://fbmv-foundbyme.firebaseio.com",
  projectId: "fbmv-foundbyme",
  storageBucket: "fbmv-foundbyme.appspot.com", // We don't actually use this because it is in USA
  messagingSenderId: "1001400799770",
  appId: "1:1001400799770:web:56fe442c419630920ab7a3",
});
// AUTH
export const auth = firebaseApp.auth();

// STORAGE: We need a write-only storage bucket in UK
// We made this in Google Cloud Platform and made it world WRITABLE (not readable) and then from Firebase Cloud we connected to it.
export const enrolmentFirebaseStorage = firebase
  .app()
  .storage("gs://1m-inclusion-enrolment");

// const testFile = new Blob(["Hello"], { type: "text/plain" });
// enrolmentFirebaseStorage
//   .ref("test/Proof of concept of writing to firebase.txt")
//   .put(testFile)
//   .then(x => console.log(JSON.stringify(x)))
//   .catch(err => console.error(JSON.stringify(err)));

// FUNCTIONS
const firebaseFunctions = firebase.functions(); // https://firebase.google.com/docs/functions/callable
export const getIdFromEmailObjToDataObj =
  firebaseFunctions.httpsCallable("getIdFromEmail"); // input and output is an OBJECT: {email: "a@nhs.net"} => {data: {id: "KJHLKJHLKJHLKJHL"}}
export const getEmailFromIdObjToDataObj = firebaseFunctions.httpsCallable(
  "getEmailFromId" //_DIFFERENT NAME
); // input is an OBJECT: {id: "KJHLKJHLKJHLKJHL"}
export const getFullNameFromIdObjToDataObj =
  firebaseFunctions.httpsCallable("getFullNameFromId");
export const getFullNameOrEmailFromIdObjToDataObj =
  firebaseFunctions.httpsCallable("getFullNameOrEmailFromId");
export const setStatus = firebaseFunctions.httpsCallable("setStatus");

export const getManagementPeersAndSubordinatesInData =
  firebaseFunctions.httpsCallable("getManagementPeersAndSubordinatesInData");
export const setManagementPathStringAccess = firebaseFunctions.httpsCallable(
  "setManagementPathStringAccess"
);
export const getTrialsManagedByUserIdInData = firebaseFunctions.httpsCallable(
  "getTrialsManagedByUserIdInData"
);

export const setResearcherAccess = firebaseFunctions.httpsCallable(
  "setResearcherAccess"
);

export const submitReferralPartB = firebaseFunctions.httpsCallable(
  "submitReferralPartB"
); // where partA is the AWS Lambda that deals with NHS number

export const onEnrol = firebaseFunctions.httpsCallable("onEnrol");
export const onEnrol_TeleACS =
  firebaseFunctions.httpsCallable("onEnrol_TeleACS");
export const onEnrol_TeleTAVI =
  firebaseFunctions.httpsCallable("onEnrol_TeleTAVI");

// DATABASE
const db = firebaseApp.database(); // Don't allow components to access this directly. They must do using one of the following.

export function dbRefFromURL(x) {
  // Enabling firebase refs having been passed as a string (and therefore stored in Vue router's "/:param" format), to be reconstituted into a Ref.
  return db.refFromURL(x);
}

// ------------------------------- MANUALLY KEEP IN SYNC! (Each row in this location needs an extra "export" )
// Globals
export const systemStatusRef = db.ref("systemStatus");
export const systemAdminRef = db.ref("systemAdmin");
export const amOnlineRef = db.ref(".info/connected");
// These roots then have branches per user
export const contactRootRef = db.ref("userContact");
export const presenceRootRef = db.ref("userPresence");
export const privilegesRootRef = db.ref("userPrivileges");
// Describing trials, centres, etc
export const scienceFactRootRef = db.ref("scienceFact");
export const trialCriterionRootRef = db.ref("trialCriterion");
export const trialPrivateRootRef = db.ref("trialPrivate");
export const trialPublicRootRef = db.ref("trialPublic");
// Describing trials, centres, etc
export const userReferralRootRef = db.ref("userReferral");
export const trialReferralRootRef = db.ref("trialReferral");
export const trialMonitorRootRef = db.ref("trialMonitor");
// Describing access to trials
export const accessByTrialThenUserRef = db.ref("access/byTrialThenUser");
export const accessByUserThenTrialRef = db.ref("access/byUserThenTrial");
// Describing Management Tree
export const managementTreeRef = db.ref("managementTree");
// Log
export const logRootRef = db.ref("log");

// CONSTANTS
export const ACCESS = {
  // WARNING: update the Database rules for ".trialReferral" too
  SEE_NHS: "seeNhs",
  SUBSCRIBE_INSTANT: "subscribeInstant",
  SUBSCRIBE_REGULAR: "subscribeRegular",
};
export const FREQUENCY = {
  IMMEDIATE: "immediate",
  HOURLY: "hourly",
  DAILY: "daily",
  MONTHLY: "monthly",
};

export const STATUS_OPTIONS = [
  {
    groupName: "Not spoken to patient", // whenever an element contains a groupName, it is the START of a new group
    id: "sta~100100", // This value appears in the creation of a new referral, and in the auto-upgrade from "unopened" to "opened"
    short: "Unopened",
    long: "Researchers not yet opened",
    background: "#d73027",
    color: "#ffffff",
  },
  {
    id: "sta~100200", // This value appears in the auto-upgrade from "unopened" to "opened"
    short: "Opened",
    long: "Opened referral, not yet called",
    background: "#f46d43",
    color: "#ffffff",
  },
  {
    id: "sta~100400",
    short: "Left message",
    long: "Left message for pt to call back",
    background: "#fdae61",
    color: "#000000",
  },
  {
    groupName: "Spoken to patient",
    id: "sta~100500",
    short: "Coming",
    long: "Patient booked for visit to talk",
    background: "#fee090",
    color: "#000000",
  },
  {
    id: "sta~100600",
    short: "Considering",
    long: "Patient gone away to consider",
    background: "#ffffbf",
    color: "#000000",
  },
  {
    id: "sta~100700",
    short: "Agreed",
    long: "Agreed to participate",
    background: "#e0f3f8",
    color: "#000000",
  },

  /*  For the ones >=sta~100750, expecting to update but no prompts.  */

  {
    id: "sta~100800",
    short: "Consented",
    long: "Consented, awaiting randomization",
    background: "#abd9e9",
    color: "#000000",
  },
  {
    id: "sta~100900",
    short: "Randomized",
    long: "Randomized (or enrolled in nonRCT)",
    background: "#74add1",
    color: "#ffffff",
  },
  /*  For the ones >=sta~100950, no expectation of updating. Move to end. */

  {
    id: "sta~101000",
    short: "Completed",
    long: "Patient has completed trial",
    background: "#4575b4",
    color: "#ffffff",
  },
  {
    groupName: "Will not complete",
    id: "sta~102000",
    short: "Ineligible",
    long: "Patient is not eligible",
    background: "#e0e0e0",
    color: "#202020",
    allowReason: true,
  },
  {
    id: "sta~102100",
    short: "Refused",
    long: "Declined to participate",
    background: "#e0e0e0",
    color: "#202020",
    allowReason: true,
  },
  {
    id: "sta~102200",
    short: "Withdrew",
    long: "Enrolled but later withdrew",
    background: "#e0e0e0",
    color: "#202020",
    allowReason: true,
  },
  {
    id: "sta~102300",
    short: "Lost",
    long: "Permanently lost contact",
    background: "#c0c0c0",
    color: "#202020",
  },
  {
    id: "sta~102400",
    short: "Died",
    long: "Deceased",
    background: "#c0c0c0",
    color: "#202020",
  },
  {
    id: "sta~102600",
    short: "Before",
    long: "Referred by someone else first",
    background: "#c0c0c0",
    color: "#202020",
    allowReason: true,
  },
  {
    id: "sta~102700",
    short: "Another",
    long: "Enrolled in a conflicting trial",
    background: "#c0c0c0",
    color: "#202020",
    allowReason: true,
  },
  {
    id: "sta~105000",
    short: "? Tel no",
    long: "All phones not working",
    background: "#000000",
    color: "#ffffff",
    groupName: "Failure of process",
    allowReason: true,
  },
  {
    id: "sta~105500",
    short: "Denied",
    long: "Denies agreeing to be approached",
    background: "#000000",
    color: "#ffffff",
    allowReason: true,
  },
  {
    id: "sta~109000",
    short: "Ignore (test)",
    long: "Ignore: test referral",
    background: "#e0e0e0",
    color: "#808080",
    groupName: "Ignore referral",
  },
  {
    id: "sta~109100",
    short: "Ignore (duplicate)",
    long: "Ignore: accidental duplicate",
    background: "#e0e0e0",
    color: "#808080",
  },
];

// ------------------------------- End MANUALLY KEEP IN SYNC

// General constants
export const ServerValueTIMESTAMP = firebase.database.ServerValue.TIMESTAMP;

////////////////////////////////////////

export function statusObjFromId(id, options = undefined) {
  // options: {newReferral:true means 100100 becomes "New Referral Arrived" instead of "unopened"}
  const matchingStatuses = STATUS_OPTIONS.filter(
    statusObj => statusObj.id === id
  );
  if (matchingStatuses.length === 0) {
    return "Unknown status code " + id;
  }

  const out = JSON.parse(JSON.stringify(matchingStatuses[0]));
  if (options && options.newReferral) {
    if (out.id === "sta~100100") {
      out.short = "New";
      out.long = "New referral arrived"; // It's actually this which is displayed
    }
  }
  return out;
}

<template>
  <div class="p-2 page-scroll-y" @input="userHasEnteredTwoChars">
    <!-- for some reason @input fires once they have entered TWO characters not one -->

    <app-header-bar title="Edit fact" :hide-back-arrow="userHasEdited"></app-header-bar>

    <div v-if="formIsLoaded">
      <b-form class>
        <b-form-group label="Short description">
          <b-form-input
            size="lg"
            type="text"
            v-model="form.shortText"
            required
            placeholder="e.g. Gender, or Height"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Categorical or continuous?">
          <b-form-select
            size="lg"
            variant="primary"
            :options="typesOfFact"
            required
            v-model="form.type"
            @change="onTypeOfFactChanged($event)"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Classification within tree of facts">
          <b-form-row>
            &nbsp;
            <b-button
              variant="light"
              @click="onClickEditPath"
              @userHasSetPath="onUserHasSetPath($event.target.path)"
            >{{formatPath(form.path) }}</b-button>
          </b-form-row>
        </b-form-group>

        <b-form-group label="Clarification shown if they ask for help (optional)">
          <b-form-textarea
            v-model="form.longText"
            :rows="3"
            placeholder="e.g. Select the hair colour that is the closest match, or 'Bald' for no hair."
          ></b-form-textarea>
        </b-form-group>

        <div v-if="form.type === 'Categorical'">
          <b-form-group label="Options" label-size="lg" class="mt-5">
            <div
              class="mb-4 small"
              style="line-height:1;"
            >Avoid making major changes or deleting options, because doing so may mangle trials already using this variable.</div>
            <b-form-group v-for="option in form.options" :key="option.id">
              <b-form-input
                type="text"
                v-model="form.options[option.id].shortText"
                size="lg"
                required
                placeholder="e.g. Current smoker"
              ></b-form-input>
              <b-form-input
                type="text"
                v-model="option.longText"
                placeholder="Clarification (optional) e.g. Smoked any time in last month"
              ></b-form-input>
              <b-form-group class="officeUseOnly">
                <b-form-row>
                  <b-col>Permanent ID of option</b-col>
                  <b-col>{{option.id}}</b-col>
                </b-form-row>
              </b-form-group>
            </b-form-group>

            <b-button v-on:click="addCategoricalOption" variant="light" size="sm">Add option</b-button>
          </b-form-group>
        </div>

        <div v-if="form.type === 'Continuous'">
          <b-form-group label="Units (optional)">
            <b-form-input v-model="form.units" placeholder="e.g. mmol/L"></b-form-input>
          </b-form-group>

          <b-form-group label="Lowest value to allow being entered (optional)">
            <b-input-group :append="form.units">
              <b-form-input v-model="form.lowestValue" placeholder="e.g. 0.01"></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Highest value to allow being entered (optional)">
            <b-input-group :append="form.units">
              <b-form-input v-model="form.highestValue" placeholder="e.g. 999"></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Round to nearest (optional)">
            <b-input-group :append="form.units">
              <b-form-input
                v-model="form.roundingValue"
                placeholder="e.g. 0.1 for potassium levels, 0.01 for height in m"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>

        <b-form-group label="Priority">
          <div
            class="small"
            style="line-height:1.2;"
          >Questions will be displayed in alphabetical order of this. You might use values A1000-A9999 for Age/sex etc, and Z1000-9999 for DNA subtyping.</div>
          <b-form-input v-model="form.priority" placeholder="e.g. C2030 or E4900"></b-form-input>
        </b-form-group>

        <b-form-group>
          <div v-if="validityCheck.isValid && userHasEdited">
            <b-button v-on:click="onSubmit" variant="light" block>Save</b-button>
          </div>
          <div v-else-if="!validityCheck.isValid">
            <b-alert show variant="danger">{{ validityCheck.errorMessage }}</b-alert>
          </div>
          <div v-else>
            <br />
            <h6 style="text-align:center;">
              As long as you aren't
              <br />
              <em>inside</em> a text field:
            </h6>
            <h3 style="text-align:center;">Server is up-to-date</h3>
          </div>
        </b-form-group>

        <hr />
        <b-form-group label="FoundBy.Me Headquarters use only" class="officeUseOnly">
          <b-form-row>
            <b-col>Permanent ID</b-col>
            <b-col>{{id}}</b-col>
          </b-form-row>
        </b-form-group>
      </b-form>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import generatePushId from "../util/generatePushId.js";
import { scienceFactRootRef } from "../util/firebase.js";

const EMPTY_FORM = {
  path: "",
  type: "",
  shortText: "",
  longText: "",
  options: {}
};

export default {
  components: { AppHeaderBar },

  props: ["id"],

  data: () => ({
    form: EMPTY_FORM,
    formBeforeUserEditing: {}, // gets updated when firebase reads
    formIsLoaded: false, // gets updated when firebase reads
    typesOfFact: [
      { text: "Select One", value: null },
      "Categorical",
      "Continuous"
    ],
    inputSinceLastSave: false
  }),

  computed: {
    userHasEdited: function() {
      return (
        JSON.stringify(this.form) !==
          JSON.stringify(this.formBeforeUserEditing) || this.inputSinceLastSave
      );
    },

    validityCheck: function() {
      let isValid = true;
      let errorMessage = "";

      if (this.form.shortText.length < 1) {
        isValid = false;
        errorMessage += "Need to enter a short description of this fact.\n";
      }

      if (this.form.type === "Categorical") {
        if (Object.keys(this.form.options).length < 2) {
          isValid = false;
          errorMessage += "Need at least two options.\n";
        }

        const checkObj = this.shortTextAllFilledAndUnique(this.form.options);
        if (!checkObj.isAllFilled) {
          isValid = false;
          errorMessage += "All options need at least a short description.\n";
        }
        if (!checkObj.isAllUnique) {
          isValid = false;
          errorMessage += "All option short descriptions must be different.\n";
        }
      } else if (this.form.type === "Continuous") {
        // No special checks for continuous
      } else {
        isValid = false;
        errorMessage = "Need to select a type of fact.\n" + errorMessage; // Prepends this message as it belongs at the beginning.
      }
      return { isValid, errorMessage };
    }
  },

  created: function() {
    scienceFactRootRef.child(this.id).on("value", this.firebaseBinding);
  },

  beforeDestroy: function() {
    scienceFactRootRef.child(this.id).off("value", this.firebaseBinding);
  },

  methods: {
    userHasEnteredTwoChars() {
      this.inputSinceLastSave = true;
    },
    firebaseBinding: function(snapshot) {
      // This will function will need to be un-bound just before component destroyed
      if (snapshot.val()) {
        this.form = snapshot.val();
      } // else it already is set as default ot EMPTY_FORM so that the Vue watchers can work
      this.formBeforeUserEditing = JSON.parse(JSON.stringify(this.form));
      this.formIsLoaded = true;
    },

    addCategoricalOption: function() {
      const id = "opt~" + generatePushId();
      this.$set(this.form.options, id, { id: id });
    },

    shortTextAllFilledAndUnique: function(obj) {
      const keys = Object.keys(obj);
      const shortTextArray = keys.map(key =>
        (obj[key].shortText || "").trim().toUpperCase()
      );
      const isAllFilled = shortTextArray.every(
        shortText => shortText.length >= 1
      );
      const uniqueShortTexts = [...new Set(shortTextArray)];
      const isAllUnique = uniqueShortTexts.length === shortTextArray.length;
      return { isAllFilled, isAllUnique };
    },

    onTypeOfFactChanged: function(event) {
      if (event === "Categorical") {
        if (!this.form.options) {
          this.form.options = {};
        }
        while (Object.keys(this.form.options).length < 2) {
          this.addCategoricalOption();
        }
      }
    },

    formatPath: function(pathList) {
      return pathList ? pathList.join(" • ") : "Click to set classification";
    },

    onClickEditPath: function() {
      // must save form before going to other page, otherwise form contents are lost
      scienceFactRootRef.child(this.id).set(this.form);
      this.$router.push({
        name: "editOneFactClassification",
        params: {
          id: this.id,
          shortText: this.form.shortText,
          path: [], //this.form.path,
          pathRef: scienceFactRootRef.child(this.id).child("path")
        }
      });
    },

    onUserHasSetPath: function(pathList) {
      console.log("Received pathList: ", pathList);
      this.form.path = pathList;
    },

    onSubmit: function() {
      this.form.id = this.id; // Poke the id into the object, just in case
      scienceFactRootRef
        .child(this.id)
        .set(this.form)
        .then(() => {
          this.$router.back();
        });
    },

    onReset: function() {}
  }
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div
      id="home-page-buttons"
      class="d-flex flex-row justify-content-around"
      style="width:100%;"
    >

      <b-button
        size="sm"
        v-if="user.privilegesObj.researcher || user.privilegesObj.manager ||user.privilegesObj.hq"
        :variant="( from==='clinician')?'light':'outline'"
        @click="$router.push({name:'clinician'})"
      >
        Clinician<br>Home
      </b-button>

      <b-button
        size="sm"
        v-if="user.privilegesObj.researcher"
        :variant="( from==='researcher')?'light':'outline'"
        @click="$router.push({name:'researcher'})"
      >
        Researcher<br>Home
      </b-button>

      <b-button
        size="sm"
        v-if="user.privilegesObj.manager"
        :variant="( from==='manager')?'light':'outline'"
        @click="$router.push({name:'manage'})"
      >
        Manager<br>Home
      </b-button>

      <b-button
        size="sm"
        v-if="user.privilegesObj.hq"
        :variant="( from==='hq')?'light':'outline'"
        @click="$router.push({name:'hq'})"
      >
        FBM HQ<br>Home
      </b-button>

    </div>

    <AppAutoupdate :onlyShowCopyright="true"></AppAutoupdate>

  </div>

</template>

<script>
import AppAutoupdate from "./AppAutoupdate.vue";

export default {
  components: { AppAutoupdate },
  props: {
    from: { type: String, requied: true },
    user: { type: Object, required: true } // for privileges
  },
  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style scoped>
</style>

<template>
  <div class="p-2 page-scroll-y">

    <app-header-bar title="HQ Edit Trials"></app-header-bar>

    <div>
      <b-table
        striped
        hover
        :items="trialsMergedArray"
        :fields="trialsMergedFields"
        sort-by="priority"
        @row-clicked="onClickOneTrial"
      >
      </b-table>

      <b-button
        variant="light"
        @click="onAddATrial"
      >Add a trial
      </b-button>

    </div>

  </div>

</template>

<script>
import generatePushId from "../util/generatePushId.js";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { AppHeaderBar },

  props: {
    trials: { type: Object, required: true },
    trialPrivateObj: { type: Object, required: true }
  },

  data: () => ({
    trialsMergedArray: {},

    trialsMergedFields: [
      { key: "shortText", label: "Name", sortable: true },
      { key: "centreManagementPath", label: "Management", sortable: true },
      { key: "diagnosisTop", label: "Main diagnosis", sortable: true }
    ]
  }),

  created: function() {
    // Combine Public and Private into a single object
    this.trialsMergedObj = JSON.parse(JSON.stringify(this.trials.publicObj));
    Object.keys(this.trialsMergedObj).forEach(key => {
      if (this.trialPrivateObj[key]) {
        Object.assign(this.trialsMergedObj[key], this.trialPrivateObj[key]);
      }
    });
    // .. and convert into an array
    this.trialsMergedArray = Object.keys(this.trialsMergedObj).map(id => ({
      ...this.trialsMergedObj[id],
      id: id
    }));
    // ... then add a default sorting key ("priority")
    this.trialsMergedArray.forEach(trial => {
      const priority =
        trial.centreManagementPath.trim() + " : " + trial.shortText;
      // ":" sorts before "|", so parent organisations ("FBM | UK | Imperial") will have all their trials ("FBM | UK | Imperial : ORBITA-2" etc) shown before any child organisations ("FBM | UK | Imperial | Hammersmith")
      trial.priority = priority;
    });
  },

  beforeDestroy: function() {},

  methods: {
    onAddATrial: function() {
      const newTrialId = "tri~" + generatePushId();
      this.$router.push({ name: "editTrial", params: { id: newTrialId } });
    },

    onClickOneTrial: function(trialObj /* , index */) {
      this.$router.push({
        name: "editTrial",
        params: { id: trialObj.id }
      });
    },

    // NAVIGATION FUNCTIONS =============================
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
</style>

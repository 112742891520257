var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 d-flex flex-column",staticStyle:{"height":"100vh"}},[_c('app-header-bar',{attrs:{"title":"Randomised patients"}}),(_vm.myMonitorsArrayFilteredForDisplay.length > 1)?_c('section',{staticClass:"mb-1 small",attrs:{"id":"sortingReferrals"}},[(
          _vm.currentSortContext.sortBy === 'statusId' &&
          _vm.currentSortContext.sortDesc === false
        )?_c('div',[_vm._v(" In order of priority "),_c('b-button',{staticClass:"ml-2",staticStyle:{"font-size":"70%"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){_vm.explainWhyOrderedByPriority = !_vm.explainWhyOrderedByPriority;
            _vm.choosingWhichOneToShow = false;}}},[_vm._v("Why?")]),_c('transition',{attrs:{"name":"swish"}},[(_vm.explainWhyOrderedByPriority)?_c('div',{staticStyle:{"background-color":"white","color":"black","border-radius":"1em","padding":"1em"}},[_c('h5',[_vm._v("Take control with priority ordering")]),_c('p',[_vm._v(" Attend to the unopened and uncalled patients first. Once dealt with, they move down in priority. ")]),_c('p',[_vm._v(" Patients progress through the stages until they enter the study (blue) or do not (grey). ")]),_c('p',[_vm._v(" No patient can be lost, and you always spend your precious time on the highest-priority tasks. ")]),_c('h5',[_vm._v("Inbox-like alternative")]),_vm._v(" If you prefer the 'inbox-like' display, click twice on 'Time'. Now the most recent referrals are at the top, and older ones further down, where they may be forgotten. "),_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.explainWhyOrderedByPriority = false}}},[_vm._v("OK")])],1)]):_vm._e()])],1):_vm._e()]):_vm._e(),(_vm.myTrialIds && _vm.myTrialIds.length > 1)?_c('section',{staticClass:"mb-2",attrs:{"id":"filteringTrials"}},[_c('transition',{attrs:{"name":"swish"}},[(_vm.choosingWhichOneToShow)?_c('div',[_vm._v(" Choose trial to display: "),_c('b-list-group',{staticStyle:{"z-index":"100"}},[_vm._l((_vm.myTrialIds),function(trialId){return _c('b-list-group-item',{key:trialId,staticClass:"mb-1",staticStyle:{"border-radius":"0.8em","background-color":"white","color":"black"},on:{"click":function($event){_vm.displayTrialIds = [trialId];
                _vm.choosingWhichOneToShow = false;}}},[_vm._v(_vm._s(_vm.trials.publicObj[trialId].shortText))])}),_c('b-list-group-item',{staticClass:"mb-3",staticStyle:{"border-radius":"0.8em","background-color":"lightgray","color":"black"},on:{"click":function($event){_vm.displayTrialIds = _vm.myTrialIds;
                _vm.choosingWhichOneToShow = false;}}},[_vm._v(" Return to showing "),_c('strong',[_vm._v("all")]),_vm._v(" trials ")])],2)],1):_vm._e()]),(!_vm.choosingWhichOneToShow && _vm.displayTrialIds.length === 1)?_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-baseline small"},[_vm._v(" Showing only "+_vm._s(_vm.trials.publicObj[_vm.displayTrialIds[0]].shortText)+" "),_c('b-button',{staticClass:"ml-1",staticStyle:{"font-size":"70%"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){_vm.choosingWhichOneToShow = true;
            _vm.explainWhyOrderedByPriority = false;}}},[_vm._v("Change")])],1):_vm._e(),(!_vm.choosingWhichOneToShow && _vm.displayTrialIds.length > 1)?_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-baseline small"},[_vm._v(" Showing all trials "),_c('b-button',{staticClass:"ml-1",staticStyle:{"font-size":"70%"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){_vm.choosingWhichOneToShow = true;
            _vm.explainWhyOrderedByPriority = false;}}},[_vm._v("Show only one")])],1):_vm._e()],1):_vm._e(),(_vm.myMonitorsArrayIsLoaded)?_c('section',{staticStyle:{"height":"100%","width":"100%","overflow-y":"auto"}},[(_vm.myMonitorsArrayFilteredForDisplay.length === 0)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",staticStyle:{"height":"100%"}},[_c('h1',[_vm._v("None")])]):_vm._e(),(_vm.myMonitorsArrayFilteredForDisplay.length > 0)?_c('b-table',{attrs:{"items":_vm.myMonitorsArrayFilteredForDisplay,"fields":_vm.fields,"sort-by":"priority","striped":""},on:{"DISABLED___row-clicked":_vm.onClickOneMonitor,"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(enrolmentIso)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.dateParts(data.item.enrolmentIso).dateMonth)+" ")]),(_vm.isoIsMoreThanNDaysAgo(data.item.enrolmentIso, 360))?_c('div',[_vm._v(" "+_vm._s(_vm.dateParts(data.item.enrolmentIso).year)+" ")]):_vm._e(),_c('div',{staticStyle:{"font-size":"90%"}},[_vm._v(" "+_vm._s(_vm.dateParts(data.item.enrolmentIso).t.slice(0, 5))+" ")]),_c('div',{staticStyle:{"font-size":"90%","width":"1em"}})])]}},{key:"cell(trialId)",fn:function(data){return [_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(_vm.trials.publicObj[data.item.trialId].shortText))])]}}],null,false,1593739109)}):_vm._e()],1):_vm._e(),_c('section',{attrs:{"id":"bottom"}},[_c('transition',{attrs:{"name":"swish"}},[(_vm.showNotResearcherMessage)?_c('div',[_vm._v(" When your researchers click on a referral, the personal information will be obtained and decrypted for them. ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
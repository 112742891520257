<template>
  <div>
    <!-- 
  _____       _                        _   _                   _
 |_   _|     | |                      | | (_)                 | |
   | |  _ __ | |_ ___ _ __ _ __   __ _| |_ _  ___  _ __   __ _| |
   | | | '_ \| __/ _ \ '__| '_ \ / _` | __| |/ _ \| '_ \ / _` | |
  _| |_| | | | ||  __/ |  | | | | (_| | |_| | (_) | | | | (_| | |
 |_____|_| |_|\__\___|_|  |_| |_|\__,_|\__|_|\___/|_| |_|\__,_|_|

    -->

    <div v-if="user.contactObj.location.type==='international'">
      <transition name="swish">
        <div v-if="localPatientIdStage===''">
          <!-- the b-input shows an "X" in Edge, which we remove in the scoped style at the bottom of the component -->
          <!-- iPhone showed inadequate height at 1.4em so I've increased it a bit -->
          <b-input
            style="font-size:200%; height:1.7em; width:8.4em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
            type="text"
            v-model="localPatientId"
            placeholder="Patient ID"
            :formatter="formatLocalPatientId"
            autofocus
            @keyup.enter.native="localPatientIdStage='doneOne'"
          />
          <div class="d-flex justify-content-end" v-if="localPatientId!==''">
            <b-button
              class="mt-2"
              size="lg"
              variant="light"
              @click="localPatientIdStage='doneOne'"
            >OK</b-button>
          </div>
        </div>
      </transition>
      <transition name="swish">
        <div v-if="localPatientIdStage==='doneOne'">
          Repeat entry to confirm:
          <b-input
            style="font-size:200%; height:1.7em; width:8.4em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
            type="text"
            v-model="localPatientId2"
            placeholder="Patient ID"
            :formatter="formatLocalPatientId"
            autofocus
            @keyup.enter.native="checkAndSubmitLocalPatientIdIfMatch"
          />
          <div class="d-flex justify-content-end" v-if="localPatientId2!==''">
            <b-button
              class="mt-2"
              size="lg"
              variant="light"
              @click="checkAndSubmitLocalPatientIdIfMatch"
            >OK</b-button>
          </div>
        </div>
      </transition>
      <transition name="swish">
        <div
          v-if="localPatientIdStage==='doneBothMismatch'"
          class="d-flex flex-column align-items-center"
        >
          Sorry, entries did not match:
          <div style="font-size:200%;">{{localPatientId}}</div>
          <div>and</div>
          <div style="font-size:200%;">{{localPatientId2}}</div>
          <div class="d-flex justify-content-end">
            <b-button
              class="mt-2"
              size="lg"
              variant="light"
              @click="localPatientId='';localPatientId2='';localPatientIdStage=''"
            >Try again</b-button>
          </div>
        </div>
      </transition>
    </div>
    <!--
  _    _ _  __
 | |  | | |/ /
 | |  | | ' /
 | |  | |  <
 | |__| | . \
  \____/|_|\_\
    -->

    <div v-else>
      <b-input
        style="font-size:250%; height:1.7em; width:6.7em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
        type="tel"
        v-model="nhsNumberString"
        placeholder="NHS Number"
        :formatter="formatNhs"
        @input="checkAndSubmitNhsNumberIfValid"
        autofocus
      />

      <transition name="swish">
        <b-alert
          show
          v-if="nhsNumberValidationState==='invalid'"
          variant="danger"
          class="my-3"
          style="text-align:center;"
        >Invalid NHS Number</b-alert>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: { user: { required: true, type: Object } },
  data() {
    return {
      // Actual Data
      nhsNumberString: "", // "000 000 0000" or "XYZHosp:3985743985MZ709",
      localPatientId: "", // Some alphanumeric code,
      localPatientId2: "",
      localPatientIdStage: "" // ""/"doneOne"/"doneBothMatch"/"doneBothMismatch"

      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {
    nhsNumberValidationState: function() {
      const s = this.formatNhs(this.nhsNumberString);
      if (s.length !== 12) return "short";
      if (this.isValidNhsNumber(s)) {
        return "valid";
      } else {
        return "invalid";
      }
    }
  },
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    checkAndSubmitLocalPatientIdIfMatch: function() {
      if (this.localPatientId === this.localPatientId2) {
        const siteAndLocalId =
          (this.user.contactObj.location.shortName || "UnknownSite") +
          ":" +
          this.localPatientId;
        this.localPatientIdStage = "doneBothMatch";
        this.$emit("updatePatientIdAndValidationState", {
          nhsOrSiteAndLocalId: siteAndLocalId,
          patientIdValidationState: "valid"
        });
      } else {
        this.localPatientIdStage = "doneBothMismatch";
      }
    },

    checkAndSubmitNhsNumberIfValid: function() {
      this.$emit("updatePatientIdAndValidationState", {
        nhsOrSiteAndLocalId: this.nhsNumberString,
        patientIdValidationState: this.nhsNumberValidationState
      });
    },

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    formatNhs: function(s) {
      s = s.replace(/\D/g, "").substr(0, 10);
      if (s.length >= 4) {
        s = s.slice(0, 3) + " " + s.slice(3);
      }
      if (s.length >= 8) {
        s = s.slice(0, 7) + " " + s.slice(7);
      }
      return s;
    },

    isValidNhsNumber: function(sWithSpaces) {
      const s = sWithSpaces.replace(/\D/g, "");
      let isValid = false;
      if (s.length === 10) {
        let total = 0;
        for (let i = 0; i <= 8; i++) {
          const digit = s.substr(i, 1);
          const factor = 10 - i;
          total += digit * factor;
        }
        const checkDigit = (11 - (total % 11)) % 11;
        isValid = checkDigit === parseInt(s.substr(9, 1));
      }
      return isValid;
    },

    formatLocalPatientId: function(s = "") {
      return (
        "" +
        s
          .toUpperCase()
          .replace(/[^A-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]/gi, "")
      );
      // List of unicode alphabeticals comes from https://stackoverflow.com/questions/388996/regex-for-javascript-to-allow-only-alphanumeric
    },

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
::-ms-clear {
  /* hide the "X" that Edge shows at the right of an input box once you start enterng, as it messes up NHS Number input for Edge. "ms-clear" is the name of that decoration.
  Source:  https://stackoverflow.com/questions/29396844/remove-x-input-decoration-in-microsoft-edge-formerly-project-spartan
  */
  display: none;
}
</style>

<template>
  <transition
    :name="transitionName"
    :mode="transitionMode"
    :enter-active-class="transitionEnterActiveClass"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @afterEnter="afterEnter"
  >
    <slot />
  </transition>
</template>

<script>
// This page comes originates in (the bottom excerpt of) https://markus.oberlehner.net/blog/vue-router-page-transitions/
// Augmented by:

// (a) the option to use meta.sliderPath to provide a heirarchy that is NOT visible to the user on the URL line, but is still used in "/"-counting to determine depth and therefore left/right direction

// (b) X, Y, Z stuff

const DEFAULT_TRANSITION = "fade";
const DEFAULT_TRANSITION_MODE = "out-in";

function getNetDistanceInDirection(sliderPath, positiveSymbol, negativeSymbol) {
  const path = sliderPath || "";
  return path.split(positiveSymbol).length - path.split(negativeSymbol).length;
}

function getXYZ(sliderPath) {
  return {
    x: getNetDistanceInDirection(sliderPath, "[R]", "[L]"),
    y: getNetDistanceInDirection(sliderPath, "[U]", "[D]"),
    z: getNetDistanceInDirection(sliderPath, "[I]", "[O]")
  };
}

function getRelativeXYZ(sliderPathFrom, sliderPathTo) {
  const fromXYZ = getXYZ(sliderPathFrom);
  const toXYZ = getXYZ(sliderPathTo);
  return {
    x: toXYZ.x - fromXYZ.x,
    y: toXYZ.y - fromXYZ.y,
    z: toXYZ.z - fromXYZ.z
  };
}

export default {
  name: "TransitionPage",

  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: ""
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName =
        to.meta.transitionName ||
        from.meta.transitionName ||
        DEFAULT_TRANSITION;

      const bothMetaSliderPathsPresent =
        typeof to.meta.sliderPath === "string" &&
        typeof from.meta.sliderPath === "string";

      if (bothMetaSliderPathsPresent) {
        const relativeXYZ = getRelativeXYZ(
          from.meta.sliderPath,
          to.meta.sliderPath
        );

        if (relativeXYZ.z > 0) {
          transitionName = "zoom-in";
        } else if (relativeXYZ.z < 0) {
          transitionName = "zoom-out";
        } else if (relativeXYZ.y > 0) {
          transitionName = "slide-up";
        } else if (relativeXYZ.y < 0) {
          transitionName = "slide-down";
        } else if (relativeXYZ.x > 0) {
          transitionName = "slide-left";
        } else if (relativeXYZ.x < 0) {
          transitionName = "slide-right";
        }
      }

      this.transitionName = transitionName;

      next();
    });
  },
  methods: {
    // I've removed all the HEIGHT shenanigans from the original website as it caused problems when I transitioned between "full page" and "please auto-centre" pages. Works fine without them.
    beforeLeave(/* element */) {
      //    this.prevHeight = getComputedStyle(element).height;
    },
    enter(/* element */) {
      //  const { height } = getComputedStyle(element);
      //  element.style.height = this.prevHeight;
      setTimeout(() => {
        //     element.style.height = height;
      });
    },
    afterEnter(/* element */) {
      //    element.style.height = "auto";
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-up-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
  transition-duration: 500ms;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(100vw, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-100vw, 0);
}

.slide-up-enter,
.slide-down-leave-active {
  opacity: 0;
  transform: translate(0, -100vh);
}

.slide-up-leave-active,
.slide-down-enter {
  opacity: 0;
  transform: translate(0, 100vh);
}

.zoom-out-enter-active,
.zoom-out-leave-active {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: zoom-out;
}

.zoom-out-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-out {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: zoom-in;
}

.zoom-in-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale3d(3, 3, 3);
  }

  100% {
    opacity: 1;
  }
}
</style>
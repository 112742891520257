<template>
  <!-- style="height:100vh; overflow-y:hidden;" -->
  <div style="height:100vh; overflow-y:auto;" class="mx-4 d-flex flex-column align-items-center">
    <transition name="swish">
      <clin-cancel-referral></clin-cancel-referral>
    </transition>

    <div>
      <br />
      <transition name="swish">
        <div v-if="patientIdValidationState!=='valid'">
          <div v-if="user.contactObj.inTour">
            <tour-message>
              <h4>Now you would take the phone back from the patient, and enter the patient's unique identifier.</h4>
              <h2>For now, just put ten zeros (0000000000).</h2>
            </tour-message>
          </div>
          <div v-else>
            <br />
            <h1 style="text-align:center;">Thank you!</h1>
            <br />
            <h4 style="text-align:center; font-size:245%;">Back to the</h4>
            <h2 style="text-align:center; font-size:350%; line-height:0.8;">
              Medical
              <br />
              <span style="font-size:160%;">Staff</span>
            </h2>
            <br />
            <h4
              v-if="user.contactObj.location.type==='international'"
              style="text-align:center;"
            >Enter patient identifier</h4>
            <h4 v-else style="text-align:center;">Enter NHS Number</h4>
          </div>
        </div>
      </transition>

      <transition name="swish">
        <div class="d-flex flex-row justify-content-center">
          <div class="d-flex flex-column">
            <ClinEnterId
              :user="user"
              @updatePatientIdAndValidationState="updatePatientIdAndValidationState"
            ></ClinEnterId>

            <!-- <div v-if="user.contactObj.location.type==='international'">
              <transition name="swish">
                <div v-if="localPatientIdStage===''">
                  <b-input
                    style="font-size:200%; height:1.7em; width:8.4em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
                    type="text"
                    v-model="localPatientId"
                    placeholder="Patient ID"
                    :formatter="formatLocalPatientId"
                    autofocus
                  />
                  <div class="d-flex justify-content-end" v-if="localPatientId!==''">
                    <b-button
                      class="mt-2"
                      size="lg"
                      variant="light"
                      @click="localPatientIdStage='doneOne'"
                    >OK</b-button>
                  </div>
                </div>
              </transition>
              <transition name="swish">
                <div v-if="localPatientIdStage==='doneOne'">
                  Repeat entry to confirm:
                  <b-input
                    style="font-size:200%; height:1.7em; width:8.4em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
                    type="text"
                    v-model="localPatientId2"
                    placeholder="Patient ID"
                    :formatter="formatLocalPatientId"
                    autofocus
                  />demo
                  <div class="d-flex justify-content-end" v-if="localPatientId2!==''">
                    <b-button
                      class="mt-2"
                      size="lg"
                      variant="light"
                      @click="compareLocalPatientIds"
                    >OK</b-button>
                  </div>
                </div>
              </transition>
              <transition name="swish">
                <div
                  v-if="localPatientIdStage==='doneBothMismatch'"
                  class="d-flex flex-column align-items-center"
                >
                  Sorry, entries did not match:
                  <div style="font-size:200%;">{{localPatientId}}</div>
                  <div>and</div>
                  <div style="font-size:200%;">{{localPatientId2}}</div>
                  <div class="d-flex justify-content-end">
                    <b-button
                      class="mt-2"
                      size="lg"
                      variant="light"
                      @click="localPatientId='';localPatientId2='';localPatientIdStage=''"
                    >Try again</b-button>
                  </div>
                </div>
              </transition>
            </div>
            <div v-else>
              <b-input
                style="font-size:250%; height:1.7em; width:6.7em; padding-left:0.15em; border-radius:0.5rem;border-style:none;"
                type="tel"
                v-model="nhsNumberString"
                placeholder="NHS Number"
                :formatter="formatNhs"
                autofocus
              />
            </div>-->
          </div>
        </div>
      </transition>

      <div style="max-width:20em;">
        <transition name="swish">
          <b-form-group
            label="Where should the researchers look for the medical records?"
            v-if="patientIdValidationState==='valid' && recordOptions.length>0"
            class="my-3 d-flex flex-row justify-content-center"
          >
            <b-form-select v-model="recordLocation" :options="recordOptions" value="select"></b-form-select>
            <div
              class="small"
              style="line-height:1;"
            >If needed, direct researchers to other systems using free text box below.</div>
          </b-form-group>
        </transition>

        <transition name="swish">
          <b-form-group
            label="Where was patient when giving permission?"
            v-if="patientIdValidationState==='valid' && recordLocation!==null"
            class="my-3 d-flex flex-row justify-content-center"
          >
            <b-form-select v-model="permissionLocation" :options="permissionOptions"></b-form-select>
          </b-form-group>
        </transition>

        <br />

        <transition name="swish">
          <section
            v-if="patientIdValidationState==='valid' && recordLocation!==null && permissionLocation!==null && user.contactObj.inTour"
          >
            <tour-message>
              <h3>
                In real life you would enter the patient's preferred contact info below.
                <br />For now, just click OK.
              </h3>
              <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
              </div>-->
            </tour-message>
          </section>
        </transition>

        <transition name="swish">
          <b-form-group
            v-if="patientIdValidationState==='valid' && recordLocation!==null && permissionLocation!==null"
            class="d-flex flex-column justify-content-center"
          >
            Free text for contact preferences
            <br />
            <span style="color:lightgray; font-size:71%;">Outpatients</span> Best phone numbers / times?
            <br />
            <span style="color:lightgray; font-size:71%;">Inpatients</span> Which ward?
            <br />
            <b-form-textarea v-model="contactPreferences" :rows="3" :max-rows="10"></b-form-textarea>

            <div class="d-flex flex-row justify-content-end">
              <b-button style="width:50%;" variant="light" size="lg" class="mt-3" @click="goNext">OK</b-button>
            </div>
          </b-form-group>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ClinCancelReferral from "./ClinCancelReferral.vue";
import ClinEnterId from "./ClinEnterId.vue";

export default {
  components: { ClinCancelReferral, ClinEnterId },

  props: {
    trialIdsPatientAccepted: {
      type: Array,
      default: () => ["tri~dummy-for-testing-001"], // https://github.com/vuejs/vue/issues/1032
    },
    user: { type: Object, required: true },
  },

  data() {
    return {
      patientIdString: "", // Actually either "123 456 7890" or "BR:XYZHOSP:09FFDSG8769FSD7869876"
      patientIdValidationState: false,
      // localPatientId: "", // Some alphanumeric code,
      // localPatientId2: "",
      // localPatientIdStage: "", // ""/"doneOne"/"doneBothMatch"/"doneBothMismatch"
      recordLocation: "Standard records",
      recordOptions: [],
      permissionLocation: "Outpatient visit",
      permissionOptions: [
        { value: null, text: "Select" },
        "Outpatient visit",
        "General practice",
        "Hospital ward",
        "Hospital, attending for a test",
        "On telephone",
      ],
      contactPreferences: "",
    };
  },

  computed: {},

  created() {
    if (
      this.user.contactObj.location &&
      Array.isArray(this.user.contactObj.location.recordLocations) &&
      this.user.contactObj.location.recordLocations.length > 0
    ) {
      this.recordOptions = this.user.contactObj.location.recordLocations;
      this.recordLocation = this.recordOptions[0];
    }
  },

  methods: {
    updatePatientIdAndValidationState(emission) {
      this.patientIdString = emission.nhsOrSiteAndLocalId;
      this.patientIdValidationState = emission.patientIdValidationState;
    },

    goNext: function () {
      this.$router.push({
        name: "clin60Refer",
        params: {
          nhsNumberString: this.patientIdString,
          recordLocation: this.recordLocation,
          permissionLocation: this.permissionLocation,
          contactPreferences: this.contactPreferences,
          trialIdsPatientAccepted: this.trialIdsPatientAccepted,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>

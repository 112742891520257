import { geographyPostcodeOutcodes } from "../util/tableGeographyPostcodeOutcodes.js";
const geographyPostcodeAlphaArray = getUniqueAlphaPrefixes(
  geographyPostcodeOutcodes
);

// Create a single PLACES array
import { nhsCcgs } from "../util/tableLocationsNhsCcgs";
import { nhsTrusts } from "../util/tableLocationsNhsTrusts";
import { internationalHospitals } from "./tableLocationsInternationalHospitals.js";

nhsCcgs.forEach(x => (x.type = "ccg"));
nhsTrusts.forEach(x => (x.type = "trust"));
internationalHospitals.forEach(x => (x.type = "international"));

// @ts-ignore
const placesArray = nhsTrusts.concat(nhsCcgs).concat(internationalHospitals);

//  _____      _     _                     _   _               _____ _     _
// |  __ \    | |   | |                   | | (_)             |  _  | |   (_)
// | |  \/ ___| |_  | |     ___   ___ __ _| |_ _  ___  _ __   | | | | |__  _
// | | __ / _ \ __| | |    / _ \ / __/ _` | __| |/ _ \| '_ \  | | | | '_ \| |
// | |_\ \  __/ |_  | |___| (_) | (_| (_| | |_| | (_) | | | | \ \_/ / |_) | |
//  \____/\___|\__| \_____/\___/ \___\__,_|\__|_|\___/|_| |_|  \___/|_.__/| |
//                                                                       _/ |
//                                                                      |__/

export function existsNhsLocationObjByLongName(longName) {
  return getOneNhsLocationObjByLongName(longName) !== null;
}
export function getOneNhsLocationObjByLongName(longName) {
  const foundPlaces = getNhsLocationObjsPluralByLongName(longName);
  if (foundPlaces.length === 0) {
    // eslint-disable-next-line no-console
    console.error("No such place as ", longName);
    return null;
  } else {
    return foundPlaces[0];
  }
}

export function getNhsLocationObjsPluralByLongName(longName) {
  return placesArray.filter(place => place.longName === longName);
}

export function checkUniquenessNhsLocations() {
  // returns either {unique:true, arrayOfNonUnique:[]}
  //  or { unique:false,
  //   arrayOfNonUnique: [
  //     {
  //       longName: "Corby", matchingPlaces: [
  //         { longName: "Corby", ......},
  //         { longName: "Corby", ......},
  //       ]
  //     }]
  //   }

  const arrayOfNonUnique = [];
  const longNames = placesArray.map(place => place.longName);
  longNames.forEach(longName => {
    const matchingPlaces = getNhsLocationObjsPluralByLongName(longName);
    if (matchingPlaces.length > 1) {
      arrayOfNonUnique.push(longName);
    }
  });
  return {
    unique: arrayOfNonUnique.length === 0,
    arrayOfNonUnique
  };
}

// ______         _                _            __                _ _
// | ___ \       | |              | |          / /               | (_)
// | |_/ /__  ___| |_ ___ ___   __| | ___     / /   _ __ __ _  __| |_ _   _ ___
// |  __/ _ \/ __| __/ __/ _ \ / _` |/ _ \   / /   | '__/ _` |/ _` | | | | / __|
// | | | (_) \__ \ || (_| (_) | (_| |  __/  / /    | | | (_| | (_| | | |_| \__ \
// \_|  \___/|___/\__\___\___/ \__,_|\___| /_/     |_|  \__,_|\__,_|_|\__,_|___/

function isValidPostcodeOutcode(ha3) {
  return !!geographyPostcodeOutcodes[ha3.trim()];
}

function isValidPostcodeOutcodeAlpha(ha) {
  return geographyPostcodeAlphaArray.includes(ha);
}

export function isValidPostcodeZone(haOrHa3) {
  // can be HA or HA3 or W or W12, but not W12 0NN
  return (
    isValidPostcodeOutcode(haOrHa3) || isValidPostcodeOutcodeAlpha(haOrHa3)
  );
}

export function isValidRadiusGeography(radiusGeography) {
  const validate = validateAndDecomposeRadiusGeography(radiusGeography);
  return validate.valid;
}

function validateAndDecomposeRadiusGeography(radiusGeography) {
  // we get jus the (W12,4.3), i.e. not the leading "r"
  let valid = false;
  let postcodeOutcode = null;
  let radiusKm = null;
  if (typeof radiusGeography === "string") {
    const len = radiusGeography.length;
    if (
      len >= 5 &&
      radiusGeography[0] === "(" &&
      radiusGeography[len - 1] === ")"
    ) {
      const parts = radiusGeography.slice(1, len - 1).split(":");
      if (parts.length === 2) {
        postcodeOutcode = parts[0];
        radiusKm = parseFloat(parts[1]);
        if (isValidPostcodeOutcode(postcodeOutcode) && radiusKm >= 0) {
          valid = true;
        }
      }
    }
  }
  return {
    valid,
    postcodeOutcode,
    radiusKm
  };
}

// function getOutcodeFromPostcode(postcode) {
//   if (typeof postcode !== "string") throw new Error("Postcode " + postcode + " is not a string");
//   const words = postcode.split(" ");
//   const outcode = words[0];
//   return outcode;
// }

export function referrerPlacesInRange(trialPublicGeographiesString) {
  return placesArray
    .map(place => ({
      ...place,
      ...matchGeoBooleanAndDistance(
        "p" + place.postcode,
        trialPublicGeographiesString
      )
    }))
    .filter(place => place.matched)
    .sort((a, b) => (a.distanceKm || 0) - (b.distanceKm || 0));
}

export function matchGeo /* boolean only */(
  patientGeography,
  trialPublicGeographiesString
) {
  const booleanAndDistance = matchGeoBooleanAndDistance(
    patientGeography,
    trialPublicGeographiesString
  );
  return booleanAndDistance.matched;
}

// ______     _            _
// | ___ \   (_)          | |
// | |_/ / __ ___   ____ _| |_ ___
// |  __/ '__| \ \ / / _` | __/ _ \
// | |  | |  | |\ V / (_| | ||  __/
// \_|  |_|  |_| \_/ \__,_|\__\___|

function matchGeoBooleanAndDistance(
  patientGeography,
  trialPublicGeographiesString
) {
  // To get into the trial, the (single) geography of the patient must be within the areas described by the trialist.
  // This is used
  // 1. by the SYMPTOM display page, to only show symptoms for which the patient's current geography has a trial
  // 2. by the SCREENING page to re-check this before matching against criteria

  const trialPublicGeographiesArray = trialPublicGeographiesString.split(",");
  let matched = false;
  const distanceKmList = [];
  trialPublicGeographiesArray.forEach(trialGeo => {
    // A trial is allowed to have multiple location specifiers. You only have to match ONE to be in. So scan through each location specifier (trialGeo)...
    trialGeo = trialGeo.trim();
    switch (trialGeo.charAt(0)) {
      case "g": // "this trial accepts from global"
        matched = true;
        break;
      case "c": // "this trial accepts from the following  2-letter country code"  (only one at a time is looked for).
        // There are TWO ways to win in a country.
        // Way 1:  the trial specifies cGB and the patient has a postcode.
        if (trialGeo === "cGB" && patientGeography.slice(0, 1) === "p") {
          matched = true;
        }
        // Way 2: the trial specifies a country, and the patient is in that country
        if (patientGeography.slice(0, 1) === "c") {
          const trialCountry = trialGeo.slice(1, 3).toUpperCase(); // Only two chars
          const patientCountry = patientGeography.slice(1, 3).toUpperCase(); // Only two chars
          if (trialCountry === patientCountry) {
            matched = true;
          }
        }

        break;
      case "p":
        if (trialGeo === patientGeography.slice(0, trialGeo.length)) {
          matched = true;
          distanceKmList.push(0);
        }

        break;
      case "n":
        {
          // matched = false;
        }
        break;
      case "r":
        {
          const radius_and_postcodeOutcode = trialGeo.slice(1);
          const {
            valid,
            postcodeOutcode,
            radiusKm
          } = validateAndDecomposeRadiusGeography(radius_and_postcodeOutcode);
          if (valid) {
            if (
              postcodeOutcode &&
              typeof postcodeOutcode === "string" &&
              geographyPostcodeOutcodes[postcodeOutcode] &&
              geographyPostcodeOutcodes[postcodeOutcode].x
            ) {
              // trialGeo has valid postcode outcode
              const trialGeoXY = geographyPostcodeOutcodes[postcodeOutcode];
              if (patientGeography.slice(0, 1) === "p") {
                const patientGeographyPostcode = patientGeography.slice(1);
                const words = patientGeographyPostcode.split(" ");
                const patientGeographyPostcodeOutcode = words[0];
                const patientGeoXY =
                  geographyPostcodeOutcodes[patientGeographyPostcodeOutcode];
                if (patientGeoXY && trialGeoXY) {
                  // if both are defined
                  const distanceKm = distanceKmBetweenTwoXYObjects(
                    patientGeoXY,
                    trialGeoXY
                  );
                  if (distanceKm <= radiusKm) {
                    matched = true;
                    distanceKmList.push(distanceKm);
                  }
                }
              }
            }
          }
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn(`Skipping unrecognised trial geography: ${trialGeo}`);
    }
  });
  distanceKmList.sort((a, b) => a - b);
  return {
    matched,
    distanceKm: distanceKmList[0]
  };
}

function distanceKmBetweenTwoXYObjects(obj1, obj2) {
  // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula#comment36895098_27943
  // function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  let { x: lon1, y: lat1 } = obj1;
  let { x: lon2, y: lat2 } = obj2;

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

//  _   _      _                    __
// | | | |    | |                  / _|
// | |_| | ___| |_ __   ___ _ __  | |_ _ __  ___
// |  _  |/ _ \ | '_ \ / _ \ '__| |  _| '_ \/ __|
// | | | |  __/ | |_) |  __/ |    | | | | | \__ \
// \_| |_/\___|_| .__/ \___|_|    |_| |_| |_|___/
//              | |
//              |_|

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getUniqueAlphaPrefixes(objOfAlphaNumeric) {
  const alphaList = Object.keys(objOfAlphaNumeric)
    .sort()
    .map(x => x.replace(/[0-9 ]/g, ""));
  const uniqueAlphaList = [...new Set(alphaList)];
  return uniqueAlphaList;
}

<template>
  <div
    class="p-4 d-flex flex-column justify-content-space-between"
    style="height: 100vh"
  >
    <section
      id="progress-graphical"
      v-if="state !== 'referred'"
      class="mb-4 d-flex flex-column align-items-center"
    >
      <b-progress
        style="width: 90vw; background-color: none"
        height="32px"
        :value="progress"
        :max="100"
        animated
      ></b-progress>
      <br />
      <svg class="ion" style="width: 40vw; height: 40vw">
        <use xlink:href="#cloud-padlock" />
      </svg>
    </section>

    <div
      style="height: 100%"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <section v-if="state === ''">Referring</section>

      <section v-if="state === 'awaitTrapdoor'">
        Establishing
        <br />secure <br />connection
      </section>

      <section v-if="state === 'errorTrapdoor'">
        <b-alert show variant="danger">
          Cannot reach secure server
          <hr />
          <h4>Solutions</h4>
          <ol>
            <li>Ensure internet connected.</li>
            <li>
              If on NHS WiFi, try switching off WiFi and using phone's mobile
              network.
            </li>
          </ol>
          <div class="blink_me">Automatically retrying ...</div>
        </b-alert>
      </section>

      <section v-if="state === 'awaitFirebase'">Transmitting</section>

      <section v-if="state === 'errorFirebase'">
        <b-alert show variant="danger">
          Error during data transmission
          <hr />
          <h4>Solutions</h4>
          <ol>
            <li>Ensure internet connected.</li>
            <!-- ## this will have an auto check -->
            <li>
              If on NHS WiFi, try switching WiFi off and using phone's own
              GPRS/3G/4G etc.
            </li>
            <!-- <li>In longer term, ask WiFi provider to unblock j446prlfe3.execute-api.eu-west-2.amazonaws.com</li> -->
          </ol>
          <!--- we will make the below message blink etc -->
          <div class="blink_me">Automatically retrying ...</div>
        </b-alert>
      </section>

      <section v-if="state === 'referred'">
        <div class="mb-5">
          <h1 style="text-align: center">
            <span style="font-size: 190%">Done!</span>
          </h1>
        </div>

        <div
          v-if="!user.contactObj.inTour"
          class="my-5"
          style="text-align: center"
        >
          <hr style="background-color: white" />
          <h5>Please tell the patient:</h5>
          <br />
          <h1>Thank you,</h1>
          <h3>
            the researchers
            <br />will be in touch.
          </h3>
        </div>

        <transition name="swish">
          <section v-if="user.contactObj.inTour">
            <tour-message>
              <h4>
                Referral encrypted, transmitted, and safely received by server.
              </h4>
              <h6>The research nurse immediately receives an SMS and email.</h6>
              <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
              </div>-->
            </tour-message>
          </section>
        </transition>

        <div>
          <b-button block variant="light" size="lg" @click="onClickOk()"
            >OK</b-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import "../util/amazon.js"; // This contains the endpoint location constant
import { TRAPDOOR_WRITE } from "../util/amazon.js";
import { /* auth , */ submitReferralPartB } from "../util/firebase.js";

function rand3() {
  return (100 + Math.round(Math.random() * 899) + "").trim();
}

export default {
  props: {
    user: {
      type: Object, // This comes from the APP level. All the other props below come from the previous page (clin50 etc)
    },
    trialIdsPatientAccepted: {
      type: Array,
      default: () => ["tri~dummy-for-testing-001"],
    },
    nhsNumberString: {
      type: String,
      default:
        Math.random().toString().slice(3, 6) +
        " " +
        Math.random().toString().slice(3, 6) +
        " TEST",
    },
    recordLocation: {
      type: String,
      default:
        "The HAL-" + (Math.round(Math.random() * 100) + "").trim() + " system",
    },
    permissionLocation: {
      type: String,
      default:
        "Clinic " +
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(Math.floor(Math.random() * 26)),
    },
    contactPreferences: {
      type: String,
      default: "Tel 07" + rand3() + " " + rand3() + " " + rand3(),
    },
  },

  data: function () {
    return {
      state: "",
      progress: 0, // out of 100
      referralId: "",
      referralPassword: "",
      backoffDelayS: 5, // Current value (this gets modified)
      backoffMinS: 5, // What it gets reset to after a successful transmission
      backoffMultiplier: 1.5,
      backoffMaxS: 60,
    };
  },

  created: function () {
    if (
      this.trialIdsPatientAccepted.length > 0 &&
      typeof this.trialIdsPatientAccepted[0] === "string"
    ) {
      this.attemptReferral();
    } else {
      this.goHome();
    }
  },

  methods: {
    async attemptReferral() {
      // PART A. Contact TRAPDOOR function (AWS London) which stores NHS number and other secret stuff, and returns a unique ID and Password

      this.state = "awaitTrapdoor";
      setTimeout(() => {
        this.progress = 33;
      }, 300);

      const referralItemTrapdoor = {
        // Empty string is not allowed (!) on DynamoDB
        trialIds: this.trialIdsPatientAccepted,
        recordLocation: this.mustNotBeEmptyString(this.recordLocation),
        nhsNumberString: this.mustNotBeEmptyString(this.nhsNumberString),
        permissionLocation: this.mustNotBeEmptyString(this.permissionLocation),
        contactPreferences: this.mustNotBeEmptyString(this.contactPreferences),
      };

      while (this.state === "awaitTrapdoor" || this.state === "errorTrapdoor") {
        const trapdoorResponseAndError = await this.postRequestRtnResponseError(
          TRAPDOOR_WRITE,
          referralItemTrapdoor
        );
        if (this.state === "abandoned" /* pressing ABANDON button */) {
          return "abandoned";
        }
        if (trapdoorResponseAndError.error) {
          this.state = "errorTrapdoor";
          await this.backoffP();
        } else {
          this.backoffResetDelay();
          this.referralId = trapdoorResponseAndError.response.referralId;
          this.referralIso = trapdoorResponseAndError.response.referralIso;
          this.referralPassword =
            trapdoorResponseAndError.response.referralPassword;
          this.state = "awaitFirebase";
          this.progress = 66;
        }
      }

      // PART B. Contact Firebase cloud functions which stores the non-NHS number info and triggers any immediate SMS/email notifications

      const initialStatusObj = {};
      (this.trialIdsPatientAccepted || []).forEach((trialId) => {
        initialStatusObj[trialId] = "sta~100100";
      });

      const referralItemFirebase = {
        trialIds: this.trialIdsPatientAccepted || [
          "Error - missing trial ID(s)",
        ],
        referrerId: this.user.id || "Error - missing referrer ID",
        referrerLocationText:
          (this.user.contactObj &&
            this.user.contactObj.location &&
            this.user.contactObj.location.longName) ||
          "Referrer has not given their location",

        referralId: this.referralId || "Error - missing referral ID",
        referralPassword:
          this.referralPassword || "Error - missing referral password",
        referralIso: this.referralIso || "Error - missing referral ISO time",
        statusByTrial: initialStatusObj,
      };

      while (this.state === "awaitFirebase" || this.state === "errorFirebase") {
        try {
          await submitReferralPartB({
            referralId: this.referralId,
            referralObj: referralItemFirebase,
          });

          this.backoffResetDelay(); // Just for completeness
          this.progress = 100;
          await new Promise((resolve) =>
            setTimeout(() => resolve("done!"), 1000)
          );
          this.state = "referred";
        } catch (error) {
          console.error(error);
          this.state = "errorFirebase";
          await this.backoffP();
        }
      }
    },

    async postRequestRtnResponseError(url, data) {
      // https://gist.github.com/justsml/529d0b1ddc5249095ff4b890aad5e801
      try {
        const fetchPromise = await fetch(url, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          body: JSON.stringify(data), // Coordinate the body type with 'Content-Type'
        });
        const response = await fetchPromise.json();
        return { response, error: null };
      } catch (error) {
        console.error("error in postReq", error);
        return { response: null, error: error };
      }
    },

    mustNotBeEmptyString(s) {
      if (typeof s === "string") {
        if (s.length > 0) {
          return s;
        } else {
          return "None";
        }
      } else return "Error";
    },

    //--------------- Backoff Manager

    async backoffP() {
      this.backoffDelayS *= this.backoffMultiplier;
      if (this.backoffDelayS > this.backoffMaxS) {
        this.backoffDelayS = this.backoffMaxS;
      }
      return new Promise((resolve, reject) => {
        setTimeout(resolve, this.backoffDelayS * 1000);
        if (Math.random() > 5) reject(null); // Dummy statement to avoid ESLint complaining no reject
      });
    },

    backoffResetDelay() {
      this.backoffDelayS = this.backoffMinS;
    },

    //----------------

    onClickOk: async function () {
      if (this.user.contactObj.inTour) {
        await this.user.contactRef.update({ inTourStage: 2 });
      }
      this.goHome();
    },

    goHome: function () {
      this.$router.replace({
        name: "home",
      });
    },
  },
};
</script>

<style scoped>
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>

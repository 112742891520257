<template>
  <div
    class="p-4 d-flex flex-column justify-content-around"
    style="height:100vh; max-width:100%;"
  >
    <div
      class="d-flex flex-row justify-content-center "
      @click="$router.replace({name:'home'})"
    >
      <img
        src="../assets/logo.svg"
        style="height:20vh;"
      >
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center ">
      <h1>
        Starting
      </h1>
      <h6>
        Please<br>wait
      </h6>
      <br>
      <!-- <b-button
        variant="light"
        size="lg"
        @click="$router.replace('/')"
      >
        Home
      </b-button> -->
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.replace("/");
  }
};
</script>

<style>
</style>

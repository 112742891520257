import { YES, NO } from "../util/constants.js";

export function updateKnownCategorical(known, scienceFactObj, factCriterionObj, factId, optionId) {
  // STEP 1. Update fact
  if (!known.fact[factId]) {
    known.fact[factId] = {};
    known.fact[factId][optionId] = true;
  }

  // STEP 2. Update any other facts that are dependent on this, e.g. if they are a man, we now know they are not a woman.
  const options = scienceFactObj[factId].options;

  // Look for what is known
  let isThereATrue = false;
  Object.keys(known.fact[factId]).forEach(optionId => {
    if (known.fact[factId][optionId] === true) {
      isThereATrue = true;
    }
  });
  if (isThereATrue) {
    // set all other options to FALSE
    Object.keys(options).forEach(optionId => {
      if (!known.fact[factId][optionId] === true) {
        known.fact[factId][optionId] = false;
      }
    });
  }

  // STEP 3. Update criteria

  Object.keys(factCriterionObj[factId]).forEach(criterionId => {
    const criterion = factCriterionObj[factId][criterionId];
    const optionId = criterion.optionId;
    if (known.fact[factId].hasOwnProperty(optionId)) {
      if (known.fact[factId][optionId] === criterion.isIn) {
        known.criterion[criterionId] = { suit: YES };
      } else {
        known.criterion[criterionId] = { suit: NO };
      }
    }
  });
}

export function updateKnownContinuous(known, scienceFactObj, factCriterionObj, factId, band) {
  const pseudoValue = band.pseudoValue;

  // STEP 1. Update fact
  if (!known.fact[factId]) {
    known.fact[factId] = {};
    known.fact[factId].pseudoValue = pseudoValue;
  }

  // STEP 2. Nil

  // STEP 3. Update criteria
  Object.keys(factCriterionObj[factId]).forEach(criterionId => {
    const criterion = factCriterionObj[factId][criterionId];
    let passedSoFar = true;
    if (passedSoFar && criterion.hasOwnProperty("ul")) {
      passedSoFar = pseudoValue < criterion.ul || (pseudoValue === criterion.ul && criterion.lteq);
    }
    if (passedSoFar && criterion.hasOwnProperty("ll")) {
      passedSoFar = pseudoValue > criterion.ll || (pseudoValue === criterion.ll && criterion.gteq);
    }
    known.criterion[criterionId] = { suit: passedSoFar ? YES : NO };
  });
}

let branchBindingTable = {};

export function bindBranchAsREFandOBJandISLOADED(
  containerObject,
  keyPrefix,
  firebaseRef,
  options
) {
  //Use this to put userContact database into user.contactObj etc.
  // and then separately to put trialPublic database into trial.publicObj etc.
  // OPTIONS:
  //   addArray: true if you want an Array version as well as the usual Obj
  //   callback: function that you want executed once data loaded

  //--------------------------------
  // CONTAINEROBJECT is either this.user or this.trial

  // "keyPrefix" can be, for example, "status"
  // That would create in the "this.user" object, the following:
  //   this.user.statusRef    which would be the firebase Ref for the status
  //   this.user.statusObj    which would be the firebase-bound status
  //   this.user.statusIsLoaded    which would be true once read from firebase

  // Step 1. Open up the pathway to that point in the table

  if (!branchBindingTable) {
    branchBindingTable = {};
  }

  // Step 2. If this variable is already bound to a firebase node, unbind
  if (branchBindingTable[keyPrefix]) {
    // If this keyPrefix is already bound to a firebase node, unbind it. Otherwise updates to the OLD node will wrongly cause themselves to be read in and trigger an incorrect "update" to the new node, i.e. overwrite your new patient's data with the previous patient's data (just because the previous patient happened to update their data)
    branchBindingTable[keyPrefix].off();
    containerObject[keyPrefix + "IsLoaded"] = false;
  }

  // Step 3. Now bind to the new firebase node and store the three things, "REF","OBJ" itself, and "ISLOADED".
  if (firebaseRef) {
    // ... if NULL, then leave it unbinded
    branchBindingTable[keyPrefix] = firebaseRef;
    containerObject[keyPrefix + "Ref"] = firebaseRef;

    firebaseRef.on("value", snapshot => {
      // Every time firebase value changes (including the first where firebase just comes live) this will cause an Object.assign, causing a re-render of the object in this and the children components.

      let newKeyValuePairOrPairs = {};
      let newValueAsObj = snapshot.val() || {};
      newKeyValuePairOrPairs[keyPrefix + "Obj"] = newValueAsObj;
      if (options && options.addArray) {
        const newValueAsArray = Object.keys(newValueAsObj).map(key => ({
          ...newValueAsObj[key],
          id: key
        }));
        newKeyValuePairOrPairs[keyPrefix + "Array"] = newValueAsArray;
      }
      Object.assign(containerObject, newKeyValuePairOrPairs);

      containerObject[keyPrefix + "IsLoaded"] = true;
      if (options && options.callback) {
        options.callback();
      }
    });
  }
}

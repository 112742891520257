var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3 d-flex flex-column",staticStyle:{"height":"100vh","max-width":"100%"}},[_c('app-header-bar',{attrs:{"title":"HQ Edit User"}}),_c('section',{staticClass:"iphone-scroll-fudge",staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},attrs:{"id":"middle-scrollable"}},[_c('b-form',[_c('h5',[_vm._v(_vm._s(_vm.targetUser.contactObj.fullName || ""))]),_c('br'),_c('h6',[_vm._v("Email")]),_c('h5',[_vm._v(" "+_vm._s(_vm.targetUser.presenceObj.emailAtLastLogin || "No email address")+" ")]),(
          _vm.targetUser.presenceObj.emailAtLastLogin &&
          !_vm.targetUser.contactObj.emailVerified
        )?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('h3',[_vm._v("Awaiting email verification")]),_c('hr'),_c('h6',[_vm._v(" Link has been emailed to user. If they can't find it, ask them to click \"Send New Link\" on their App. ")])])],1):_vm._e(),_c('br'),_c('h6',[_vm._v(" Title and Name "),_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v("in conventional full form")])]),_c('b-form-input',{attrs:{"type":"text","required":"","placeholder":"e.g. Dr Jane Smith or Sister Ong Chen-Li"},on:{"change":function($event){_vm.targetUser.contactRef
            .child('fullName')
            .set(_vm.targetUser.contactObj.fullName)}},model:{value:(_vm.targetUser.contactObj.fullName),callback:function ($$v) {_vm.$set(_vm.targetUser.contactObj, "fullName", $$v)},expression:"targetUser.contactObj.fullName"}}),_c('br'),_c('h6',[_vm._v(" Mobile phone "),_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v("for identity confirmation")])]),_c('b-form-input',{attrs:{"type":"text","required":"","placeholder":"e.g. +44 7000 000 000"},on:{"change":function($event){_vm.targetUser.contactRef
            .child('mobileSelf')
            .set(_vm.formatMobile(_vm.targetUser.contactObj.mobileSelf))}},model:{value:(_vm.targetUser.contactObj.mobileSelf),callback:function ($$v) {_vm.$set(_vm.targetUser.contactObj, "mobileSelf", $$v)},expression:"targetUser.contactObj.mobileSelf"}}),_c('br'),_c('h6',[_vm._v(" Administrative contact tel "),_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v("in case difficulty tracing patient info")]),_vm._v(" (Optional) ")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"e.g. Clinic reception phone number"},on:{"change":function($event){_vm.targetUser.contactRef
            .child('adminTel')
            .set(_vm.targetUser.contactObj.adminTel)}},model:{value:(_vm.targetUser.contactObj.adminTel),callback:function ($$v) {_vm.$set(_vm.targetUser.contactObj, "adminTel", $$v)},expression:"targetUser.contactObj.adminTel"}}),_c('br'),(_vm.targetUser.contactObj.adminTel)?_c('div',[_c('h6',[_vm._v(" Name of administrative contact "),_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v("if anyone in particular covers your patients")]),_vm._v(" (Optional) ")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"e.g. Chris"},on:{"change":function($event){_vm.targetUser.contactRef
              .child('adminWho')
              .set(_vm.targetUser.contactObj.adminWho)}},model:{value:(_vm.targetUser.contactObj.adminWho),callback:function ($$v) {_vm.$set(_vm.targetUser.contactObj, "adminWho", $$v)},expression:"targetUser.contactObj.adminWho"}}),_c('br')],1):_vm._e()],1),(
        _vm.targetUser.contactIsLoaded &&
        (!_vm.targetUser.contactObj.fullName ||
          _vm.targetUser.contactObj.fullName.length < 7)
      )?_c('div',[_c('h6',[_vm._v("They haven't entered their name.")])]):_vm._e(),_c('div',[_vm._v("Full User ID")]),_c('div',{staticStyle:{"color":"white","user-select":"all","text-align":"right","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.id)+" ")]),_c('br'),_c('hr',{staticStyle:{"border-color":"white"}}),_c('br'),_c('b-form',[(_vm.targetUser.privilegesObj.manager)?_c('div',[_c('h4',[_vm._v("This person is a manager")])]):_c('div',[_c('h4',[_vm._v("To make this person a manager")]),_c('p',[_vm._v("Go to \"Manager Home\", \"Manage Research Managers\"")])]),(_vm.targetUser.privilegesObj.researcher)?_c('div',[_c('h4',[_vm._v("This person is a researcher")])]):_c('div',[_c('h4',[_vm._v("To make this person a researcher")]),_c('p',[_vm._v(" They automatically become a researcher (i.e. able to access \"Researcher Home\") when someone adds them as a researcher to a trial. Usually their manager will do this."),_c('br'),_vm._v("If you want to do it yourself at HQ, go to \"Manager Home\", \"Manage Access and Notifications Trial-by-Trial\", select the trial and add the person. ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export function getNewest() {
  // Get latest version of this App
  // Used by autoupdate and also to allow elective manual updating

  // v1,
  // This gets caught up in the PwA Service Worker cache, so it goes into continuous reload loop on Mobile Chrome (but not desktop)
  // window.location.reload(true);

  // v2, go to same page, with cacheBusting. Problem is that sometimes this is an error if missing data
  // const oldLocationHref = window.location.href;
  // const locationHrefNoCb = oldLocationHref.split("?")[0];
  // const randHex = Math.round(Math.random() * 2 ** 32).toString(16);
  // const newLocationHref = locationHrefNoCb + "?cB=" + randHex;
  // window.location.href = newLocationHref;

  // v3, alsways go home
  const locationHrefNoCb = window.location.origin + "/";
  const randHex = Math.round(Math.random() * 2 ** 32).toString(16);
  const newLocationHref = locationHrefNoCb + "?cB=" + randHex;
  window.location.href = newLocationHref + "";
}

<template>
  <div
    style="height: 100vh"
    class="p-2 d-flex flex-column justify-content-between"
  >
    <app-header-bar title="Enrol"></app-header-bar>

    <section
      id="middleScrolling"
      class="iphone-scroll-fudge"
      style="height: 100%; overflow-y: auto"
    >
      <transition name="swish">
        <article v-if="!trialRandomAllocation">
          <h4 class="mx-3 pt-3"></h4>
          <div
            class="m-3 d-flex flex-row align-items-center justify-content-center"
          >
            <img
              src="../assets/c19-acs-trial-logo.svg"
              style="width: 12em; height: 12em; border-radius: 1em"
            />
          </div>
        </article>
      </transition>

      <transition name="swish">
        <article
          id="PIS/Consent"
          v-if="(eligibility.undecided || eligibility.yes) && !allPagesUploaded"
          class="p-3"
          align="center"
        >
          Obtain informed consent.
          <br />Patient information sheet <br />and consent form are at:
          <h4 class="pt-2">c19acs.com</h4>
        </article>
      </transition>

      <transition name="swish">
        <article
          id="PIS/Consent"
          v-if="
            (eligibility.undecided || eligibility.yes) &&
            !allPagesUploaded &&
            !cameraTestPassed
          "
          class="p-3 d-flex flex-column align-items-center"
        >
          <div
            id="test camera container"
            :style="
              'position: relative; top:0; left: 0; width:11em; height: 4em;overflow:hidden; border:2px solid white; border-radius: 0.5em;' +
              (cameraTestPassed ? '' : 'color:black;background-color:white;')
            "
          >
            <div class="d-flex align-items-center">
              <svg
                class="mr-2 ion ion-ios-camera"
                style="width: 4em; height: 4em"
              >
                <use xlink:href="#ion-ios-camera" />
              </svg>

              <div>
                <span v-if="cameraTestPassed">Retest</span>
                <span v-else>Test the</span>
                <br />camera
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              @click="
                cameraTestHappened = true;
                cameraTestPassed = null;
              "
              @change="
                cameraTestHappened = true;
                cameraTestPassed = null;
              "
              style="
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;
                font-size: 4em;
                opacity: 0;
              "
            />
          </div>

          <transition name="swish">
            <div
              v-if="cameraTestHappened && cameraTestPassed === null"
              class="pt-5"
            >
              <h6>Did the camera work, or did it show a black screen?</h6>

              <div class="d-flex flex-row align-items-center">
                <b-button
                  variant="light"
                  class="mr-2 mb-1 px-2"
                  style="min-height: 4.5em; min-width: 7em"
                  @click="cameraTestPassed = true"
                  >Worked</b-button
                >

                <b-button
                  variant="dark"
                  class="mb-1 px-2"
                  style="min-height: 4.5em; min-width: 7em"
                  @click="cameraTestPassed = false"
                  >Black screen</b-button
                >
              </div>
            </div>
          </transition>

          <transition name="swish">
            <b-alert
              show
              v-if="cameraTestPassed === false"
              variant="warning"
              class="mt-2"
            >
              <div v-if="device.os.name === 'iOS'">
                <h1>iPhone or iPad camera fix</h1>
                <h6>
                  Go to Settings, choose Safari, and set "Camera" to "Allow".
                </h6>
                <h6>
                  If still black, it is a known iOS 13 bug which loses the
                  camera link when app goes to background.
                </h6>
                <h6>Apple are fixing this, but for now:</h6>
                <ul>
                  <li>
                    Close app by double clicking Home button
                    <strong>and swiping app up</strong>.
                  </li>
                  <li>Reopen app from home screen</li>
                </ul>
              </div>

              <div v-if="device.os.name === 'Android'">
                <h1>Camera fix for Android</h1>
                <h6>
                  Go to Settings, Apps, Chrome, and in Permissions set "Camera"
                  to "Allow".
                </h6>
              </div>

              <div v-if="!['iOS', 'Android'].includes(device.os.name)">
                <h1>Incompatible mobile device</h1>
                <h4>Please use an iPhone, iPad or Android device.</h4>
                <h4>
                  This app may not work correctly on this
                  {{ device.os.name }} {{ device.os.version }} device running
                  {{ device.browser.name }} {{ device.browser.version }}.
                </h4>
              </div>
            </b-alert>
          </transition>
        </article>
      </transition>

      <transition name="swish">
        <article
          id="Identity, Location"
          v-if="eligibility.undecided && !identityAndLocationConfirmed"
          class="p-3"
        >
          <h6 class="pt-5">
            Please confirm that you are {{ user.contactObj.fullName }}, using
            this device at {{ user.contactObj.location.longName }}.
          </h6>

          <div class="d-flex flex-row align-items-center">
            <b-button
              variant="light"
              class="mr-1 mb-1 px-2"
              style="min-height: 4.5em; min-width: 5em"
              @click="identityAndLocationConfirmed = true"
              >Yes</b-button
            >

            <b-button
              variant="outline-light"
              class="mr-1 mb-1 px-2"
              size="sm"
              @click="trySignOut"
            >
              No, I'm
              <br />someone <br />else
            </b-button>

            <b-button
              variant="outline-light"
              size="sm"
              class="mr-1 mb-1 px-2"
              @click="goMyLocation"
            >
              No, I'm
              <br />somewhere <br />else
            </b-button>
          </div>
        </article>
      </transition>

      <transition name="swish" class="p-3">
        <article
          id="Patient characteristics"
          v-if="
            eligibility.undecided &&
            cameraTestPassed &&
            identityAndLocationConfirmed
          "
        >
          <h3>Checking patient eligibility</h3>
          <transition name="swish">
            <b-card v-if="!characteristic.covid">
              <div class="d-flex flex-row justify-content-between">
                <h4>Clinical diagnosis of COVID-19?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.covid = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.covid = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card
              v-if="
                !characteristic.dxImaging &&
                characteristic.dxLaboratory !== 'yes' &&
                characteristic.dxLymphopaenia !== 'yes'
              "
            >
              <div class="d-flex flex-row justify-content-between">
                <h4>CT or CXR evidence of Covid?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxImaging = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxImaging = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card
              v-if="
                characteristic.dxImaging !== 'yes' &&
                !characteristic.dxLaboratory &&
                characteristic.dxLymphopaenia !== 'yes'
              "
            >
              <div class="d-flex flex-row justify-content-between">
                <h4>Laboratory evidence of Covid?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxLaboratory = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxLaboratory = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card
              v-if="
                characteristic.dxImaging !== 'yes' &&
                characteristic.dxLaboratory !== 'yes' &&
                !characteristic.dxLymphopaenia
              "
            >
              <div class="d-flex flex-row justify-content-between">
                <h4>Lymphopaenia?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxLymphopaenia = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.dxLymphopaenia = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.inpatient">
              <div class="d-flex flex-row justify-content-between">
                <h4>Needs inpatient care?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.inpatient = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.inpatient = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.sex">
              <div class="d-flex flex-row justify-content-between">
                <h4>Sex</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="
                    characteristic.sex = 'male';
                    characteristic.pregnant = 'no';
                  "
                  >Male</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.sex = 'female'"
                  >Female</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.ageConfirmed">
              <h4>Age</h4>
              <b-input-group>
                <b-form-input
                  v-model="characteristic.ageYearsString"
                  type="number"
                  @keyup.enter.native="characteristic.ageConfirmed = true"
                ></b-form-input>
                <b-input-group-append is-text>years</b-input-group-append>
              </b-input-group>
              <transition name="swish">
                <b-alert
                  show
                  v-if="parseFloat(characteristic.ageYearsString) > 150"
                  variant="danger"
                  >Please check the age you have typed.</b-alert
                >
              </transition>

              <transition name="swish">
                <div
                  v-if="
                    characteristic.ageYearsString &&
                    parseFloat(characteristic.ageYearsString) <= 150
                  "
                  class="d-flex justify-content-end"
                >
                  <b-button
                    variant="primary"
                    class="mt-2"
                    style="min-width: 10em"
                    @click="characteristic.ageConfirmed = true"
                    >OK</b-button
                  >
                </div>
              </transition>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.pregnant">
              <div class="d-flex flex-row justify-content-between">
                <h4 class="mr-3">Pregnant</h4>
                <div>Women of childbearing age need a pregnancy test.</div>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.pregnant = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.pregnant = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.diabetes">
              <div class="d-flex flex-row justify-content-between">
                <h4>Diabetes?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.diabetes = 'yes'"
                  >Diabetes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.diabetes = 'no'"
                  >No diabetes</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.hypertension">
              <div class="d-flex flex-row justify-content-between">
                <h4>Known hypertension?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.hypertension = 'yes'"
                  >Known hypertension</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.hypertension = 'no'"
                  >No known hypertension</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.coronary">
              <div class="d-flex flex-row justify-content-between">
                <h4>Known coronary artery disease?</h4>
                <div>
                  Previous stent, CABG, myocardial infarction, or treatment for
                  angina
                </div>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.coronary = 'yes'"
                  >Known CAD</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.coronary = 'no'"
                  >No known CAD</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.acs">
              <div class="d-flex flex-row justify-content-between">
                <h4>
                  Evidence of pathology requiring treatment for acute coronary
                  syndrome?
                </h4>
                <small>Exclusion criterion</small>
              </div>
              <div class="d-flex flex-row">
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.acs = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.acs = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.myocarditis">
              <div class="d-flex flex-row justify-content-between">
                <h4>
                  Clear evidence of myo-pericarditis that requires specific
                  treatment precluding randomization?
                </h4>
                <small>Exclusion criterion</small>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.myocarditis = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.myocarditis = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>
        </article>
      </transition>

      <transition name="swish">
        <article id="NHS number" v-if="eligibility.yes">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-column">
              <ClinEnterId
                :user="user"
                @updatePatientIdAndValidationState="
                  updatePatientIdAndValidationState
                "
              ></ClinEnterId>
            </div>
          </div>
        </article>
      </transition>

      <article id="Not eligible" v-if="eligibility.no" class="py-5">
        <div class="d-flex flex-row justify-content-center">
          <h3 style="max-width: 75%">
            Sorry, patient
            <br />is not eligible.
          </h3>
        </div>
      </article>

      <transition name="swish">
        <article
          id="Consent"
          v-if="
            patientIdValidationState === 'valid' &&
            !trialRandomAllocation &&
            !showRandomisationSpinner &&
            !trialRandomisationErrorOccurred
          "
          class="pt-3"
        >
          Photograph the signed consent form. Ensure lighting is good and the
          text is legible.
          <div
            v-for="pageBase0 in [0, 1, 2]"
            :key="pageBase0"
            class="mt-2 d-flex flex-row justify-content-center align-items-center"
          >
            <div
              id="nice Upload button container"
              :style="
                'position: relative; top:0; left: 0; width:11em; height: 4em;overflow:hidden; border:2px solid white; border-radius: 0.5em;' +
                (pageUploaded[pageBase0]
                  ? ''
                  : 'color:black;background-color:white;')
              "
            >
              <div class="d-flex align-items-center">
                <svg
                  class="mr-2 ion ion-ios-camera"
                  style="width: 4em; height: 4em"
                >
                  <use xlink:href="#ion-ios-camera" />
                </svg>

                <div>
                  <span v-if="pageUploaded[pageBase0]">Retake</span>
                  <span v-else>Take</span>
                  <br />
                  <span v-if="pageBase0 > 0">Page {{ pageBase0 }}</span>
                  <span v-else>Cover page</span>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                @change="onPhotoArrived($event, pageBase0)"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  margin: 0;
                  padding: 0;
                  font-size: 4em;
                  opacity: 0;
                "
              />
            </div>
            <div
              class="ml-4"
              :style="
                'font-size:300%; visibility:' +
                (pageUploaded[pageBase0] ? 'visible' : 'hidden')
              "
            >
              ✓
            </div>
          </div>
        </article>
      </transition>

      <transition name="swish">
        <article
          v-if="
            allPagesUploaded &&
            trialRandomAllocation === null &&
            !showRandomisationSpinner &&
            !trialRandomisationErrorOccurred
          "
          id="Randomise"
          class="pt-5 mx-3"
        >
          <h1 align="center">Thank you</h1>
          <h5>Your answers indicate that the patient is eligible.</h5>
          <div
            class="py-4 d-flex flex-column justify-content-center align-items-center"
          >
            <transition name="swish">
              <b-button variant="warning" size="lg" @click="onRandomise"
                >Enrol and randomise</b-button
              >
            </transition>
          </div>
        </article>
      </transition>

      <Spinner v-if="showRandomisationSpinner" class="mh-50 mw-50"></Spinner>

      <transition name="swish">
        <article v-if="trialRandomAllocation" id="Allocation" class="py-3 mx-3">
          <b-card variant="light" class="mb-5">
            <h5 align="center">
              Your patient is enrolled with Trial Patient Identification Number:
            </h5>
            <h1 align="center" class="py-3">{{ trialRandomisationId }}</h1>
            <h5 align="center">and has been allocated to:</h5>
            <h1 align="center" class="py-3">
              {{ trialRandomAllocation.toUpperCase() }}
            </h1>
          </b-card>
          <h5>
            Write the Trial Patient Identification Number on the consent form,
            and keep it for collection by the research team.
          </h5>
          <div v-if="trialRandomAllocation === 'active'">
            <h5 class="mt-4">
              If not on aspirin, add
              <span class="drug">aspirin</span> 300mg loading and then 75mg once
              daily, unless contraindicated.
            </h5>

            <h5 class="mt-4">
              If not on clopidogrel, or equivalent e.g. ticagrelor or prasugrel,
              add
              <span class="drug">clopidogrel</span> 300mg loading and then 75mg
              once daily, unless contraindicated.
            </h5>

            <h5 class="mt-4">
              If not on anticoagulation, add
              <span class="drug">rivaroxaban 2.5mg twice daily</span> unless
              contraindicated. If already clinically on another DOAC then change
              to rivaroxaban 15mg once a day.
            </h5>

            <h5 class="mt-4">
              If not on a statin, add
              <span class="drug">atorvastatin 80mg</span> once daily unless
              contraindicated.
            </h5>

            <h5 class="mt-4">
              If not on a proton pump inhibitor, add
              <span class="drug">omeprazole 20mg</span> or
              <span class="drug">lansoprazole 30mg</span> once daily.
            </h5>
          </div>

          <h5 class="mt-5" align="center">Thank you</h5>
        </article>
      </transition>

      <article
        v-if="trialRandomisationErrorOccurred"
        id="Allocation"
        class="py-3 mx-3"
      >
        <b-card>
          <h1>Sorry</h1>
          <h5>There is a problem with the randomisation system.</h5>
          <h5>
            Please call the emergency software response team on 07973 105 394.
          </h5>
          <h5>"{{ trialRandomisationErrorMessage }}".</h5>
        </b-card>
      </article>

      <article
        v-if="!trialRandomisationErrorOccurred"
        id="help"
        class="m-3 mt-5 d-flex flex-column align-items-center"
      >
        <h6 align="center" style="font-size: 120%">
          <span style="font-size: 102%">If unsure or need advice,</span>
          <br />please call research team
        </h6>
        <h2 v-if="user.contactObj.location.type === 'international'">
          +55 11 5085-6204
        </h2>
        <h2 v-else>07776 224 520</h2>
        <b-button variant="success" href="tel:+447776224520">
          <svg class="ion ion-ios-telephone" style="min-width: 12.5em">
            <use xlink:href="#ion-ios-telephone" />
          </svg>
        </b-button>
      </article>

      <hr />
      <div v-if="1" class="d-flex flex-row justify-content-end">
        <b-button variant="outline-dark" size="sm" @click="debugFillEligible()">
          FoundByMe Ltd
          <br />Testing mode
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import ClinEnterId from "./ClinEnterId.vue";
import Spinner from "./ext/Spinner.vue";
import { auth, enrolmentFirebaseStorage, onEnrol } from "../util/firebase.js";
import * as bowser from "bowser";

export default {
  name: "EnrolTrialC19Acs",
  components: { AppHeaderBar, Spinner, ClinEnterId },
  props: {
    user: {
      type: Object,
      required: true, // We INSIST on being given an object, but we still give a default value, to make Vue walk the tree
      default() {
        return {
          contactObj: { fullName: undefined },
        };
      },
    },
  },
  data() {
    return {
      // Actual Data
      device: {},
      cameraTestHappened: null,
      cameraTestPassed: null,
      trialCodeForDocumentUpload: "c-19-acs",
      identityAndLocationConfirmed: false,
      characteristic: {
        covid: null,
        dxImaging: null,
        dxLaboratory: null,
        dxLymphopaenia: null,

        inpatient: null,
        sex: null,
        diabetes: null,
        coronary: null,
        hypertension: null,
        ageYearsString: null,
        ageConfirmed: false,
        acs: null,

        myocarditis: null,
        plateletsGramsPerNanolitreString: null,
        plateletsConfirmed: false,
        hbGramsPerDecilitreString: null,
        hbConfirmed: false,
        dic: null,
        recentSurgery: null,
        historyGiBleed: null,
        bleeding: null,
        pregnant: null,
      },
      patientIdString: "", //  either "123 456 7890" or "BR:XYZHOSP:09FFDSG8769FSD7869876"
      patientIdValidationState: false,
      pageUploaded: [false, false, false],
      pagePath: ["", "", ""],
      showCameraHelp: false,
      showRandomisationSpinner: false,
      trialRandomAllocation: null,
      trialRandomisationId: null,
      trialRandomisationErrorOccurred: false,
      trialRandomisationErrorCode: null, // a number
      trialRandomisationErrorType: "", // short capitalised string
      trialRandomisationErrorMessage: "", // long sentence
      // Making global consts and functions available to THIS
    };
  },
  computed: {
    eligibility: function () {
      const tCh = this.characteristic;

      const yes =
        // Inclusion
        tCh.covid === "yes" &&
        (tCh.dxImaging === "yes" ||
          tCh.dxLaboratory === "yes" ||
          tCh.dxLymphopaenia === "yes") &&
        tCh.inpatient === "yes" &&
        (parseFloat(tCh.ageYearsString) >= 40 ||
          tCh.diabetes === "yes" ||
          tCh.coronary === "yes" ||
          tCh.hypertension === "yes") &&
        // Exclusion
        tCh.acs === "no" &&
        tCh.myocarditis === "no" &&
        tCh.pregnant === "no" &&
        parseFloat(tCh.ageYearsString) >= 1 &&
        parseFloat(tCh.ageYearsString) <= 999 && // -- AND has non-null values in features needed for minimisation
        tCh.sex &&
        tCh.diabetes &&
        tCh.coronary;
      const no =
        // Inclusion
        tCh.covid === "no" ||
        (tCh.dxImaging === "no" &&
          tCh.dxLaboratory === "no" &&
          tCh.dxLymphopaenia === "no" &&
          tCh.inpatient === "no") ||
        (parseFloat(tCh.ageYearsString) < 40 &&
          tCh.diabetes === "no" &&
          tCh.coronary === "no" &&
          tCh.hypertension === "no") ||
        // Exclusion
        tCh.acs === "yes" ||
        tCh.myocarditis === "yes" ||
        tCh.pregnant === "yes" ||
        (tCh.ageConfirmed && parseFloat(tCh.ageYearsString) < 1) ||
        (tCh.ageConfirmed && parseFloat(tCh.ageYearsString) > 999);

      const undecided = !(yes || no);

      return { yes, no, undecided };
    },

    allPagesUploaded: function () {
      return this.pageUploaded.every((x) => !!x);
    },
  },
  created() {},
  mounted() {
    const parser = bowser.getParser(window.navigator.userAgent);
    this.device = parser.parse().parsedResult;
  },

  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    onRandomise() {
      this.showRandomisationSpinner = true;

      onEnrol({
        nhsNumberString: this.patientIdString,
        userId: this.user.id,
        characteristic: this.characteristic,
        email: this.user.fromAuth.email,
        mobile: this.user.contactObj.mobileSelf,
        locationShortName: this.user.contactObj.location.shortName,
        locationLongName: this.user.contactObj.location.longName,
        documentScanPaths: this.pagePath,
      })
        .then((thingContainingData) => {
          const data = thingContainingData.data;
          console.log("Raw data", data);
          if (data.success) {
            this.trialRandomAllocation = data.arm;
            this.trialRandomisationId = data.randomisation_id;
          } else {
            this.trialRandomisationErrorOccurred = true;
            this.trialRandomisationErrorCode = data.errorcode;
            this.trialRandomisationErrorType = data.errortype;
            this.trialRandomisationErrorMessage = data.errorinfo;
          }
        })
        .finally(() => {
          this.showRandomisationSpinner = false;
        });
    },

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    updatePatientIdAndValidationState(emission) {
      this.patientIdString = emission.nhsOrSiteAndLocalId || "";
      this.patientIdValidationState =
        emission.patientIdValidationState || false;
    },

    trySignOut() {
      auth.signOut();
    },

    debugFillEligible() {
      this.characteristic = {
        covid: "yes",
        dxImaging: null,
        dxLaboratory: "yes",
        dxLymphopaenia: null,
        inpatient: "yes",
        sex: "male",
        diabetes: "no",
        coronary: "yes",
        hypertension: "yes",
        ageYearsString: "55",
        ageConfirmed: true,
        acs: "no",
        myocarditis: "no",
        pregnant: "no",
      };
      // this.patientIdString = "0000000000";
      this.pageUploaded = this.pageUploaded.map((x) => x || true);
      this.pagePath = this.pagePath.map((x) => x + "");
    },

    async onPhotoArrived(evt, pageBase0) {
      evt.preventDefault();
      const file = evt.target.files[0];

      const timeComponent = new Date()
        .toISOString()
        .replace("T", " ")
        .replace(/:/g, "-")
        .replace(".", "p");
      const randomComponent =
        Math.random().toString(36).substr(2) +
        "-" +
        Math.random().toString(36).substr(2);
      const uniqueFilename = timeComponent + "~" + randomComponent;
      const despacedPatientIdString = this.patientIdString.replace(/ /g, "");
      try {
        const storagePathAndFilename = `trial/${this.trialCodeForDocumentUpload}/patient/${despacedPatientIdString}/pageBase0/${pageBase0}/${uniqueFilename}`;
        const ref = enrolmentFirebaseStorage.ref(storagePathAndFilename);
        await ref.put(file);
        this.$set(this.pageUploaded, pageBase0, true);
        this.$set(this.pagePath, pageBase0, storagePathAndFilename);
      } catch (err) {
        console.error(err);
      }
    },

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goMyLocation() {
      this.$router.push({
        name: "myLocation",
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    // iPhone close full screen

    exitFullScreen(element) {
      var requestMethod =
        element.exitFullscreen ||
        element.mozCancelFullScreen ||
        element.webkitExitFullscreen ||
        element.msExitFullscreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else {
        console.log("Oops. Request method false.");
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
  color: black;
}

.drug {
  font-weight: 700;
  font-size: 150%;
}
</style>

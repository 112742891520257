import { render, staticRenderFns } from "./ResReferralAll.vue?vue&type=template&id=28124744"
import script from "./ResReferralAll.vue?vue&type=script&lang=js"
export * from "./ResReferralAll.vue?vue&type=script&lang=js"
import style0 from "./ResReferralAll.vue?vue&type=style&index=0&id=28124744&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="p-2 ">

    <app-header-bar title="HQ Edit Users"></app-header-bar>

    <section class="page-scroll-y">
      <b-table
        striped
        :items="usersArray"
        :fields="fields"
        @row-clicked="goUser"
      ></b-table>

    </section>

  </div>
</template>

<script>
import { bindBranchAsREFandOBJandISLOADED } from "../util/firebaseBind";
import {
  contactRootRef,
  privilegesRootRef,
  presenceRootRef
} from "../util/firebase";
import AppHeaderBar from "./AppHeaderBar.vue";
const windowInnerWidthPx = window.innerWidth; // 320 for iPhone 4
const windowInnerWidthChar = windowInnerWidthPx / 8; // VERY rough!

export default {
  components: { AppHeaderBar },
  data() {
    return {
      // for holding the 3 pieces
      usersFragment: {},
      // Then combined for use in the component
      usersObj: {},
      usersArray: [],
      fields: [
        {
          key: "emailAtLastLogin",
          label: "Email",
          sortable: true,
          class: "email"
        },
        {
          key: "fullName",
          label: "Name",
          sortable: true,
          class: "full-name"
        },
        {
          key: "locationLongNameCopy",
          label: "Says is at",
          sortable: true,
          formatter: this.generateTruncateN(1.5 * windowInnerWidthChar),
          class: "trust"
        },
        {
          key: "researcher",
          label: "Researcher",
          sortable: true,
          class: "researcher"
        },
        { key: "manager", label: "Manager", sortable: true, class: "manager" }
      ]
    };
  },

  created() {
    bindBranchAsREFandOBJandISLOADED(
      this.usersFragment,
      "contact",
      contactRootRef,
      {
        callback: this.createMingledUserArray
      }
    );
    bindBranchAsREFandOBJandISLOADED(
      this.usersFragment,
      "privileges",
      privilegesRootRef,
      { callback: this.createMingledUserArray }
    );
    bindBranchAsREFandOBJandISLOADED(
      this.usersFragment,
      "presence",
      presenceRootRef,
      {
        callback: this.createMingledUserArray
      }
    );
  },
  beforeDestroy() {
    //   bindBranchAsREFandOBJandISLOADED(this.users, "contact", null);
  },
  methods: {
    /** Call this when you update (or load for the first time) any of the USER database pieces, so the combined one can be made */
    createMingledUserArray() {
      if (
        this.usersFragment &&
        this.usersFragment.contactIsLoaded &&
        this.usersFragment.privilegesIsLoaded &&
        this.usersFragment.presenceIsLoaded
      ) {
        // Only do it if all 3 pieces are present, avoiding having to do it 3 times at startup
        const ids = Object.keys(this.usersFragment.contactObj);
        this.usersArray = ids.map(id => ({
          id,
          ...this.usersFragment.contactObj[id],
          ...this.usersFragment.privilegesObj[id],
          ...this.usersFragment.presenceObj[id],
          locationLongNameCopy: (
            this.usersFragment.contactObj[id].location || {}
          ).longName /* flattened here so can be displayed in b-table */
        }));
        this.usersObj = {};
        this.usersArray.forEach(user => {
          this.usersObj[user.id] = user;
        });
        this.usersIsLoaded = true;
      }
    },

    generateTruncateN(maxLen = 30) {
      return function(text) {
        return (text || "").slice(0, maxLen);
      };
    },

    goBack() {
      this.$router.back();
    },

    goUser(user) {
      this.$router.push({ name: "editUser", params: { id: user.id } });
    }
  }
};
</script>

<style>
.full-name,
.trust,
.email,
.researcher,
.manager {
  font-size: 70%;
  max-width: 30vw;
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>

<template>
  <div
    style="height: 100vh"
    class="p-2 d-flex flex-column justify-content-between"
  >
    <app-header-bar title="Enrol"></app-header-bar>

    <section
      id="middleScrolling"
      class="iphone-scroll-fudge"
      style="height: 100%; overflow-y: auto"
    >
      <transition name="swish">
        <article v-if="!trialRandomAllocation">
          <h1 class="pt-3 d-flex justify-content-center">Tele-ACS</h1>
        </article>
      </transition>

      <transition name="swish">
        <article
          id="Identity, Location"
          v-if="eligibility.undecided && !identityAndLocationConfirmed"
          class="p-3 d-flex justify-content-center"
        >
          <div
            class="mt-5"
            style="max-width: 30em; background-color: #ffffff10; padding: 1em"
          >
            <h6>
              Please confirm that you are {{ user.contactObj.fullName }}, using
              this device at {{ user.contactObj.location.longName }}.
            </h6>

            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <b-button
                variant="light"
                class="mr-1 mb-1 px-2"
                style="min-height: 4.5em; min-width: 5em"
                @click="identityAndLocationConfirmed = true"
                >Yes</b-button
              >

              <b-button
                variant="outline-light"
                class="mr-1 mb-1 px-2"
                size="sm"
                @click="trySignOut"
              >
                No, I'm
                <br />someone <br />else
              </b-button>

              <b-button
                variant="outline-light"
                size="sm"
                class="mr-1 mb-1 px-2"
                @click="goMyLocation"
              >
                No, I'm
                <br />somewhere <br />else
              </b-button>
            </div>
          </div>
        </article>
      </transition>

      <transition name="swish" class="p-3">
        <article
          id="Patient characteristics"
          v-if="identityAndLocationConfirmed && eligibility.undecided"
        >
          <h3>Patient characteristics</h3>
          <transition name="swish">
            <b-card v-if="!characteristic.sex">
              <div class="d-flex flex-row justify-content-between">
                <h4>Sex</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="
                    characteristic.sex = 'male';
                    characteristic.pregnant = 'no';
                  "
                  >Male</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.sex = 'female'"
                  >Female</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.ageConfirmed">
              <h4>Age</h4>
              <b-input-group>
                <b-form-input
                  v-model="characteristic.ageYearsString"
                  type="number"
                  @keyup.enter.native="characteristic.ageConfirmed = true"
                ></b-form-input>
                <b-input-group-append is-text>years</b-input-group-append>
              </b-input-group>
              <transition name="swish">
                <b-alert
                  show
                  v-if="parseFloat(characteristic.ageYearsString) > 150"
                  variant="danger"
                  >Please check the age you have typed.</b-alert
                >
              </transition>

              <transition name="swish">
                <div
                  v-if="
                    characteristic.ageYearsString &&
                    parseFloat(characteristic.ageYearsString) <= 150
                  "
                  class="d-flex justify-content-end"
                >
                  <b-button
                    variant="primary"
                    class="mt-2"
                    style="min-width: 10em"
                    @click="characteristic.ageConfirmed = true"
                    >OK</b-button
                  >
                </div>
              </transition>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.diabetes">
              <div class="d-flex flex-row justify-content-between">
                <h4>Diabetes?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.diabetes = 'yes'"
                  >Diabetes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.diabetes = 'no'"
                  >No diabetes</b-button
                >
              </div>
            </b-card>
          </transition>

          <transition name="swish">
            <b-card v-if="!characteristic.prevrevasc">
              <div class="d-flex flex-row justify-content-between">
                <h4>Previous revascularisation?</h4>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-5 mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.prevrevasc = 'yes'"
                  >Yes</b-button
                >
                <b-button
                  variant="primary"
                  class="mb-1 p-2"
                  style="min-width: 6em"
                  @click="characteristic.prevrevasc = 'no'"
                  >No</b-button
                >
              </div>
            </b-card>
          </transition>
        </article>
      </transition>

      <transition name="swish">
        <article id="NHS number" v-if="eligibility.yes">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-column">
              <ClinEnterId
                :user="user"
                @updatePatientIdAndValidationState="
                  updatePatientIdAndValidationState
                "
              ></ClinEnterId>
            </div>
          </div>
        </article>
      </transition>

      <transition name="swish">
        <article
          id="Consent"
          v-if="
            patientIdValidationState === 'valid' &&
            !trialRandomAllocation &&
            !showRandomisationSpinner &&
            !trialRandomisationErrorOccurred
          "
          class="pt-3"
        >
          <div
            class="py-4 d-flex flex-column justify-content-center align-items-center"
          >
            <transition name="swish">
              <b-button variant="warning" size="lg" @click="onRandomise"
                >Enrol and randomise</b-button
              >
            </transition>
          </div>
        </article>
      </transition>

      <Spinner v-if="showRandomisationSpinner" class="mh-25 mw-50"></Spinner>

      <transition name="swish">
        <article v-if="trialRandomAllocation" id="Allocation" class="py-3 mx-3">
          <b-card variant="light" class="mb-5">
            <h5 align="center">
              Your patient is enrolled with Trial Patient Identification Number:
            </h5>
            <h1 align="center" class="py-3">{{ trialRandomisationId }}</h1>
            <h5 align="center">and has been allocated to:</h5>
            <h1 align="center" class="py-3">
              {{ trialRandomAllocation.toUpperCase() }}
            </h1>
          </b-card>
          <h5>
            Write the Trial Patient Identification Number on the consent form.
          </h5>

          <h5 class="mt-5" align="center">Thank you</h5>
        </article>
      </transition>

      <article
        v-if="trialRandomisationErrorOccurred"
        id="Allocation"
        class="py-3 mx-3"
      >
        <b-card>
          <h1>Sorry</h1>
          <h5>There is a problem with the randomisation system.</h5>
          <h5>
            Please call the emergency software response team on 07973 105 394.
          </h5>
          <h5>"{{ trialRandomisationErrorMessage }}".</h5>
        </b-card>
      </article>

      <article
        v-if="!trialRandomisationErrorOccurred"
        id="help"
        class="m-3 mt-5 d-flex flex-column align-items-center"
      >
        <h6 align="center" style="font-size: 120%">
          <span style="font-size: 102%">If unsure or need advice,</span>
          <br />please call research team
        </h6>
        <h2 v-if="user.contactObj.location.type === 'international'">
          +55 11 5085-6204
        </h2>
        <h2 v-else>07733 344 588</h2>
        <b-button variant="success" href="tel:+447000000000">
          <svg class="ion ion-ios-telephone" style="min-width: 12.5em">
            <use xlink:href="#ion-ios-telephone" />
          </svg>
        </b-button>
      </article>

      <hr />
      <div v-if="0" class="d-flex flex-row justify-content-end">
        <b-button variant="outline-dark" size="sm" @click="debugFillEligible()">
          FoundByMe Ltd
          <br />Testing mode
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import ClinEnterId from "./ClinEnterId.vue";
import Spinner from "./ext/Spinner.vue";
import { auth, onEnrol_TeleACS } from "../util/firebase.js";
import * as bowser from "bowser";

export default {
  name: "EnrolTrialTeleACS",
  components: { AppHeaderBar, Spinner, ClinEnterId },
  props: {
    user: {
      type: Object,
      required: true, // We INSIST on being given an object, but we still give a default value, to make Vue walk the tree
      default() {
        return {
          contactObj: { fullName: undefined },
        };
      },
    },
  },
  data() {
    return {
      // Actual Data
      device: {},
      identityAndLocationConfirmed: false,
      characteristic: {
        age: null,
        sex: "",
        diabetes: null,
        prevrevasc: null,
        ageYearsString: null,
        ageConfirmed: false,
      },
      patientIdString: "", //  either "123 456 7890" or "BR:XYZHOSP:09FFDSG8769FSD7869876"
      patientIdValidationState: false,
      showRandomisationSpinner: false,
      trialRandomAllocation: null,
      trialRandomisationId: null,
      trialRandomisationErrorOccurred: false,
      trialRandomisationErrorCode: null, // a number
      trialRandomisationErrorType: "", // short capitalised string
      trialRandomisationErrorMessage: "", // long sentence
      // Making global consts and functions available to THIS
    };
  },
  computed: {
    eligibility: function () {
      const tCh = this.characteristic;

      const yes =
        // Inclusion
        ["male", "female"].includes(tCh.sex) &&
        ["yes", "no"].includes(tCh.diabetes) &&
        ["yes", "no"].includes(tCh.prevrevasc) &&
        tCh.ageConfirmed;

      // Exclusion
      const no = false;

      const undecided = !(yes || no);

      return { yes, no, undecided };
    },

    allPagesUploaded: function () {
      return this.pageUploaded.every(x => !!x);
    },
  },
  created() {},
  mounted() {
    const parser = bowser.getParser(window.navigator.userAgent);
    this.device = parser.parse().parsedResult;
  },

  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    onRandomise() {
      this.showRandomisationSpinner = true;

      onEnrol_TeleACS({
        nhsNumberString: this.patientIdString,
        userId: this.user.id,
        characteristic: this.characteristic,
        email: this.user.fromAuth.email,
        mobile: this.user.contactObj.mobileSelf,
        locationShortName: this.user.contactObj.location.shortName,
        locationLongName: this.user.contactObj.location.longName,
        documentScanPaths: this.pagePath,
      })
        .then(thingContainingData => {
          const data = thingContainingData.data;
          console.log("Raw data", data);
          if (data.success) {
            this.trialRandomAllocation = data.arm;
            this.trialRandomisationId = data.randomisation_id;
          } else {
            this.trialRandomisationErrorOccurred = true;
            this.trialRandomisationErrorCode = data.errorcode;
            this.trialRandomisationErrorType = data.errortype;
            this.trialRandomisationErrorMessage = data.errorinfo;
          }
        })
        .finally(() => {
          this.showRandomisationSpinner = false;
        });
    },

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    updatePatientIdAndValidationState(emission) {
      this.patientIdString = emission.nhsOrSiteAndLocalId || "";
      this.patientIdValidationState =
        emission.patientIdValidationState || false;
    },

    trySignOut() {
      auth.signOut();
    },

    debugFillEligible() {
      this.characteristic = {
        sex: "male",
        diabetes: "no",
        ageYearsString: "55",
        ageConfirmed: true,
        prevrevasc: "yes",
      };
      this.pageUploaded = this.pageUploaded.map(x => x || true);
      this.pagePath = this.pagePath.map(x => x + "");
    },

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goMyLocation() {
      this.$router.push({
        name: "myLocation",
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    // iPhone close full screen

    exitFullScreen(element) {
      var requestMethod =
        element.exitFullscreen ||
        element.mozCancelFullScreen ||
        element.webkitExitFullscreen ||
        element.msExitFullscreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else {
        console.log("Oops. Request method false.");
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
  color: black;
}

.drug {
  font-weight: 700;
  font-size: 150%;
}
</style>

<template>
  <div style="height:100vh; " class="p-2 d-flex flex-column justify-content-between">
    <app-header-bar title="Managing managers" style="flex-shrink:0;"></app-header-bar>

    <section id="middleScrolling" style="height:100%;overflow-y:auto;">
      <div class="p-3 my-3" style="border:white solid;border-radius:10em;">
        <h6>Research team</h6>
        <h4>"{{deFbmUk(pathStringToTheSubtreeToEdit)}}"</h4>
      </div>

      <h6>
        There
        <span v-if="nodeDataN===0">are no research managers</span>
        <span v-if="nodeDataN===1">is 1 research manager</span>
        <span v-if="nodeDataN>= 2">are {{nodeDataN}} research managers</span>
        specifically in charge of this research team. (Managers at levels above this can do do anything that managers at this level can do.)
        <!-- <span style="color:lightgray; font-size:71%">Identify people by their email, since names are self-entered and so could be misleading.</span> -->
      </h6>

      <ul class="list-group">
        <li
          v-for="person in nodeDataPeople"
          :key="person.id"
          class="list-group-item"
          style="color:black; width:100%;"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div id="emailAndNameBlock">
              <div>
                <strong>{{emailFromIdCache[person.id] || "...no email yet..."}}</strong>
              </div>
              <div style="max-width:60vw;">
                <p
                  style="overflow-wrap: break-word;"
                >{{fullNameFromIdCache[person.id] || "...no name yet..."}}</p>
              </div>
            </div>
            <div id="actionBlock">
              <!-- The server  splits people into 3 exclusive and exhaustive types -->
              <div v-if="person.isSelf" class="you-or-your-colleague">You</div>
              <div v-if="person.isPeer" class="you-or-your-colleague">Your colleague</div>
              <div v-if="person.isSubordinate">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mx-2"
                  @click="deleteManagerById(person.id)"
                >
                  Remove
                  <br />access
                </b-button>
              </div>
            </div>
          </div>
        </li>

        <!-- Now the extra LI at the end for adding a person -->
      </ul>

      <div class="mt-4">
        <h4>Add research manager</h4>

        <div>
          <b-form-input
            id="emailAddressInput"
            v-model="emailOfNewManager"
            :formatter="formatEmail"
            placeholder="Email"
            @click.native="clearAddNewManagerMessage()"
            @keyup.enter.native="tryAddNewManagerByEmail(emailOfNewManager)"
          ></b-form-input>
          <transition name="swish-enter">
            <b-alert show v-if="addNewManagerMessage">{{addNewManagerMessage}}</b-alert>
          </transition>
          <div v-if="emailOfNewManager.includes('@')">
            <div>I confirm that the person I am adding is a Trust Research Manager, authorised to grant individual staff access to NHS numbers in trial referrals. I am responsible for supervising their adherence to Information Governance policy.</div>
            <div class="mt-2 d-flex justify-content-end">
              <b-button
                variant="warning"
                @click="tryAddNewManagerByEmail(emailOfNewManager)"
              >&nbsp; &nbsp; Add &nbsp; &nbsp;</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="my-2">
        <h6
          v-if="nodeDataPeopleContainsPeers"
        >You can add colleagues (fellow managers at your level of management) but not remove them. Their managers can remove them.</h6>
        <h6>
          Do not simply add all front-line research staff. Only add
          <em>managers</em>, defined as people who (like you)
          <em>manage</em> access of other staff to confidential referral data (NHS Numbers).
        </h6>
        <h4>Need to extend management structure?</h4>
        <h6>
          If you are a large organisation with multiple layers of management, please contact
          <email-admin-link :user="user" sty="color:white; text-decoration:underline;"></email-admin-link>.
        </h6>
      </div>

      <!-- <div
        id="hqOnly"
        v-if="user.privilegesObj.hq"
      >
        <b-alert show variant="warning">

<h3>HQ Special Function</h3>
<H6>You are seeing this because you are HQ staff, as well as a manager. </H6>
        </b-alert>
      </div>-->
    </section>
  </div>
</template>

<script>
import {
  // Async functions
  getIdFromEmailObjToDataObj,
  getEmailFromIdObjToDataObj,
  getFullNameFromIdObjToDataObj,
  setManagementPathStringAccess
} from "../util/firebase.js";
import AppHeaderBar from "./AppHeaderBar.vue";
import EmailAdminLink from "./EmailAdminLink.vue";

export default {
  components: { AppHeaderBar, EmailAdminLink },

  props: { propsJsonB: { type: String, required: true }, user: Object },

  data() {
    return {
      // Actual Data
      node: JSON.parse(atob(this.propsJsonB)).node,
      pathStringToTheSubtreeToEdit: JSON.parse(atob(this.propsJsonB))
        .pathStringToTheSubtreeToEdit,
      emailFromIdCache: {},
      fullNameFromIdCache: {},
      emailOfNewManager: "",
      addNewManagerMessage: ""

      // Making global consts and functions available to THIS
    };
  },

  computed: {
    nodeDataN() {
      return this.node.data.n || 0;
    },
    nodeDataPeople() {
      return this.node.data.people || [];
    },
    nodeDataPeopleContainsPeers() {
      return this.nodeDataPeople.filter(person => person.isPeer).length > 0;
    }
  },
  created() {
    this.fillCacheAsynchronously();
  },
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    fillCacheAsynchronously() {
      return this.nodeDataPeople.forEach(person => {
        const id = person.id;
        // First, email
        getEmailFromIdObjToDataObj({ id: id })
          .then(objContainingDataObj => {
            this.$set(
              this.emailFromIdCache,
              id,
              objContainingDataObj.data.email
            );
          })
          .catch(e => {
            this.$set(this.emailFromIdCache, id, id + " (no email available)");
            console.error(`Error ${e} when retrieving Email of ${id}.`);
          });

        // Second, fullName
        getFullNameFromIdObjToDataObj({ id: id })
          .then(objContainingDataObj => {
            this.$set(
              this.fullNameFromIdCache,
              id,
              objContainingDataObj.data.fullName
            );
          })
          .catch(e => {
            this.$set(
              this.fullNameFromIdCache,
              id,
              "(they haven't entered their name)"
            );
            console.error(`Error ${e} when retrieving fullName of ${id}.`);
          });
      });
    },

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    async tryAddNewManagerByEmail(email) {
      this.addNewManagerMessage = `Searching for ${email}.`;
      this.emailOfNewManager = "";
      let targetUserId;
      try {
        targetUserId = (
          await getIdFromEmailObjToDataObj({
            email: email
          })
        ).data.id;
        this.addNewManagerMessage = "";
      } catch (e) {
        this.addNewManagerMessage = `${email} is not on the system yet. Please ask them to register on the App first.`;
        return;
      }

      if (this.nodeDataPeople.map(person => person.id).includes(targetUserId)) {
        this.addNewManagerMessage = `${email} is already listed above.`;
        return;
      }

      await setManagementPathStringAccess(
        // Permissions will be enforced on the server
        {
          targetUserId: targetUserId,
          targetManagementPathString: this.pathStringToTheSubtreeToEdit,
          enableTrueFalse: true
        }
      );

      this.goBack(); // Need to go back to previous screen to cause the database to be refreshed
    },

    async deleteManagerById(targetUserId) {
      await setManagementPathStringAccess(
        // Permissions will be enforced on the server
        {
          targetUserId: targetUserId,
          targetManagementPathString: this.pathStringToTheSubtreeToEdit,
          enableTrueFalse: false
        }
      );

      this.goBack(); // Need to go back to previous screen to cause the database to be refreshed
    },

    clearAddNewManagerMessage() {
      this.addNewManagerMessage = "";
    },

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    formatEmail(email /*, event */) {
      // To add a non NHS email, put the @ part first!
      email = email.trim();
      // if (email.length >= 5 && email.charAt(email.length - 1) === "@") {
      //   email += "nhs.net";
      // }
      return email;
    },

    deFbmUk(s) {
      return (s || "").replace("FBM | UK | ", "");
    },

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.you-or-your-colleague {
  font-style: italic;
}
</style>

<template>
  <div class="p-3 d-flex flex-column" style="height: 100vh">
    <app-header-bar title="My patients"></app-header-bar>

    <transition name="swish">
      <div v-if="user.contactObj.inTourStage === 2">
        <tour-message>
          <h4>Your patients' progress will be shown on this page.</h4>
          <h5>
            For confidentiality, we identify them only by their date and time of
            referral.
          </h5>
          <div class="d-flex flex-row justify-content-end">
            <b-button variant="primary" @click="onClickOk">OK</b-button>
          </div>
        </tour-message>
      </div>
    </transition>

    <section
      v-if="user.referralIsLoaded"
      style="height: 100%; overflow-y: auto"
    >
      <b-table :items="user.referralArray" :fields="fields">
        <template #cell(id)="data">
          <div style="font-size: 90%">
            {{ dateParts(isoFromReferralId(data.item.id)).t.slice(0, 5) }},
            {{ dateParts(isoFromReferralId(data.item.id)).weekday }}
          </div>
          <div style="font-size: 150%">
            {{ dateParts(isoFromReferralId(data.item.id)).dateMonth }}
          </div>
          <div
            v-if="isoIsMoreThanNDaysAgo(isoFromReferralId(data.item.id), 360)"
          >
            {{ dateParts(isoFromReferralId(data.item.id)).year }}
          </div>
        </template>

        <template #cell(statusVirtual)="data">
          <div
            v-for="(trialId, trialIndex) in data.item.trialIds"
            :key="trialId"
          >
            <hr
              v-if="trialIndex > 0"
              style="border: 0.5px solid white"
              class="m-0 p-0"
            />
            <div class="mt-2" style="font-size: 70%">
              {{ trials.publicObj[trialId].shortText }}:
            </div>
            <div class="my-2">
              {{
                statusObjFromId(
                  ((data.item.statusByTrial || {})[trialId] || {}).statusId || {
                    long: "No news",
                  }
                ).long
              }}
            </div>
          </div>
        </template>
      </b-table>
    </section>

    <section v-else>
      <spinner></spinner>
    </section>
  </div>
</template>

<script>
import { userReferralRootRef, statusObjFromId } from "../util/firebase.js";
import { dateParts, isoFromReferralId } from "../util/formatDate.js";
import AppHeaderBar from "./AppHeaderBar.vue";
import Spinner from "./ext/Spinner.vue";

export default {
  components: { AppHeaderBar, Spinner },

  props: {
    user: { type: Object, required: true },
    trials: { type: Object, required: true },
  },

  data() {
    return {
      branchBindingTable: {},
      options: [], // To be filled with  list of  NHS Trusts OR  list of  CCGs

      // Table
      fields: [
        {
          label: "Referred",
          key: "id",
          sortable: true,
        },
        { label: "Status", key: "statusVirtual", sortable: false },
      ],
    };
  },

  created() {
    this.bindBranchAsREFandOBJandISLOADED(
      this.user,
      "referral",
      userReferralRootRef.child(this.user.id),
      { addArray: true }
    );
  },

  beforeDestroy() {
    this.bindBranchAsREFandOBJandISLOADED(this.user, "referral", null);
  },

  methods: {
    bindBranchAsREFandOBJandISLOADED(
      containerObject,
      keyPrefix,
      firebaseRef,
      options
    ) {
      //Use this to put userContact database into user.contactObj etc.
      // and then separately to put trialPublic database into trial.publicObj etc.
      // OPTIONS:
      //   addArray: true if you want an Array version as well as the usual Obj
      //   callBack: function that you want executed once data loaded

      //--------------------------------
      // CONTAINEROBJECT is either this.user or this.trial

      // "keyPrefix" can be, for example, "status"
      // That would create in the "this.user" object, the following:
      //   this.user.statusRef    which would be the firebase Ref for the status
      //   this.user.statusObj    which would be the firebase-bound status
      //   this.user.statusIsLoaded    which would be true once read from firebase

      // Step 1. Open up the pathway to that point in the table
      if (!this.branchBindingTable) {
        this.branchBindingTable = {};
      }

      // Step 2. If this variable is already bound to a firebase node, unbind
      if (this.branchBindingTable[keyPrefix]) {
        // If this keyPrefix is already bound to a firebase node, unbind it. Otherwise updates to the OLD node will wrongly cause themselves to be read in and trigger an incorrect "update" to the new node, i.e. overwrite your new patient's data with the previous patient's data (just because the previous patient happened to update their data)
        this.branchBindingTable[keyPrefix].off();
        containerObject[keyPrefix + "IsLoaded"] = false;
      }

      // Step 3. Now bind to the new firebase node and store the three things, "REF","OBJ" itself, and "ISLOADED".
      if (firebaseRef) {
        // ... if NULL, then leave it unbinded
        this.branchBindingTable[keyPrefix] = firebaseRef;
        containerObject[keyPrefix + "Ref"] = firebaseRef;

        firebaseRef.on("value", (snapshot) => {
          // Every time firebase value changes (including the first where firebase just comes live) this will cause an Object.assign, causing a re-render of the object in this and the children components.

          let newKeyValuePairOrPairs = {};
          let newValueAsObj = snapshot.val() || {};
          newKeyValuePairOrPairs[keyPrefix + "Obj"] = newValueAsObj;
          if (options && options.addArray) {
            const newValueAsArray = Object.keys(newValueAsObj).map((key) => ({
              ...newValueAsObj[key],
              id: key,
            }));
            newKeyValuePairOrPairs[keyPrefix + "Array"] = newValueAsArray;
          }
          Object.assign(containerObject, newKeyValuePairOrPairs);

          containerObject[keyPrefix + "IsLoaded"] = true;
          if (options && options.callback) {
            options.callback();
          }
        });
      }
    },

    isoIsMoreThanNDaysAgo(isoString, days = 300) {
      const oldDate = new Date(isoString);
      const nowDate = new Date();
      const diffMs = nowDate - oldDate;
      const oneDayMs = 1000 * 60 * 60 * 24;
      return diffMs > days * oneDayMs;
    },

    onClickOk() {
      this.$router.push({ name: "clinTrained" });
    },

    // Transduce imported (non-this) functions to be available in template (i.e. as part of THIS)
    isoFromReferralId,
    dateParts,
    statusObjFromId,

    // Navigation
    goBack() {
      this.$router.go(-2);
    },
  },
};
</script>

<style>
</style>
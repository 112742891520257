<template>
  <section
    id="titleAndNavigation"
    class="mb-2 d-flex justify-content-between align-items-center"
    style="flex-shrink:0;"
  >

    <div>
      <b-button
        v-if="!hideBackArrow"
        :variant="darkVariant?'outline-dark':'outline-light'"
        size="sm"
        @click="goBack"
      >

        <svg class="ion-small">
          <use xlink:href="#ion-chevron-left"></use>
        </svg>
      </b-button>
    </div>

    <h2 style="text-align:center;">{{title}}</h2>

    <div>
      <div
        v-if="!hideHomeIcon"
        @click="$router.replace({name:'home'})"
      >
        <img
          src="../assets/logo.svg"
          style="height:48px; "
        >
      </div>
    </div>

  </section>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    darkVariant: { type: Boolean, default: false },
    hideBackArrow: Boolean,
    hideHomeIcon: Boolean
  },

  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
</style>

export function formatCriterionContinuous(shortText, form) {
  // form is called "form" in EditLogic.
  // it consists of:

  // a Lower limit OR
  // an Upper lit OR
  // both.

  // gteq & lteq which say whether equality is allowed on each side

  // llString is meaningful if it is t r u t h y and then has length >1
  // likewise ulString

  let llString;
  let ulString;

  if (form.hasOwnProperty("llString")) {
    llString = form.llString;
  } else if (form.hasOwnProperty("ll")) {
    llString = String(form.ll);
  }

  if (form.hasOwnProperty("ulString")) {
    ulString = form.ulString;
  } else if (form.hasOwnProperty("ul")) {
    ulString = String(form.ul);
  }

  const hasLL = llString && llString.length > 0;
  const hasUL = ulString && ulString.length > 0;

  let out;

  if (hasLL && hasUL) {
    out = llString + " " + (form.gteq ? "≤" : "<") + " " + shortText + " " + (form.lteq ? "≤" : "<") + " " + ulString;
  }

  if (hasLL && !hasUL) {
    out = shortText + " " + (form.gteq ? "≥" : ">") + " " + llString;
  }

  if (hasUL && !hasLL) {
    out = shortText + " " + (form.lteq ? "≤" : "<") + " " + ulString;
  }

  if (!hasLL && !hasUL) {
    // eslint-disable-next-line no-console
    console.error("The criterion ", shortText, " has been given with neither an upper nor lower limit. form is: ", form);
    out = shortText + " (no limit)";
  }

  return out;
}

export function formatCriterionCategorical(shortText, form, option, isIn) {
  return shortText + " is " + (isIn ? "" : "NOT ") + option.shortText;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 p-0 d-flex flex-column",staticStyle:{"height":"100vh","overflow":"hidden"},attrs:{"id":"app"}},[(_vm.amOnline === false)?_c('section',{staticStyle:{"position":"absolute"}},[_c('AppOfflineWarning')],1):_vm._e(),_c('section',[_c('AppAutoupdate',{attrs:{"onlyShowCopyright":false}})],1),(_vm.deviceToPromptInstallation.length > 0)?_c('section',{staticClass:"d-flex flex-column justify-content-center align-content-center align-items-center",staticStyle:{"height":"100%","background-color":"white","color":"black"}},[(_vm.deviceToPromptInstallation === 'Android')?_c('div',[_c('img',{staticStyle:{"max-width":"100vw","max-height":"95vh"},attrs:{"src":"/install-android.png","alt":"Click the triple dot menu at the top right, choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."}}),_c('AppAutoupdate',{attrs:{"onlyShowCopyright":true}})],1):_vm._e(),(_vm.deviceToPromptInstallation === 'iPhone')?_c('div',[_c('img',{staticStyle:{"max-width":"100vw","max-height":"95vh"},attrs:{"src":"/install-iPhone.png","alt":"Click the SHARE menu at the bottom of the screen (box with upward arrow), choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."}}),_c('AppAutoupdate',{attrs:{"onlyShowCopyright":true}})],1):_vm._e(),(_vm.deviceToPromptInstallation === 'iPad')?_c('div',[_c('img',{staticStyle:{"max-width":"100vw","max-height":"95vh"},attrs:{"src":"/install-iPad.png","alt":"Click the SHARE menu at the top of the screen (box with upward arrow), choose 'Add to Home Screen', and once installed open '1m Inclusion' from your home screen."}}),_c('AppAutoupdate',{attrs:{"onlyShowCopyright":true}})],1):_vm._e()]):_vm._e(),(
      _vm.deviceToPromptInstallation.length === 0 && _vm.loggedState === undefined
    )?_c('section',{staticClass:"center-container"},[_c('img',{staticStyle:{"max-width":"70vw","max-height":"70vh"},attrs:{"src":require("./assets/logo.svg"),"alt":"Logo"}})]):_vm._e(),(_vm.deviceToPromptInstallation.length === 0 && _vm.loggedState === 'out')?_c('section',[_c('Login',{attrs:{"auth":_vm.auth}})],1):_vm._e(),(_vm.deviceToPromptInstallation.length === 0 && _vm.loggedState === 'in')?_c('section',[(
        _vm.user.privilegesIsLoaded &&
        _vm.user.contactIsLoaded &&
        _vm.user.presenceIsLoaded &&
        _vm.trials.publicIsLoaded &&
        _vm.trials.criterionIsLoaded &&
        _vm.trials.factIsLoaded
      )?_c('div',[_c('TransitionPage',[_c('router-view',{staticStyle:{"overflow-y":"scroll"},attrs:{"user":_vm.user,"trials":_vm.trials,"georestrictedTrialIdArray":_vm.georestrictedTrialIdArray},on:{"checkUserGivenEnoughInfo":function($event){return _vm.forceInfoToBeGiven()}}})],1),_c('AppHelp',{attrs:{"userId":_vm.user.id,"privileges":_vm.user.privilegesObj}})],1):_c('div',[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%","justify-content":"space-evenly","opacity":"0.2"}},[_c('div',[_vm._v(_vm._s(_vm.user.presenceIsLoaded ? "■" : "□"))]),_c('div',[_vm._v(" "+_vm._s(_vm.user.contactIsLoaded ? "■" : "□")+" ")]),_c('div',[_vm._v(_vm._s(_vm.user.privilegesIsLoaded ? "■" : "□"))]),_c('div',[_vm._v(_vm._s(_vm.trials.factIsLoaded ? "■" : "□"))]),_c('div',[_vm._v(_vm._s(_vm.trials.publicIsLoaded ? "■" : "□"))]),_c('div',[_vm._v(_vm._s(_vm.trials.criterionIsLoaded ? "■" : "□"))])]),_c('Spinner')],1)]):_vm._e(),_c('Icons')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
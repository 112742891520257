<template>
  <div style="height:100vh;">
    <div v-if="!trials.publicIsLoaded">
      <spinner></spinner>
    </div>

    <div v-else class="d-flex flex-column" style="height:100%;">
      <section id="top-bar" class="my-0 pt-1 pb-0 mx-2 d-flex flex-row justify-content-between">
        <div>
          <div>
            <b-button
              variant="outline-light"
              size="sm"
              @click="goBack()"
              class="d-flex flex-row align-items-center"
            >
              <svg class="ion" style="height:1em;width:1em;">
                <use xlink:href="#ion-chevron-left"></use>
              </svg>
              <div class="small" style="line-height:1;">Redo
                <br>questions
              </div>
            </b-button>
          </div>

          <div v-if="nTrialsRemaining>0">
            <h5 class="my-0 pb-2">{{nTrialsSummary}}</h5>
          </div>

          <div v-if="nTrialsRemaining===0" class="center-container">
            <div style="background-color:white; color:black; border-radius:0.5em;" class="p-4 m-2">
              <h3>Thanks for looking</h3>
              <h5>Next time you visit, please ask again about trials. New ones are always being added.</h5>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" size="lg" @click="goHome">OK</b-button>
              </div>
            </div>
          </div>
        </div>

        <div>&nbsp;</div>

        <transition>
          <div
            v-if="nTrialsAccept>=1"
            @click="showBasket = !showBasket"
            :class="'basket-indicator '+(showBasket?'basket-indicator-shown':'')"
            key="dummyKey"
          >
            <svg
              style="height:1.5em;"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1000 1000"
              xml:space="preserve"
            >
              <g>
                <path
                  :fill="showBasket?'black':'white'"
                  d="M186.1,132.5H40.6c-16.9,0-30.6-13.7-30.6-30.6C10,85,23.7,71.3,40.6,71.3h145.5c16.9,0,30.6,13.7,30.6,30.6C216.7,118.8,203,132.5,186.1,132.5L186.1,132.5z M844.5,775.6H346.9c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6h497.7c16.9,0,30.6,13.7,30.6,30.6C875.2,761.9,861.4,775.6,844.5,775.6L844.5,775.6z M959.4,255H232c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6h727.3c16.9,0,30.6,13.7,30.6,30.6C990,241.3,976.3,255,959.4,255L959.4,255z M911.5,438.8H279.9c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6h631.6c16.9,0,30.6,13.7,30.6,30.6C942.1,425,928.4,438.8,911.5,438.8L911.5,438.8z M867.5,622.5H327.7c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6h539.8c16.9,0,30.6,13.7,30.6,30.6C898.1,608.8,884.4,622.5,867.5,622.5L867.5,622.5z M588,618.7c-16.9,0-30.6-13.7-30.6-30.6V258.8c0-16.9,13.7-30.6,30.6-30.6c16.9,0,30.6,13.7,30.6,30.6V588C618.7,605,605,618.7,588,618.7L588,618.7z M798.8,236.8l-39.2,326.9c-2,16.8-17.3,28.8-34.1,26.8c-16.8-2-28.8-17.3-26.8-34L738,229.6c2-16.8,17.3-28.8,34.1-26.8C788.9,204.8,800.8,220.1,798.8,236.8L798.8,236.8z M988.3,232.6l-88.3,356c-4,16.4-20.6,26.5-37.1,22.4c-16.4-4-26.5-20.6-22.4-37.1l88.3-356c4-16.4,20.6-26.5,37.1-22.4C982.3,199.6,992.3,216.2,988.3,232.6L988.3,232.6z M451.5,595.2c-16.8,2.2-32.1-9.7-34.3-26.5l-39.4-307.6c-2.2-16.8,9.7-32.1,26.5-34.3c16.8-2.2,32.1,9.7,34.3,26.5L478,560.9C480.1,577.7,468.3,593,451.5,595.2L451.5,595.2z M352.6,768.4c-16.5,4.1-33.1-5.9-37.2-22.3L158,115.5c-4.1-16.4,5.9-33,22.4-37.1c16.5-4.1,33.1,5.9,37.2,22.3l157.3,630.6C379,747.7,369,764.4,352.6,768.4L352.6,768.4z M400.5,928.7c-33.8,0-61.2-27.4-61.2-61.2c0-33.8,27.4-61.3,61.2-61.3c33.8,0,61.2,27.4,61.2,61.3C461.7,901.3,434.3,928.7,400.5,928.7L400.5,928.7z M775.6,928.7c-33.8,0-61.2-27.4-61.2-61.2c0-33.8,27.4-61.3,61.2-61.3c33.8,0,61.3,27.4,61.3,61.3C836.9,901.3,809.4,928.7,775.6,928.7L775.6,928.7z"
                ></path>
              </g>
            </svg>
            <small>&thinsp;</small>
            <b-badge variant="light">{{nTrialsAccept}}</b-badge>
          </div>
        </transition>
      </section>

      <transition name="swish">
        <section
          id="basketPreview"
          v-if="showBasket"
          class="mx-2 mb-2"
          key="dummyKey"
          @click="showBasket=false;"
        >
          <div class="basket-preview">
            <h6 v-if="nTrialsAccept===0">You haven't selected any trials.</h6>
            <!-- <h6 v-if="nTrialsAccept===1">You have marked</h6>
            <h6 v-if="nTrialsAccept>1">You have marked these trials:</h6>-->
            <h6
              v-for="trial in trialsDisposed[ACCEPT]"
              :key="trial.id"
              class="my-0 px-1"
            >{{trials.publicObj[trial.id].shortText}}</h6>
            <hr style="border-color:white;" class="my-1">
            <b-button
              variant="danger"
              size="sm"
              class="m-1"
              @click="undisposeTrials"
            >Clear selection</b-button>
            <!-- <b-button variant="primary" size="sm" class="m-1" @click="showBasket=false;">
              Close<br>basket
            </b-button>-->
            <b-button variant="primary" size="sm" class="m-1" @click="goNext">Skip on to refer</b-button>
          </div>
        </section>
      </transition>

      <section
        v-if="nTrialsRemaining>0"
        id="mid-scrolling"
        class="mx-2 iphone-scroll-fudge"
        style="height:100%;overflow:auto;"
      >
        <transition name="swish">
          <section v-if="user.contactObj.inTour">
            <br>
            <tour-message>
              <h3>Say yes/no to trials</h3>
              <h6>Trials suiting your patient will be listed below. (In this tour, there is just an example.)</h6>
              <h4>Click "Yes" under the trial.</h4>
              <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
              </div>-->
            </tour-message>
          </section>
        </transition>

        <transition-group :name="'stage-'+direction">
          <div
            v-for="(trial,iTrial) in trialsOnDisplay"
            :key="trial.id"
            :class="'stage-'+direction+'-item mb-2'"
            style="display:block;"
          >
            <b-card tag="article" style="max-width: 30rem; color:black;">
              <div
                v-if="trials.publicObj[trial.id].videoUrl && (''+trials.publicObj[trial.id].videoUrl).length>=5"
                style
              >
                <video
                  :src="trials.publicObj[trial.id].videoUrl"
                  style="width:100%;border-radius:1em;"
                  controls
                >
                  <!-- https://storage.googleapis.com/found-by-me/media/ISCOMAT.mp4 -->
                  Unable to play this video on this device currently.
                </video>
              </div>
              <div v-else>
                <img src="../assets/logo.svg" style="width:100%;" alt="No video">
              </div>

              <div
                id="trial-name-mgmtpath-flags"
                class="mt-3 d-flex flex-row"
                style="align-items:baseline;"
              >
                <!-- To make baelines of LAST lines of text align, each ITEM in the flexbox has to have a CHILD ITEM which has display:inline-block. Not the item, but a child-item containing the text. See https://stackoverflow.com/questions/32209427/align-flex-box-items-to-baseline-of-last-text-line-in-a-block
                -->
                <div>
                  <h5
                    :style="'flex-basis:0.7;font-weight:700;line-height:1;'+(trial.suit===YES?'color:$primary;':'color:gray;')"
                  >
                    <div class="d-inline-block">{{trials.publicObj[trial.id].shortText}}</div>
                  </h5>
                  <h6
                    v-if="trials.publicObj[trial.id].centre && trials.publicObj[trial.id].centre.length>0"
                    style="line-height:0.4; font-size:70%;"
                  >{{trials.publicObj[trial.id].centre}}</h6>
                </div>
                <div style="width:0.5em;">
                  <!-- spacer -->
                </div>
                <div style="flex-basis:0.2; font-size:62%; line-height:0.9;">
                  <div class="d-inline-block" v-if="trial.suit===YES" style="color:green;">Suitable
                    <br>trial
                  </div>
                  <div
                    class="d-inline-block p-1"
                    v-if="trial.suit===DEPENDS"
                    style="color:white; background-color:gray;"
                  >Suitability
                    <br>uncertain
                  </div>
                </div>
              </div>
              <div class="stage-left-text">
                {{trials.publicObj[trial.id].thisTrialIs}}
                <ul>
                  <li
                    v-for="(bullet,iBullet) in trials.publicObj[trial.id].bullets"
                    :key="iBullet"
                  >{{bullet}}</li>
                </ul>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <b-button
                  variant="danger"
                  @click="disposeTrial(trial.suit,iTrial,REJECT);showBasket=false;"
                >&nbsp; No &nbsp;</b-button>
                <b-button
                  variant="primary"
                  @click="disposeTrial(trial.suit,iTrial,ACCEPT);showBasket=false;"
                >&nbsp; Yes &nbsp;</b-button>
              </div>
            </b-card>
          </div>
        </transition-group>
      </section>

      <section id="bottom-bar">
        <transition>
          <div v-if="0===1" class="d-flex flex-row justify-content-between"></div>
        </transition>
      </section>
    </div>
  </div>
</template>

<script>
import { YES, NO, DEPENDS, REJECT, ACCEPT } from "../util/constants.js";
import Spinner from "./ext/Spinner.vue";

const EMPTY_TRIALS_DISPOSED = {
  [ACCEPT]: [],
  [REJECT]: []
};

export default {
  components: { Spinner },
  props: {
    trials: { type: Object, required: true },
    trialIdsForDisplayStringified: {
      type: String, // has branches of YES, NO and DEPENDS,
      required: true
    },
    user: { type: Object }
  },
  data: function() {
    return {
      trialsTrifurcation: JSON.parse(this.trialIdsForDisplayStringified),
      direction: "left", // set to LEFT or RIGHT depending on which way you want the current card disappearance to send it
      trialsDisposed: JSON.parse(JSON.stringify(EMPTY_TRIALS_DISPOSED)),
      showBasket: false,

      // constants
      YES,
      NO,
      DEPENDS,
      REJECT,
      ACCEPT
    };
  },
  computed: {
    trialsOnDisplay: function() {
      // This is a single array rather than separate branches with an array each for "definitely suitable" and "possibly suitable" respectively
      return this.trialsTrifurcation[YES].concat(
        this.trialsTrifurcation[DEPENDS]
      );
    },
    nTrialsYes: function() {
      return ((this.trialsTrifurcation || {})[YES] || []).length;
    },
    nTrialsDepends: function() {
      return ((this.trialsTrifurcation || {})[DEPENDS] || []).length;
    },
    nTrialsReject: function() {
      return ((this.trialsDisposed || {})[REJECT] || []).length;
    },
    nTrialsAccept: function() {
      return ((this.trialsDisposed || {})[ACCEPT] || []).length;
    },
    nTrialsRemaining: function() {
      return this.nTrialsYes + this.nTrialsDepends;
    },
    nTrials: function() {
      return (
        this.nTrialsYes +
        this.nTrialsDepends +
        this.nTrialsReject +
        this.nTrialsAccept
      );
    },
    nTrialsSummary: function() {
      //   const someAlreadyDecided = this.nTrialsReject + this.nTrialsAccept > 0;
      if (this.nTrialsRemaining === 0) {
        return "All trials reviewed";
      } else if (this.nTrials === 1) {
        return ""; // "There's a trial!";
      } else {
        // There is more
        return "";
        // (
        //   this.nTrialsRemaining +
        //     (someAlreadyDecided ? " more" : "") +
        //     (this.nTrialsRemaining > 1 ? " trials" : " trial") +
        //     (someAlreadyDecided ? "" : " for you, interested?")
        // );
      }
    }
  },

  methods: {
    // firebaseBindingTrialPublic: function(snapshot) {
    //   this.trialPublicObj = snapshot.val();
    //   this.trialPublicIsLoaded = true;
    // },

    disposeTrial: function(suit, iTrial, acceptStatus) {
      this.direction = acceptStatus === ACCEPT ? "right" : "left";
      this.trialsDisposed[acceptStatus].push(
        this.trialsTrifurcation[suit][iTrial]
      );
      this.trialsTrifurcation[suit].splice(iTrial, 1);
      if (this.nTrialsRemaining === 0) {
        if (this.nTrialsAccept > 0) {
          this.goNext();
        } else {
          // No suitable trials, will see "All trials reviewed" and sorry, message
        }
      }
    },

    undisposeTrials: function() {
      this.direction = "up"; // So that stuff comes down from above, rather than confusingly ALL from the side that the last disposal was
      this.trialsDisposed = JSON.parse(JSON.stringify(EMPTY_TRIALS_DISPOSED));
      this.trialsTrifurcation = JSON.parse(this.trialIdsForDisplayStringified);
    },

    goNext: function() {
      this.$router.push({
        name: "clin40Permission",
        params: {
          trialIdsPatientAccepted: this.trialsDisposed[ACCEPT].map(
            trialItem => trialItem.id
          )
        }
      });
    },

    goHome: function() {
      this.$router.replace({ name: "home" });
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.basket-indicator {
  border-radius: 0.5em;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 0.5em;
}

.basket-indicator-shown {
  margin-top: 0.25em;
  background-color: white;
  border-color: white;
  border-style: solid;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.basket-preview {
  justify-content: end;
  border-radius: 0.5em;
  border-top-right-radius: 0;
  background-color: white;
  color: black;
  text-align: right;
}

/* 3 directions: left for reject, right for accept, and up as the place BOTH come from when you undo (because we can only animate a group in one direction at once) */
.stage-left-item,
.stage-right-item,
.stage-up-item {
  transition: all 1s;
  display: inline-block;
}
.stage-left-leave-active,
.stage-right-leave-active,
.stage-up-leave-active {
  position: absolute;
}
.stage-left-enter,
.stage-left-leave-to {
  opacity: 0;
  transform: translateX(-100vw);
}
.stage-right-enter,
.stage-right-leave-to {
  opacity: 0;
  transform: translateX(+100vw);
}
.stage-up-enter,
.stage-up-leave-to {
  opacity: 0;
  transform: translateY(-100vh);
}
</style>

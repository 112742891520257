export const internationalHospitals = [
  /* spell-checker: disable */
  {
    longName: "Instituto Dante Pazzanese de Cardiologia",
    shortName: "BR:IDPC",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital São Luiz São Caetano",
    shortName: "BR:Luiz-Caetano",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital Vila Lobos",
    shortName: "BR:Vila-Lobos",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Instituto de Assistência Médica ao Servidor Público Estadual",
    shortName: "BR:IAMPSE",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital dos servidores do Estado RJ",
    shortName: "BR:HSE-RJ",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital do Coração de Messejana",
    shortName: "BR:HM-CE",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Marcelino Champagnat",
    shortName: "BR:CHAMP",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital Clementino Fraga Filho UFRJ",
    shortName: "BR:CLEMFF",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital Universitário Pedro Ernesto UERJ",
    shortName: "BR:ERNESTO",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Hospital São Luiz - Unidade Anália Franco",
    shortName: "BR:SLAFRANCO",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Prevent Senior - São Paulo",
    shortName: "BR:Prev-Sen",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Primavera",
    shortName: "BR:Prima",
    country: "BR",
    la: "pt",
  },
  {
    longName: "Angelina Caron",
    shortName: "BR:ACARON",
    country: "BR",
    la: "pt",
  },
];

export const nhsTrusts = [
  // Source: https://www.nhs.uk/servicedirectories/pages/nhstrustlisting.aspx
  {
    longName: "2gether NHS Foundation Trust",
    shortName: "2gether",
    postcode: "GL3 4AW"
  },
  {
    longName: "Aintree University Hospital NHS Foundation Trust",
    shortName: "Aintree",
    postcode: "L9 7AL"
  },
  {
    longName: "Airedale NHS Foundation Trust",
    shortName: "Airedale",
    postcode: "BD20 6TD"
  },
  {
    longName: "Alder Hey Children's NHS Foundation Trust",
    shortName: "Alder Hey",
    postcode: "L12 2AP"
  },
  {
    longName: "Ashford and St Peter's Hospitals NHS Foundation Trust",
    shortName: "Ashford-St Peter's",
    postcode: "KT16 0PZ"
  },
  {
    longName: "Avon and Wiltshire Mental Health Partnership NHS Trust",
    shortName: "Avon-Wiltshire",
    postcode: "BA1 3QE"
  },
  {
    longName: "Barking, Havering and Redbridge University Hospitals NHS Trust",
    shortName: "Barking-Havering-Redbridge",
    postcode: "RM7 0AG"
  },
  {
    longName: "Barnet, Enfield and Haringey Mental Health NHS Trust",
    shortName: "Barnet-Enfield-Haringey",
    postcode: "N15 3TH"
  },
  {
    longName: "Barnsley Hospital NHS Foundation Trust",
    shortName: "Barnsley",
    postcode: "S75 2EP"
  },
  {
    longName: "Barts Health NHS Trust",
    shortName: "Barts",
    postcode: "E1 8PR"
  },
  {
    longName: "Basildon and Thurrock University Hospitals NHS Foundation Trust",
    shortName: "Basildon-Thurrock",
    postcode: "SS16 5NL"
  },
  {
    longName: "Bedford Hospital NHS Trust",
    shortName: "Bedford",
    postcode: "MK42 9DJ"
  },
  {
    longName: "Berkshire Healthcare NHS Foundation Trust",
    shortName: "Berkshire",
    postcode: "RG12 1BQ"
  },
  {
    longName: "Birmingham and Solihull Mental Health NHS Foundation Trust",
    shortName: "Birmingham-Solihull",
    postcode: "B1 3RB"
  },
  {
    longName: "Birmingham Community Healthcare NHS Foundation Trust",
    shortName: "Birmingham",
    postcode: "B7 4BN"
  },
  {
    longName: "Birmingham Women's and Children's NHS Foundation Trust",
    shortName: "Bham Wom-Chldrn",
    postcode: "B15 2TG"
  },
  {
    longName: "Black Country Partnership NHS Foundation Trust",
    shortName: "Black Country",
    postcode: "B70 9PL"
  },
  {
    longName: "Blackpool Teaching Hospitals NHS Foundation Trust",
    shortName: "Blackpool",
    postcode: "FY3 8NR"
  },
  {
    longName: "Bolton NHS Foundation Trust",
    shortName: "Bolton",
    postcode: "BL4 0JR"
  },
  {
    longName: "Bradford District NHS Foundation Trust",
    shortName: "Bradford District",
    postcode: "BD18 3LD"
  },
  {
    longName: "Bradford Teaching Hospitals NHS Foundation Trust",
    shortName: "Bradford Teaching",
    postcode: "BD9 6RJ"
  },
  {
    longName: "Bridgewater Community Healthcare NHS Foundation Trust",
    shortName: "Bridgewater",
    postcode: "WA1 4LS"
  },
  {
    longName: "Brighton and Sussex University Hospitals NHS Trust",
    shortName: "Brighton-Sussex",
    postcode: "BN2 1ES"
  },
  {
    longName: "Buckinghamshire Healthcare NHS Trust",
    shortName: "Bucks",
    postcode: "HP11 2TT",
    recordLocations: ["Evolve", "Rio"]
  },
  {
    longName: "Calderdale and Huddersfield NHS Foundation Trust",
    shortName: "Calderdale-Huddersfield",
    postcode: "HD3 3EA"
  },
  {
    longName: "Cambridge University Hospitals NHS Foundation Trust",
    shortName: "Cambridge Univ",
    postcode: "CB2 0QQ"
  },
  {
    longName: "Cambridgeshire and Peterborough NHS Foundation Trust",
    shortName: "Camb-Peterboro",
    postcode: "CB21 5EF"
  },
  {
    longName: "Cambridgeshire Community Services NHS Trust",
    shortName: "Cambridgeshire",
    postcode: "PE27 4LG"
  },
  {
    longName: "Camden and Islington NHS Foundation Trust",
    shortName: "Camden-Islington",
    postcode: "NW1 0PE"
  },
  {
    longName: "Central and North West London NHS Foundation Trust",
    shortName: "Central-NW London",
    postcode: "NW1 3AX"
  },
  {
    longName: "Central London Community Healthcare NHS Trust",
    shortName: "Central London",
    postcode: "NW1 5JD"
  },
  {
    longName: "Chelsea and Westminster Hospital NHS Foundation Trust",
    shortName: "Chelsea-Westminster",
    postcode: "SW10 9NH"
  },
  {
    longName: "Cheshire and Wirral Partnership NHS Foundation Trust",
    shortName: "Cheshire-Wirral",
    postcode: "CH2 1BQ"
  },
  {
    longName: "Chesterfield Royal Hospital NHS Foundation Trust",
    shortName: "Chesterfield Royal",
    postcode: "S44 5BL"
  },
  {
    longName: "Christie NHS Foundation Trust",
    shortName: "Christie",
    postcode: "M20 4BX"
  },
  {
    longName: "City Hospitals Sunderland NHS Foundation Trust",
    shortName: "City Sunderland",
    postcode: "SR4 7TP"
  },
  {
    longName: "Clatterbridge Cancer Centre NHS Foundation Trust",
    shortName: "Clatterbridge Cancer",
    postcode: "CH63 4JY"
  },
  {
    longName: "Cornwall Partnership NHS Foundation Trust",
    shortName: "Cornwall",
    postcode: "TR15 2SP"
  },
  {
    longName: "Countess Of Chester Hospital NHS Foundation Trust",
    shortName: "Countess Of Chester",
    postcode: "CH2 1UL"
  },
  {
    longName: "County Durham and Darlington NHS Foundation Trust",
    shortName: "County Durham-Darlington",
    postcode: "DL3 6JL"
  },
  {
    longName: "Coventry and Warwickshire Partnership NHS Trust",
    shortName: "Coventry-Warwickshire",
    postcode: "CV6 6NY"
  },
  {
    longName: "Croydon Health Services NHS Trust",
    shortName: "Croydon",
    postcode: "CR7 7YE"
  },
  {
    longName: "Cumbria Partnership NHS Foundation Trust",
    shortName: "Cumbria",
    postcode: "LA14 1NB"
  },
  {
    longName: "Dartford and Gravesham NHS Trust",
    shortName: "Dartford-Gravesham",
    postcode: "DA2 8DA"
  },
  {
    longName: "Derbyshire Community Health Services NHS Foundation Trust",
    shortName: "Derbyshire Community",
    postcode: "DE45 1AD"
  },
  {
    longName: "Derbyshire Healthcare NHS Foundation Trust",
    shortName: "Derbyshire Healthcare",
    postcode: "DE22 3LZ"
  },
  {
    longName: "Devon Partnership NHS Trust",
    shortName: "Devon",
    postcode: "EX2 5AF"
  },
  {
    longName: "Doncaster and Bassetlaw Teaching Hospitals NHS Foundation Trust",
    shortName: "Doncaster-Bassetlaw",
    postcode: "DN2 5LT"
  },
  {
    longName: "Dorset County Hospital NHS Foundation Trust",
    shortName: "Dorset County",
    postcode: "DT1 2JY"
  },
  {
    longName: "Dorset Healthcare University NHS Foundation Trust",
    shortName: "Dorset Univ",
    postcode: "BH17 0RB"
  },
  {
    longName: "Dudley and Walsall Mental Health Partnership NHS Trust",
    shortName: "Dudley-Walsall",
    postcode: "DY2 8PS"
  },
  {
    longName: "Dudley Group NHS Foundation Trust",
    shortName: "Dudley Group",
    postcode: "DY1 2HQ"
  },
  {
    longName: "East and North Hertfordshire NHS Trust",
    shortName: "East-North Herts",
    postcode: "SG1 2EF"
  },
  {
    longName: "East Cheshire NHS Trust",
    shortName: "East Cheshire",
    postcode: "SK10 3BL"
  },
  {
    longName: "East Kent Hospitals University NHS Foundation Trust",
    shortName: "East Kent",
    postcode: "CT1 3NG"
  },
  {
    longName: "East Lancashire Hospitals NHS Trust",
    shortName: "East Lancashire",
    postcode: "BB10 2PQ"
  },
  {
    longName: "East London NHS Foundation Trust",
    shortName: "East London",
    postcode: "E1 4DG"
  },
  {
    longName: "East Midlands Ambulance Service NHS Trust",
    shortName: "East Midlands Ambu",
    postcode: "NG8 6PY"
  },
  {
    longName: "East Of England Ambulance Service NHS Trust",
    shortName: "East Of England Ambu",
    postcode: "SG1 2EQ"
  },
  {
    longName: "East Suffolk and North Essex NHS Foundation Trust",
    shortName: "E Suffolk - N Essex",
    postcode: "CO4 9QR"
  },
  {
    longName: "East Sussex Healthcare NHS Trust",
    shortName: "East Sussex",
    postcode: "BN25 1DH"
  },
  {
    longName: "Epsom and St Helier University Hospitals NHS Trust",
    shortName: "Epsom-St Helier",
    postcode: "KT18 7EG"
  },
  {
    longName: "Essex Partnership University NHS Foundation Trust",
    shortName: "Essex",
    postcode: "SS11 7XX"
  },
  {
    longName: "Frimley Health NHS Foundation Trust",
    shortName: "Frimley",
    postcode: "GU16 7UJ"
  },
  {
    longName: "Gateshead Health NHS Foundation Trust",
    shortName: "Gateshead",
    postcode: "NE9 6SX"
  },
  {
    longName: "George Eliot Hospital NHS Trust",
    shortName: "George Eliot",
    postcode: "CV10 7DJ"
  },
  {
    longName: "Gloucestershire Care Services NHS Trust",
    shortName: "Gloucs Care",
    postcode: "GL3 4AW"
  },
  {
    longName: "Gloucestershire Hospitals NHS Foundation Trust",
    shortName: "Gloucs Hospitals",
    postcode: "GL53 7AN"
  },
  {
    longName: "Great Ormond Street Hospital for Children NHS Foundation Trust",
    shortName: "GOSH",
    postcode: "WC1N 3JH"
  },
  {
    longName: "Great Western Hospitals NHS Foundation Trust",
    shortName: "Great Western",
    postcode: "SN3 6BB"
  },
  {
    longName: "Greater Manchester Mental Health NHS Foundation Trust",
    shortName: "Grtr Manchester",
    postcode: "M21 9UN"
  },
  {
    longName: "Guy's and St Thomas' NHS Foundation Trust",
    shortName: "Guy's-St Thomas'",
    postcode: "SE1 7EH"
  },
  {
    longName: "Hampshire Hospitals NHS Foundation Trust",
    shortName: "Hampshire",
    postcode: "RG24 9NA"
  },
  {
    longName: "Harrogate and District NHS Foundation Trust",
    shortName: "Harrogate",
    postcode: "HG2 7SX"
  },
  {
    longName: "Hertfordshire Community NHS Trust",
    shortName: "Herts Community",
    postcode: "AL7 1BW"
  },
  {
    longName: "Hertfordshire Partnership University NHS Foundation Trust",
    shortName: "Herts Univ",
    postcode: "SG1 4YS"
  },
  {
    longName: "Hillingdon Hospitals NHS Foundation Trust",
    shortName: "Hillingdon",
    postcode: "UB8 3NN"
  },
  {
    longName: "Homerton University Hospital NHS Foundation Trust",
    shortName: "Homerton",
    postcode: "E9 6SR"
  },
  {
    longName: "Hounslow and Richmond Community Healthcare NHS Trust",
    shortName: "Hounslow-Richmond",
    postcode: "TW11 8HU"
  },
  {
    longName: "Hull and East Yorkshire Hospitals NHS Trust",
    shortName: "Hull-East Yorkshire",
    postcode: "HU3 2JZ"
  },
  {
    longName: "Humber NHS Foundation Trust",
    shortName: "Humber",
    postcode: "HU17 9LP"
  },
  {
    longName: "Imperial College Healthcare NHS Trust",
    shortName: "Imperial",
    postcode: "W12 0NN",
    recordLocations: ["CERNER", "SystemOne", "Emis"]
  },
  {
    longName: "Isle Of Wight NHS Trust",
    shortName: "Isle Of Wight",
    postcode: "PO30 5TG"
  },
  {
    longName: "James Paget University Hospitals NHS Foundation Trust",
    shortName: "James Paget",
    postcode: "NR31 6LA"
  },
  {
    longName: "Kent and Medway NHS and Social Care Partnership Trust",
    shortName: "Kent-Medway-Social",
    postcode: "ME7 4JL"
  },
  {
    longName: "Kent Community Health NHS Foundation Trust",
    shortName: "Kent Community",
    postcode: "ME16 9NT"
  },
  {
    longName: "Kettering General Hospital NHS Foundation Trust",
    shortName: "Kettering",
    postcode: "NN16 8UZ"
  },
  {
    longName: "King's College Hospital NHS Foundation Trust",
    shortName: "King's",
    postcode: "SE5 9RS"
  },
  {
    longName: "Kingston Hospital NHS Foundation Trust",
    shortName: "Kingston",
    postcode: "KT2 7QB"
  },
  {
    longName: "Lancashire Care NHS Foundation Trust",
    shortName: "Lancashire Care",
    postcode: "PR5 6AW"
  },
  {
    longName: "Lancashire Teaching Hospitals NHS Foundation Trust",
    shortName: "Lancashire Teaching",
    postcode: "PR7 1PP"
  },
  {
    longName: "Leeds and York Partnership NHS Foundation Trust",
    shortName: "Leeds-York",
    postcode: "LS15 8ZB"
  },
  {
    longName: "Leeds Community Healthcare NHS Trust",
    shortName: "Leeds Community",
    postcode: "LS6 1PF"
  },
  {
    longName: "Leeds Teaching Hospitals NHS Trust",
    shortName: "Leeds Teaching",
    postcode: "LS9 7TF"
  },
  {
    longName: "Leicestershire Partnership NHS Trust",
    shortName: "Leicestershire",
    postcode: "LE19 1SS"
  },
  {
    longName: "Lewisham and Greenwich NHS Trust",
    shortName: "Lewisham-Greenwich",
    postcode: "SE4 2LA"
  },
  {
    longName: "Lincolnshire Community Health Services NHS Trust",
    shortName: "Lincolnshire Community",
    postcode: "LN5 7JH"
  },
  {
    longName: "Lincolnshire Partnership NHS Foundation Trust",
    shortName: "Lincolnshire Partnership",
    postcode: "LN1 1FS"
  },
  {
    longName: "Liverpool Heart and Chest NHS Foundation Trust",
    shortName: "Liverpool Heart-Chest",
    postcode: "L14 3PE"
  },
  {
    longName: "Liverpool Women's NHS Foundation Trust",
    shortName: "Liverpool Women's",
    postcode: "L8 7SS"
  },
  {
    longName: "London Ambulance Service NHS Trust",
    shortName: "London Ambu",
    postcode: "SE1 0BW"
  },
  {
    longName: "London North West University Healthcare NHS Trust",
    shortName: "London NW",
    postcode: "HA1 3UJ"
  },
  {
    longName: "Luton and Dunstable University Hospital NHS Foundation Trust",
    shortName: "Luton-Dunstable",
    postcode: "LU4 0DZ"
  },
  {
    longName: "Maidstone and Tunbridge Wells NHS Trust",
    shortName: "Maidstone-TunbridgeW",
    postcode: "TN2 4QJ"
  },
  {
    longName: "Manchester University NHS Foundation Trust",
    shortName: "Manchester",
    postcode: "M13 9WL"
  },
  {
    longName: "Medway NHS Foundation Trust",
    shortName: "Medway",
    postcode: "ME7 5NY"
  },
  {
    longName: "Mersey Care NHS Foundation Trust",
    shortName: "Mersey Care",
    postcode: "L31 1HW"
  },
  {
    longName: "Mid Cheshire Hospitals NHS Foundation Trust",
    shortName: "Mid Cheshire",
    postcode: "CW1 4QJ"
  },
  {
    longName: "Mid Essex Hospital Services NHS Trust",
    shortName: "Mid Essex",
    postcode: "CM2 0QH"
  },
  {
    longName: "Mid Yorkshire Hospitals NHS Trust",
    shortName: "Mid Yorkshire",
    postcode: "WF1 4DG"
  },
  {
    longName: "Midlands Partnership NHS Foundation Trust",
    shortName: "Midlands",
    postcode: "ST16 3SR"
  },
  {
    longName: "Milton Keynes University Hospital NHS Foundation Trust",
    shortName: "Milton Keynes",
    postcode: "MK6 5LD"
  },
  {
    longName: "Moorfields Eye Hospital NHS Foundation Trust",
    shortName: "Moorfields Eye",
    postcode: "EC1V 2PD"
  },
  {
    longName: "Newcastle Upon Tyne Hospitals NHS Foundation Trust",
    shortName: "Newcastle Upon Tyne",
    postcode: "NE7 7DN"
  },
  {
    longName: "Norfolk and Norwich University Hospitals NHS Foundation Trust",
    shortName: "Norfolk-Norwich",
    postcode: "NR4 7UY"
  },
  {
    longName: "Norfolk and Suffolk NHS Foundation Trust",
    shortName: "Norfolk-Suffolk",
    postcode: "NR6 5BE"
  },
  {
    longName: "Norfolk Community Health and Care NHS Trust",
    shortName: "Norfolk Community",
    postcode: "NR2 3TU"
  },
  {
    longName: "North Bristol NHS Trust",
    shortName: "North Bristol",
    postcode: "BS10 5NB"
  },
  {
    longName: "North Cumbria University Hospitals NHS Trust",
    shortName: "North Cumbria",
    postcode: "CA2 7HY"
  },
  {
    longName: "North East Ambulance Service NHS Foundation Trust",
    shortName: "North East Ambu",
    postcode: "SE1 0BW"
  },
  {
    longName: "North East London NHS Foundation Trust",
    shortName: "North East London",
    postcode: "RM13 8EU"
  },
  {
    longName: "North Middlesex University Hospital NHS Trust",
    shortName: "North Middlesex",
    postcode: "N18 1QX"
  },
  {
    longName: "North Staffordshire Combined Healthcare NHS Trust",
    shortName: "North Staffs",
    postcode: "ST4 8HH"
  },
  {
    longName: "North Tees and Hartlepool NHS Foundation Trust",
    shortName: "North Tees-Hartlepool",
    postcode: "TS19 8PE"
  },
  {
    longName: "North West Ambulance Service NHS Trust",
    shortName: "North West Ambu",
    postcode: "CH2 1UL"
  },
  {
    longName: "North West Anglia NHS Foundation Trust",
    shortName: "North West Anglia",
    postcode: "PE3 9GZ"
  },
  {
    longName: "North West Boroughs Healthcare NHS Foundation Trust",
    shortName: "North West Boroughs",
    postcode: "WA2 8WA"
  },
  {
    longName: "Northampton General Hospital NHS Trust",
    shortName: "Northampton General",
    postcode: "NN1 5BD"
  },
  {
    longName: "Northamptonshire Healthcare NHS Foundation Trust",
    shortName: "Northamptonshire",
    postcode: "NN15 6XR"
  },
  {
    longName: "Northern Devon Healthcare NHS Trust",
    shortName: "Northern Devon",
    postcode: "EX31 4JB"
  },
  {
    longName: "Northern Lincolnshire and Goole NHS Foundation Trust",
    shortName: "Northern Lincolnshire-Goole",
    postcode: "DN15 7BH"
  },
  {
    longName: "Northumberland, Tyne and Wear NHS Foundation Trust",
    shortName: "Northumberland-Tyne-Wear",
    postcode: "NE3 3XT"
  },
  {
    longName: "Northumbria Healthcare NHS Foundation Trust",
    shortName: "Northumbria",
    postcode: "NE27 0QJ"
  },
  {
    longName: "Nottingham University Hospitals NHS Trust",
    shortName: "Nottingham Univ",
    postcode: "NG5 1PB"
  },
  {
    longName: "Nottinghamshire Healthcare NHS Foundation Trust",
    shortName: "Nottinghamshire",
    postcode: "NG3 6AA"
  },
  {
    longName: "Oxford Health NHS Foundation Trust",
    shortName: "Oxford Health",
    postcode: "OX4 2PG"
  },
  {
    longName: "Oxford University Hospitals NHS Foundation Trust",
    shortName: "Oxford Univ",
    postcode: "OX4 2PG"
  },
  {
    longName: "Oxleas NHS Foundation Trust",
    shortName: "Oxleas",
    postcode: "SE20 7TS"
  },
  {
    longName: "Pennine Acute Hospitals NHS Trust",
    shortName: "Pennine Acute",
    postcode: "OL1 2JH"
  },
  {
    longName: "Pennine Care NHS Foundation Trust",
    shortName: "Pennine Care",
    postcode: "OL6 7SR"
  },
  {
    longName: "Poole Hospital NHS Foundation Trust",
    shortName: "Poole",
    postcode: "BH15 2JB"
  },
  {
    longName: "Portsmouth Hospitals NHS Trust",
    shortName: "Portsmouth",
    postcode: "PO6 3LY"
  },
  {
    longName: "Princess Alexandra Hospital NHS Trust",
    shortName: "Princess Alexandra",
    postcode: "CM20 1QX"
  },
  {
    longName: "Queen Elizabeth Hospital, King's Lynn. NHS Foundation Trust",
    shortName: "Queen Eliz King's Lynn",
    postcode: "PE30 4ET"
  },
  {
    longName: "Queen Victoria Hospital NHS Foundation Trust",
    shortName: "Queen Victoria",
    postcode: "RH19 3DZ"
  },
  {
    longName:
      "Robert Jones and Agnes Hunt Orthopaedic and District Hospital NHS Trust",
    shortName: "Robert Jones-Agnes Hunt",
    postcode: "SY10 7AG"
  },
  {
    longName: "Rotherham Doncaster and South Humber NHS Foundation Trust",
    shortName: "Rotherham-Doncaster-S Humber",
    postcode: "DN4 8QN"
  },
  {
    longName: "Rotherham NHS Foundation Trust",
    shortName: "Rotherham",
    postcode: "S60 2UD"
  },
  {
    longName: "Royal Berkshire NHS Foundation Trust",
    shortName: "Royal Berkshire",
    postcode: "RG1 5AN"
  },
  {
    longName:
      "Royal Bournemouth and Christchurch Hospitals NHS Foundation Trust",
    shortName: "Royal Bournemouth-Christchurch",
    postcode: "BH7 7DW"
  },
  {
    longName: "Royal Brompton and Harefield NHS Foundation Trust",
    shortName: "Brompton-Harefield",
    postcode: "SW3 6NP"
  },
  {
    longName: "Royal Cornwall Hospitals NHS Trust",
    shortName: "Royal Cornwall",
    postcode: "TR1 3LJ"
  },
  {
    longName: "Royal Devon and Exeter NHS Foundation Trust",
    shortName: "Royal Devon-Exeter",
    postcode: "EX2 5DW"
  },
  {
    longName: "Royal Free London NHS Foundation Trust",
    shortName: "Royal Free London",
    postcode: "NW3 2QG"
  },
  {
    longName: "Royal Liverpool and Broadgreen University Hospitals NHS Trust",
    shortName: "Royal Liverpool-Broadgreen",
    postcode: "L7 8XP"
  },
  {
    longName: "Royal Marsden NHS Foundation Trust",
    shortName: "Royal Marsden",
    postcode: "SW3 6JJ"
  },
  {
    longName: "Royal National Orthopaedic Hospital NHS Trust",
    shortName: "Royal National Orthopaedic",
    postcode: "HA7 4LP"
  },
  {
    longName: "Royal Orthopaedic Hospital NHS Foundation Trust",
    shortName: "Royal Orthopaedic",
    postcode: "B31 2AP"
  },
  {
    longName: "Royal Papworth Hospital NHS Foundation Trust",
    shortName: "Royal Papworth",
    postcode: "CB23 3RE"
  },
  {
    longName: "Royal Surrey County NHS Foundation Trust",
    shortName: "Royal Surrey County",
    postcode: "GU2 7XX"
  },
  {
    longName: "Royal United Hospitals Bath NHS Foundation Trust",
    shortName: "Royal United Bath",
    postcode: "BA1 3NG"
  },
  {
    longName: "Royal Wolverhampton NHS Trust",
    shortName: "Royal Wolverhampton",
    postcode: "WV10 0QP"
  },
  {
    longName: "Salford Royal NHS Foundation Trust",
    shortName: "Salford Royal",
    postcode: "M6 8HD"
  },
  {
    longName: "Salisbury NHS Foundation Trust",
    shortName: "Salisbury",
    postcode: "SP2 8BJ"
  },
  {
    longName: "Sandwell and West Birmingham Hospitals NHS Trust",
    shortName: "Sandwell-West Birmingham",
    postcode: "B71 4HJ"
  },
  {
    longName: "Sheffield Children's NHS Foundation Trust",
    shortName: "Sheffield Children's",
    postcode: "S10 2TQ"
  },
  {
    longName: "Sheffield Health and Social Care NHS Foundation Trust",
    shortName: "Sheffield -Social Care",
    postcode: "S10 3TG"
  },
  {
    longName: "Sheffield Teaching Hospitals NHS Foundation Trust",
    shortName: "Sheffield",
    postcode: "S10 2JF"
  },
  {
    longName: "Sherwood Forest Hospitals NHS Foundation Trust",
    shortName: "Sherwood Forest",
    postcode: "NG18 5QJ"
  },
  {
    longName: "Shrewsbury and Telford Hospital NHS Trust",
    shortName: "Shrewsbury-Telford",
    postcode: "SY3 8XQ"
  },
  {
    longName: "Shropshire Community Health NHS Trust",
    shortName: "Shropshire",
    postcode: "SY3 8XL"
  },
  { longName: "Solent NHS Trust", shortName: "Solent", postcode: "SO19 8BR" },
  {
    longName: "Somerset Partnership NHS Foundation Trust",
    shortName: "Somerset",
    postcode: "TA6 4RN"
  },
  {
    longName: "South Central Ambulance Service NHS Foundation Trust",
    shortName: "South Central Ambu",
    postcode: "SO21 2RU"
  },
  {
    longName: "South East Coast Ambulance Service NHS Foundation Trust",
    shortName: "South East Coast Ambu",
    postcode: "SM7 2AS"
  },
  {
    longName: "South London and Maudsley NHS Foundation Trust",
    shortName: "S London-Maudsley",
    postcode: "SE8 5HA"
  },
  {
    longName: "South Tees Hospitals NHS Foundation Trust",
    shortName: "S Tees",
    postcode: "TS6 6TD"
  },
  {
    longName: "South Tyneside NHS Foundation Trust",
    shortName: "S Tyneside",
    postcode: "NE33 4JP"
  },
  {
    longName: "South Warwickshire NHS Foundation Trust",
    shortName: "S Warwickshire",
    postcode: "CV34 5BW"
  },
  {
    longName: "South West London and St George's Mental Health NHS Trust",
    shortName: "SW London-St George's",
    postcode: "SW17 7DJ"
  },
  {
    longName: "South West Yorkshire Partnership NHS Foundation Trust",
    shortName: "SW Yorkshire",
    postcode: "WF1 3SP"
  },
  {
    longName: "South Western Ambulance Service NHS Foundation Trust",
    shortName: "South Western Ambu",
    postcode: "TA1 2LB"
  },
  {
    longName: "Southend University Hospital NHS Foundation Trust",
    shortName: "Southend",
    postcode: "SS0 0RY"
  },
  {
    longName: "Southern Health NHS Foundation Trust",
    shortName: "Southern",
    postcode: "PO9 2JJ"
  },
  {
    longName: "Southport and Ormskirk Hospital NHS Trust",
    shortName: "Southport-Ormskirk",
    postcode: "PR8 6PN"
  },
  {
    longName: "St George's University Hospitals NHS Foundation Trust",
    shortName: "St George's",
    postcode: "SW17 0RE"
  },
  {
    longName: "St Helens and Knowsley Hospitals NHS Trust",
    shortName: "St Helens-Knowsley",
    postcode: "WA9 3DA"
  },
  {
    longName: "Stockport NHS Foundation Trust",
    shortName: "Stockport",
    postcode: "SK2 7JE"
  },
  {
    longName: "Surrey and Borders Partnership NHS Foundation Trust",
    shortName: "Surrey-Borders",
    postcode: "RH1 1TA"
  },
  {
    longName: "Surrey and Sussex Healthcare NHS Trust",
    shortName: "Surrey-Sussex",
    postcode: "KT22 9AE"
  },
  {
    longName: "Sussex Community NHS Foundation Trust",
    shortName: "Sussex Community",
    postcode: "BN2 9DH"
  },
  {
    longName: "Sussex Partnership NHS Foundation Trust",
    shortName: "Sussex Partnership",
    postcode: "BN11 1RY"
  },
  {
    longName: "Tameside Hospital NHS Foundation Trust",
    shortName: "Tameside",
    postcode: "OL6 9RW"
  },
  {
    longName: "Taunton and Somerset NHS Foundation Trust",
    shortName: "Taunton-Somerset",
    postcode: "TA1 5DA"
  },
  {
    longName: "Tavistock and Portman NHS Foundation Trust",
    shortName: "Tavistock-Portman",
    postcode: "NW3 5BA"
  },
  {
    longName: "Tees, Esk and Wear Valleys NHS Foundation Trust",
    shortName: "Tees, Esk-Wear Valleys",
    postcode: "TS19 0EA"
  },
  {
    longName: "Torbay and South Devon NHS Foundation Trust",
    shortName: "Torbay-South Devon",
    postcode: "TQ2 7AA"
  },
  {
    longName: "United Lincolnshire Hospitals NHS Trust",
    shortName: "United Lincolnshire",
    postcode: "LN2 5QY"
  },
  {
    longName: "University College London Hospitals NHS Foundation Trust",
    shortName: "UCL",
    postcode: "NW1 2BU"
  },
  {
    longName: "University Hospital Birmingham NHS Foundation Trust",
    shortName: "UH Birmingham",
    postcode: "B15 2TH"
  },
  {
    longName: "University Hospital of Derby and Burton NHS Foundation Trust",
    shortName: "Derby-Burton",
    postcode: "DE13 0RB"
  },
  {
    longName: "University Hospital Southampton NHS Foundation Trust",
    shortName: "Southampton",
    postcode: "SO16 6YD"
  },
  {
    longName: "University Hospitals Bristol NHS Foundation Trust",
    shortName: "Bristol",
    postcode: "BS2 8AE"
  },
  {
    longName: "University Hospitals Coventry and Warwickshire NHS Trust",
    shortName: " Coventry-Warwickshire",
    postcode: "CV2 2DX"
  },
  {
    longName: "University Hospitals Of Leicester NHS Trust",
    shortName: " Leicester",
    postcode: "LE5 4PW"
  },
  {
    longName: "University Hospitals Of Morecambe Bay NHS Foundation Trust",
    shortName: " Morecambe Bay",
    postcode: "LA9 7RG"
  },
  {
    longName: "University Hospitals of North Midlands",
    shortName: " U North Midlands",
    postcode: "ST4 6QG"
  },
  {
    longName: "University Hospitals Plymouth NHS Trust",
    shortName: " Plymouth",
    postcode: "PL6 5QZ"
  },
  {
    longName: "Walsall Healthcare NHS Trust",
    shortName: "Walsall",
    postcode: "WS2 9PS"
  },
  {
    longName: "Walton Centre NHS Foundation Trust",
    shortName: "Walton",
    postcode: "L9 7LJ"
  },
  {
    longName: "Warrington and Halton Hospitals NHS Foundation Trust",
    shortName: "Warrington-Halton",
    postcode: "WA5 1QG"
  },
  {
    longName: "West Hertfordshire Hospitals NHS Trust",
    shortName: "West Hertfordshire",
    postcode: "WD18 0HB"
  },
  {
    longName: "West London NHS Trust",
    shortName: "West London",
    postcode: "UB1 3EU"
  },
  {
    longName: "West Midlands Ambulance Service NHS Foundation Trust",
    shortName: "West Midlands Ambu",
    postcode: "DY5 1LX"
  },
  {
    longName: "West Suffolk NHS Foundation Trust",
    shortName: "West Suffolk",
    postcode: "IP33 2QZ"
  },
  {
    longName: "Western Sussex Hospitals NHS Foundation Trust",
    shortName: "Western Sussex",
    postcode: "BN11 2DH"
  },
  {
    longName: "Weston Area Health NHS Trust",
    shortName: "Weston Area",
    postcode: "BS23 4TQ"
  },
  {
    longName: "Whipps Cross University Hospital NHS Trust",
    shortName: "Whipps Cross",
    postcode: "E11 1NR"
  },
  {
    longName: "Whittington Health NHS Trust",
    shortName: "Whittington",
    postcode: "N19 5NF"
  },
  {
    longName: "Wirral Community NHS Foundation Trust",
    shortName: "Wirral Community",
    postcode: "CH42 0LQ"
  },
  {
    longName: "Wirral University Teaching Hospital NHS Foundation Trust",
    shortName: "Wirral Teaching",
    postcode: "CH49 5PE"
  },
  {
    longName: "Worcestershire Acute Hospitals NHS Trust",
    shortName: "Worcestershire Acute",
    postcode: "WR5 1DD"
  },
  {
    longName: "Worcestershire Health and Care NHS Trust",
    shortName: "Worcestershire Health and Care",
    postcode: "WR4 9RW"
  },
  {
    longName: "Wrightington, Wigan and Leigh NHS Foundation Trust",
    shortName: "Wrightington-Wigan-Leigh",
    postcode: "WN1 2NN"
  },
  {
    longName: "Wye Valley NHS Trust",
    shortName: "Wye Valley",
    postcode: "HR2 9RP"
  },
  {
    longName: "Yeovil District Hospital NHS Foundation Trust",
    shortName: "Yeovil",
    postcode: "BA21 4AT"
  },
  {
    longName: "York Teaching Hospital NHS Foundation Trust",
    shortName: "York",
    postcode: "YO31 8HE"
  },
  {
    longName: "Yorkshire Ambulance Service NHS Trust",
    shortName: "Yorkshire Ambu",
    postcode: "WF2 0XQ"
  },
  {
    longName: "ZZTest Non-existent NHS Trust (testing only)",
    shortName: "ZZTest",
    postcode: "ZZ0 0ZZ"
  }
];

<template>
  <div>
    <section class="p-2" style="height: 100vh; overflow-y: auto">
      <app-header-bar
        :title="trials.publicObj[id] ? trials.publicObj[id].shortText : id"
      ></app-header-bar>
      <div v-if="trialUserAccessIsLoaded">
        <transition name="swish">
          <b-alert show v-if="nobody" variant="primary">
            <h3>Welcome</h3>
            <h6>
              This trial needs a primary researcher, who will be notified by
              email and SMS as soon as a referral arrives. Ask them to log in on
              the App, and enter their mobile number under "My Account".
            </h6>
          </b-alert>
        </transition>

        <transition name="swish">
          <b-alert show v-if="onlyOne" variant="light">
            <h3>Other researchers?</h3>
            <h6>
              Are there any other researchers in the team? If the main
              researcher is away, is there another researcher that covers the
              work? If it is you who provides this cover, you should add
              yourself as a researcher, so that you can give yourself access to
              the NHS number (which you can't see as only a manager).
            </h6>
          </b-alert>
        </transition>

        <transition name="swish">
          <b-alert show v-if="!nobody && nobodyCanSeeNhs" variant="danger">
            <h3>NHS Number</h3>
            <h6>
              Currently you are not letting anyone see the NHS Number of
              referrals. Please give this permission to at least one person.
              Usually you will give it to all research nurses or doctors who
              will receive referrals, check the patient's medical records, and
              contact the patient with more information about the trial.
            </h6>
          </b-alert>
        </transition>

        <transition name="swish">
          <b-alert
            show
            v-if="nobodyGettingInstantNotifications && !nobody"
            variant="danger"
          >
            <h3>New referral notifications</h3>
            <hr />
            <h6>
              Please set at least one researcher to receive instant
              notifications when new referrals arrive. They should enter their
              mobile number on their app under "My Account".
            </h6>
          </b-alert>
        </transition>

        <div>
          <b-table
            striped
            hover
            :items="trialUserAccessArray"
            :fields="trialUserFields"
            style="background-color: white; color: black"
            class="mb-0 pb-0"
          >
            <template #cell(email)="data">
              <div>{{ data.item.email || "..." }}</div>
              <div v-if="0">{{ data.item.email || "..." }}</div>
            </template>

            <template #cell(seeNhsTableSlotKey)="data">
              <div>
                <big-check-box
                  :checked="data.item[ACCESS.SEE_NHS]"
                  class="checkbox-big"
                  @click="
                    setAccess(
                      data.item.userId,
                      ACCESS.SEE_NHS,
                      !data.item[ACCESS.SEE_NHS]
                    )
                  "
                ></big-check-box>
                <div
                  v-if="
                    !data.item[ACCESS.SEE_NHS] &&
                    data.item[ACCESS.SUBSCRIBE_INSTANT]
                  "
                  style="color: red"
                >
                  Enable "see" or disable "notification", to avoid frustrating
                  them.
                </div>
              </div>
            </template>

            <template #cell(subscribeInstantTableSlotKey)="data">
              <div>
                <big-check-box
                  :checked="data.item[ACCESS.SUBSCRIBE_INSTANT]"
                  class="checkbox-big"
                  @click="
                    setAccess(
                      data.item.userId,
                      ACCESS.SUBSCRIBE_INSTANT,
                      !data.item[ACCESS.SUBSCRIBE_INSTANT]
                    )
                  "
                ></big-check-box>
              </div>
            </template>

            <template #cell(subscribeRegularTableSlotKey)="data">
              <div>
                <big-check-box
                  :checked="data.item[ACCESS.SUBSCRIBE_REGULAR]"
                  class="checkbox-big"
                  @click="
                    setAccess(
                      data.item.userId,
                      ACCESS.SUBSCRIBE_REGULAR,
                      !data.item[ACCESS.SUBSCRIBE_REGULAR]
                    )
                  "
                ></big-check-box>
              </div>
            </template>
          </b-table>

          <div
            class="d-flex flex-row justify-content-end mt-0 pt-0"
            style="font-size: 70%"
          >
            <div>
              Please be patient when you make a change. The app notifies the
              server immediately, but only updates the screen when the server
              confirms that the change has been enacted.
            </div>
          </div>

          <div>
            <h4>Add researcher by email</h4>
            <b-form-input
              v-model="emailOfNewResearcher"
              :formatter="formatEmail"
              @click.native="clearAddNewResearcherMessage()"
              @keyup.enter.native="
                tryAddNewResearcherByEmail(emailOfNewResearcher)
              "
            ></b-form-input>
            <transition name="swish-enter">
              <b-alert show v-if="addNewResearcherMessage">{{
                addNewResearcherMessage
              }}</b-alert>
            </transition>
            <b-button
              v-if="emailOfNewResearcher.includes('@')"
              variant="warning"
              @click="tryAddNewResearcherByEmail(emailOfNewResearcher)"
              >Add</b-button
            >
          </div>
        </div>
      </div>
      <div v-else>Loading access details for this trial ...</div>
    </section>
  </div>
</template>

<script>
import {
  //DB
  accessByTrialThenUserRef,

  // Async functions
  getIdFromEmailObjToDataObj,
  getEmailFromIdObjToDataObj,
  getFullNameFromIdObjToDataObj,
  setResearcherAccess,
} from "../util/firebase.js";
import BigCheckBox from "./BigCheckBox.vue";
import { ACCESS } from "../util/firebase.js";
import AppHeaderBar from "./AppHeaderBar.vue";

export default {
  components: { BigCheckBox, AppHeaderBar },
  props: {
    // Props sent from top level of App
    user: { type: Object, required: true },
    trials: { type: Object, required: true },

    // Props sent by preceding page
    id: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      trialUserAccessObject: {},
      trialUserAccessArray: [],
      trialUserAccessIsLoaded: false,
      trialUserFields: [
        // "..TableSlotKey" is the suffix I am using to say "This is a virtual field, whose exact definition is given within the b-table"
        { label: "Email and Mobile", key: "email", sortable: true },
        // { label: "Read Status", key: "read", sortable: true },
        // { label: "Write Status", key: "write", sortable: true },
        {
          label: "Can see NHS Numbers (Give to all researchers)",
          key: "seeNhsTableSlotKey",
          sortable: false,
        },
        {
          label: "Gets instant notification of new referrals",
          key: "subscribeInstantTableSlotKey",
          sortable: false,
        },
        {
          label: "Gets routine reports without NHS numbers",
          key: "subscribeRegularTableSlotKey",
          sortable: false,
        },
        { label: "Name (self-entered)", key: "fullName", sortable: true },
      ],
      emailOfNewResearcher: "",
      addNewResearcherMessage: "",

      // Just make visible in THIS, From IMPORTed globals:
      ACCESS,
    };
  },

  computed: {
    nobody() {
      return this.trialUserAccessArray.length === 0;
    },
    onlyOne() {
      return this.trialUserAccessArray.length === 1;
    },
    nobodyCanSeeNhs() {
      return (
        this.trialUserAccessArray.filter((user) => user[ACCESS.SEE_NHS])
          .length === 0
      );
    },
    nobodyGettingInstantNotifications() {
      return (
        this.trialUserAccessArray.filter(
          (user) => user[ACCESS.SUBSCRIBE_INSTANT]
        ).length === 0
      );
    },
  },

  created: function () {
    this.accessListener = accessByTrialThenUserRef
      .child(this.id)
      .on("value", (snapshot) => {
        // Make main array of people, but this is by ID, and doesn't contain emails or fullName yet.
        const newObject = snapshot.val() || {};
        Object.keys(newObject).forEach((userId) => {
          if (!this.trialUserAccessObject[userId]) {
            this.trialUserAccessObject[userId] = {};
          }
          Object.assign(this.trialUserAccessObject[userId], newObject[userId]); // Copy into trialUserAccessObject but don't blank out emails already held in there.

          if (!this.trialUserAccessObject[userId].email) {
            getEmailFromIdObjToDataObj({ id: userId }).then((result) => {
              this.trialUserAccessObject[userId].email = result.data.email;
              this.updateTrialUserAccessArray();
            });
          }

          if (!this.trialUserAccessObject[userId].email) {
            getFullNameFromIdObjToDataObj({ id: userId }).then((result) => {
              this.trialUserAccessObject[userId].fullName =
                result.data.fullName;
              this.updateTrialUserAccessArray();
            });
          }
        });

        this.updateTrialUserAccessArray();
        this.trialUserAccessIsLoaded = true; // But emails and fullName will be filled in later
      });
  },

  beforeDestroy: function () {
    accessByTrialThenUserRef.child(this.id).off("value", this.accessListener);
  },

  methods: {
    updateTrialUserAccessArray() {
      this.trialUserAccessArray = Object.keys(this.trialUserAccessObject).map(
        (userId) => ({
          ...this.trialUserAccessObject[userId],
          userId: userId,
        })
      );
    },

    setAccess(targetUserId, accessType, newValue) {
      // First set the LOCAL state to "waitServer", which makes the Vue component big-check-box show a "waiting" indicator.

      // Then send info to firebase.

      // When firebase updates, the "on ... value" thingy will fire and replace the local state with the (new) firebase value, causing the component to update on screen

      this.trialUserAccessArray
        .filter((userObj) => userObj.userId === targetUserId)
        .forEach(
          /* there will only be one, and this will be it --> */ (userObj) => {
            this.$set(userObj, accessType, "waitServer");
          }
        );

      return setResearcherAccess({
        targetUserId,
        trialId: this.id,
        updateObject: { [accessType]: newValue },
      });
    },

    async tryAddNewResearcherByEmail(email) {
      this.addNewResearcherMessage = `Searching for ${email}.`;
      this.emailOfNewResearcher = "";
      let targetUserId;
      try {
        targetUserId = (
          await getIdFromEmailObjToDataObj({
            email: email,
          })
        ).data.id;
        this.addNewResearcherMessage = "";
      } catch (e) {
        this.addNewResearcherMessage = `${email} is not on the system yet. Please ask them to register on the App first.`;
        return;
      }

      if (this.trialUserAccessObject[targetUserId]) {
        this.addNewResearcherMessage = `${email} is already listed above.`;
        return;
      }

      return setResearcherAccess({
        targetUserId,
        trialId: this.id,
        updateObject: {
          [ACCESS.SEE_NHS]: true,
          [ACCESS.SUBSCRIBE_INSTANT]: true,
          [ACCESS.SUBSCRIBE_REGULAR]: true,
        },
      });
    },

    clearAddNewResearcherMessage() {
      this.addNewResearcherMessage = "";
    },

    formatEmail(email /*, event */) {
      // To add a non NHS email, put the @ part first!
      email = email.trim();
      // if (email.length >= 5 && email.charAt(email.length - 1) === "@") {
      //   email += "nhs.net";
      // }
      return email;
    },

    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.full-name {
  max-width: 30vw;
  max-height: 6em;
  overflow-wrap: break-word;
  overflow: hidden;
}

.user-id {
  max-width: 20vw;
  overflow-wrap: break-word;
}

.checkbox-big {
  color: #005eb9;
}
</style>


export function tabbing(node, wholeTreeObj) {
  // To be used with SlVueTree
  const path = node.path;
  const length = path.length;
  let out = "";
  for (let tab = 0; tab < length - 1; tab++) {
    const pathAtThisLevel = path.slice(0, tab + 1);
    let potentialNextSiblingPathAtThisLevel = JSON.parse(
      JSON.stringify(pathAtThisLevel)
    );
    potentialNextSiblingPathAtThisLevel[
      potentialNextSiblingPathAtThisLevel.length - 1
    ]++;
    const nextSiblingExistsAtThisLevel =
      !!wholeTreeObj &&
      !!wholeTreeObj.getNode(potentialNextSiblingPathAtThisLevel);

    out += (nextSiblingExistsAtThisLevel ? "│" : "\u00a0") + " ";
  }
  return out;
}

import { TITLE_PATH_SEPARATOR } from "../util/constants.js";
import { diagnosisTreeFullTheoreticalForm } from "../util/diagnosisTreeFullTheoreticalForm.js";

export function matchDiagnosis(a1, a2) {
  // Any intersection is accepted for diagnoses, so if your trial lists "AF" and "HF" as diagnoses, it will pop up for a patient with "AF" and a patient with "HF"
  const s1 = new Set(a1);
  const s2 = new Set(a2);
  const intersection = [...s1].filter(x => s2.has(x));
  return intersection.length > 0;
}

export function getLastElementsOnly(arrayOfDiagnosisTitlePathsWithSeparators) {
  return arrayOfDiagnosisTitlePathsWithSeparators.map(title => {
    if (title.includes(TITLE_PATH_SEPARATOR)) {
      title = title.slice(title.lastIndexOf(TITLE_PATH_SEPARATOR) + TITLE_PATH_SEPARATOR.length);
    }
    return title.trim();
  });
}

export function treeOfDiagnosesOfTrialsInGeography(diagnosisTreeOfNodesOfUnknownGeovalidity, georestrictedTrialIdArray, trials) {
  // Step 1. Find all symptom (last element) of all trials that are geoValid

  let geovalidSymptomTitles = [];
  georestrictedTrialIdArray.forEach(trialId => {
    const trialObj = trials.publicObj[trialId];
    const diagnosesLastElementsOnly = getLastElementsOnly(trialObj.diagnoses);
    diagnosesLastElementsOnly.forEach(diagnosisLastElement => {
      geovalidSymptomTitles.push(diagnosisLastElement);
    });
  });
  const uniqueGeovalidSymptomTitles = [...new Set(geovalidSymptomTitles)];

  // Step 2. Delete tree LEAVES that do not feed in to any eligible trial, and flatten branches that have only one child
  function prunedTree(oldTree, uniqueGeovalidSymptomTitles) {
    //   console.log("oldTree:", oldTree);
    let newTree = [];
    oldTree.forEach(node => {
      node.isDraggable = false;
      node.isSelectable = true;
      node.isExpanded = true;

      if (!node.children || node.children.length === 0) {
        node.isLeaf = true;
        if (matchDiagnosis(getLastElementsOnly([node.title]), uniqueGeovalidSymptomTitles)) {
          //         console.log(node.title, " is a geovalid leaf");
          newTree.push(JSON.parse(JSON.stringify(node)));
        } else {
          //         console.log(node.title, " is a discarded leaf");
        }
      } else {
        // node is NOT leaf, i.e. it is a parent of 0 or more children
        const newChildrenTree = prunedTree(node.children, uniqueGeovalidSymptomTitles);
        newChildrenTree.sort((node1, node2) => {
          if (node1.isLeaf && !node2.isLeaf) {
            return -1;
          }
          if (node2.isLeaf && !node1.isLeaf) {
            return +1;
          }
        });
        if (newChildrenTree.length >= 2) {
          const newNode = JSON.parse(JSON.stringify(node));
          newNode.children = JSON.parse(JSON.stringify(newChildrenTree));
          newTree.push(newNode);
          //       console.log(node.title, " is a layer preserved");
        }
        if (newChildrenTree.length === 1) {
          newTree.push(newChildrenTree[0]); // Flatten the tree here. There was only one child at the lower level, so promote it.
          //       console.log(node.title, " is a layer collapsed");
        }
        if (newChildrenTree.length === 0) {
          //      console.log(node.title, " is a tree deleted");
          return; // don't push anything
        }
      }
    });
    //  console.log(newTree);
    return newTree;
  }

  // Step 3. Delete  non-leafs that have no children (keep doing until none left to delete)

  return prunedTree(diagnosisTreeOfNodesOfUnknownGeovalidity, uniqueGeovalidSymptomTitles);
}

export function checkDiagnosesAllAppearInFullTree(listOfTrialIdAndDiagnosisPairs) {
  let listOfLeaves = [];
  function addLeavesToListOfLeaves(tree) {
    tree.forEach(node => {
      if (node.children && node.children.length > 0) {
        addLeavesToListOfLeaves(node.children);
      } else {
        listOfLeaves.push(node.title);
      }
    });
  }
  addLeavesToListOfLeaves(diagnosisTreeFullTheoreticalForm);

  let diagnosesAllAppearInFullTree = true;
  let listOfTrialIdAndDiagnosisPairsNotAppearing = [];
  listOfTrialIdAndDiagnosisPairs.forEach(({ trialId, diagnosis }) => {
    if (!listOfLeaves.includes(diagnosis)) {
      diagnosesAllAppearInFullTree = false;
      listOfTrialIdAndDiagnosisPairsNotAppearing.push({ trialId, diagnosis });
    }
  });

  return { diagnosesAllAppearInFullTree, listOfTrialIdAndDiagnosisPairsNotAppearing };
}

<template>
  <div
    style="width:100%;"
    class="d-flex flex-column"
  >
    <section v-if="state==='initial'">
      <b-button
        variant="outline-dark"
        size="sm"
        class="mt-1 d-flex flex-row align-items-center"
        @click="state='onePress'"
      >
        <svg
          class="ion"
          style="height:1em;width:1em;"
        >
          <use xlink:href="#ion-chevron-left">
          </use>
        </svg>
        <div
          class="small"
          style="line-height:1;"
        >Abandon<br>referral</div>

      </b-button>
    </section>

    <!-- <transition name="swish"> -->
    <section v-if="state==='onePress'">
      <b-alert
        show
        variant="danger"
      >
        <h6 style="width:100%; text-align:center;"> You have not yet referred.</h6>

        <div class="d-flex flex-row justify-content-around">
          <b-button
            variant="danger"
            size="sm"
            @click="goHome()"
          >Abandon<br>referral</b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="state='initial'"
          >Continue<br>with<br>referral</b-button>
        </div>

      </b-alert>
    </section>
    <!-- </transition> -->

  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      // Actual Data
      state: "initial"
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.replace({ name: "home" });
    }
  }
};
</script>

<style scoped>
</style>

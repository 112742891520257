export const nhsCcgs = [
  { longName: "Airedale, Wharfedale and Craven CCG: 02N", shortName: "Airedale-Wharfedale-Craven", postcode: "BD20 6RB" },
  { longName: "Ashford CCG: 09C", shortName: "Ashford", postcode: "TN25 4AB" },
  { longName: "Barking & Dagenham CCG: 07L", shortName: "Barking-Dagenham", postcode: "IG11 8HG" },
  { longName: "Barnet CCG: 07M", shortName: "Barnet", postcode: "N11 1NP" },
  { longName: "Barnsley CCG: 02P", shortName: "Barnsley", postcode: "S75 2PY" },
  { longName: "Basildon and Brentwood CCG: 99E", shortName: "Basildon-Brentwood", postcode: "SS14 3HG" },
  { longName: "Bassetlaw CCG: 02Q", shortName: "Bassetlaw", postcode: "DN22 7XF" },
  { longName: "Bath and North East Somerset CCG: 11E", shortName: "Bath-N E Somerset", postcode: "BA2 5RP" },
  { longName: "Bedfordshire CCG: 06F", shortName: "Bedfordshire", postcode: "MK45 4HR" },
  { longName: "Berkshire West CCG: 15A (includes the merged Newbury and District, North and West Reading, South Reading and Wokingham CCGs)", shortName: "Berkshire West", postcode: "RG30 2BJ" },
  { longName: "Bexley CCG: 07N", shortName: "Bexley", postcode: "DA6 7AT" },
  { longName: "Birmingham and Solihull CCG: 15E (includes the merged Birmingham South Central, Birmingham CrossCity and Solihull CCGs)", shortName: "Birmingham-Solihull", postcode: "B15 1LZ" },
  { longName: "Blackburn with Darwen CCG: 00Q", shortName: "Blackburn-Darwen", postcode: "BB1 2FD" },
  { longName: "Blackpool CCG: 00R", shortName: "Blackpool", postcode: "FY1 6JX" },
  { longName: "Bolton CCG: 00T", shortName: "Bolton", postcode: "BL1 1PP" },
  { longName: "Bradford City CCG: 02W", shortName: "Bradford City", postcode: "BD1 4AS" },
  { longName: "Bradford Districts CCG: 02R", shortName: "Bradford Districts", postcode: "BD1 4AS" },
  { longName: "Brent CCG: 07P", shortName: "Brent", postcode: "HA0 4UZ" },
  { longName: "Brighton and Hove CCG: 09D", shortName: "Brighton-Hove", postcode: "BN3 4AH" },
  {
    longName: "Bristol, North Somerset and South Gloucestershire CCG: code 15C (includes the merged Bristol, North Somerset, and South Gloucestershire CCGs)",
    shortName: "Bristol-N Somerset-South Gloucs",
    postcode: "BS1 3NX"
  },
  { longName: "Bromley CCG: 07Q", shortName: "Bromley", postcode: "BR3 3QL" },
  { longName: "Buckinghamshire CCG: code 14Y (includes the merged Aylesbury Vale and Chiltern CCGs)", shortName: "Buckinghamshire", postcode: "HP19 8FF" },
  { longName: "Bury CCG: 00V", shortName: "Bury", postcode: "BL9 0SN" },
  { longName: "Calderdale CCG: 02T", shortName: "Calderdale", postcode: "HX3 5AX" },
  { longName: "Cambridgeshire and Peterborough CCG: 06H", shortName: "Cambridgeshire-Peterborough", postcode: "CB2 8FH" },
  { longName: "Camden CCG: 07R", shortName: "Camden", postcode: "NW1 3DP" },
  { longName: "Cannock Chase CCG:  04Y", shortName: "Cannock Chase:  04Y", postcode: "WS11 1JP" },
  { longName: "Canterbury and Coastal CCG: 09E", shortName: "Canterbury-Coastal", postcode: "CT1 1YW" },
  { longName: "Castle Point and Rochford CCG: 99F", shortName: "Castle Point-Rochford", postcode: "SS6 7QF" },
  { longName: "Central London (Westminster) CCG: 09A", shortName: "Central London (Westminster)", postcode: "NW1 5JD" },
  { longName: "Chorley and South Ribble CCG: 00X", shortName: "Chorley-South Ribble", postcode: "PR26 6TT" },
  { longName: "City and Hackney CCG: 07T", shortName: "City-Hackney", postcode: "N1 5LZ" },
  { longName: "Coastal West Sussex CCG: 09G", shortName: "Coastal West Sussex", postcode: "BN12 6BT" },
  { longName: "Corby CCG: 03V", shortName: "Corby", postcode: "NN17 5EU" },
  { longName: "Coventry and Rugby CCG: 05A", shortName: "Coventry-Rugby", postcode: "CV1 2NJ" },
  { longName: "Crawley CCG: 09H", shortName: "Crawley", postcode: "RH11 7DH" },
  { longName: "Croydon CCG: 07V", shortName: "Croydon", postcode: "CR0 1EA" },
  { longName: "Darlington CCG: 00C", shortName: "Darlington", postcode: "DL3 6JH" },
  { longName: "Dartford, Gravesham and Swanley CCG: 09J", shortName: "Dartford-Gravesham-Swanley", postcode: "DA12 1AU" },
  { longName: "Doncaster CCG: 02X", shortName: "Doncaster", postcode: "DN4 5DJ" },
  { longName: "Dorset CCG: 11J", shortName: "Dorset", postcode: "DT1 1TG" },
  { longName: "Dudley CCG: 05C", shortName: "Dudley", postcode: "DY5 1RU" },
  { longName: "Durham Dales, Easington and Sedgefield CCG: 00D", shortName: "Durham Dales-Easington-Sedgefield", postcode: "TS21 3EE" },
  { longName: "Ealing CCG: 07W", shortName: "Ealing", postcode: "W5 2HL" },
  { longName: "East and North Hertfordshire CCG: 06K", shortName: "East-N Hertfordshire", postcode: "AL8 6JL" },
  {
    longName: "East Berkshire CCG: 15D (includes the merged Bracknell and Ascot, Slough and Windsor, Ascot and Maidenhead CCGs)",
    shortName: "E Berkshire",
    postcode: "SL4 3DP"
  },
  { longName: "East Lancashire CCG: 01A", shortName: "E Lancashire", postcode: "BB9 8AS" },
  { longName: "East Leicestershire and Rutland CCG: 03W", shortName: "E Leics-Rutland", postcode: "LE3 8TB" },
  { longName: "East Riding of Yorkshire CCG: 02Y", shortName: "E Riding of Yorkshire", postcode: "HU10 6DT" },
  { longName: "East Staffordshire CCG: 05D", shortName: "E Staffordshire", postcode: "DE14 2WF" },
  { longName: "East Surrey CCG: 09L", shortName: "E Surrey", postcode: "RH8 0BT" },
  { longName: "Eastbourne, Hailsham and Seaford CCG: 09F", shortName: "Eastbourne-Hailsham-Seaford", postcode: "BN7 2PB" },
  { longName: "Eastern Cheshire CCG: 01C", shortName: "E Cheshire", postcode: "SK10 3BL" },
  { longName: "Enfield CCG: 07X", shortName: "Enfield", postcode: "EN4 0DR" },
  { longName: "Erewash CCG: 03X", shortName: "Erewash", postcode: "DE7 5FH" },
  { longName: "Fareham & Gosport CCG: 10K", shortName: "Fareham-Gosport", postcode: "PO17 6AR" },
  { longName: "Fylde and Wyre CCG: 02M", shortName: "Fylde-Wyre", postcode: "PR4 3AL" },
  { longName: "Gloucestershire CCG: 11M", shortName: "Gloucestershire", postcode: "GL3 4FE" },
  { longName: "Great Yarmouth and Waveney CCG: 06M", shortName: "Great Yarmouth-Waveney", postcode: "NR34 9BN" },
  { longName: "Greater Huddersfield CCG: 03A", shortName: "Greater Huddersfield", postcode: "WF12 8DJ" },
  { longName: "Greater Preston CCG: 01E", shortName: "Greater Preston", postcode: "PR26 6TT" },
  { longName: "Greenwich CCG: 08A", shortName: "Greenwich", postcode: "SE18 6ND" },
  { longName: "Guildford and Waverley CCG: 09N", shortName: "Guildford-Waverley", postcode: "GU1 4PU" },
  { longName: "Halton CCG: 01F", shortName: "Halton", postcode: "WA7 5TD" },
  { longName: "Hambleton, Richmondshire and Whitby CCG: 03D", shortName: "Hambleton-Richmondshire-Whitby", postcode: "DL6 2UU" },
  { longName: "Hammersmith & Fulham CCG: 08C", shortName: "Hammersmith-Fulham", postcode: "NW1 5JD" },
  { longName: "Hardwick CCG: 03Y", shortName: "Hardwick", postcode: "S41 7PF" },
  { longName: "Haringey CCG: 08D", shortName: "Haringey", postcode: "N22 8HQ" },
  { longName: "Harrogate and Rural District CCG: 03E", shortName: "Harrogate-Rural District", postcode: "HG5 8QB" },
  { longName: "Harrow CCG: 08E", shortName: "Harrow", postcode: "HA3 0YX" },
  { longName: "Hartlepool and Stockton-on-Tees CCG: 00K", shortName: "Hartlepool-Stockton-on-Tees", postcode: "TS23 2LA" },
  { longName: "Hastings and Rother CCG: 09P", shortName: "Hastings-Rother", postcode: "TN40 2DZ" },
  { longName: "Havering CCG: 08F", shortName: "Havering", postcode: "RM1 1SS" },
  { longName: "Herefordshire CCG: 05F", shortName: "Herefordshire", postcode: "HR1 2PL" },
  { longName: "Herts Valleys CCG: 06N", shortName: "Herts Valleys", postcode: "HP2 5HJ" },
  { longName: "Heywood, Middleton & Rochdale CCG: 01D", shortName: "Heywood-Middleton-Rochdale", postcode: "OL16 1XU" },
  { longName: "High Weald Lewes Havens CCG: 99K", shortName: "High Weald Lewes Havens", postcode: "BN7 2PB" },
  { longName: "Hillingdon CCG: 08G", shortName: "Hillingdon", postcode: "UB8 1QG" },
  { longName: "Horsham and Mid Sussex CCG: 09X", shortName: "Horsham-Mid Sussex", postcode: "RH11 7DH" },
  { longName: "Hounslow CCG: 07Y", shortName: "Hounslow", postcode: "TW3 4DN" },
  { longName: "Hull CCG: 03F", shortName: "Hull", postcode: "HU1 1UY" },
  { longName: "Ipswich and East Suffolk CCG: 06L", shortName: "Ipswich-E Suffolk", postcode: "IP1 2BX" },
  { longName: "Isle of Wight CCG: 10L", shortName: "Isle of Wight", postcode: "PO30 5XW" },
  { longName: "Islington CCG: 08H", shortName: "Islington", postcode: "N1 1TH" },
  { longName: "Kernow CCG: 11N", shortName: "Kernow", postcode: "PL25 5AS" },
  { longName: "Kingston CCG: 08J", shortName: "Kingston", postcode: "TW11 8HU" },
  { longName: "Knowsley CCG: 01J", shortName: "Knowsley", postcode: "L36 6GA" },
  { longName: "Lambeth CCG: 08K", shortName: "Lambeth", postcode: "SE1 7NT" },
  {
    longName: "Leeds CCG: 15F (includes the merged Leeds North, Leeds South and East and Leeds West CCGs)",
    shortName: "Leeds",
    postcode: "LS16 6EB"
  },
  { longName: "Leicester City CCG: 04C", shortName: "Leicester City", postcode: "LE1 6NB" },
  { longName: "Lewisham CCG: 08L", shortName: "Lewisham", postcode: "SE6 4RU" },
  { longName: "Lincolnshire East CCG: 03T", shortName: "Lincolnshire East", postcode: "LN4 2HN" },
  { longName: "Lincolnshire West CCG: 04D", shortName: "Lincolnshire West", postcode: "LN4 2HN" },
  { longName: "Liverpool CCG: 99A", shortName: "Liverpool", postcode: "L1 2SA" },
  { longName: "Luton CCG: 06P", shortName: "Luton", postcode: "LU1 2LJ" },
  { longName: "Manchester CCG: 14L", shortName: "Manchester", postcode: "M14 7LU" },
  { longName: "Mansfield and Ashfield CCG: 04E", shortName: "Mansfield-Ashfield", postcode: "NG21 0HJ" },
  { longName: "Medway CCG: 09W", shortName: "Medway", postcode: "ME4 4EL" },
  { longName: "Merton CCG: 08R", shortName: "Merton", postcode: "SW19 1RH" },
  { longName: "Mid Essex CCG: 06Q", shortName: "Mid Essex", postcode: "CM2 5PF" },
  { longName: "Milton Keynes CCG: 04F", shortName: "Milton Keynes", postcode: "MK3 6RT" },
  { longName: "Morecambe Bay CCG: 01K", shortName: "Morecambe Bay", postcode: "PR26 6TR" },
  { longName: "Nene CCG: 04G", shortName: "Nene", postcode: "NN3 6BF" },
  { longName: "Newark and Sherwood CCG: 04H", shortName: "Newark-Sherwood", postcode: "NG24 3HJ" },
  { longName: "Newcastle Gateshead CCG: 13T", shortName: "Newcastle Gateshead", postcode: "NE15 8NY" },
  { longName: "Newham CCG: 08M", shortName: "Newham", postcode: "E15 1DA" },
  { longName: "North Cumbria CCG: 01H", shortName: "N Cumbria", postcode: "CA1 2SE" },
  { longName: "North Derbyshire CCG: 04J", shortName: "N Derbyshire", postcode: "S41 7NW" },
  { longName: "North Durham CCG: 00J", shortName: "N Durham", postcode: "DH1 5TS" },
  { longName: "North East Essex CCG: 06T", shortName: "N E Essex", postcode: "CO4 9QR" },
  { longName: "North East Hampshire and Farnham CCG: 99M", shortName: "N E Hampshire-Farnham", postcode: "GU11 1AY" },
  { longName: "North East Lincolnshire CCG: 03H", shortName: "N E Lincolnshire", postcode: "DN31 1HU" },
  { longName: "North Hampshire CCG: 10J", shortName: "N Hampshire", postcode: "RG24 8GU" },
  { longName: "North Kirklees CCG: 03J", shortName: "N Kirklees", postcode: "WF12 8DJ" },
  { longName: "North Lincolnshire CCG: 03K", shortName: "N Lincolnshire", postcode: "DN20 8GS" },
  { longName: "North Norfolk CCG: 06V", shortName: "N Norfolk", postcode: "NR7 0WG" },
  { longName: "North Staffordshire CCG: 05G", shortName: "N Staffordshire", postcode: "ST1 4FA" },
  { longName: "North Tyneside CCG: 99C", shortName: "N Tyneside", postcode: "NE29 7ST" },
  { longName: "North West Surrey CCG: 09Y", shortName: "N West Surrey", postcode: "KT13 8DP" },
  { longName: "Northern, Eastern and Western Devon CCG: 99P", shortName: "N-E-W Devon", postcode: "EX2 7JU" },
  { longName: "Northumberland CCG: 00L", shortName: "Northumberland", postcode: "NE61 2EF" },
  { longName: "Norwich CCG: 06W", shortName: "Norwich", postcode: "NR2 1NH" },
  { longName: "Nottingham City CCG: 04K", shortName: "Nottingham City", postcode: "NG1 6GN" },
  { longName: "Nottingham North & East CCG: 04L", shortName: "Nottingham NE", postcode: "NG5 6LU" },
  { longName: "Nottingham West CCG: 04M", shortName: "Nottingham West", postcode: "NG9 8DB" },
  { longName: "Oldham CCG: 00Y", shortName: "Oldham", postcode: "OL9 6EE" },
  { longName: "Oxfordshire CCG: 10Q", shortName: "Oxfordshire", postcode: "OX4 2LH" },
  { longName: "Portsmouth CCG: 10R", shortName: "Portsmouth", postcode: "PO4 8LD" },
  { longName: "Redbridge CCG: 08N", shortName: "Redbridge", postcode: "IG1 2QX" },
  { longName: "Redditch & Bromsgrove CCG: 05J", shortName: "Redditch-Bromsgrove", postcode: "B61 0TX" },
  { longName: "Richmond CCG: 08P", shortName: "Richmond", postcode: "TW11 8HU" },
  { longName: "Rotherham CCG: 03L", shortName: "Rotherham", postcode: "S66 1YY" },
  { longName: "Rushcliffe CCG: 04N", shortName: "Rushcliffe", postcode: "NG11 6LQ" },
  { longName: "Salford CCG: 01G", shortName: "Salford", postcode: "M6 5FW" },
  { longName: "Sandwell and West Birmingham CCG: 05L", shortName: "Sandwell-West Birmingham", postcode: "B70 9LD" },
  { longName: "Scarborough and Ryedale CCG: 03M", shortName: "Scarborough-Ryedale", postcode: "YO11 2HG" },
  { longName: "Sheffield CCG: 03N", shortName: "Sheffield", postcode: "S9 4EU" },
  { longName: "Shropshire CCG: 05N", shortName: "Shropshire", postcode: "SY3 8XL" },
  { longName: "Somerset CCG: 11X", shortName: "Somerset", postcode: "BA22 8HR" },
  { longName: "South Cheshire CCG: 01R", shortName: "South Cheshire", postcode: "CW5 5RD" },
  { longName: "South Devon and Torbay CCG: 99Q", shortName: "South Devon-Torbay", postcode: "TQ2 7FF" },
  { longName: "South East Staffordshire and Seisdon Peninsula CCG: 05Q", shortName: "South E Staffordshire-Seisdon Peninsula", postcode: "B79 7BZ" },
  { longName: "South Eastern Hampshire CCG: 10V", shortName: "South Eastern Hampshire", postcode: "PO17 6AR" },
  { longName: "South Kent Coast CCG: 10A", shortName: "South Kent Coast", postcode: "CT16 3PJ" },
  { longName: "South Lincolnshire CCG: 99D", shortName: "South Lincolnshire", postcode: "NG34 8GG" },
  { longName: "South Norfolk CCG: 06Y", shortName: "South Norfolk", postcode: "NR7 0WG" },
  { longName: "South Sefton CCG: 01T", shortName: "South Sefton", postcode: "L20 3DL" },
  { longName: "South Tees CCG: 00M", shortName: "South Tees", postcode: "TS3 6AL" },
  { longName: "South Tyneside CCG: 00N", shortName: "South Tyneside", postcode: "NE32 5NN" },
  { longName: "South Warwickshire CCG: 05R", shortName: "South Warwickshire", postcode: "CV34 4DE" },
  { longName: "South West Lincolnshire CCG: 04Q", shortName: "SW Lincolnshire", postcode: "NG34 8GG" },
  { longName: "South Worcestershire CCG: 05T", shortName: "South Worcestershire", postcode: "WR3 7NS" },
  { longName: "Southampton CCG: 10X", shortName: "Southampton", postcode: "SO16 4GX" },
  { longName: "Southend CCG: 99G", shortName: "Southend", postcode: "SS2 6HT" },
  { longName: "Southern Derbyshire CCG: 04R", shortName: "Southern Derbyshire", postcode: "DE1 3QT" },
  { longName: "Southport & Formby CCG: 01V", shortName: "Southport-Formby", postcode: "PR8 6PL" },
  { longName: "Southwark CCG: 08Q", shortName: "Southwark", postcode: "SE1 2TZ" },
  { longName: "St Helens CCG: 01X", shortName: "St Helens", postcode: "WA10 1YF" },
  { longName: "Stafford and Surrounds CCG: 05V", shortName: "Stafford-Surrounds", postcode: "ST16 2LP" },
  { longName: "Stockport CCG: 01W", shortName: "Stockport", postcode: "SK1 3XE" },
  { longName: "Stoke on Trent CCG: 05W", shortName: "Stoke on Trent", postcode: "ST1 4FA" },
  { longName: "Sunderland CCG: 00P", shortName: "Sunderland", postcode: "SR5 3XB" },
  { longName: "Surrey Downs CCG: 99H", shortName: "Surrey Downs", postcode: "KT22 9AE" },
  { longName: "Surrey Heath CCG: 10C", shortName: "Surrey Heath", postcode: "GU15 3HD" },
  { longName: "Sutton CCG: 08T", shortName: "Sutton", postcode: "SM3 8LR" },
  { longName: "Swale CCG: 10D", shortName: "Swale", postcode: "ME10 2ST" },
  { longName: "Swindon CCG: 12D", shortName: "Swindon", postcode: "SN25 4DL" },
  { longName: "Tameside and Glossop CCG: 01Y", shortName: "Tameside-Glossop", postcode: "SK16 4LA" },
  { longName: "Telford and Wrekin CCG: 05X", shortName: "Telford-Wrekin", postcode: "TF7 4QQ" },
  { longName: "Thanet CCG: 10E", shortName: "Thanet", postcode: "CT9 1XZ" },
  { longName: "Thurrock CCG: 07G", shortName: "Thurrock", postcode: "RM17 6SL" },
  { longName: "Tower Hamlets CCG: 08V", shortName: "Tower Hamlets", postcode: "E1 4DG" },
  { longName: "Trafford CCG: 02A", shortName: "Trafford", postcode: "M33 7FT" },
  { longName: "Vale of York CCG: 03Q", shortName: "Vale of York", postcode: "YO1 6GA" },
  { longName: "Vale Royal CCG: 02D", shortName: "Vale Royal", postcode: "CW5 5QU" },
  { longName: "Wakefield CCG: 03R", shortName: "Wakefield", postcode: "WF1 1LT" },
  { longName: "Walsall CCG: 05Y", shortName: "Walsall", postcode: "WS2 7JL" },
  { longName: "Waltham Forest CCG: 08W", shortName: "Waltham Forest", postcode: "E11 1HP" },
  { longName: "Wandsworth CCG: 08X", shortName: "Wandsworth", postcode: "SW15 2SR" },
  { longName: "Warrington CCG: 02E", shortName: "Warrington", postcode: "WA3 7QH" },
  { longName: "Warwickshire North CCG: 05H", shortName: "Warwickshire North", postcode: "CV11 4EL" },
  { longName: "West Cheshire CCG: 02F", shortName: "West Cheshire", postcode: "CH2 1HJ" },
  { longName: "West Essex CCG: 07H", shortName: "West Essex", postcode: "CM16 6TN" },
  { longName: "West Hampshire CCG: 11A", shortName: "West Hampshire", postcode: "SO50 5PB" },
  { longName: "West Kent CCG: 99J", shortName: "West Kent", postcode: "TN9 1RE" },
  { longName: "West Lancashire CCG: 02G", shortName: "West Lancashire", postcode: "L39 2JW" },
  { longName: "West Leicestershire CCG: 04V", shortName: "West Leicestershire", postcode: "LE11 2TZ" },
  { longName: "West London CCG: 08Y", shortName: "West London", postcode: "NW1 5JD" },
  { longName: "West Norfolk CCG: 07J", shortName: "West Norfolk", postcode: "PE30 1EL" },
  { longName: "West Suffolk CCG: 07K", shortName: "West Suffolk", postcode: "IP33 3YU" },
  { longName: "Wigan Borough CCG: 02H", shortName: "Wigan Borough", postcode: "WN1 1NJ" },
  { longName: "Wiltshire CCG:99N", shortName: "Wiltshire", postcode: "SN10 5EQ" },
  { longName: "Wirral CCG: 12F", shortName: "Wirral", postcode: "CH41 5AL" },
  { longName: "Wolverhampton CCG: 06A", shortName: "Wolverhampton", postcode: "WV10 9RU" },
  { longName: "Wyre Forest CCG: 06D", shortName: "Wyre Forest", postcode: "DY10 1PG" }
];

<template>
  <div
    class="
      px-2
      pt-2
      d-flex
      flex-column
      justify-content-around
      align-items-center
    "
    style="height: 100vh; width: 100%"
  >
    <section
      v-if="!(user.contactObj.inTourStage === 1)"
      id="header-bar"
      class="pb-2 mb-2"
      style="
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: solid white 0.5px;
      "
    >
      <div id="left-icon" class="mr-2" @click="goHome">
        <img src="../assets/logo.png" style="height: 55px; width: 55px" />
      </div>

      <div id="mid-text" @click="goMy">
        <div
          style="
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-align: right;
          "
        >
          <div
            class="ellipsis-must-set-width"
            style="width: calc(100vw - 150px); font-size: 0.8rem"
          >
            {{
              user.contactObj.fullName ||
              "What's your name? Click and set MY ACCOUNT ↘"
            }}
          </div>
        </div>

        <div
          class="ellipsis-must-set-width"
          style="
            width: calc(100vw - 150px);
            font-size: 0.8rem;
            text-align: right;
          "
        >
          {{
            (user.contactObj.location && user.contactObj.location.shortName) ||
            "Where are you? Click and set MY LOCATION →"
          }}
        </div>

        <div
          style="
            width: calc(100vw - 150px);
            font-size: 0.8rem;
            text-align: right;
          "
        >
          &nbsp;
        </div>
      </div>

      <div id="right-icon" @click="goMy">
        <svg class="ion" style="height: 60px; width: 60px">
          <use xlink:href="#ion-ios-gear" />
        </svg>
      </div>
    </section>

    <transition name="swish">
      <div v-if="user.contactObj.inTourStage === 2">
        <tour-message>
          <h4>Congratulations!</h4>
          <h5>
            Just one more thing to see. Click the settings icon,
            <svg class="ion" style="height: 2em; width: 2em; fill-color: black">
              <use xlink:href="#ion-ios-gear" /></svg
            >, at the top right.
          </h5>
        </tour-message>
      </div>
    </transition>

    <section id="top-instruction" style="width: 100%; flex-grow: 0">
      <div v-if="user.contactObj.inTourStage === 1 && nHasSymptoms === 0">
        <tour-message>
          <h2>Welcome!</h2>
          <h5>
            Click the "Just looking at App" button below, and see how quick and
            easy it is!
          </h5>
        </tour-message>
      </div>
    </section>

    <!-- "columns" below:
    CHROME: columns sets  MAXIMUM number of columns, and minimum size of each column, but
    EDGE: requires you to set MAXWIDTH or WIDTH otherwise it just creates an overflowing wide div and your data may be off-screen-->
    <section
      id="mid-scrolling"
      class="mx-2 iphone-scroll-fudge"
      style="flex-grow: 1; overflow-y: auto; max-width: 100%; columns: 1 15em"
    >
      <div v-if="!user.contactObj.inTour">
        <transition name="swish">
          <div v-if="showCovidAlert && !user.contactObj.inTour">
            <h6 class="mt-2">
              <span v-if="nHasSymptoms === 0"
                >To screen for trials, choose your patient's condition(s) from
                the list below.</span
              >
              <span v-else
                >Add any other conditions patient has, then click OK
                below.</span
              >
            </h6>
          </div>
        </transition>

        <!-- <h5 style="text-align:center;">Click your patient's condition(s)</h5> -->
      </div>

      <!-- the MINIMUM WIDTH OF THE COLUMN ABOVE, must be tuned to be same or bigger than the width of the thing below-->
      <div class="tree-container">
        <sl-vue-tree
          v-model="diagnosisTree"
          ref="slVueTreeDiagnosis"
          class
          style="min-width: 15em; min-height: 3em"
        >
          <template #title="{ node }">
            <div
              v-if="
                node.title !== 'Just looking at App' || user.contactObj.inTour
              "
            >
              <div v-if="node.isLeaf">
                <div v-if="!node.data.has">
                  {{ tabbing(node) }}
                  {{ tabbing(node) }}
                  <b-button
                    size="sm"
                    variant="outline-light"
                    class="my-1"
                    @click.passive="
                      setHasSymptom(node.title, true);
                      calcSymptomsHas();
                    "
                    :style="
                      'font-size:' +
                      fontPercentForLevel[node.level] / 100 +
                      'rem;'
                    "
                  >
                    {{ node.title }}
                    <!-- {{fontPercentForLevel[node.level]}} -->
                  </b-button>
                </div>

                <div
                  v-if="node.data.has"
                  class="
                    activated-symptom
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-start
                    pl-3
                  "
                >
                  <div>{{ tabbing(node) }}{{ tabbing(node) }}</div>
                  <div
                    :style="
                      'flex-grow:1;font-size:' +
                      fontPercentForLevel[node.level] / 100 +
                      'rem;'
                    "
                  >
                    <strong>{{ node.title }}</strong>
                  </div>
                  <div>
                    <b-button
                      size="sm"
                      variant="link"
                      class="m-0 p-0"
                      @click.passive="
                        setHasSymptom(node.title, false);
                        calcSymptomsHas();
                      "
                    >
                      <svg class="ion" style="height: 2.4em; fill: red">
                        <use xlink:href="#ion-ios-minus" />
                      </svg>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- We need this ELSE to stop the "Just looking at app" showing up as plain text when it is not wanted -->
            </div>
          </template>

          <template #toggle="{ node }">
            <div class="mt-2">
              {{ tabbing(node) }} {{ tabbing(node) }}
              <span
                :style="
                  'font-size:' + fontPercentForLevel[node.level] / 100 + 'rem;'
                "
              >
                {{ node.title }}
                <!-- {{fontPercentForLevel[node.level]}} -->
              </span>

              <span v-if="!node.isLeaf">
                <span v-if="!node.isExpanded">
                  <svg class="ion" style="height: 1em">
                    <use xlink:href="#ion-chevron-right" />
                  </svg>
                </span>
                <span v-else>
                  <svg class="ion" style="height: 1em">
                    <use xlink:href="#ion-chevron-down" />
                  </svg>
                </span>
              </span>
            </div>
          </template>
        </sl-vue-tree>
      </div>

      <div style="min-height: 30vh">
        <!-- For aesthetics, prevents a very short list being split into a huge number of columns just because the screen is wide. It is only 50vh, though, and not near 100vh, so that the list of actual text can never scroll off the top of screen. -->
      </div>
    </section>

    <transition name="swish">
      <section
        v-if="user.contactObj.inTourStage === 1 && this.symptomIsTourSymptom"
      >
        <tour-message>
          <h5>
            In real life, you would have selected your patient's condition or
            conditions above.
          </h5>
          <h4>Click "OK" below to continue tour.</h4>
          <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
          </div>-->
        </tour-message>
      </section>
    </transition>
    <transition name="swish">
      <section
        v-if="
          user.contactObj.inTourStage === 1 &&
          nHasSymptoms > 0 &&
          !this.symptomIsTourSymptom
        "
      >
        <tour-message>
          <h2>No, sorry.</h2>
          <h4>
            For this tour, please select
            <strong>ONLY</strong> the "Just looking at App" condition. Deselect
            any others by pressing their red 'minus' button.
          </h4>
          <!-- <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline-dark" size="sm">Cancel tour</b-button>
          </div>-->
        </tour-message>
      </section>
    </transition>

    <transition name="swish">
      <section
        id="bottom-bar"
        style="flex-grow: 0; width: 100%"
        v-if="
          !user.contactObj.inTour ||
          (user.contactObj.inTourStage === 1 && symptomIsTourSymptom)
        "
      >
        <div
          v-if="nHasSymptoms >= 1"
          class="mt-1 py-2"
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-top: 0.6px solid white;
          "
        >
          <div>{{ hasSymptomsDisplayList }}&nbsp;</div>

          <div class="d-flex flex-column">
            <div v-if="nHasSymptoms === 1">
              <small class="blinking">Add more or click</small>
            </div>
            <b-button variant="light" size="lg" @click="okTheseSymptoms()">
              OK&nbsp;
              <svg class="ion" style="height: 1em">
                <use xlink:href="#ion-chevron-right" />
              </svg>
            </b-button>
          </div>
        </div>

        <div
          v-if="nHasSymptoms === 0"
          id="elsewhere-you-can-go"
          style="flex-grow: 0; width: 100%"
        >
          <home-pages-bar :user="user" from="clinician"></home-pages-bar>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import SlVueTree from "sl-vue-tree";
import { tabbing } from "../util/tabbing.js";
import { TITLE_PATH_SEPARATOR } from "../util/constants.js";
import { diagnosisTreeFullTheoreticalForm } from "../util/diagnosisTreeFullTheoreticalForm.js";
import { treeOfDiagnosesOfTrialsInGeography } from "../util/diagnosis.js";
import HomePagesBar from "./HomePagesBar";

// const previousDiagnosisTitlePaths = [
//   [
//     "Cardiology | Heart failure | Acute heart failure",
//     "Renal | Chronic renal failure"
//   ]
// ];

export default {
  components: { SlVueTree, HomePagesBar },

  props: {
    user: { type: Object, required: true },
    trials: { type: Object, required: true },
    georestrictedTrialIdArray: {
      /* Initially ["notReady"], but then replaced with an array by parent ("App.vue") once BOTH user AND trials are loaded */
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      showCovidAlert: true,

      nHasSymptoms: 0,
      hasSymptomsTitlePaths: [],
      hasSymptomsTitles: [],
      hasSymptomsDisplayList: "",
      diagnosisTree: [],

      fontPercentForLevel: [
        100 /* never happens */, 100, 80, 64, 50, 50, 50, 50,
      ],

      tabbing, // This just makes the TABBING function we have imported, available as "THIS.TABBING" so the template can refer to it as "TABBING"
    };
  },

  watch: {
    georestrictedTrialIdArray: function (georestrictedTrialIdArray) {
      // App.vue detects changes in EITHER user location, or trial database, it updates
      this.calcGeorestrictedDiagnosisTreeFromGeorestrictedTrialIds(
        georestrictedTrialIdArray
      );
    },
  },

  computed: {
    // locationGeography() {
    //   return (
    //     "p" +
    //     ((this.user.contactObj &&
    //       this.user.contactObj.location &&
    //       this.user.contactObj.location.postcode) ||
    //       "W12 0NN")
    //   );
    // },
    symptomIsTourSymptom() {
      return (
        this.hasSymptomsTitlePaths.length === 1 &&
        this.hasSymptomsTitlePaths[0] === "Just looking at App"
      );
    },

    symptomIncludesCovid19() {
      return this.hasSymptomsTitlePaths.includes("COVID-19");
    },
  },

  created() {
    this.$emit("checkUserGivenEnoughInfo");
    this.user.contactRef.update({ lastHomePage: "clinician" });
  },

  mounted() {
    this.diagnosisSlVueTree = this.$refs.slVueTreeDiagnosis; // This is the 3rd party library SlVueTree, tied to the current dataset

    this.calcGeorestrictedDiagnosisTreeFromGeorestrictedTrialIds(
      this.georestrictedTrialIdArray
    ); // Need this solely so that when you go forward and then come BACK with back button, you don't end up with an empty diagnosisList.

    if (!this.user.contactObj.doneTour && !this.user.contactObj.inTour) {
      this.startTour();
    }
  },

  methods: {
    // setInteractionOptionsOfTree(treeLayer, previousDiagnosisTitlePaths) {
    //   for (const node of treeLayer) {
    //     node.isDraggable = false;
    //     node.isSelectable = true;
    //     node.isExpanded = false;
    //     if (node.children && node.children.length >= 1) {
    //       node.isLeaf = false;
    //       this.setInteractionOptionsOfTree(node.children);
    //     } else {
    //       node.isLeaf = true;
    //     }
    //   }
    // },

    calcGeorestrictedDiagnosisTreeFromGeorestrictedTrialIds: function (
      georestrictedTrialIdArray
    ) {
      if (
        JSON.stringify(georestrictedTrialIdArray) ===
        JSON.stringify(["notReady"])
      ) {
        // // This is the initial status, before the App.vue has managed to read the firebase USER.CONTACTOBJ.LOCATIONGEO and the TRIALS.PUBLICOBJ, so just show a default (full) set of symptoms.
        // this.diagnosisTree = JSON.parse(
        //   JSON.stringify(diagnosisTreeFullTheoreticalForm)
        // );

        this.diagnosisTree = [];
      } else {
        this.diagnosisTree = treeOfDiagnosesOfTrialsInGeography(
          diagnosisTreeFullTheoreticalForm,
          georestrictedTrialIdArray,
          this.trials
        );
      }
    },

    setHasSymptom(title, truth) {
      this.diagnosisSlVueTree.traverse((node) => {
        if (node.title === title) {
          //    Just  "node.data.has = truth;" will not work: you gotta do this:
          this.$refs.slVueTreeDiagnosis.updateNode(node.path, {
            data: { has: truth },
          });
        }
        return true; // needed to cause TRAVERSE to continue traversing
      });
    },

    calcSymptomsHas() {
      this.nHasSymptoms = 0;
      this.hasSymptomsTitlePaths = [];
      this.hasSymptomsTitles = [];
      this.hasSymptomsDisplayList = "";
      this.diagnosisSlVueTree.traverse((node) => {
        if (node.data.has) {
          this.nHasSymptoms += 1;
          if (!this.hasSymptomsTitles.includes(node.title)) {
            this.hasSymptomsTitles.push(node.title);
          }
          this.hasSymptomsTitlePaths.push(this.titlePathOfNode(node));
        }
        return true; // needed to cause TRAVERSE to continue traversing
      });
      this.hasSymptomsDisplayList = this.hasSymptomsTitles.join(", ");
    },

    titlePathOfNode(node) {
      let oneTitlePath = "";
      for (let i = 0; i < node.path.length; i++) {
        const titleElement = this.diagnosisSlVueTree
          .getNode(node.path.slice(0, i + 1))
          .title.trim();
        const optionalSpacer = i > 0 ? " " + TITLE_PATH_SEPARATOR + " " : "";
        oneTitlePath += optionalSpacer + titleElement;
      }
      return oneTitlePath;
    },

    okTheseSymptoms() {
      // Append user's list of referred symptoms to his firebase
      this.user.contactRef
        .child("lastHasSymptomsTitlePaths")
        .set(this.hasSymptomsTitlePaths)
        .then(() => {
          // If we wait for response, we can't prepare referral offline. So don't wait.
        });
      this.goNext();
    },

    /*
   _______
  |__   __|
     | | ___  _   _ _ __
     | |/ _ \| | | | '__|
     | | (_) | |_| | |
     |_|\___/ \__,_|_|
*/
    startTour() {
      this.user.contactRef.update({ inTour: true, inTourStage: 1 });
    },

    async autoPressCovid() {
      this.showCovidAlert = false;
      await this.timeoutMs(500);
      this.setHasSymptom("COVID-19", true);
      this.calcSymptomsHas();
      await this.timeoutMs(500); // Not just for show - need to allow computed property time to execute
      this.okTheseSymptoms();
    },

    timeoutMs(ms) {
      // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    //  _   _             _             _   _
    // | \ | |           (_)           | | (_)
    // |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    // | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    // | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    // \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                       __/ |
    //                      |___/

    goHome() {
      this.$router.replace({ name: "home" });
    },

    goNext() {
      this.$router.push({
        name: "clin20Screen",
        params: {
          hasSymptomsTitlesJoined:
            this.hasSymptomsTitles.join(TITLE_PATH_SEPARATOR),
        },
      });
    },

    goMy() {
      this.$router.push({
        name: "my",
      });
    },

    goMyLocation() {
      this.$router.push({
        name: "myLocation",
      });
    },

    goResearcher() {
      this.$router.push({
        name: "researcher",
      });
    },

    goManager() {
      this.$router.push({
        name: "manage",
      });
    },

    goHq() {
      this.$router.push({
        name: "hq",
      });
    },

    goPage(pageName) {
      this.$router.push({ name: pageName });
    },
  },
};
</script>

<style scoped>
/* Fudge to remove ugly scrollbar */

::-webkit-scrollbar {
  display: none;
}

.expander-icon-space {
  display: inline-block;
  width: 1em;
}

.activated-symptom {
  display: inline-block;
  /* min-width: 80vw; */
  margin: 0.1em;
  color: black;
  background-color: white;
}

.top-bar-enter,
.top-bar-leave-to {
  opacity: 0;
  max-height: 0;
}

.top-bar-leave,
.top-bar-enter-to {
  opacity: 1;
  max-height: 50vh;
}

.top-bar-enter-active {
  transition: max-height 2s, opacity 2s;
}

.top-bar-leave-active {
  transition: max-height 2s, opacity 1s;
}

.tray-enter,
.tray-leave-to {
  opacity: 0;
  max-height: 0;
}

.tray-leave,
.tray-enter-to {
  opacity: 1;
  max-height: 5em;
}

.tray-enter-active {
  transition: max-height 1s, opacity 2s;
}

.tray-leave-active {
  transition: max-height 2s, opacity 1s;
}
</style>

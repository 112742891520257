export const diagnosisTreeFullTheoreticalForm = [
  // The Logic Engine will intelligently "collapse" things if there is only one entry under a heading, so people won't see this full tree unless there are an awful lot of trials.
  {
    title: "Just looking at App",
  },
  {
    title: "COVID-19",
  },
  {
    title: "Cardiovascular",
    children: [
      { title: "Anaemia" },
      { title: "PRACTICE heart problem" },
      {
        title: "Surgery",
        children: [
          { title: "Cardiac Surgery" },
          { title: "Mitral valve surgery" },
        ],
      },
      {
        title: "Coronary",
        children: [
          { title: "Angina" },
          { title: "Troponin negative ACS" },
          { title: "NSTEMI" },
          { title: "STEMI" },
          { title: "Twinges" },
        ],
      },
      {
        title: "Heart failure",
        children: [
          { title: "In-patient with heart failure" },
          { title: "Chronic heart failure" },
        ],
      },
      {
        title: "Devices",
        children: [{ title: "Pacing" }],
      },

      {
        title: "Arrhythmia",
        children: [
          { title: "Paroxysmal AF" },
          { title: "Persistent AF" },
          { title: "Permanent AF" },
          { title: "Ventricular tachycardia" },
          { title: "Vasovagal syncope" },
        ],
      },
      {
        title: "Prevention",
        children: [
          { title: "Hypertension" },
          { title: "Hyperlipidaemia" },
          { title: "Cardiovascular risk" },
        ],
      },
      {
        title: "Hypertension",
        children: [{ title: "Renal denervation" }],
      },
      { title: "Obesity" },
      { title: "Bicuspid aortic valve" },
      {
        title: "Background conditions",
        children: [
          { title: "Previous MI" },
          { title: "Previous stroke or TIA" },
          { title: "Peripheral arterial disease" },
        ],
      },
      {
        title: "Intermittent claudication",
      },
    ],
  },
  {
    title: "Dermatology",
    children: [
      { title: "Eczema" },
      { title: "Psoriasis" },
      { title: "Erythema" },
    ],
  },
  {
    title: "App software testing only",
    children: [
      { title: "Other stuff" },
      { title: "Dandruff" },
      { title: "Loneliness" },
    ],
  },
];

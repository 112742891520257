<template>
  <div class="p-2 page-scroll-y">

    <app-header-bar title="Edit Facts"></app-header-bar>

    <div v-if="scienceFactIsLoaded">

      <b-table
        striped
        hover
        :items="scienceFactArray"
        :fields="scienceFactFields"
        sort-by="priority"
        @row-clicked="onClickOneFact"
      >
      </b-table>

      <b-button
        variant="light"
        @click="onAddAFact"
      >Add a fact</b-button>

    </div>
    <div v-else>
      <!-- !scienceFactIsLoaded -->
      Loading facts
    </div>

  </div>
</template>

<script>
import generatePushId from "../util/generatePushId.js";
import { scienceFactRootRef } from "../util/firebase.js";
import AppHeaderBar from "./AppHeaderBar.vue";

// const EMPTY_FORM = {
//   path: "",
//   type: "",
//   shortText: "",
//   longText: "",
//   options: {}
// };

export default {
  components: { AppHeaderBar },
  props: { id: String },

  data: () => ({
    scienceFactFields: [
      {
        key: "path",
        label: "Path",
        sortable: true,
        formatter: "pathFormatter"
      },
      { key: "shortText", label: "Fact", sortable: true },
      { key: "priority", label: "Priority code", sortable: true }
    ],

    /// VARIABLES
    scienceFactIsLoaded: false,
    scienceFactObj: {},
    scienceFactArray: []
  }),

  computed: {
    userHasEdited: function() {
      return (
        JSON.stringify(this.form) !== JSON.stringify(this.formBeforeUserEditing)
      );
    }
  },

  created: function() {
    scienceFactRootRef.on("value", this.firebaseBinding);
  },

  beforeDestroy: function() {
    scienceFactRootRef.off("value", this.firebaseBinding);
  },

  methods: {
    firebaseBinding: function(snapshot) {
      this.scienceFactObj = snapshot.val();
      this.scienceFactArray = Object.keys(this.scienceFactObj).map(key => ({
        ...this.scienceFactObj[key],
        id: key,
        priority:
          this.scienceFactObj[key].priority ||
          "zzNoCodeYet " + this.scienceFactObj[key].shortText
      }));
      this.scienceFactIsLoaded = true;
    },

    pathFormatter: function(pathList) {
      return (pathList || []).join(" • ");
    },

    onAddAFact: function() {
      const newFactId = "fac~" + generatePushId();
      this.$router.push({ name: "editOneFact", params: { id: newFactId } });
    },

    onClickOneFact: function(factObj /*, index */) {
      this.$router.push({
        name: "editOneFact",
        params: { id: factObj.id }
      });
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <div
    class="px-2 pt-2 d-flex flex-column justify-content-between"
    style="height:100vh; width:100%;"
  >
    <app-header-bar title="FBM HQ Home" :hide-back-arrow="true" :hide-home-icon="true"></app-header-bar>

    <section
      id="middleScrolling"
      class="iphone-scroll-fudge d-flex flex-column justify-content-around"
      style="height:100%;width:100%;overflow-y:auto;"
    >
      <div id="checks">
        <div id="checks-passed" class="small">
          Auto-checks passed:
          <br />
          <div class="d-flex flex-row justify-content-left small">
            <div v-if="locationsHaveUniqueLongName" id="LocsUniq">LocsUniq&nbsp;</div>
            <div v-if="diagnosesAllAppearInFullTree" id="DxTree">DxTree&nbsp;</div>
          </div>
          <div>
            <b-tooltip
              target="LocsUniq"
            >Each referrer location (Trust/CCG) has a distinctive longName.</b-tooltip>
            <b-tooltip
              target="DxTree"
            >Each diagnosis in a trial appears at least once in the diagnosisTreeFullTheoreticalForm.</b-tooltip>
          </div>
        </div>
        <div id="checks-failed">
          <b-alert show v-if="!locationsHaveUniqueLongName" variant="danger">
            <h5>Long names in "tableLocationsNhsTrusts.js" / "tableLocationsNhsCcgs.js" are not unique. Please edit them.</h5>
            <p v-for="(place,index) in locationsArrayOfNonUnique" :key="index">'{{place}}'</p>
          </b-alert>

          <b-alert show v-if="!diagnosesAllAppearInFullTree" variant="danger">
            <h6>Not all trials' diagnoses appear in 'diagnosisTreeFullTheoreticalForm.js'. Please update, remembering it is case-sensitive and that if changing the tree (rather than the trial), test locally to see the effect immediately.</h6>
            <p
              v-for="(trialAndDiagnosis,index) in listOfTrialIdAndDiagnosisPairsNotAppearing"
              :key="index"
            >
              <strong>{{trialAndDiagnosis.diagnosis}}</strong>
              in {{(trials.publicObj[trialAndDiagnosis.trialId]||{}).shortText}}
            </p>
          </b-alert>
        </div>
      </div>

      <div
        class="d-flex flex-row align-items-center justify-content-around"
        @click="goPage('editUsers')"
      >
        <svg class="ion" style="height:60px; width:60px;">
          <use xlink:href="#ion-android-people" />
        </svg>
        <h2 style="width:60%;">Edit Users</h2>
        <svg class="ion">
          <use xlink:href="#ion-chevron-right" />
        </svg>
      </div>

      <div
        class="d-flex flex-row align-items-center justify-content-around"
        @click="goPage('editTrials')"
      >
        <svg class="ion" style="height:60px; width:60px; transform: rotate(180deg);">
          <use xlink:href="#ion-network" />
        </svg>
        <h2 style="width:60%;">Edit Trials</h2>
        <svg class="ion">
          <use xlink:href="#ion-chevron-right" />
        </svg>
      </div>

      <div
        class="d-flex flex-row align-items-center justify-content-around"
        @click="goPage('editFacts')"
      >
        <svg class="ion" style="height:60px; width:60px;">
          <use xlink:href="#ion-levels" />
        </svg>
        <h2 style="width:60%;">Edit Facts</h2>
        <svg class="ion">
          <use xlink:href="#ion-chevron-right" />
        </svg>
      </div>

      <div class="d-flex flex-row justify-content-around align-items-center" @click="goPage('my')">
        <svg class="ion" style="height:60px;width:60px;">
          <use xlink:href="#ion-android-person" />
        </svg>
        <h2 style="width:60%;">My details</h2>
        <svg class="ion">
          <use xlink:href="#ion-chevron-right" />
        </svg>
      </div>
      <!-- <div
      class="d-flex flex-row align-items-center justify-content-around"
      @click="goPage('myPatients')"
    >
      <svg
        class="ion"

      >
        <use xlink:href="#ion-ios-list-outline"></use>
      </svg>
      <h2 style="text-align:center;">See Referrals</h2>
      <svg
        class="ion"

      >
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
      </div>-->
      <!-- <div
      class="d-flex flex-row align-items-center justify-content-around"
      @click="goPage('settings')"
    >
      <svg
        class="ion"

      >
        <use xlink:href="#ion-android-options"></use>
      </svg>
      <h2 style="text-align:center;">Settings</h2>
      <svg
        class="ion"

      >
        <use xlink:href="#ion-chevron-right"></use>
      </svg>
      </div>-->
    </section>

    <home-pages-bar from="hq" :user="user"></home-pages-bar>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import HomePagesBar from "./HomePagesBar.vue";
import { trialPrivateRootRef } from "../util/firebase";
import { checkUniquenessNhsLocations } from "../util/geography.js";
import { checkDiagnosesAllAppearInFullTree } from "../util/diagnosis";

export default {
  components: { AppHeaderBar, HomePagesBar },

  props: {
    user: { type: Object, required: true },
    trials: { type: Object, required: true }
  },
  data() {
    return {
      trialPrivateIsLoaded: false,
      trialPrivateObj: {},
      trialPrivateArray: [],

      // AutoChecks
      locationsHaveUniqueLongName: false,
      locationsArrayOfNonUnique: [],

      diagnosesAllAppearInFullTree: false,
      listOfTrialIdAndDiagnosisPairsNotAppearing: []
    };
  },

  created() {
    trialPrivateRootRef.on("value", this.firebaseBindingTrialPrivate);
    this.$emit("checkUserGivenEnoughInfo");
    this.user.contactRef.update({ lastHomePage: "hq" });

    this.sanityChecksOnDatabase();
  },

  beforeDestroy() {
    trialPrivateRootRef.off("value", this.firebaseBindingTrialPrivate);
  },

  methods: {
    firebaseBindingTrialPrivate: function(snapshot) {
      this.trialPrivateObj = snapshot.val();
      this.trialPrivateIsLoaded = true;
    },

    sanityChecksOnDatabase() {
      // Locations Unique
      ({
        unique: this.locationsHaveUniqueLongName,
        arrayOfNonUnique: this.locationsArrayOfNonUnique
      } = checkUniquenessNhsLocations());

      // All diagnoses appear in diagnosisTreeFullTheoreticalForm
      const listOfTrialIdAndDiagnosisPairs = [];
      Object.keys(this.trials.publicObj).forEach(trialId => {
        this.trials.publicObj[trialId].diagnoses.forEach(diagnosis => {
          listOfTrialIdAndDiagnosisPairs.push({ trialId, diagnosis });
        });
      });

      ({
        diagnosesAllAppearInFullTree: this.diagnosesAllAppearInFullTree,
        listOfTrialIdAndDiagnosisPairsNotAppearing: this
          .listOfTrialIdAndDiagnosisPairsNotAppearing
      } = checkDiagnosesAllAppearInFullTree(listOfTrialIdAndDiagnosisPairs));
    },

    //

    goPage(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          trialPrivateObj: this.trialPrivateObj,
          trialPrivateIsLoaded: this.trialPrivateIsLoaded
        }
      });
    }
  }
};
</script>

<style>
</style>

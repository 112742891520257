// DF Found these at setup
// it started here
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
// and ended here

import "./util/scssMerger.scss";

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import TourMessage from "./components/TourMessage.vue";
import "./registerServiceWorker";
Vue.component("tour-message", TourMessage);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

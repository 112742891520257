<template>
  <a
    :style="sty"
    :href="'mailto:'+ADMIN_EMAIL+'?Subject=Query%20from%20'+userFullName+'%20'+(userEmail||'new user')"
  >{{ADMIN_EMAIL}}
  </a>
</template>

<script>
import { ADMIN_EMAIL } from "../util/constants.js";
export default {
  components: {},
  props: {
    user: { type: Object, required: false /* during login, not avail */ },
    sty /* the STYLE string */: { type: String, required: true }
  },
  data() {
    return {
      // Actual Data
      userFullName:
        (this.user && this.user.contactObj && this.user.contactObj.fullName) ||
        "",
      userEmail:
        (this.user &&
          this.user.presenceObj &&
          this.user.presenceObj.emailAtLastLogin) ||
        "",

      // .. put here
      // Making global consts and functions available to THIS
      ADMIN_EMAIL
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //
    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //
    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //
    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/
  }
};
</script>

<style scoped>
</style>

import { YES, NO, DEPENDS } from "../util/constants.js";

export function yesNoDependsTree(tree, known, scienceFactObj) {
  /*       Input:                 ----------------------------------

SINGLE NODE tree, e.g. an "ALL", or any other logical thing, or a fact.
CANNOT be an array.

Example of a valid node:

[ title: "ALL",
children: [{
factId: "fact~-LPPrNgYHtPnIUknfwCp"
id: "cri~-LPoHgMC3YT6M7FD7hgo"
isIn: false
optionId: "opt~-LPPrhmtdf9bxXR7S2-Z"
title: "Gender is NOT Male"},{
    title: "ANY"
    children: [ ...blah blah ]
}]
]

Object of known facts. At this stage, we don't actually need the CRITERIA, which are things like "Creatinine less than 50"

{
"fact~-LPPrNgYHtPnIUknfwCp":{  // This is a categorical fact with 3 options
"opt~-LPPrhmtdf9bxXR7S2-Z": false,
"opt~-LPPrhmtdf9bxXRr984s": false,  // <---- once we get this, we can fill in the 3rd option as True!
},
"fact~-g6gd9876h98769h9876":{  // This is a CONTINUOUS fact
"value": 42,
},
}

   Output:                 ----------------------------------
2 things:


(1) suit:
+1 for yes
-1 for no
0 for "depends"

(2) criteriaSought: This is an array if "yes" or "no", or an object if "depends"
{
// Example of a categorical variable. We need hair colour to be NOT red
"cri~fdg79df8g7d09h7d098h70f":{ // Unique id is criterion because one trial (or option of a trial) might ask for blue hair and another yellow
factId: "fac~-LPPrNgYHtPnIUknfwCp":, // Hair colour
optionId:"opt~-LPPrhmtdf9bxXR7S2-Z", // we are interested in the RED option's suit value
isIn:false, // and we need it to be FALSE
id:"cri~fdg79df8g7d09h7d098h70f"  // (copy of criterion id)
},

// Example of a continuous variable. We need creatinine to be GT.EQ 250
"cri~-LPPrN84753095874309":{  // e.g. the Nth criterion of trial X
 factId: "fac~f9d8h70df9h709h7f": // Its about creatinine.
 ll:250, // Lower limit is 250
 ge:true, // and Equality is allowed (alternative is false or absent)
 // Instead or simultaneously, we can specifiy a "ul" (upper limit) and the corresponding boolean is "le" (less than or equal to)
 id:"cri~-LPPrN84753095874309"  // (copy of criterion id)
 },
}

*/
  let suit = undefined;
  let criteriaSought = {};
  const children = tree.children || []; // Only relevant for logical nodes but no harm in this as we won't use it if we go down the "medical" branch instead

  if (tree.title === "ALL") {
    suit = YES;
    children.forEach(child => {
      const childReply = yesNoDependsTree(child, known, scienceFactObj);
      if (childReply.suit === YES) {
        // No action. Nothing to see here, move along please.
      } else if (childReply.suit === NO) {
        // OK we are excluded from this trial, so we don't need any more criteria from here.
        suit = NO;
      } else if (childReply.suit === DEPENDS && (suit === YES || suit === DEPENDS)) {
        // Uncertainty in a child only matters if we are currntly on a YES or DEPENDS.
        suit = DEPENDS; //  Our answer goes from YES or DEPENDS, to DEPENDS
        Object.assign(criteriaSought, childReply.criteriaSought); // Slurp up all the criteria the child wanted to know about
      } else if (childReply.suit === DEPENDS && suit === NO) {
        // We don't care, as we are out anyway.
      } else {
        console.error("Child gave an unexpected suit tri-state of ", childReply.suit, " instead of +1,0,-1.");
      }
    });
  } else if (tree.title === "ANY") {
    suit = NO;
    children.forEach(child => {
      const childReply = yesNoDependsTree(child, known, scienceFactObj);
      if (childReply.suit === NO) {
        // Nil
      } else if (childReply.suit === YES) {
        suit = YES;
      } else if (childReply.suit === DEPENDS && suit !== YES) {
        suit = DEPENDS;
        Object.assign(criteriaSought, childReply.criteriaSought);
      } else if (childReply.suit === DEPENDS && suit === YES) {
        // No action. We already know that suit is YES, so it does't matter that another child is conditional
      } else {
        console.error("Child gave a suit state of ", childReply.suit);
      }
    });
  } else if (tree.title === "NO") {
    suit = YES;
    children.forEach(child => {
      const childReply = yesNoDependsTree(child, known, scienceFactObj);
      if (childReply.suit === NO) {
        // Nil
      } else if (childReply.suit === YES) {
        suit = NO;
      } else if (childReply.suit === DEPENDS && suit !== NO) {
        suit = DEPENDS;
        Object.assign(criteriaSought, childReply.criteriaSought);
      } else if (childReply.suit === DEPENDS && suit === NO) {
        // No action. We already know that suit is NO, so it does't matter that another child is conditional
      } else {
        console.error("Child gave a suit state of ", childReply.suit);
      }
    });
  } else {
    // Because it is not one of the limited number of logical connectors, it must be an actual criterion

    const criterion = tree; // The specific criterion in a particular branch of the logic of a particular trial, e.g. the requirement in LOPRESOR-3 for a WOMAN to have a diastolic below 140. dias <140. (But in another branch even of the same trial, the same FACT of dias BP, may have a different cutoff, e.g. 150 for men)
    const factId = tree.factId; // e.g. The general concept of "dias BP". This will be the same in all trials that address dias BP.
    const fact = scienceFactObj[factId];

    if (!fact) {
      console.error("Fact ", factId, " has no entry in scienceFact.");
    }

    switch (fact.type) {
      case "Categorical": // note intentional fallthrough: both handled same way
      case "Continuous":
        suit = DEPENDS;
        if (known.criterion[criterion.id]) {
          suit = known.criterion[criterion.id].suit || DEPENDS;
        }
        if (suit === DEPENDS) {
          criteriaSought = { [criterion.id]: criterion };
        }
        break;

      default:
        console.error("Unexpected fact type '" + fact.type + "' in fact " + factId);
        break;
    }
  }

  // OK Gathered up all children or the leaf itself, now time to come to a conclusion:
  if (suit !== DEPENDS) {
    criteriaSought = {};
  }
  return {
    suit,
    criteriaSought
  };
}

<template>
  <div class="p-4 page-scroll-y">
    <section v-if="step==='1'">
      <div class="header-logged-out">
        <img
          class="mb-4"
          style="max-width:50vw; max-height: 40vh;"
          src="../assets/logo.svg"
          alt="Logo"
        />
      </div>

      <div class="d-flex flex-column align-items-center">
        <h1 style="line-height:0.6;">
          <span style="font-size:220%; font-weight:900; ">You</span>
        </h1>
        <h5>can help achieve</h5>
        <h4>
          <span style="font-size:95%">breakthroughs</span>
        </h4>
        <!-- <h3 align="center"><em>&lt; 1 min per patient!</em></h3> -->
      </div>
      <br />

      <div v-if="loginError.message">
        <b-alert show variant="danger">{{loginError.message}}</b-alert>
      </div>

      <div class="d-flex flex-row justify-content-between mb-3">
        <div>Step 1. Register with your email</div>
      </div>
      <div class="input-group pb-3 w-100">
        <transition name="swish">
          <div v-if="emailWhole===''" class="w-100 pb-3">
            <div class="d-flex flex-row align-items-center pb-1">
              <img style="width:5em;" class="mr-2" src="../assets/flag-uk.png" />
              <div>UK staff</div>
            </div>
            <b-input-group append="@nhs.net">
              <b-form-input
                id="emailAddressInputNhs"
                v-model="emailHead"
                type="text"
                placeholder="e.g. john.smith23"
                style="text-align:right;"
                @keyup.enter.native="submitEmailWholeOrHead(emailHead,emailWhole)"
              />
            </b-input-group>
          </div>
        </transition>
        <transition name="swish">
          <div v-if="emailHead===''" class="w-100">
            <div class="d-flex flex-row align-items-center pb-1">
              <img
                class="mr-2"
                style="width:5em; color:#ffffff; fill:currentColor;"
                src="../assets/world-map.png"
              />
              <div>Staff outside UK</div>
            </div>

            <b-form-input
              id="emailAddressInput"
              v-model="emailWhole"
              type="text"
              placeholder="email address"
              style="text-align:right;"
              @keyup.enter.native="submitEmailWholeOrHead(emailHead,emailWhole)"
            />
          </div>
        </transition>
      </div>

      <div class="d-flex flex-row justify-content-end">
        <div>&nbsp;</div>
        <b-button
          block
          variant="light"
          size="lg"
          style="width:50%;"
          @click="submitEmailWholeOrHead(emailHead,emailWhole)"
        >OK</b-button>
      </div>

      <br />
      <!-- not using the EmailAdminLink component because that relies on having USER loaded -->
      <p style="font-size:70%;">
        Need help?
        <EmailAdminLink sty="text-decoration:underline; color:white;"></EmailAdminLink>
      </p>
    </section>

    <!-- <div
      v-if="loginErr.message"
      class="err-font err-container"
    ><br>
      {{loginErr.message}}
      <br><br>
    </div>
    <br>
    <div>
      <b-input
        prefix="md-person"
        v-model="email"
        placeholder="email"
        type="text"
        style="width: auto"
      />
    </div>
    <br>

    <section class="main">
      <b-form @submit="tryLogin(email,password)">
        <h2>Already joined?</h2>
        <br>
        <b-button
          size="lg"
          variant="light"
          @click="tryLogin(email,password)"
        >Log back in</b-button>

        <br>

    <br>-->

    <!-- ----------------------- -->

    <section
      v-if="step==='1p5'"
      style="max-height:50vh;display:flex;flex-direction:row;justify-content:center;"
    >
      <Spinner></Spinner>
    </section>

    <!-- ----------------------- -->

    <section v-if="step==='2'">
      <div class="header-logged-out">
        <img
          class="mb-4"
          style="max-width:30vw; max-height: 30vh;"
          src="../assets/logo.svg"
          alt="Logo"
        />
      </div>

      <div>
        <div>Step 2</div>

        <div v-if="existingUser">
          <h3>
            Welcome back!
            <br />Enter your password
          </h3>
        </div>
        <div v-else style="text-align:right;">
          <h3>New user {{email}}</h3>
          <br />
          <h5 style="line-height:0.9;">
            Create a
            <label for="passwordInput">password</label>
            <br />
            <span v-if="password.length<6" style="font-size:70%;">at least 6 characters</span>
          </h5>
        </div>
      </div>

      <div style="height:0.5em;"></div>

      <div class="d-flex flex-column align-items-center">
        <b-input
          autofocus
          id="password"
          prefix="md-key"
          v-model="password"
          type="password"
          placeholder="Password"
          style="width: 100%;font-size:200%;"
          @keyup.enter.native="trySignupOrLogin(existingUser,email,password)"
        />
      </div>

      <div style="height:0.5em;"></div>

      <div v-if="loginError.message">
        <b-alert show variant="danger">{{loginError.message}}</b-alert>
        <div style="height:0.5em;"></div>
        <div style="height:0.5em;"></div>
      </div>

      <b-alert variant="primary" show v-if="showPrivacy">
        <h5>Privacy Policy</h5>
        <h6>This system stores your email address in order to send you notifications of the progress of your referrals. It asks you for your healthcare organisation (e.g. NHS Trust) so that the right subset of trials are shown, and your name and phone number. This information is only accessible to researchers on the trials to which you choose to refer patients, to support the referral process (and to staff of FoundBy.Me Ltd which administers this system, for technical support). The information will not be shared with anyone else, or for any other purpose. The information is stored within the EU-US Privacy Shield Program.</h6>
      </b-alert>

      <div class="d-flex flex-row justify-content-end align-items-center">
        <div v-if="existingUser" @click="tryForgotPassword(email)">
          <span style="text-decoration:underline;">Forgot password?</span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
        <div v-if="!existingUser">
          I agree to the
          <span
            @click="showPrivacy=!showPrivacy"
            style="text-decoration:underline;"
          >privacy policy</span>.
        </div>
        <div>&nbsp;</div>
        <b-button
          variant="warning"
          size="lg"
          class="btn btn-secondary"
          @click="trySignupOrLogin(existingUser,email,password)"
        >
          <div v-if="existingUser">&nbsp;OK&nbsp;</div>
          <div v-else>OK</div>
        </b-button>
      </div>

      <hr />
    </section>

    <!-- ----------------------- -->

    <section v-if="step==='1' || step==='2'">
      <!-- Only show when being asked for email or password -->
      <br />
      <br />
      <br />
      <br />
      <br />
      <b-button
        variant="outline-dark"
        size="sm"
        @click="showTechSupport=!showTechSupport"
      >Office use only</b-button>
      <div v-if="showTechSupport">
        <h5>Device information</h5>
        <p>This information might be needed if you experience issues on this device</p>
        <pre style="color:white;font-size:70%;background-color:black;">S = {{!!standalone}}
          <br />{{device}}</pre>
      </div>
    </section>
  </div>
</template>

<script>
import Spinner from "./ext/Spinner.vue";
import * as bowser from "bowser";
import EmailAdminLink from "./EmailAdminLink.vue";

export default {
  components: { Spinner, EmailAdminLink },

  props: {
    auth: {
      // the firebase AUTH object being passed in here from the APP page
      type: Object,
      required: true
    }
  },

  data: function() {
    return {
      step: "1",
      emailHead: "",
      emailWhole: "",
      email: "",
      existingUser: null,
      password: "",
      showSpinner: false,
      loginError: {},
      showPrivacy: false,
      device: {},
      standalone: false,
      showTechSupport: false
    };
  },

  created() {
    // Just for debugging purposes, will remove in main program
    const parser = bowser.getParser(window.navigator.userAgent);
    this.device = parser.parse().parsedResult;
    this.standalone = false;
    if (window.matchMedia("(display-mode: standalone)").matches) {
      // Chromium-based
      this.standalone = true;
    }
    if (window.navigator && window.navigator.standalone) {
      // iOS
      this.standalone = true;
    }
  },

  mounted() {},

  methods: {
    submitEmailWholeOrHead(emailHead, emailWhole) {
      this.loginError = {};
      this.step = "1p5";
      if (emailHead !== "") {
        this.email = this.establishFullEmailAddress(emailHead);
      } else {
        this.email = emailWhole;
      }
      this.auth
        .fetchSignInMethodsForEmail(this.email)
        .then(methodsArray => {
          this.existingUser = methodsArray.length > 0;
          this.step = "2";
        })
        .catch(error => {
          this.loginError = error;
          this.step = "1";
        });
    },

    establishFullEmailAddress(emailHead) {
      emailHead = emailHead.trim().toLowerCase();
      const lettersPreAt = emailHead
        .replace(/@.*/g, "")
        .replace(/[^a-zA-Z]/g, "")
        .toLowerCase();
      if (
        (lettersPreAt === "alexnowbar" || lettersPreAt === "darrelfrancis") &&
        emailHead.includes("@gmail.com")
      ) {
        return emailHead;
      } else {
        return emailHead + "@nhs.net";
      }
    },

    trySignupOrLogin(existingUser, email, password) {
      if (existingUser) {
        this.tryLogin(email, password);
      } else {
        this.trySignup(email, password);
      }
    },

    tryLogin(email, password) {
      this.showSpinner = true;
      this.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {})
        .catch(e => {
          this.loginError = e;
          console.log("Signin error:", e);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },

    trySignup(email, password) {
      this.showSpinner = true;
      this.auth
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          this.auth.currentUser.sendEmailVerification();
        })
        .catch(e => (this.loginError = e))
        .finally(() => {
          this.showSpinner = false;
        });
    },

    // ------ This function moved to "MyAccount"
    // trySignOut() {
    //   this.showSpinner = true;
    //   this.auth
    //     .signOut()
    //     .then(() => {})
    //     .catch(e => (this.loginError = e))
    //     .finally(() => {
    //       this.showSpinner = false;
    //     });
    // },

    tryForgotPassword(email) {
      this.loginError = {
        message: ""
      };
      this.showSpinner = true;

      this.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          this.loginError = {
            message:
              "We've just emailed you a password reset link. Please open your email and click the link from us."
          };
        })
        .catch(e => (this.loginError = e))
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }
};
</script>

<style>
.main {
  text-align: center;
  margin-top: 40px;
}

.header-logged-out {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.err-font {
  background: pink;
  color: darkred;
}

.err-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}
</style>

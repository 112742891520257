<template>
  <div
    class="mx-3"
    v-cloak
  >

    <div class="d-flex flex-row justify-content-between">
      <!-- <div style="color:gray">&nbsp; © ORBITA-HQ 2018</div> -->
      &nbsp;
      <b-button
        v-b-toggle.collapseHelp
        @click="scrollHelpIntoView"
        variant="link"
        size="sm"
      > Help</b-button>
    </div>

    <b-collapse
      id="collapseHelp"
      class="mt-2"
    >

      <h2 id="scrollTarget">Help</h2>

      <b-alert
        show
        variant="primary"
        v-if="privileges.blindedResearcher"
      >You are a blinded researcher. The help below is the outline of the layout seen by the last participant you accessed. If you don't currently have a participant open above, you can ignore any error messages about missing information.</b-alert>

      <b-alert
        show
        variant="primary"
        v-if="privileges.unblindedResearcher"
      >You are an <strong>unblinded</strong> researcher, so remember you only have access to participants before they are randomized. The help below is the outline of the layout seen by the last participant you accessed. If you don't currently have a participant open above, you can ignore any error messages about missing information.</b-alert>

      <h4>What happens if I miss a day?</h4>
      <p>If you miss a day, for example from lack of internet access, you can enter the data later. Our team will phone you if there is too much delay.</p>

      <h4>How do I use the mild-moderate-severe slider?</h4>
      <p>Use the slider to record your personal opinion on the severity of the angina you had on the day being asked about. This is a personal judgement for you to make at the time you are asked. We expect the way you make these judgements may naturally change over the course of the study. We are asking your independent view on how you feel each day.</p>

      <h4>What happens if I make a mistake?</h4>
      <p>Make a note of what you intended to enter and then call our helpline.</p>

      <h4>Why can't I see my previous entries?</h4>
      <p>During the trial we need your independent answers from the separate days and therefore the app does not show previous entries. After you finish the trial, we can show you all your entries.</p>

      <h4>What if the app isn’t working properly?</h4>
      <p>Please call our helpline.</p>

      <h4>What should I do if I don’t feel well?</h4>
      <p>If you want to discuss this with us, please call our helpline at any time.</p>

      <h4>Helpline number</h4>
      <p>#########</p>

      <h4>Advanced Tools</h4>
      <p>ORBITA staff may ask you to click this button <b-button
          b-button
          v-b-toggle.collapseAdvanced
          size="sm"
          variant="outline-primary"
        >Advanced Tools</b-button>
      </p>

      <b-collapse
        id="collapseAdvanced"
        class="mt-2"
      >
        <b-alert
          variant="light"
          show
        >

          <div
            style="width:100%"
            class="my-2 d-flex flex-row justify-content-between"
          >
            <div>Your unique user ID</div>
            <div>{{userId}}</div>
          </div>

          <!-- <div style="width:100%" class="my-2 d-flex flex-row justify-content-between">
          <div>If they ask you to ensure you have the newest version of the App</div>
          <b-button @click="getNewest">Get newest</b-button>
        </div> -->

          <div
            style="width:100%"
            class="my-2 d-flex flex-row justify-content-between"
          >
            <div>If asked to log out of the App</div>
            <b-button @click="$emit('trySignOut')">Log out</b-button>
          </div>

          <div
            style="width:100%"
            class="my-2 d-flex flex-row justify-content-between"
          >
            <div>App on your device was built at:</div>
            <div>{{buildVersion}}</div>
          </div>

          <b-button
            b-button
            v-b-toggle.collapseAdvanced
            size="sm"
            variant="outline-primary"
          >
            Close Advanced Tools
          </b-button>

          <br>
        </b-alert>

      </b-collapse>

      <div
        style="width:100%"
        class="my-2 d-flex flex-row justify-content-between"
      >
        <div>&nbsp;</div>
        <b-button
          block
          v-b-toggle.collapseHelp
          variant="primary"
        >Close Help</b-button>
      </div>

    </b-collapse>

  </div>
</template>

<script>
import { BUILDVERSION } from "./ext/buildVersion.js"; // This is overwritten by the building process
import { getNewest } from "../util/getNewest.js"; // but remember to also re-export it in methods
export default {
  components: {},

  props: {
    userId: String,
    privileges: {}
  },

  data: () => ({
    buildVersion: BUILDVERSION, // A constant written to buildVersion.js by the build command
    doc: document
  }),

  methods: {
    getNewest, // It was imported into the global area, and then I have to re-export it here so the HTML Template can see it. Basically I create a this.getNewest which is identical to the global getNewest
    scrollHelpIntoView: function() {
      setTimeout(
        () =>
          document
            .getElementById("scrollTarget")
            .scrollIntoView({ behaviour: "smooth" }),
        500
      );
    }
  }
};
</script>

<style>
</style>

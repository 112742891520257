
<template>
  <div class="p-3 d-flex flex-column" style="height: 100vh; max-width: 100%">
    <transition name="swish">
      <div v-if="okToUseApp">
        <app-header-bar title="My Account"></app-header-bar>
      </div>
    </transition>

    <section
      v-if="user && user.presenceIsLoaded && user.contactIsLoaded"
      id="middle-scrollable"
      style="overflow-y: auto; overflow-x: hidden"
    >
      <b-form>
        <h5>{{ user.contactObj.fullName || "" }}</h5>

        <!-- <div v-if="!user.contactObj.privacyAgreedIso">
          <b-alert
            show
            variant="danger"
            class="my-3"
          >
            <h3>Privacy</h3>
            <hr>
            <h6>Please confirm blah blah you agree etc etc </h6>

            <h6
              style="color: blue;"
              @click="showPrivacyMore = !showPrivacyMore"
            ><span v-if="showPrivacyMore">Hide</span><span v-else>Show</span> details blah blah</h6>

            <div v-if="showPrivacyMore">
              More info blah blah blah.
              <br>
              Interminable stuff.
              <br>
              etc.
            </div>
            <b-button
              block
              variant="light"
              size="lg"
              @click="user.contactRef.update({privacyAgreedIso:(new Date()).toISOString()})"
            >OK whatevs</b-button>
          </b-alert>
        </div>-->
        <br />
        <h6>Account</h6>

        <h5>
          {{
            (user && user.presenceObj && user.presenceObj.emailAtLastLogin) ||
            "We do not have your email address."
          }}
        </h5>

        <transition name="swish">
          <div
            v-if="
              user.presenceObj.emailAtLastLogin &&
              !user.contactObj.emailVerified &&
              !hideVerifyEmailWarning
            "
          >
            <b-alert show variant="warning">
              <h3>Verify your email</h3>
              <hr />
              <h6>
                Please go to your email inbox and click the verification link
                we've sent you.
              </h6>

              <div
                class="d-flex flex-row align-items-center justify-content-between"
              >
                Done? &nbsp;
                <b-button variant="light" @click="checkEmailVerification()"
                  >I've just done it</b-button
                >
              </div>

              <div
                class="d-flex flex-row align-items-center justify-content-between"
              >
                Can't find in your email inbox? &nbsp;
                <b-button
                  class="mt-2"
                  variant="light"
                  @click="
                    emailVerificationLinkSentLocaleTime = undefined;
                    sendEmailVerification();
                  "
                  >Send new link</b-button
                >
              </div>
              <div
                v-if="emailVerificationLinkSentLocaleTime"
                style="text-align: right; color: darkred"
              >
                <strong>
                  New link sent at {{ emailVerificationLinkSentLocaleTime }}.
                </strong>
                <br />Old link cancelled.
              </div>
              <div
                v-if="verficationErrorMessage"
                style="text-align: right; color: darkred"
              >
                Error:
                {{ verficationErrorMessage }}
              </div>

              <div
                v-if="
                  (((user || {}).presenceObj || {}).emailAtLastLogin || '')
                    .toLowerCase()
                    .indexOf('@nhs.net') !== -1
                "
                class="d-flex flex-row align-items-center justify-content-between"
              >
                Need help getting to your email? &nbsp;
                <b-button
                  class="mt-2"
                  variant="light"
                  @click="openEmailNhsNet()"
                  >Open nhs.net</b-button
                >
              </div>
            </b-alert>
          </div>
        </transition>
        <br />

        <h6>
          Title and Name
          <span style="font-size: 70%">in conventional full form</span>
        </h6>

        <b-form-input
          type="text"
          v-model="user.contactObj.fullName"
          required
          placeholder="e.g. Dr Jane Smith or Sister Ong Chen-Li"
          v-on:change="
            user.contactRef.child('fullName').set(user.contactObj.fullName)
          "
        ></b-form-input>
        <br />

        <transition name="swish">
          <div v-if="!fullNameOk">
            <b-alert show variant="danger"
              >Please do enter your name above. Use the professional format that
              would be used in a letter, e.g Dr Barbara Jones.</b-alert
            >
          </div>
        </transition>

        <h6>
          Mobile phone
          <span style="font-size: 70%">for identity confirmation</span>
        </h6>
        <b-form-input
          type="text"
          required
          v-model="user.contactObj.mobileSelf"
          @input="
            user.contactObj.mobileSelf = formatMobile(
              user.contactObj.mobileSelf
            )
          "
          @change="
            user.contactRef
              .child('mobileSelf')
              .set(formatMobile(user.contactObj.mobileSelf))
          "
          placeholder="e.g. +44 7000 000 000"
        ></b-form-input>
        <br />

        <h6>
          Administrative contact tel
          <span style="font-size: 70%">Optional</span>
        </h6>
        <p style="font-size: 70%">
          If you might be referring patients through this system, and prefer
          that researchers contact an administrator or secretary with any
          queries (instead of you), please give their number:
        </p>

        <b-form-input
          type="text"
          v-model="user.contactObj.adminTel"
          v-on:change="
            user.contactRef.child('adminTel').set(user.contactObj.adminTel)
          "
          placeholder="e.g. Clinic reception phone number"
        ></b-form-input>
        <br />

        <div v-if="user.contactObj.adminTel">
          <h6>
            Name of administrative contact
            <span style="font-size: 70%"
              >if anyone in particular covers your patients</span
            >
            &nbsp; (Optional)
          </h6>
          <b-form-input
            type="text"
            v-model="user.contactObj.adminWho"
            v-on:change="
              user.contactRef.child('adminWho').set(user.contactObj.adminWho)
            "
            placeholder="e.g. Chris"
          ></b-form-input>
          <br />
        </div>
      </b-form>

      <br />

      <div v-if="okToUseApp">
        <div class="d-flex flex-row justify-content-center">
          <b-button variant="light" size="lg" @click="goHome()"
            >&nbsp; &nbsp; OK &nbsp; &nbsp;</b-button
          >
        </div>
      </div>

      <br />
      <hr style="border-color: white" />

      <div class="d-flex flex-row justify-content-between">
        <b-button
          variant="outline-light"
          size="sm"
          @click="
            showAdvancedBottom = !showAdvancedBottom;
            scrollToBottomAfterAMoment();
          "
          >Advanced</b-button
        >
        <div>&nbsp;</div>
      </div>
      <br />

      <div v-if="showAdvancedBottom">
        <div>Turning over control of this device to someone else?</div>
        <div class="d-flex flex-row justify-content-between">
          <div>&nbsp;</div>
          <b-button variant="light" size="sm" @click="trySignOut()"
            >Log out</b-button
          >
        </div>
        <br />

        <div>Are our support team asking for your full user ID?</div>
        <div
          style="
            color: white;
            user-select: all;
            text-align: right;
            font-weight: 700;
          "
        >
          {{ user.id }}
        </div>
        <br />

        <div>Need to contact us?</div>
        <div style="text-align: right; font-weight: 700">
          <email-admin-link :user="user" sty="color:white;"></email-admin-link>
        </div>
        <br />
      </div>
    </section>
  </div>
</template>

<script>
import { auth } from "../util/firebase.js";
import { formatMobile } from "../util/phoneNumber.js";
import AppHeaderBar from "./AppHeaderBar.vue";
import EmailAdminLink from "./EmailAdminLink.vue";
import { getNewest } from "../util/getNewest.js";

export default {
  components: { AppHeaderBar, EmailAdminLink },

  props: {
    user: {
      type: Object,
      required: true, // We INSIST on being given an object, but we still give a default value, solely to me Vue walk the tree to "emailVerified" to make it reactive
      default() {
        return {
          presenceObj: {
            emailVerified: false,
            emailAtLastLogin: "",
          },
          presenceIsLoaded: undefined,
          contactObj: { fullName: undefined },
          contactIsLoaded: undefined,
        };
      },
    },
  },

  data: function () {
    return {
      //  showPrivacyMore: false,
      showAdvancedBottom: false,
      emailVerificationLinkSentLocaleTime: undefined,
      verficationErrorMessage: "",
      hideVerifyEmailWarning: false,

      // Make imported functions available in THIS, so template can reference
      formatMobile,
    };
  },

  computed: {
    emailOk: function () {
      return (
        this.user && this.user.contactObj && this.user.contactObj.emailVerified
      );
    },

    fullNameOk: function () {
      return (
        this.user &&
        this.user.contactObj &&
        this.user.contactObj.fullName &&
        this.user.contactObj.fullName.length >= 7
      );
    },

    okToUseApp: function () {
      return this.emailOk && this.fullNameOk;
    },
  },

  created() {},

  methods: {
    sendEmailVerification() {
      auth.currentUser
        .sendEmailVerification()
        .then(() => {
          this.emailVerificationLinkSentLocaleTime = new Date().toLocaleTimeString();
        })
        .catch((error) => {
          this.verficationErrorMessage = error.message;
          console.error(
            "FAILED to send Email verification email. ",
            error.message
          );
        });
    },

    async checkEmailVerification() {
      this.hideVerifyEmailWarning = true;

      // Holy crap!
      // https://stackoverflow.com/questions/37900447
      // but:
      // https://stackoverflow.com/questions/51915102
      // and comment by Gabeloooooo in:
      // https://stackoverflow.com/questions/51709733

      await auth.currentUser.reload(); // according to  https://stackoverflow.com/questions/37900447
      const emailVerified = auth.currentUser.emailVerified;
      this.user.contactRef.child("emailVerified").set(emailVerified);
      setTimeout(() => {
        this.hideVerifyEmailWarning = false;
      }, 2000);
    },

    // openEmailNhsNet() {
    //   this.window.open("https://email.nhs.net", "_blank");
    // },

    trySignOut() {
      //     this.showSpinner = true;
      auth
        .signOut()
        .then(() => {
          // this.$router.replace({ name: "home" });
          // NO! The above leaves the values of "user" and "emailVerified" etc set at the old values. We need to clear it completely.
          getNewest();
        })
        .catch((e) => (this.loginError = e) /* Don't */)
        .finally(() => {
          //       this.showSpinner = false;
        });
    },

    scrollToBottomAfterAMoment() {
      setTimeout(() => {
        document.getElementById("middle-scrollable").scrollBy(0, 9999);
      }, 500);
    },

    // Navigation ===============================================

    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.replace({ name: "home" });
    },
  },
};
</script>

<style>
</style>

<template>
  <div>
    <!-------------------

There are mltiple trees, each with its own "id", and each *NEEDING* to be referenced separately.

slVueTree_readOnlyMiniImage = Shown in the PARENT component (EditTrial)
slVueTree_criteriaInTrial = Main display at TOP of EditTrialLogic, which shows the logic of this trial
slVueTree_chooseFrom = Bottom list of all avalable criteria types

 -------------------------->

    <!-- big font comments: http://patorjk.com/software/taag/#p=display&f=Cyberlarge&t=Big%20editable -->

    <section v-if="image===true">
      <!--

 ┬─┐┌─┐┌─┐┌┬┐┌─┐┌┐┌┬ ┬ ┬
 ├┬┘├┤ ├─┤ │││ │││││ └┬┘
 ┴└─└─┘┴ ┴─┴┘└─┘┘└┘┴─┘┴

 Use this branch if you just want a small, non-editing, pic of the trial logic -->
      <div v-if="trialCriterionIsLoaded">

        <sl-vue-tree
          v-model="criteriaInTrial"
          ref="slVueTree_readOnlyMiniImage"
          style="overflow:hidden;"
        >

          <template slot="toggle">
            {{"" /* To get rid of the little "-" / "+" at the left*/}}
          </template>

          <template
            slot="title"
            slot-scope="{ node }"
          >
            <span class="node--tabbing--monospaced-font">
              {{tabbing(node,$refs.slVueTree_readOnlyMiniImage)}}
              <span v-if="!node.isLastChild">├</span>
              <span v-if=" node.isLastChild">╰</span>
            </span>
            <span :class="{'node--title--is-logical':!node.isLeaf}">
              {{ node.title }}
            </span>
          </template>

        </sl-vue-tree>
      </div>

    </section>

    <!-- ONLY NOW IS THE ACTUAL STANDALONE COMPONENT -->
    <section
      v-else
      class="p-2"
      style="height:100vh; overflow-y:auto;"
    >
      <!-- best place to understand SLVUETREE is https://github.com/holiber/sl-vue-tree/blob/master/demo/dark-theme.html -->

      <!-- <link
        href="https://use.fontawesome.com/releases/v5.0.8/css/all.css"
        rel="stylesheet"
      > -->

      <div
        id="titleAndNavigation"
        class=""
      >
        <div
          v-if="saveButtonShowability()"
          class="mb-2 d-flex justify-content-between"
        >
          <b-button
            variant="warning"
            @click="onClickSaveTrial"
          >
            <!-- <svg class="ion">
              <use xlink:href="#ion-chevron-left"></use>
            </svg>  -->
            &nbsp; Save &nbsp;
          </b-button>
          <h2>Logic</h2>
          <b-button
            variant="danger"
            @click="goBack"
          > Undo
          </b-button>
        </div>
        <div
          v-else-if="!fact.data"
          class="mb-2 d-flex justify-content-between"
        >
          <!-- SAVE button NOT showable, and there is no FACT DATA middle window open ...-->
          <b-button
            variant="outline-light"
            size="sm"
            @click="goBack"
          ><svg class="ion">
              <use xlink:href="#ion-chevron-left"></use>
            </svg>
            <!-- &nbsp; Back &nbsp; -->
          </b-button>
          <h2>Logic</h2>
          &nbsp;
        </div>
        <div v-else>
          <!-- The third possibility, SAVE button NOT showable, and there IS a FACT DATA middle window open, needs nothing displayed -->
        </div>
      </div>

      <div v-if="trialCriterionIsLoaded">

        <div
          class="screen-panel--top--this-trial-criteria"
          style="color:black;overflow-hidden;padding-top:0.25em;"
          id="slVueTree_criteriaInTrial_idForScrollingTo"
        >
          <!--
┌─┐┬─┐┬┌┬┐┌─┐┬─┐┬┌─┐  ┬ ┌┐┌  ┌┬┐┬─┐┬┌─┐┬
│  ├┬┘│ │ ├┤ ├┬┘│├─┤  │ │││   │ ├┬┘│├─┤│
└─┘┴└─┴ ┴ └─┘┴└─┴┴ ┴  ┴ ┘└┘   ┴ ┴└─┴┴ ┴┴─┘
Use this branch for the usual case of live-editing

Right-click PREVENTDEFAULT does not work when the Chrome Dev Tools is open
  -->
          <sl-vue-tree
            v-model="criteriaInTrial"
            ref="slVueTree_criteriaInTrial"
            @select="dummyFunctionToPreventDefault"
            @nodeclick="dummyFunctionToPreventDefault"
            @nodedblclick="dummyFunctionToPreventDefault"
            @toggle="dummyFunctionToPreventDefault"
            @drop="dummyFunctionToPreventDefault"
            @nodecontextmenu="dummyFunctionToPreventDefault"
            @externaldrop="dummyFunctionToPreventDefault"
            :allow-multiselect="false"
          >
            <!-- Need the class "pb-1" to stop a slight vertical wobble, don't know why -->

            <template slot="toggle">
              {{"" /* To get rid of the little "-" / "+" at the left*/}}
            </template>

            <template
              slot="title"
              slot-scope="{ node }"
            > <span class="node--tabbing--monospaced-font">
                {{tabbing(node,$refs.slVueTree_criteriaInTrial)}}
                <span v-if="!node.isLastChild">├</span>
                <span v-if=" node.isLastChild">╰</span>
              </span>
              <span :class="{'node--title--is-logical':!node.isLeaf,'blinking':node.isSelected,'highlighted':node.isSelected}">
                {{ node.title }}
              </span>

              <span v-if="node.isSelected && (node.isLeaf || node.children.length===0)"> &nbsp; &nbsp; <b-button
                  @click=" slVueTree_criteriaInTrial_removeNode(node)"
                  variant="danger"
                  size="sm"
                  style="margin-top:-8px;margin-bottom:-4px; padding:0;"
                >
                  &nbsp; &nbsp; Delete &nbsp; &nbsp;
                </b-button>
              </span>
            </template>

          </sl-vue-tree>
        </div>

        <b-alert
          show
          v-if="!criteriaInclusionCheck().isValid"
          variant="danger"
          class="mt-2"
        >
          {{criteriaInclusionCheck().errorMessage}}
        </b-alert>

        <b-alert
          show
          v-if="criteriaInclusionCheck().warningMessage"
          variant="warning"
        >
          {{criteriaInclusionCheck().warningMessage}}
        </b-alert>
      </div>
      <div v-else>Loading criteria for this trial ...</div>

      <!-----------------------------

FACT BOX
--------------------------- -->
      <div
        v-if="fact.data"
        class="screen-panel--middle--fact-box-enter-cutoffs-etc"
      >
        <b-form>
          <b-form-group>
            <h3 style="text-align:center;font-weight:bold; color:black;">
              {{fact.data.shortText}}
            </h3>
            <div v-if="fact.data.type==='Categorical'">
              <b-container>
                <div
                  v-for="option in fact.data.options"
                  :key="option.id"
                >
                  <b-row
                    class="my-2"
                    align-v="center"
                  >
                    <b-col style="color:black;">
                      {{option.shortText}}
                    </b-col>
                    <b-col>
                      <b-button
                        @click="saveCriterionCategorical(fact.data,option,true,form)"
                        variant="light"
                        style="border-color:black;"
                      >
                        Inclusion
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        @click="saveCriterionCategorical(fact.data,option,false,form)"
                        variant="dark"
                      >
                        Exclusion
                      </b-button>
                    </b-col>
                  </b-row>
                </div>

                <hr>

                <b-row>
                  <b-col>
                    <b-button
                      @click="fact={};form={};"
                      variant="danger"
                    >
                      Cancel
                    </b-button>
                  </b-col>

                </b-row>

              </b-container>
            </div>
            <div v-if="fact.data.type==='Continuous'">
              <h6 style="text-align:center;">{{fact.data.units}}</h6>
              <b-container>
                <b-row class="mt-2">
                  <b-col>
                    <h4>
                      Greater than
                    </h4>
                  </b-col>
                  <b-col>
                    <h4>
                      Less than
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="form.gteq">
                      or equal to
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="form.lteq">
                      or equal to
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col>
                    <b-form-input
                      size="lg"
                      type="number"
                      v-model="form.llString"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input
                      size="lg"
                      type="number"
                      v-model="form.ulString"
                    > </b-form-input>
                  </b-col>
                </b-row>
                <div>
                  <hr>
                  <b-row class="my-2 d-flex justify-content-between">
                    <b-button
                      variant="danger"
                      size="lg"
                      @click="form={};fact={};"
                    >
                      &nbsp; Cancel &nbsp;
                    </b-button>

                    <b-button
                      v-if="continuousFormValid"
                      variant="light"
                      size="lg"
                      @click="saveCriterionContinuous(fact.data,form)"
                    > <svg
                        class="ion"
                        style="height:2em;width:2em;"
                      >
                        <use xlink:href="#ion-ios-plus-outline"></use>
                      </svg>
                      &nbsp; Add &nbsp;
                    </b-button>

                  </b-row>
                </div>
              </b-container>
            </div>
          </b-form-group>
        </b-form>
      </div>

      <br>

      <div
        v-if="!fact.data"
        class="tree-container "
      >
        <div v-if="scienceFactIsLoaded">
          <h4>Add:</h4>

          <!-- <span v-if="($refs.slVueTree_criteriaInTrial||{}).selectionSize>0">
              where flashing

            </span> -->

          <!--
┌─┐┬ ┬┌─┐┌─┐┌─┐┌─┐  ┌─┐┬─┐┌─┐┌┬┐  ┌─┐┬─┐┬┌┬┐┌─┐┬─┐┬┌─┐
│  ├─┤│ ││ │└─┐├┤   ├┤ ├┬┘│ ││││  │  ├┬┘│ │ ├┤ ├┬┘│├─┤
└─┘┴ ┴└─┘└─┘└─┘└─┘  └  ┴└─└─┘┴ ┴  └─┘┴└─┴ ┴ └─┘┴└─┴┴ ┴
 -->
          <sl-vue-tree
            v-model="chooseFrom"
            ref="slVueTree_chooseFrom"
            @select="slVueTree_chooseFrom_nodeSelect"
            @nodecontextmenu="dummyFunctionToPreventDefault"
            @nodeclick="dummyFunctionToPreventDefault"
            @nodedblclick="dummyFunctionToPreventDefault"
            @toggle="dummyFunctionToPreventDefault"
            @drop="dummyFunctionToPreventDefault"
            @externaldrop="dummyFunctionToPreventDefault"
            class="screen-panel--bottom--choose-from-available-criteria"
          >

            <template
              slot="toggle"
              slot-scope="{ node }"
            >
              {{tabbing(node)}} &nbsp; {{tabbing(node)}}
              <span v-if="!node.isLeaf">

                <svg
                  v-if="node.isExpanded"
                  class="ion screen-panel--bottom--choose-from-available-criteria--icon"
                >
                  <use xlink:href="#ion-chevron-down">
                  </use>
                </svg>

                <svg
                  v-if="!node.isExpanded"
                  class="ion screen-panel--bottom--choose-from-available-criteria--icon"
                >
                  <use xlink:href="#ion-chevron-right">
                  </use>
                </svg>

              </span>
            </template>

            <template
              slot="title"
              slot-scope="{node}"
            >
              <span
                v-if="!node.isLeaf"
                style="border-left-width:1px;border-color:red;"
              >
                {{ node.title }}
              </span>
              <span v-if="node.isLeaf">
                {{tabbing(node)}}{{tabbing(node)}}
                <span style="display:inline-block; width: 1.3em;">
                </span>
                <span>
                  <b-button
                    variant="light"
                    size="sm"
                    class="mb-1"
                    style="border-top-left-radius:0; border-bottom-left-radius:0; border-top-right-radius:0.6em; border-bottom-right-radius:0.6em;"
                  > {{ node.title }}
                  </b-button>
                </span>
              </span>
            </template>

          </sl-vue-tree>
        </div>
        <div v-else>
          Loading available fact types
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import { tabbing } from "../util/tabbing.js";

const criterionDefaults = {
  isLeaf: true,
  isSelectable: true,
  isDraggable: false
};

const treeLogicalHalf = [
  {
    title: "ALL",
    data: { isLogical: true },
    children: [],
    ...criterionDefaults
  },
  {
    title: "ANY",
    data: { isLogical: true },
    children: [],
    ...criterionDefaults
  },
  {
    title: "NO",
    data: { isLogical: true },
    children: [],
    ...criterionDefaults
  }
];

const blankOfCriteriaInTrial = [
  {
    ...JSON.parse(JSON.stringify(treeLogicalHalf[0])),
    isDraggable: false,
    isLeaf: false
  }
];

import SlVueTree from "sl-vue-tree";
import { scienceFactRootRef, trialCriterionRootRef } from "../util/firebase.js";
import generatePushId from "../util/generatePushId.js";
import {
  formatCriterionContinuous,
  formatCriterionCategorical
} from "../util/formatCriterion.js";

export default {
  components: { SlVueTree },

  props: {
    id: {
      type: String,
      default: "tri~dummy-for-testing-001"
    },
    image: {
      // If you set this to true, you only get a static image, not the actual editable live thing.
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      criteriaInTrial: blankOfCriteriaInTrial,
      criteriaInTrialOnDatabase: "", // This is the JSON-stringified version of what we last received from Firebase. If the JSON-stringified version of the CURRENT criteriaInTrial is different, then criteriaInTrial is dirty and so we should show the show/cancel buttons.
      chooseFrom: [],
      contextMenuIsVisible: false,
      fact: {},
      form: {},
      trialCriterionIsLoaded: false,
      scienceFactIsLoaded: false
    };
  },

  computed: {
    continuousFormValid: function() {
      return (
        ((this.form.llString || "").length > 0 ||
          (this.form.ulString || "").length > 0) && // there is SOMETHING in some part of it
        !(this.form.gteq && (this.form.llString || "").length === 0) && // if you've said greater than OR EQUAL TO, you've put a number
        !(this.form.lteq && (this.form.ulString || "").length === 0) && // ditto LTEQ
        !(
          (this.form.llString || "").length > 0 &&
          (this.form.ulString || "").length > 0 &&
          parseFloat(this.form.llString) > parseFloat(this.form.ulString)
        ) // UL and LL not backwards!
      );
    }
  },

  created: function() {
    // Every Firebase link you make here, you must break in the beforeDestroy, to prevent repetitious pointless data-getting which costs $$
    scienceFactRootRef.on("value", this.firebaseBindingScienceFact);
    trialCriterionRootRef
      .child(this.id)
      .on("value", this.firebaseBindingTrialCriterion);
  },

  beforeDestroy: function() {
    // Undo the created
    scienceFactRootRef.off("value", this.firebaseBindingScienceFact);
    trialCriterionRootRef
      .child(this.id)
      .off("value", this.firebaseBindingTrialCriterion);
  },

  methods: {
    tabbing: /* the imported global */ tabbing, // This just makes the imported helper funciton "tabbing" available as "this.tabbing" so the template can refer to it.

    // SL VUE TREE EVENT HANDLERS
    // Note that:
    // There are three trees, each with their own handlers
    // To trigger some things like context menu (long-press/right-click) you must:
    // (a) NOT have chrome dev tools open (dont know why)
    // (b) Put an "event.preventDefault()" in the handler

    // ┬ ┬┌─┐┌┐┌┌┬┐┬  ┌─┐  ┌─┐┬─┐┬┌┬┐┌─┐┬─┐┬┌─┐  ┬ ┌┐┌  ┌┬┐┬─┐┬┌─┐┬
    // ├─┤├─┤│││ │││  ├┤   │  ├┬┘│ │ ├┤ ├┬┘│├─┤  │ │││   │ ├┬┘│├─┤│
    // ┴ ┴┴ ┴┘└┘─┴┘┴─┘└─┘  └─┘┴└─┴ ┴ └─┘┴└─┴┴ ┴  ┴ ┘└┘   ┴ ┴└─┴┴ ┴┴─┘

    /////////////////////////////////////

    slVueTree_criteriaInTrial_removeNode(node) {
      // Not called direct by a standard event, but called if you click the DELETE button, which appears (within the template TITLE slot) only once you SELECT a node
      this.$refs.slVueTree_criteriaInTrial.remove([
        node.path /* expects an array */
      ]);
    },

    // ┬ ┬┌─┐┌┐┌┌┬┐┬  ┌─┐  ┌─┐┬ ┬┌─┐┌─┐┌─┐┌─┐  ┌─┐┬─┐┌─┐┌┬┐
    // ├─┤├─┤│││ │││  ├┤   │  ├─┤│ ││ │└─┐├┤   ├┤ ├┬┘│ ││││
    // ┴ ┴┴ ┴┘└┘─┴┘┴─┘└─┘  └─┘┴ ┴└─┘└─┘└─┘└─┘  └  ┴└─└─┘┴ ┴

    // 1. Handle Bottom Panel (clicking an available node)
    slVueTree_chooseFrom_nodeSelect: function(nodes, event) {
      event.preventDefault(); // Stop slVueTree from marking it as selected. Instead, we will do our custom handling here.

      const node = nodes[0]; // We get an array but are only expecting one item
      if (node.data.isLogical) {
        // Logical node, i.e. ALL, ANY, NO. THese don't need any further information: they can be poked straight into the trial's criteria tree.

        // Step 1. Scroll screen to bring the "criteria in trial" tree into view
        document
          .getElementById("slVueTree_criteriaInTrial_idForScrollingTo")
          .scrollIntoView({ behaviour: "smooth", block: "center" });

        // Step 2. Deselect the existing node
        const destinationArray = this.arrayIntoWhichToPushNewNode(
          this.$refs.slVueTree_criteriaInTrial
        ); // Get this before we deselect and lose the information of what target node is currently sleected

        this.deselectRecursively(this.criteriaInTrial);

        // Step 3. After a second so people can see what is happening, add the element. Have it SELECTED when you add LEAF items next, it goes onto here.
        setTimeout(() => {
          destinationArray.push({
            title: node.title,
            isDraggable: true,
            isLeaf: false,
            isSelectable: true,
            isSelected: true, // So next addition goes here.
            children: [] // Need this so you can add items here.
          });
        }, 1000);
      } else {
        // Medical node
        if (node.isLeaf) {
          // i.e. is an actual FACT and not a heading
          // CANNOT CREATE IT YET. Have to open middle box, which in turn has a SAVE button which does the UPDATENODE.
          this.fact = JSON.parse(JSON.stringify(node)); // THe act of doing this causes the middle panel to open up.
        }
      }
    },

    arrayIntoWhichToPushNewNode(slVueTreeObj) {
      // Returns a nodeModel, i.e. the array itself, into which you can just push
      // Plan A. If a logical node is currently selected, use that.
      // Plan B. If a leaf node is currently selected, use its parent.
      // Plan C. Root node, assuming it is logical
      // Plan D. Root node is leaf, so can't inject there, so return "error"

      const tree = slVueTreeObj.nodes;

      if (tree.length === 0) {
        // if tree is empty, destination is the tree itself, i.e. you will push onto the root
        return slVueTreeObj.value;
      }

      let selectedNodes = slVueTreeObj.getSelected();

      if (selectedNodes.length === 0) {
        if (!tree[0].isLeaf) {
          // Nothing selected and root node is logical, so push there

          return slVueTreeObj.value[0].children;
        } else {
          // Nothing selected and root node is non-logical, so can't push anywhere
          console.log("nothing selected, root is non-logical, so ERROR");
          return "error";
        }
      }
      const selectedNode = selectedNodes[0];
      const selectedPath = selectedNode.path;
      let pathOfParentOfNewNode;
      if (selectedNode.isLeaf) {
        // If node is a leaf, return the PARENT's path
        pathOfParentOfNewNode = selectedPath.slice(0, selectedPath.length - 1);
      } else {
        // Else node is logical, so return its own path
        pathOfParentOfNewNode = selectedPath;
      }

      let remainingPath = pathOfParentOfNewNode;
      let currentObj = slVueTreeObj.value;
      while (remainingPath.length > 0) {
        currentObj = currentObj[remainingPath[0]].children;
        remainingPath = remainingPath.slice(1);
      }
      return currentObj;
    },

    choosePathToInjectNewNode(slVueTreeObj) {
      // Plan A. If a logical node is currently selected, use that.
      // Plan B. If a leaf node is currently selected, use its parent.
      // Plan C. Root node, assuming it is logical
      // Plan D. Root node is leaf, so can't inject there, so return "error"

      const tree = slVueTreeObj.nodes;
      if (tree.length === 0) {
        // if tree is empty, destination is the tree itself, i.e. you will push onto the root
        return [0];
      }
      let selectedNodes = slVueTreeObj.getSelected();
      if (selectedNodes.length === 0) {
        if (!tree[0].isLeaf) {
          // Nothing selected and root node is logical, so push there
          return [0, tree[0].children.length];
        } else {
          // Nothing selected and root node is non-logical, so can't push anywhere
          return "error"; // Dummy "sink" destination
        }
      }

      const selectedNode = selectedNodes[0];
      const selectedPath = selectedNode.path;
      let pathOfParentOfNewNode;
      if (selectedNode.isLeaf) {
        // If node is a leaf, return the PARENT's path
        pathOfParentOfNewNode = selectedPath.slice(0, selectedPath.length - 1);
      } else {
        // Else node is logical, so return its own path
        pathOfParentOfNewNode = selectedPath;
      }

      const nChildrenOfParentOfNewNode = this.$refs.slVueTree_criteriaInTrial.getNode(
        pathOfParentOfNewNode
      ).children.length;
      const pathOfNewNode = pathOfParentOfNewNode.concat(
        nChildrenOfParentOfNewNode
      );
      return pathOfNewNode;
    },

    // Used by all my slVueTree instances

    dummyFunctionToPreventDefault: function(nodes, event) {
      event.preventDefault();
    },

    // ┌─┐┬┬─┐┌─┐┌┐ ┌─┐┌─┐┌─┐
    // ├┤ │├┬┘├┤ ├┴┐├─┤└─┐├┤
    // └  ┴┴└─└─┘└─┘┴ ┴└─┘└─┘

    firebaseBindingScienceFact: function(snapshot) {
      this.scienceFactObj = snapshot.val();
      this.scienceFactArray = Object.keys(this.scienceFactObj).map(key => ({
        ...this.scienceFactObj[key],
        id: key
      }));

      {
        let treeMedicalhalf = [];
        this.scienceFactArray.forEach(scienceFact => {
          if (!scienceFact.path) {
            scienceFact.path = ["~ Unclassified facts"];
          }
          const pathLength = scienceFact.path.length;
          let subtree = treeMedicalhalf;
          for (let iPathStep = 0; iPathStep < pathLength; iPathStep++) {
            const titleOfStep = scienceFact.path[iPathStep];
            const titles = subtree.map(obj => obj.title);
            let indexOfMatchingChild = titles.indexOf(titleOfStep);
            if (indexOfMatchingChild < 0) {
              subtree.push({ title: titleOfStep, children: [] });
              indexOfMatchingChild = subtree.length - 1;
            }
            subtree = subtree[indexOfMatchingChild].children;
          }
          subtree.push({
            isLeaf: true,
            title: scienceFact.shortText,
            data: { ...scienceFact }
          });
        });

        this.chooseFrom = [
          { title: "Medical facts", isLeaf: false, children: treeMedicalhalf },
          {
            title: "Logical connectors",
            isLeaf: false,
            children: treeLogicalHalf
          }
        ];
      }
      this.scienceFactIsLoaded = true;
    },

    firebaseBindingTrialCriterion: function(snapshot) {
      const val = snapshot.val();
      if (val) {
        this.criteriaInTrial = this.makeCriteriaExpandRecursively(val);
        this.criteriaInTrialAfterLoadingCloudJSONStringified = JSON.stringify(
          this.criteriaInTrial
        );
        //     this.trialCriterionIsLoaded = true;
      } else {
        this.criteriaInTrial = blankOfCriteriaInTrial;
      }
      this.trialCriterionIsLoaded = true;
    },

    // HANDLERS FOR CLICKING IN BOTTOM 2 panels OF SCREEN

    // 4. Handle MIDDLE panel click, on a fact that one has clarified what category or range one wants to make a criterion --------------------------------------------------

    // 4a. CONTINUOUS: first MAKE the coninuous criterion object, then use the generic criterion-saving function to save it
    saveCriterionContinuous(factObjPlusData, form) {
      const criterionObj = this.makeCriterionContinuous(factObjPlusData, form);
      this.saveCriterionOfEitherType(factObjPlusData, form, criterionObj);
    },

    // 4a. CONTINUOUS: first MAKE the coninuous criterion object, then use the generic criterion-saving function to save it
    saveCriterionCategorical(factObjPlusData, option, inVersusExClusion, form) {
      const criterionObj = this.makeCriterionCategorical(
        factObjPlusData,
        form,
        option,
        inVersusExClusion
      );
      this.saveCriterionOfEitherType(factObjPlusData, form, criterionObj);
    },

    // 4c. Auxilliary functions to make the criterion objects of each type and to write either type

    makeCriterionContinuous(factObjPlusData, form) {
      let criterionObj = {
        id: form.id || "cri~" + generatePushId(), // The id of this INSTANCE of use of the fact
        title: formatCriterionContinuous(factObjPlusData.shortText, form),
        factId: factObjPlusData.id, // The id of the UNDERLYING fact (common to all trials)
        isSelectable: true,
        isDraggable: true,
        isLeaf: true
      };
      if (form.llString && form.llString.length > 0) {
        criterionObj.ll = parseFloat(form.llString);
        criterionObj.gteq = form.gteq || null;
      }
      if (form.ulString && form.ulString.length > 0) {
        criterionObj.ul = parseFloat(form.ulString);
        criterionObj.lteq = form.lteq || null;
      }

      return criterionObj;
    },

    makeCriterionCategorical(
      factObjPlusData,
      form,
      option,
      isIn /* isINCLUSION: true for inclusion, false for exclusion*/
    ) {
      return {
        id: form.id || "cri~" + generatePushId(), // The id of this INSTANCE of use of the fact
        title: formatCriterionCategorical(
          factObjPlusData.shortText,
          form,
          option,
          isIn
        ),
        factId: factObjPlusData.id, // The id of the UNDERLYING fact (common to all trials using this fact)
        optionId: option.id,
        isIn,
        isSelectable: true,
        isDraggable: true,
        isLeaf: true
      };
    },

    saveCriterionOfEitherType(factObjPlusData, form, criterionObj) {
      if (!form.pathWithinCriteriaInTrial) {
        // NEWLY CREATED criterion.
        const arrayIntoWhichToPush = this.arrayIntoWhichToPushNewNode(
          this.$refs.slVueTree_criteriaInTrial
        );
        const combinedCriterionAndFactObj = Object.assign(
          {},
          factObjPlusData,
          criterionObj,
          { factId: factObjPlusData.id } // criterion ID overwrites fact ID, so we add the factId back in under a different property name
        );
        arrayIntoWhichToPush.push(combinedCriterionAndFactObj);
      } else {
        // EXISTING criterion needing to be updated
        // NOT WRITTEN CODE FOR THIS
      }
      this.fact = {};
      this.form = {};
    },

    //////////////////////////////////////////////////

    deselectRecursively(tree) {
      // a tree is an array of objects.
      tree.forEach(nodeObj => {
        nodeObj.isSelected = false;
        if (nodeObj && nodeObj.children && nodeObj.children.length > 0) {
          this.deselectRecursively(nodeObj.children);
        }
      });
    },

    // CHECK SAVE BUTTON eligibility, and VALIDITY OF TRIAL CRITERIA, 3 funcitons
    saveButtonShowability: function() {
      if (!this.criteriaInclusionCheck().isValid) {
        return false;
      } else {
        return this.userHasEdited();
      }
    },

    userHasEdited: function() {
      const criteriaInTrialJSONStringified = JSON.stringify(
        this.criteriaInTrial
      );
      return (
        criteriaInTrialJSONStringified !==
        this.criteriaInTrialAfterLoadingCloudJSONStringified
      );
    },

    criteriaInclusionCheck: function() {
      let runningCheck = {
        isValid: true,
        warningMessage: "",
        errorMessage: ""
      };

      if (!this.criteriaInTrial || this.criteriaInTrial.length === 0) {
        runningCheck.isValid = false;
        runningCheck.errorMessage +=
          "Please add a root element, usually the logical connector ALL, onto which you can attach many facts.\n";
      }
      if (this.criteriaInTrial && this.criteriaInTrial.length >= 1) {
        if (this.criteriaInTrial.length > 1) {
          runningCheck.isValid = false;
          runningCheck.errorMessage +=
            "Attach all your elements under just one root element, usually an ALL. Delete the second root element.\n";
        }
        this.criteriaInclusionCheckRecursive(
          runningCheck,
          this.criteriaInTrial
        );
      }
      return runningCheck;
    },

    criteriaInclusionCheckRecursive(runningCheck, tree) {
      if (tree) {
        tree.forEach(nodeObj => {
          // First deal with the 3 types of logical operator
          if (nodeObj.title === "ALL") {
            if (!nodeObj.children || nodeObj.children.length < 1) {
              runningCheck.isValid = false;
              runningCheck.errorMessage +=
                "'ALL' means all its children criteria must be met. Please add children.\n";
            } else if (nodeObj.children.length === 1) {
              runningCheck.warningMessage += "";
              // "ALL means all its children criteria must be met, but there is only one. This is just a note for your interest: it is not an error.\n";
            }
          }
          if (nodeObj.title === "ANY") {
            if (!nodeObj.children || nodeObj.children.length < 1) {
              runningCheck.isValid = false;
              runningCheck.errorMessage +=
                "'ANY' means any one or more of its children criteria must be met. Please add children.\n";
            } else if (nodeObj.children.length === 1) {
              runningCheck.warningMessage += "";
              // "ANY means at least one of its children criteria must be met, but there is only one, so the ANY is a superfluous layer.\n";
            }
          }
          if (nodeObj.title === "NO") {
            if (!nodeObj.children || nodeObj.children.length < 1) {
              runningCheck.isValid = false;
              runningCheck.errorMessage +=
                "'NO' requires one or more children. To use it as 'NOT', add one child. To use it as 'NONE OF', add several children.\n";
            }
          }

          // Then recurse to deeper levels
          if (nodeObj && nodeObj.children && nodeObj.children.length > 0) {
            this.criteriaInclusionCheckRecursive(
              runningCheck,
              nodeObj.children
            );
          }
        });
      }
    },

    ///// SAVE AND LOAD
    onClickSaveTrial() {
      const criteriaCompact = this.makeCriteriaCompactRecursively(
        this.criteriaInTrial
      );
      trialCriterionRootRef
        .child(this.id)
        .set(criteriaCompact)
        .then(() => {
          this.goBack();
        });
    },

    makeCriteriaCompactRecursively(tree) {
      return tree.map(node => this.makeItemCompactRecursively(node));
    },

    makeItemCompactRecursively(node) {
      let compact = {
        ...node,
        ...node.data
      };
      delete compact.isLeaf;
      delete compact.isSelected;
      delete compact.isSelectable;
      delete compact.isDraggable;
      delete compact.data;
      delete compact.dummyVarToCauseRefresh;

      if (node.children && node.children.length > 0) {
        compact.children = this.makeCriteriaCompactRecursively(node.children);
      }

      return compact;
      // this means we can just do ".children" and it will be t r u t h y if there are any, and falsy (undefined) if not
    },

    makeCriteriaExpandRecursively(tree) {
      return tree.map(node => this.makeItemExpandRecursively(node));
    },

    makeItemExpandRecursively(node) {
      let expanded = {
        data: { ...node },
        isLeaf: node.children && node.children.length > 0 ? false : true,
        title: node.title,
        isSelected: false,
        isSelectable: true,
        isDraggable: true
      };

      if (node.children && node.children.length > 0) {
        expanded.children = this.makeCriteriaExpandRecursively(node.children);
      }

      return expanded;
    },

    // NAVIGATION FUNCTIONS =============================
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
/*
There are four areas (one as a passthrough readonly and three part of this live component).

0. The read-only mini-display for EditTrial.vue
1. Top Screen Panel, the tree of "This Trial's Criteria", as they are being put together
2. Middle Screen Panel, the "fact box", showing details of upper/lower limits for continuous variables, or options for categorical. This appears when needed and then disappears once cancelled or Saved.
3. Bottom Screen Panel, the "choose from available criteria"
*/

.screen-panel--top--this-trial-criteria {
  border-radius: 0.5em;
  background: #f0f0f0;
  min-height: 4em;
  max-height: 20em;
  overflow: auto;
}

.screen-panel--middle--fact-box-enter-cutoffs-etc {
  border-radius: 0.5em;
  padding: 0.5em;
  background: lightgray;
  color: black;
}

.screen-panel--bottom--choose-from-available-criteria {
  height: 100%;
  overflow-y: scroll;
  border-color: white;
  border-width: 0.5px;
  border-style: solid;
}

.screen-panel--bottom--choose-from-available-criteria--icon {
  height: 1.5em;
  width: 1.5em;
}

/*

Formatting an individual node, i.e. a line of text in the display of the tree.

*/

.node--tabbing--monospaced-font {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.1;
}

.node--title--is-logical {
  padding-left: 0.2em;
  padding-right: 0.4em;
  border-color: black;
  border-style: solid;
  border-radius: 1em;
}

.node--title--is-selected {
  background-color: #476ef080;
  color: black; /* need this for the lower panel */
}

/*

General stuff for slVueTree component

*/

.tree-container {
  flex-grow: 1;
}

.sl-vue-tree {
  position: relative;
  cursor: default;
  user-select: none;
  line-height: 1;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
  overflow: hidden;
  position: relative;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: orange;
}

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  padding-right: 10px;
  /* line-height: 28px; */
  border: 1px solid transparent;
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid blue;
}

.sl-vue-tree-gap {
  /* size of indent for membership */
  width: 25px;
  min-height: 1px;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree-sidebar {
  margin-left: auto;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid green;
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
  position: absolute;
  background-color: orange;
  opacity: 0.5;
  margin-left: 20px;
  padding: 5px 10px;
}

.highlighted {
  background-color: lightgray;
}
</style>


<template>

  <div class="p-2">

    <app-header-bar title="Classify fact"></app-header-bar>

    <link
      href="https://use.fontawesome.com/releases/v5.0.8/css/all.css"
      rel="stylesheet"
    >

    <!-- {{$router.params.id}} // {{$router.params.shortName}} -->
    <h2>{{shortText || "Test Fact (does not exist)"}}</h2>
    <h4>Where does it belong?</h4>
    <h6>This classification exists only for HQ's convenience when finding facts to use when setting a trial's criteria. Classification can be changed at any time. There is no effect on trials that already use the facts.</h6>
    <div>

      <br>

      <!-- <div class="tree-container">
        <sl-vue-tree
          v-model="factTree"
          ref="slVueTree_factTree"
          @select="dummyFunctionToPreventDefault"
          @nodeclick="dummyFunctionToPreventDefault"
          @nodedblclick="dummyFunctionToPreventDefault"
          @toggle="dummyFunctionToPreventDefault"
          @drop="dummyFunctionToPreventDefault"
          @nodecontextmenu="dummyFunctionToPreventDefault"
          @externaldrop="dummyFunctionToPreventDefault"
          :allow-multiselect="false"
        >
          Need the class "pb-1" to stop a slight vertical wobble, don't know why

          <template
            slot="toggle"
            slot-scope="{ node }"
          >
            <span v-if="!node.isLeaf">
              <i
                v-if="node.isExpanded"
                class="fa fa-chevron-down"
              ></i>
              <i
                v-if="!node.isExpanded"
                class="fa fa-chevron-right"
              ></i>
            </span>
          </template>

          <template
            slot="title"
            slot-scope="{ node }"
          > <span class="node--tabbing--monospaced-font">
              {{tabbing(node,$refs.slVueTree_criteriaInTrial)}}
              <span v-if="!node.isLastChild">├</span>
              <span v-if=" node.isLastChild">╰</span>
            </span>
            <span :class="{'node--title--is-logical':!node.isLeaf,'blinking':node.isSelected,'highlighted':node.isSelected}">
              {{ node.title }}
            </span>

            <span v-if="node.isSelected && (node.isLeaf || node.children.length===0)"> &nbsp; &nbsp; <b-button
                @click=" slVueTree_criteriaInTrial_removeNode(node)"
                variant="danger"
                size="sm"
                style="margin-top:-8px;margin-bottom:-4px; padding:0;"
              >
                &nbsp; &nbsp; Delete &nbsp; &nbsp;
              </b-button>
            </span>
          </template>

        </sl-vue-tree>
      </div> -->

      <div class="tree-container">
        All facts
        <sl-vue-tree
          v-model="factTree"
          ref="slVueTree2"
        >

          <template
            slot="title"
            slot-scope="{node}"
          >
            {{tabbing(node)}} {{tabbing(node)}}
            <!-- <div>{{tabbing(node)}}<strong>{{node.title}}</strong></div> -->

            <span class="item-icon">
              <i
                class="fa fa-folder"
                v-if="!node.isLeaf"
              ></i>
            </span>
            <span
              v-if="node.isLeaf"
              style="display: inline-block; width: 25px;"
            > </span>
            {{ node.title }}
          </template>

          <template
            slot="toggle"
            slot-scope="{ node }"
          >
            <span v-if="!node.isLeaf">
              <i
                v-if="node.isExpanded"
                class="fa fa-chevron-down"
              ></i>
              <i
                v-if="!node.isExpanded"
                class="fa fa-chevron-right"
              ></i>
            </span>
          </template>

          <template
            slot="sidebar"
            slot-scope="{ node }"
          >
            <div
              v-if="node.isSelected"
              class="d-flex flex-row"
            >
              <div style="width:5em;"></div>
              <b-button
                variant="outline-light"
                @click="onPutInThisFolder(node)"
                class="d-flex flex-row"
              >
                <div style="font-size:200%;">
                  &#x2B11;
                </div>
                <div>Click to<br>put here</div>
              </b-button>
            </div>
          </template>
        </sl-vue-tree>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";

const factTreeMedical = [
  {
    title: "Demographics",
    children: [{ title: "Epidemiology" }]
  },
  {
    title: "Cardiology",
    children: [
      { title: "Observations" },
      { title: "Cardiac" },
      { title: "Diagnosis" }
    ]
  },
  {
    title: "Renal",
    children: [{ title: "Biochemistry" }, { title: "Diagnosis" }]
  }
];
function makeSuitable(list) {
  for (const node of list) {
    node.isDraggable = false;
    node.isSelectable = true;
    node.isExpanded = true;
    node.isLeaf = false;

    if (node.children && node.children.length >= 1) {
      makeSuitable(node.children);
    }
  }
}
makeSuitable(factTreeMedical);

import SlVueTree from "sl-vue-tree";
import { tabbing } from "../util/tabbing.js";

export default {
  components: { SlVueTree, AppHeaderBar },

  props: {
    id: String,
    shortText: String,
    path: { type: Array, required: false, default: () => [0] },
    pathRef: Object
  },

  data: function() {
    return {
      tabbing // This just makes the TABBING function we have imported, available as "THIS.TABBING" so the template can refer to it as "TABBING"
    };
  },

  created() {
    this.factTree = JSON.parse(JSON.stringify(factTreeMedical));
  },

  mounted() {
    // expose instance to the global namespace
    window.slVueTree = this.$refs.slVueTree;
  },

  methods: {
    onPutInThisFolder(node) {
      let pathList = [];
      let remainingTree = factTreeMedical;
      node.path.forEach(branchIndex => {
        pathList.push(remainingTree[branchIndex].title);
        remainingTree = remainingTree[branchIndex].children;
      });
      this.pathRef.set(pathList).then(() => this.$router.back());
    }
  }
};
</script>

<style scoped>
.sl-vue-tree {
  position: relative;
  cursor: default;
  user-select: none;
  line-height: 1;
}

/* .sl-vue-tree.sl-vue-tree-root {
  border: 1px solid rgb(9, 22, 29);
  background-color: rgb(9, 22, 29);
  color: rgba(255, 255, 255, 0.5);
} */

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
  overflow: hidden;
  position: relative;
  /* padding-bottom: 4px; */
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: lightgray;
  /* color: white; */
}

/* .sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  color: white;
} */

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  padding-right: 10px;
  /* line-height: 28px; */
  border: 1px solid transparent;
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid blue;
}

.sl-vue-tree-gap {
  /* size of indent for membership */
  width: 25px;
  min-height: 1px;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree-sidebar {
  margin-left: auto;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid green;
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
  position: absolute;
  background-color: orange;
  opacity: 0.5;
  margin-left: 20px;
  padding: 5px 10px;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.contextmenu {
  position: absolute;
  background-color: green;
  color: black;
  border-radius: 2px;
  cursor: pointer;
}

.contextmenu > div {
  padding: 10px;
}

.contextmenu > div:hover {
  background-color: cyan;
}

.last-event {
  color: white;
  background-color: rgba(100, 100, 255, 0.5);
  padding: 10px;
  border-radius: 2px;
}

.tree-container {
  flex-grow: 1;
}

.sl-vue-tree.sl-vue-tree-root {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.json-preview {
  flex-grow: 1;
  margin-left: 10px;
  /* background-color: #13242d; */
  /* border: 1px solid black; */

  padding: 10px;
}

.item-icon {
  display: inline-block;
  text-align: left;
  width: 20px;
}
</style>


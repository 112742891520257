<template >
  <div
    style="height:100vh; overflow-y:hidden;"
    class="mx-2 d-flex flex-column justify-content-center"
  >
    <b-card style="color:black; ">
      <h4>We understand.</h4>
      <h4>Thank you for having considered it.</h4>
      <hr>
      <h4>Please give this device back to the medical team.</h4>
      <div @click="goHome()">
        <b-button
          block
          variant="primary"
        >&nbsp; Home &nbsp;</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  created: function() {
    setTimeout(this.goHome, 30000);
  },

  methods: {
    goHome: function() {
      this.$router.replace({
        name: "home"
      });
    }
  }
};
</script>

<style scoped>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 d-flex flex-column justify-content-between",staticStyle:{"height":"100vh"}},[_c('app-header-bar',{attrs:{"title":"Appoint Managers"}}),_c('section',{staticStyle:{"overflow-y":"auto","flex-grow":"1"},attrs:{"id":"middleScrolling"}},[(
          !_vm.user.privilegesIsLoaded ||
          (_vm.user.privilegesIsLoaded &&
            _vm.user.privilegesObj.managementPathStrings.length > 0 &&
            _vm.myTrees.length === 0)
        )?_c('div',[_c('Spinner')],1):_vm._e(),(
          _vm.user.privilegesIsLoaded && !_vm.user.privilegesObj.managementPathStrings
        )?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('h4',[_vm._v("No management privileges")]),_c('h6',[_vm._v(" Ask your manager to enable your management privileges. If you are the lead research manager for the Trust, ask FoundBy.Me headquarters to do so. ")])])],1):_vm._e(),_c('h4',[_vm._v("Editing your management team")]),_c('p',[_vm._v(" Click on an oval, representing a management level, to give management privileges to other managers. ")]),_vm._l((_vm.myTrees),function(tree,treeKey){return _c('article',{key:treeKey},[_c('div',{staticClass:"p-2 mt-4 bg-white text-primary",staticStyle:{"border-radius":"0.5em 0.5em 0 0"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.deFbmUk(tree.pathStringToMyTree))+" ")])]),_c('sl-vue-tree',{ref:"tree.ref",refInFor:true,scopedSlots:_vm._u([{key:"title",fn:function(ref){
        var node = ref.node;
return [_c('span',{on:{"click":function($event){_vm.onManageManagersNode(
                  node,
                  _vm.pathStringFromPathNumerical(
                    tree.pathNumericalToMyTree.concat(node.path.slice(1))
                  )
                )}}},[_c('span',{staticClass:"node--tabbing--monospaced-font"},[_vm._v(" "+_vm._s(_vm.tabbing(node, tree.ref))+" "),(!node.isLastChild)?_c('span',[_vm._v("├")]):_vm._e(),(node.isLastChild && node.path.length !== 1)?_c('span',[_vm._v("╰")]):_vm._e(),(node.isLastChild && node.path.length === 1)?_c('span'):_vm._e()]),_c('span',{class:{
                  'node--title--is-logical': !node.isLeaf,
                  blinking: node.isSelected,
                  highlighted: node.isSelected,
                }},[_vm._v(_vm._s(node.title))]),_vm._v(" "),_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v(_vm._s(_vm.managerCountString(node.data.n || 0)))])])]}}],null,true),model:{value:(tree.myTreeAsNodeModel),callback:function ($$v) {_vm.$set(tree, "myTreeAsNodeModel", $$v)},expression:"tree.myTreeAsNodeModel"}},[_c('template',{slot:"toggle"},[_vm._v(_vm._s("" /* To get rid of the little "-" / "+" at the left*/))])],2)],1)}),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"globalManagementTree"}},[_vm._v(" THIS NEEDS TO EXIST, but does not need to be displayed "),_c('sl-vue-tree',{ref:"managementSlVueTree",model:{value:(_vm.managementTreeNodeModel),callback:function ($$v) {_vm.managementTreeNodeModel=$$v},expression:"managementTreeNodeModel"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
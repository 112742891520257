<template>
  <div class="p-2 page-scroll-y">

    <app-header-bar title="Manage Access"></app-header-bar>

    <div v-if="myTrialsListIsValid">
      <div class="p-2 my-2">Control who can see NHS Numbers, and who receives notifications and reports
      </div>

      <b-table
        striped
        hover
        :items="trialsIManage"
        :fields="trialPublicFields"
        @row-clicked="onClickOneTrial"
      >

      </b-table>

      <div class="p-2 my-2">To add/edit trials, contact your Account Manager at FoundBy.Me.</div>

    </div>
    <div v-else>
      Finding your trials...
    </div>

  </div>
</template>

<script>
import AppHeaderBar from "./AppHeaderBar.vue";
import { getTrialsManagedByUserIdInData } from "../util/firebase.js";

export default {
  components: { AppHeaderBar },
  props: {
    user: {
      type: Object, // This comes from the APP level.
      required: true
    },
    trials: {
      type: Object, // This comes from the APP level.
      required: true
    }
  },
  data: () => ({
    trialPublicFields: [
      { key: "shortText", label: "Name", sortable: true },
      { key: "diagnosisTop", label: "Main diagnosis", sortable: true }
    ],

    /// VARIABLES

    trialPublicIsLoaded: false,
    trialPublicObj: {},
    trialPublicArray: [],

    trialsIManage: [], // Replaced by the user's available trials to manage
    myTrialsListIsValid: false
  }),

  created: async function() {
    getTrialsManagedByUserIdInData({ targetUserId: this.user.id })
      .then(result => {
        const trialIds = result.data;
        this.trialsIManage = trialIds
          .map(trialId => ({ ...this.trials.publicObj[trialId], id: trialId }))
          .filter(x => x && x.shortText && x.shortText.length > 0);
        this.myTrialsListIsValid = true;
      })
      .catch(e => console.error("Error", e.message));
  },

  beforeDestroy: function() {},

  methods: {
    onClickOneTrial: function(trialObj /* , index */) {
      this.$router.push({
        name: "manageTrialsOne",
        params: { id: trialObj.id }
      });
    },

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
</style>
